import { Box, Typography } from "@mui/material"
import { CreateProductForm } from "../../../component/product/CreateProductForm"

export const CreateProductScreen = () => {
    return (
        <Box p={2}>
            <Typography variant="h5" p={2}>เพิ่มสินค้า</Typography>
            <CreateProductForm />
        </Box>
    )
}
