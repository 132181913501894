import { MobileStepper, Stack } from "@mui/material";
import { InstallmentPlanDto } from "../../../client/motalvip-apis/generated";
import { LoanStatusChip } from "../LoanStatusChip";
import { LoanRegistrationStatusChip } from "./LoanRegistrationStatusChip";

type LoanRegistrationStatusMiniDisplayProps = {
  data?: InstallmentPlanDto;
  isShopFinancier?: boolean;
};

export const LoanRegistrationStatusMiniDisplay: React.FC<
  LoanRegistrationStatusMiniDisplayProps
> = ({ data, isShopFinancier }) => {
  const activeStep =
    data?.registrationStatus ===
    InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_INFO
      ? 0
      : data?.registrationStatus ===
        InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_DOCUMENTS
      ? 1
      : data?.registrationStatus ===
        InstallmentPlanDto.registrationStatus.PENDING_LOAN_AND_DEVICE_INFO
      ? 2
      : data?.registrationStatus ===
        InstallmentPlanDto.registrationStatus.PENDING_APPROVAL
      ? 3
      : data?.registrationStatus ===
          InstallmentPlanDto.registrationStatus.REJECTED &&
        data.status === InstallmentPlanDto.status.DRAFT
      ? 3
      : data?.registrationStatus ===
          InstallmentPlanDto.registrationStatus.REJECTED &&
        data.status === InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS
      ? 4
      : data?.registrationStatus ===
          InstallmentPlanDto.registrationStatus.APPROVED &&
        data.status === InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS
      ? 4
      : data?.registrationStatus ===
          InstallmentPlanDto.registrationStatus.APPROVED &&
        data.status === InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT
      ? 5
      : 6;
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ backgroundColor: "transparent" }}
    >
      {(
        isShopFinancier ? activeStep > 3 && activeStep !== 6 : activeStep > 3
      ) ? (
        <LoanStatusChip status={data?.status} />
      ) : (
        <LoanRegistrationStatusChip status={data?.registrationStatus} />
      )}
      <MobileStepper
        sx={{ backgroundColor: "transparent" }}
        variant="dots"
        steps={7}
        position="static"
        activeStep={activeStep}
        nextButton={<></>}
        backButton={<></>}
      />
    </Stack>
  );
};
