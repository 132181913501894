import { Box, Typography } from '@mui/material'

interface IProps {
    label: string
    value: string | undefined
}

const InfoItem: React.FC<IProps> = (props): JSX.Element => {
    return (
        <Box>
            <Typography variant="subtitle2" gutterBottom>
                {props.label}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{ whiteSpace: 'pre-line' }}
            >
                {props.value || '-'}
            </Typography>
        </Box>
    )
}

export default InfoItem
