import { Box, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useCategoriesSearchQuery } from "../../client/query-client/useCategoryQuery"
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import { CategoryResponse } from "../../client/motalvip-apis/generated";
import { useQueryState } from "nuqs";

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 10

interface Props {
    onOpenDeleteDialog: (category: CategoryResponse) => void
    onOpenEditDialog: (category: CategoryResponse) => void
}

export const CategoryList = ({ onOpenDeleteDialog, onOpenEditDialog }: Props) => {
    const { shopId } = useParams()
    // query
    const [page, setPage] = useQueryState<number>("page", {
        defaultValue: DEFAULT_PAGE,
        parse: (v) => Number(v) || DEFAULT_PAGE,
    })
    const [rowsPerPage, setRowsPerPage] = useQueryState<number>("limit", {
        defaultValue: DEFAULT_LIMIT,
        parse: (v) => Number(v) || DEFAULT_LIMIT,
    })
    const [search, setSearch] = useQueryState("search")

    const { data: categoriesData, isLoading, refetch: refetchProduct } = useCategoriesSearchQuery({
        shopId: shopId!,
        search: search!,
        limit: rowsPerPage,
        page,
    })

    const handleChangePage = async (_event: unknown, newPage: number) => {
        await setPage(newPage + 1)
        refetchProduct()
    }

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        await setRowsPerPage(parseInt(event.target.value, 10));
        await setPage(1)
        refetchProduct()
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const handleSearchSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        refetchProduct()
    }

    if (isLoading) {
        return <Box p={2}>Loading...</Box>
    }

    return (
        <Box p={2} my={2} >
            <Stack
                sx={{
                    width: "100%",
                    marginBottom: 4,
                }}
                gap="12px"
                direction={{ sm: 'column', xs: 'column', md: 'row' }}
            >
                <form
                    style={{
                        display: "flex",
                        gap: "16px",
                        width: "100%",
                    }}
                    onSubmit={handleSearchSubmit}
                >
                    <TextField
                        sx={{
                            margin: 0,
                            padding: 0,
                        }}
                        label="ค้นหาด้วยชื่อหมวดหมู่สินค้า"
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <IconButton
                        sx={{
                            width: '56px'
                        }}
                        type="submit"
                    >
                        <SearchIcon />
                    </IconButton>
                </form>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="product list table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ชื่อหมวดหมู่สินค้า</TableCell>
                            <TableCell align='center'>วันที่สร้าง</TableCell>
                            <TableCell align='center'>แก้ไขหมวดหมู่สินค้า</TableCell>
                            <TableCell align='center'>ลบหมวดหมู่สินค้า</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(categoriesData?.content && categoriesData.content.length > 0) ? (
                            categoriesData!.content!.map(category => {
                                // const isMobile = product.type === ProductType.MOBILE

                                return (
                                    <TableRow key={category.id}>
                                        <TableCell>{category.name}</TableCell>
                                        <TableCell align='center'>
                                            {dayjs(category.createdAt).format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <IconButton
                                                onClick={() => {
                                                    onOpenEditDialog(category)
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <IconButton
                                                onClick={() => {
                                                    onOpenDeleteDialog(category)
                                                }}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Box sx={{ textAlign: "center", p: 3 }}>
                                        <Typography variant="h6">ไม่พบหมวดหมู่สินค้า</Typography>
                                        <Typography variant="body2">
                                            กรุณาลองค้นหาอีกครั้ง หรือตรวจสอบเงื่อนไขการค้นหา
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={categoriesData?.pagination?.totalElements ?? 0}
                page={page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box >
    )
}