import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { QueryKey } from "..";
import { useBackendApiClient } from "../../motalvip-apis";
import { ShopLoanCalculationSettings } from "../../motalvip-apis/generated";

export const useGetShopLoanCalculationSettings = ({
  shopId,
  type,
}: {
  shopId: string;
  type: ShopLoanCalculationSettings.type;
}) => {
  const apiClient = useBackendApiClient();
  const { data: rawData, ...rest } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [
      QueryKey.SHOP_SETTINGS,
      "getShopLoanCalculationSettings",
      shopId,
      type,
    ],
    queryFn: () =>
      apiClient.public.getShopLoanCalculationSettings({
        shopId,
        type,
      }),
  });
  const mappedData: {
    data: {
      downPercentage: number[];
      row: number;
      month: number;
      profitFactor: number;
      enabled: boolean;
    }[];
    roundNumbers: boolean;
  } = useMemo(() => {
    if (!rawData)
      return {
        data: [],
        roundNumbers: false,
      };
    const value = JSON.parse(rawData.jsonValue) as Array<{
      downPercentage: number[];
      month: number;
      profitFactor: number;
      enabled: boolean;
    }>;
    if (Object.values(rawData).some((v) => v == null)) {
      console.error("Malformed settings", rawData);
      return {
        data: [],
        roundNumbers: false,
      };
    }

    const withRow = value.map((v, index) => {
      return {
        row: index + 1,
        downPercentage: v.downPercentage,
        month: v.month,
        profitFactor: v.profitFactor,
        enabled: v.enabled,
      };
    });

    const sorted = withRow.map((r) => {
      return {
        ...r,
        downPercentage: r.downPercentage.sort((a, b) => a - b),
      };
    });

    return {
      data: sorted,
      roundNumbers: rawData.roundNumbers,
    };
  }, [rawData]);

  return {
    ...rest,
    data: mappedData,
    rawData,
  };
};
