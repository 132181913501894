import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowRightIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useQueryState } from "nuqs";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  InstallmentPaymentReportDto,
  ShopPaymentChannelDto,
} from "../../../../client/motalvip-apis/generated";
import { useGenerateInstallmentPaymentCsvMutation } from "../../../../client/query-client/useGenerateInstallmentPaymentCsvReportMutation";
import { useViewInstallmentPaymentsReport } from "../../../../client/query-client/useViewInstallmentPaymentsReport";
import { useViewInstallmentPaymentSumReport } from "../../../../client/query-client/useViewInstallmentPaymentSumReport";
import { formatShortReadableThaiDate } from "../../../../util/DateUtil";
import { downloadFile } from "../../../../util/FileUtils";
import { useDebouncedValue } from "../../../../util/useDebouncedValue";
import { InstallmentPaymentTypeChip } from "./InstallmentPaymentTypeChip";
import { installmentPaymentTypeToTextMap } from "./mappers/installmentPaymentTypeToTextMap";
import { mapChannelToText } from "./mappers/mapChannelToText";
import { mapStatusToText } from "./mappers/mapStatusToText";
import { PaymentChannelInfo } from "./PaymentChannelInfo";
import SummaryCard from "./SummaryCard";

export const StatementsScreen = () => {
  const { shopId } = useParams();
  const [page, setPage] = useQueryState<number>("page", {
    defaultValue: 0,
    parse: (v) => Number(v) || 0,
  });
  const [rowsPerPage, setRowsPerPage] = useQueryState<number>("20", {
    defaultValue: 20,
    parse: (v) => Number(v) || 20,
  });
  const [search, setSearch] = useQueryState("");
  const debouncedSearch = useDebouncedValue(search ?? "", 250);
  const [sortBy, setSortBy] = useQueryState<keyof InstallmentPaymentReportDto>(
    "sortBy",
    {
      defaultValue: "transactionAt",
      parse: (v) => v as keyof InstallmentPaymentReportDto,
    }
  );
  const [sortDirection, setSortDirection] = useQueryState<"asc" | "desc">(
    "sortDirection",
    {
      defaultValue: "desc",
      parse: (v) =>
        (["asc", "desc"].includes(v) ? v : "desc") as "asc" | "desc",
    }
  );
  const isDateValid = (date: string) => {
    return new Date(date).toString() !== "Invalid Date";
  };
  const [startDate, setStartDate] = useQueryState<Dayjs | null>(
    "startDate",
    useMemo(
      () => ({
        defaultValue: dayjs(new Date()).startOf("day"),
        parse: (v) => {
          if (isDateValid(v)) {
            return dayjs(v);
          }
          return null;
        },
      }),
      []
    )
  );
  const [endDate, setEndDate] = useQueryState<Dayjs | null>(
    "endDate",
    useMemo(
      () => ({
        defaultValue: dayjs(new Date()).endOf("day"),
        parse: (v) => {
          if (isDateValid(v)) {
            return dayjs(v);
          }
          return null;
        },
      }),
      []
    )
  );
  const [status, setStatus] = useQueryState<
    InstallmentPaymentReportDto.status | "all"
  >("status", {
    defaultValue: "all",
    parse: (v) => v as InstallmentPaymentReportDto.status | "all",
  });
  const [installmentType, setInstallmentType] = useQueryState<
    InstallmentPaymentReportDto.type | "all"
  >("installmentType", {
    defaultValue: "all",
    parse: (v) => v as InstallmentPaymentReportDto.type | "all",
  });
  const [shopPaymentChannelType, setShopPaymentChannelType] = useQueryState<
    InstallmentPaymentReportDto.shopPaymentChannel | "all"
  >("paymentChannelType", {
    defaultValue: "all",
    parse: (v) => v as InstallmentPaymentReportDto.shopPaymentChannel | "all",
  });

  const parameters = useMemo(() => {
    return {
      shopId: shopId ?? "",
      type: installmentType === "all" ? undefined : installmentType,
      status: status === "all" ? undefined : status,
      from: startDate?.toISOString() ?? "",
      to: endDate?.toISOString() ?? "",
      direction: sortDirection?.toUpperCase(),
      shopPaymentChannelType:
        shopPaymentChannelType === "all" ? undefined : shopPaymentChannelType,
      page,
      search: debouncedSearch,
      limit: Number(rowsPerPage) || 20,
      sortBy,
    } satisfies Parameters<typeof useViewInstallmentPaymentsReport>[0];
  }, [
    debouncedSearch,
    endDate,
    installmentType,
    page,
    rowsPerPage,
    shopId,
    shopPaymentChannelType,
    sortBy,
    sortDirection,
    startDate,
    status,
  ]);

  const { data } = useViewInstallmentPaymentsReport(parameters);

  const { data: summary } = useViewInstallmentPaymentSumReport(parameters);

  const { mutateAsync: generateCsvReportAsync } =
    useGenerateInstallmentPaymentCsvMutation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property: keyof InstallmentPaymentReportDto) => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

  const handleGenerateCsv = async () => {
    setIsGeneratingCsv(true);
    try {
      const result = await generateCsvReportAsync(parameters);

      downloadFile(result.csvReportUrl, "payment-report.csv");
    } finally {
      setIsGeneratingCsv(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        sx={{
          gap: "16px",
          alignItems: "Center",
          flexDirection: { xs: "column", md: "row" },
        }}
        mb={2}
        mt={2}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            width: "100%",
          }}
        >
          <TextField
            sx={{
              margin: 0,
              padding: 0,
            }}
            label="ค้นหาชื่อลูกค้า ชื่อผู้รับ หรือหมายเลขเคส"
            value={search}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
          <DatePicker
            sx={{
              width: { xs: "100%", md: "fit-content" },
            }}
            label="วันที่เริ่มต้น"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
          <DatePicker
            sx={{
              width: { xs: "100%", md: "fit-content" },
            }}
            label="วันที่สิ้นสุด"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          maxWidth: "600px",
          width: "100%",
          gap: 2,
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            sx={{
              flex: 1,
            }}
            value={status}
            onChange={(v) =>
              setStatus(
                v.target.value as InstallmentPaymentReportDto.status | "all"
              )
            }
          >
            <MenuItem value={"all"}>ทั้งหมด</MenuItem>
            {Object.values(InstallmentPaymentReportDto.status).map((status) => (
              <MenuItem value={status} key={status}>
                {mapStatusToText(status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="channel-label">ช่องทางชำระเงิน</InputLabel>
          <Select
            labelId="channel-label"
            id="channel-select"
            sx={{
              flex: 1,
            }}
            value={shopPaymentChannelType}
            onChange={(v) =>
              setShopPaymentChannelType(
                v.target.value as
                  | InstallmentPaymentReportDto.shopPaymentChannel
                  | "all"
              )
            }
          >
            <MenuItem value={"all"}>ทั้งหมด</MenuItem>
            {Object.values(InstallmentPaymentReportDto.shopPaymentChannel).map(
              (channel) => (
                <MenuItem value={channel} key={channel}>
                  {mapChannelToText(channel)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="type-label">ประเภท</InputLabel>
          <Select
            labelId="type-label"
            id="type-label-select"
            sx={{
              flex: 1,
            }}
            value={installmentType}
            onChange={(v) =>
              setInstallmentType(
                v.target.value as InstallmentPaymentReportDto.type | "all"
              )
            }
          >
            <MenuItem value={"all"}>ทั้งหมด</MenuItem>
            {Object.values(InstallmentPaymentReportDto.type).map(
              (type) =>
                installmentPaymentTypeToTextMap[type] && (
                  <MenuItem value={type} key={type}>
                    {installmentPaymentTypeToTextMap[type]}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </Box>

      <Button
        variant="contained"
        color="primary"
        disabled={
          (data?.paymentReport?.pagination?.totalElements ?? 0) === 0 ||
          isGeneratingCsv
        }
        onClick={handleGenerateCsv}
        sx={{ mb: 2 }}
      >
        {isGeneratingCsv ? "กำลังดาวน์โหลด..." : "ดาวน์โหลดรายงาน Excel"}
      </Button>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: "16px",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        {(summary?.length ?? 0) > 0 &&
          summary?.map((s, i) => <SummaryCard key={i} report={s} />)}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={
                  sortBy === "loanReferenceId" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "loanReferenceId"}
                  direction={
                    sortBy === "loanReferenceId" ? sortDirection : "asc"
                  }
                  onClick={() => handleSort("loanReferenceId")}
                >
                  หมายเลขเคส
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "customerName" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "customerName"}
                  direction={sortBy === "customerName" ? sortDirection : "asc"}
                  onClick={() => handleSort("customerName")}
                >
                  ชื่อลูกค้า
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "installmentTermNumber" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "installmentTermNumber"}
                  direction={
                    sortBy === "installmentTermNumber" ? sortDirection : "asc"
                  }
                  onClick={() => handleSort("installmentTermNumber")}
                >
                  งวดที่
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "transactionAt" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "transactionAt"}
                  direction={sortBy === "transactionAt" ? sortDirection : "asc"}
                  onClick={() => handleSort("transactionAt")}
                >
                  วันที่ดำเนินการ
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "paidDueAmount" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "paidDueAmount"}
                  direction={sortBy === "paidDueAmount" ? sortDirection : "asc"}
                  onClick={() => handleSort("paidDueAmount")}
                >
                  จำนวนที่จ่าย
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "paidFineAmount" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "paidFineAmount"}
                  direction={
                    sortBy === "paidFineAmount" ? sortDirection : "asc"
                  }
                  onClick={() => handleSort("paidFineAmount")}
                >
                  ค่าปรับ
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "shopPaymentChannel" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "shopPaymentChannel"}
                  direction={
                    sortBy === "shopPaymentChannel" ? sortDirection : "asc"
                  }
                  onClick={() => handleSort("shopPaymentChannel")}
                >
                  ช่องทางชำระเงิน
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "createdByName" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "createdByName"}
                  direction={sortBy === "createdByName" ? sortDirection : "asc"}
                  onClick={() => handleSort("createdByName")}
                >
                  รับโดย
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "type" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "type"}
                  direction={sortBy === "type" ? sortDirection : "asc"}
                  onClick={() => handleSort("type")}
                >
                  ประเภท
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "status" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sortBy === "status" ? sortDirection : "asc"}
                  onClick={() => handleSort("status")}
                >
                  สถานะ
                </TableSortLabel>
              </TableCell>
              <TableCell>ดูข้อมูล</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.paymentReport?.content?.length ?? 0) > 0 ? (
              data!.paymentReport!.content!.map(
                (payment: InstallmentPaymentReportDto) => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.loanReferenceId ?? "-"}</TableCell>
                    <TableCell>
                      {payment.customerName + " " + payment.customerLastName}
                    </TableCell>
                    <TableCell>{payment.installmentTermNumber}</TableCell>
                    <TableCell>
                      {formatShortReadableThaiDate(
                        new Date(payment.transactionAt),
                        payment.shopPaymentChannel ===
                          InstallmentPaymentReportDto.shopPaymentChannel
                            .AUTOMATIC_QR
                      )}
                    </TableCell>
                    <TableCell>
                      {Number(
                        payment.paidDueAmount?.toFixed(2) ?? 0
                      ).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {Number(
                        payment.paidFineAmount?.toFixed(2) ?? 0
                      ).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <PaymentChannelInfo
                        channel={
                          payment.shopPaymentChannel as unknown as ShopPaymentChannelDto.type
                        }
                        accountNumber={payment.bankAccountNumber}
                        bankCode={payment.bankCode}
                      />
                    </TableCell>
                    <TableCell>
                      {payment.createdByName
                        ? payment.createdByName +
                          " " +
                          (payment.createdByLastName ?? "")
                        : "ไม่ได้กำหนด"}
                    </TableCell>
                    <TableCell>
                      <InstallmentPaymentTypeChip paymentType={payment.type} />
                    </TableCell>
                    <TableCell>
                      <Chip label={mapStatusToText(payment.status)} />
                    </TableCell>
                    <TableCell>
                      <a href={`/${shopId}/loan/${payment.loanId}`}>
                        <IconButton
                          sx={{
                            color: "black",
                          }}
                        >
                          <ArrowRightIcon />
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                )
              )
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box sx={{ textAlign: "center", p: 3 }}>
                    <Typography variant="h6">ไม่พบรายการจ่ายเงิน</Typography>
                    <Typography variant="body2">
                      กรุณาลองค้นหาอีกครั้ง หรือตรวจสอบเงื่อนไขการค้นหา
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data?.paymentReport?.pagination?.totalElements ?? 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
