/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDeviceManagementApiKeyRequest } from '../models/CreateDeviceManagementApiKeyRequest';
import type { PaginatedListDevicesResponse } from '../models/PaginatedListDevicesResponse';
import type { ViewMaskedDeviceManagementApiKeyResponse } from '../models/ViewMaskedDeviceManagementApiKeyResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DeviceManagementService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ViewMaskedDeviceManagementApiKeyResponse OK
     * @throws ApiError
     */
    public viewMaskedDeviceManagementApiKey({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ViewMaskedDeviceManagementApiKeyResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/device-management/api-key',
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public createDeviceManagementApiKey({
        requestBody,
    }: {
        requestBody: CreateDeviceManagementApiKeyRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/device-management/api-key',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteDeviceManagementApiKey({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/device-management/api-key',
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedListDevicesResponse OK
     * @throws ApiError
     */
    public listDevices({
        page,
        shopId,
        search,
    }: {
        page: number,
        shopId: string,
        search?: string,
    }): CancelablePromise<PaginatedListDevicesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/device-management',
            query: {
                'page': page,
                'shopId': shopId,
                'search': search,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
