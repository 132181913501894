import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ColumnDef, MRT_SortingState, useMaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { DownSavingTransactionResponse } from "../../client/motalvip-apis/generated";
import { useShopContext } from "../../context/ShopContextProvider";
import PaymentChannelRecord from "../PaymentChannelRecord";


type Props = {
    data: Array<DownSavingTransactionResponse> | undefined
}

export const DownSavingTransactionTable = (props: Props) => {
    const shopCtx = useShopContext();
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'sequenceNumber', desc: false }]);
    const columns = useMemo<MRT_ColumnDef<DownSavingTransactionResponse>[]>(
        () => [
            {
                id: 'date',
                accessorFn: (row: DownSavingTransactionResponse) => {
                    return <Typography>{dayjs(row.transactionAt).format('DD/MM/YYYY')}</Typography>
                }, //simple recommended way to define a column
                header: 'วันที่',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box width={100}>
                        <Typography noWrap >
                            {renderedCellValue}
                        </Typography>
                    </Box>
                ),
            },
            {
                id: 'direction',
                accessorFn: (row: DownSavingTransactionResponse) => {
                    return <Typography>{row.direction === DownSavingTransactionResponse.direction.DEPOSIT ? 'ฝาก' : 'ถอน'}</Typography>
                }, //simple recommended way to define a column
                header: 'ประเภท',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                Cell: ({ renderedCellValue, row }) => (
                    <Box >
                        <Typography noWrap >
                            {renderedCellValue}
                        </Typography>
                    </Box>
                ),
            },
            {
                id: 'amount',
                accessorFn: (row: DownSavingTransactionResponse) => <Typography >{currencyFormat.format(row.amount || 0)}</Typography>,
                header: 'จำนวนเงิน',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'shopPaymentChannelId',
                accessorFn: (row: DownSavingTransactionResponse) => {
                    const channel = shopCtx.paymentChannels?.find(ch => ch.id === row.shopPaymentChannelId)
                    if (channel) {
                        return <PaymentChannelRecord size="small" paymentChannel={channel} hideIcon />
                    } else {
                        return <Typography>ไม่ระบุ</Typography>
                    }
                },
                header: 'ช่องทาง',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            }
        ],
        [currencyFormat, shopCtx.paymentChannels],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: props.data || [],
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        onSortingChange: setSorting,
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        state: {
            sorting
        },
        enableRowActions: false,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500'
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },
    });

    return (
        <>
            {props.data && props.data.length > 0 ?
                <MaterialReactTable table={table} />
                :
                <Stack width='100%' my={4} justifyContent='center' alignItems='center'>
                    <Typography>ไม่มีข้อมูล</Typography>
                </Stack>
            }
        </>
    )
}