import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "..";
import { useBackendApiClient } from "../../motalvip-apis";
import { ShopLoanCalculationSettings } from "../../motalvip-apis/generated";

export const useUpdatShopLoanCalculationSettings = ({
  onError,
  onSuccess,
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) => {
  const queryClient = useQueryClient();
  const backendApiClient = useBackendApiClient();
  return useMutation({
    onError,
    mutationFn: (body: ShopLoanCalculationSettings) =>
      backendApiClient.shopSettings.updateShopLoanCalculationSettings({
        requestBody: body,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.SHOP_SETTINGS] });
      onSuccess?.();
    },
  });
};
