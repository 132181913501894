/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DownSavingTransactionCreateRequest = {
    downSavingId: string;
    transactionAt: string;
    shopId: string;
    amount: number;
    fineAmount?: number;
    direction: DownSavingTransactionCreateRequest.direction;
    shopPaymentChannelId: string;
};
export namespace DownSavingTransactionCreateRequest {
    export enum direction {
        DEPOSIT = 'DEPOSIT',
        WITHDRAW = 'WITHDRAW',
    }
}

