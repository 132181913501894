import { useMutation } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";

export const useGenerateInstallmentPlansCsvMutation = (shopId: string) => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [shopId],
    mutationFn: () =>
      apiClient.installmentPlan.generateCsvInstallmentPlansReport({ shopId }),
    retry: 0,
  });
};
