import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useBackendApiClient } from "../../motalvip-apis";
import {
  ApiError,
  BusinessExceptionResponse,
} from "../../motalvip-apis/generated";

export const useCreateDeviceManagementApiKey = () => {
  const backendClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deviceManagementApiKey-create"],
    mutationFn: (
      args: Parameters<
        typeof backendClient.deviceManagement.createDeviceManagementApiKey
      >[0]
    ) => {
      return backendClient.deviceManagement.createDeviceManagementApiKey(args);
    },
    onSuccess: () => {
      toast.success("สร้าง API key สำเร็จ");
      queryClient.invalidateQueries({
        queryKey: ["deviceManagement"],
      });
    },
    onError: (error) => {
      if (
        error instanceof ApiError &&
        error.body.code ===
          BusinessExceptionResponse.code.NUOVOPAY_API_KEY_INVALID
      ) {
        toast.error("API key ไม่ถูกต้อง");
        return;
      }

      toast.error("เกิดข้อผิดพลาดในการสร้าง API key");
    },
    retry: 0,
  });
};
