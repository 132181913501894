import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useBackendApiClient } from "../../../client/motalvip-apis";
import {
  InstallmentPlanDto,
  ProductDto,
  StepUpdateLoanDraftBack,
  StepUpdateLoanDraftConfirmDownPaymentRequest,
  StepUpdateLoanDraftContractDocumentRequest,
  StepUpdateLoanDraftCustomerDocumentRequest,
  StepUpdateLoanDraftCustomerInfoRequest,
  StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
} from "../../../client/motalvip-apis/generated";
import { QueryKey } from "../../../client/query-client";
import { validateThaiId } from "../../../util/thai-id-validator";

type LoanDetailScreenProps = {};

const CustomInputLabel = styled(InputLabel)({
  position: "relative",
  transform: "none",
  marginBottom: "5px",
});

export const LoanCreateScreen = (props: LoanDetailScreenProps) => {
  const queryClient = useQueryClient();

  const theme = useTheme();
  const motalClient2 = useBackendApiClient();
  const { shopId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const loanStep0Form = useForm<StepUpdateLoanDraftCustomerInfoRequest>({
    defaultValues: {
      loanId: id!,
      referenceId: "",
      shopId: shopId!,
      firstName: "",
      lastName: "",
      citizenId: "",
      passportNumber: "",
      currentAddress: "",
      citizenAddress: "",
      occupation: "",
      workPlace: "",
      email: "",
      lineId: "",
      facebookUrl: "",
    },
  });

  const loanStep2Form =
    useForm<StepUpdateLoanDraftDeviceAndLoanApprovalRequest>({
      defaultValues: {
        loanId: id!,
        shopId: shopId!,
        // referenceId: '',
        installmentPlanFundSource:
          StepUpdateLoanDraftDeviceAndLoanApprovalRequest
            .installmentPlanFundSource.SHOP,
        downPaymentAmount: 0,
        installmentAmount: 0,
        installmentCount: 0,
        downPaymentDate: dayjs().format("YYYY-MM-DD"),
        installmentStartDate: dayjs().format("YYYY-MM-DD"),
        installmentFrequency: 1,
        installmentFrequencyType:
          StepUpdateLoanDraftDeviceAndLoanApprovalRequest
            .installmentFrequencyType.MONTH,
        // usedType: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType.USED,
        usedType: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType.NEW,
        brand: "",
      },
    });
  const loanStepDownPaymentForm =
    useForm<StepUpdateLoanDraftConfirmDownPaymentRequest>({
      defaultValues: {
        loanId: id!,
        // shopId: shopId!,
        // customerCitizenId: "",
        // customerPassportNumber: "",
        note: "",
        // shopPaymentChannelId: '',
        // slipObjectKey: "",
        // transactionAt: dayjs().format("YYYY-MM-DDT00:00:00.000+07:00"),
        // amount: 0,
      },
    });
  // const form = useForm<LoanConfirmCreateRequest>({
  //     defaultValues: {
  //         commissionAmount: 0,
  //         downPaymentDate: dayjs().format("YYYY-MM-DD"),
  //         installmentAmount: 0,
  //         installmentCount: 0,
  //         installmentFrequency: 0,
  //         installmentFrequencyType: LoanConfirmCreateRequest.installmentFrequencyType.MONTH,
  //         installmentStartDate: dayjs().format("YYYY-MM-DD"),
  //         loanFundSource: LoanConfirmCreateRequest.loanFundSource.SHOP,
  //         customer: { phoneNumbers: [{ phoneNumber: '', description: '' }] },
  //         // status: LoanConfirmCreateRequest.status.NORMAL,
  //         // transactionDate: dayjs().format("YYYY-MM-DD"),
  //         shopId: shopId,

  //         device: {
  //             // usedStatus: Device.usedStatus.NEW
  //             usedType: DeviceCreateRequest.usedType.NEW
  //         },
  //         // activeTermNumber: loanQuery.data?.activeTermNumber && 1,
  //         downPaymentAmount: 0
  //     },
  //     mode: 'all'
  // });
  const loanQuery = useQuery({
    queryKey: [QueryKey.LOAN, id],
    queryFn: () =>
      motalClient2.installmentPlan.getInstallmentPlanById({ loanId: id! }),
    enabled: !!id,
    // select(data) {

    //     return data
    // }
  });
  useEffect(() => {
    if (loanQuery.data) {
      switch (loanQuery.data?.registrationStatus) {
        case InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_INFO:
          setActiveStep(0);
          break;
        case InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_DOCUMENTS:
          setActiveStep(1);
          break;
        case InstallmentPlanDto.registrationStatus.PENDING_LOAN_AND_DEVICE_INFO:
          setActiveStep(2);
          break;
        case InstallmentPlanDto.registrationStatus.PENDING_APPROVAL:
          setActiveStep(3);
          break;
        case InstallmentPlanDto.registrationStatus.REJECTED:
          setActiveStep(3);
          break;
        case InstallmentPlanDto.registrationStatus.APPROVED:
          switch (loanQuery.data?.status) {
            case InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS:
              setActiveStep(4);
              break;
            case InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT:
              setActiveStep(5);
              break;

            default:
              setActiveStep(6);
              break;
          }
          break;
      }
    }
  }, [loanQuery.data]);
  useEffect(() => {
    if (loanQuery.data && loanStep0Form) {
      loanStep0Form.reset({
        firstName: loanQuery.data.profile?.firstName,
        lastName: loanQuery.data.profile?.lastName,
        citizenId: loanQuery.data.profile?.citizenId,
        passportNumber: loanQuery.data.profile?.passportNumber,
        currentAddress: loanQuery.data.profile?.currentAddress,
        citizenAddress: loanQuery.data.profile?.citizenAddress,
        occupation: loanQuery.data.profile?.occupation,
        workPlace: loanQuery.data.profile?.workPlace,
        email: loanQuery.data.profile?.email,
        lineId: loanQuery.data.profile?.lineId,
        facebookUrl: loanQuery.data.profile?.facebookUrl,
        phoneNumber: loanQuery.data.profile?.phoneNumbers?.[0]?.phoneNumber,
        referenceId: loanQuery.data?.referenceId,
      });
    }
  }, [loanQuery.data, loanStep0Form]);
  useEffect(() => {
    if (loanQuery.data && loanStep2Form) {
      loanStep2Form.reset({
        brand: loanQuery.data.product?.brand,
        color: loanQuery.data.product?.color,
        usedType: loanQuery.data.product?.usedType || ProductDto.usedType.NEW,
        // referenceId: loanQuery.data.loan?.referenceId,
        description: loanQuery.data.product?.description,
        downPaymentAmount: loanQuery.data?.downPaymentAmount,
        installmentAmount: loanQuery.data?.installmentAmount,
        installmentCount: loanQuery.data?.installmentCount,
        downPaymentDate: loanQuery.data?.downPaymentDate,
        installmentStartDate: loanQuery.data?.installmentStartDate,
        installmentFrequency: loanQuery.data?.installmentFrequency || 1,
        installmentPlanFundSource:
          (loanQuery.data
            ?.fundSource as unknown as StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentPlanFundSource) ||
          StepUpdateLoanDraftDeviceAndLoanApprovalRequest
            .installmentPlanFundSource.SHOP,
        // loanFundSource: loanQuery.data?.loanFundSource || Loan.loanFundSource.SHOP,
        deviceImei: loanQuery.data.product?.imei,
        model: loanQuery.data.product?.model,
        deviceSerialNumber: loanQuery.data.product?.serialNumber,
        installmentFrequencyType:
          loanQuery.data?.installmentFrequencyType ||
          StepUpdateLoanDraftDeviceAndLoanApprovalRequest
            .installmentFrequencyType.MONTH,
        // loanId: id!,
        // shopId: shopId!
      });
    }
  }, [loanQuery.data, loanStep2Form]);

  const usePendingCustomerInfoMutation = useMutation({
    mutationFn: (req: StepUpdateLoanDraftCustomerInfoRequest) =>
      motalClient2.installmentPlan.stepUpdateLoanDraftCustomerInfo({
        requestBody: req,
      }),
    onSuccess: (data) => {
      toast.success("บันทึกข้อมูลสำเร็จ");
    },
    onError: (error) => {
      toast.error("เกิดข้อผิดพลาด");
    },
  });
  const usePendingLoanDocumentMutation = useMutation({
    mutationFn: (req: StepUpdateLoanDraftCustomerDocumentRequest) =>
      motalClient2.installmentPlan.stepUpdateLoanDraftCustomerDocument({
        requestBody: req,
      }),
    onSuccess: (data) => {
      toast.success("บันทึกข้อมูลสำเร็จ");
    },
    onError: (error) => {
      toast.error("เกิดข้อผิดพลาด");
    },
  });
  const usePendingLoanContractDocumentMutation = useMutation({
    mutationFn: (req: StepUpdateLoanDraftContractDocumentRequest) =>
      motalClient2.installmentPlan.stepUpdateLoanDraftContractDocument({
        requestBody: req,
      }),
    onSuccess: (data) => {
      toast.success("บันทึกข้อมูลสำเร็จ");
    },
    onError: (error) => {
      toast.error("เกิดข้อผิดพลาด");
    },
  });
  const useStepUpdateLoanDraftDeviceAndLoan = useMutation({
    mutationFn: (req: StepUpdateLoanDraftDeviceAndLoanApprovalRequest) =>
      motalClient2.installmentPlan.stepUpdateLoanDraftDeviceAndLoan({
        requestBody: req,
      }),
    onSuccess: (data) => {
      toast.success("บันทึกข้อมูลสำเร็จ");
    },
    onError: (error) => {
      toast.error("เกิดข้อผิดพลาด");
    },
  });
  const useBackButtonMutation = useMutation({
    mutationFn: (req: StepUpdateLoanDraftBack) => {
      return motalClient2.installmentPlan.stepUpdateLoanDraftBack({
        requestBody: req,
      });
    },
    onSuccess: (data) => {
      toast.success("บันทึกข้อมูลสำเร็จ");
      loanQuery.refetch();
    },
    onError: (error) => {
      toast.error("เกิดข้อผิดพลาด");
    },
  });
  // const createLoanDraftQuery = useQuery({ queryKey: ['createLoanDraft'], queryFn: () => motalClient2.loan.createLoanDraft({ requestBody: { shopId: shopId || '' } }), staleTime: 0, refetchOnWindowFocus: false })
  // const confirmLoanMutation = useMutation({
  //     mutationFn: (req: LoanConfirmCreateRequest) => motalClient2.loan.confirmCreateLoan({ requestBody: req }),
  //     onSuccess: () => {
  //         toast.success('สร้างสินเชื่อสำเร็จ')
  //         nav(`/${shopId}/loan`);

  //     },
  //     onError: (error) => {
  //         toast.error('เกิดข้อผิดพลาด')
  //     }
  // })
  const nav = useNavigate();
  // const createLoanMutation = useCreateLoanMutation();
  // const updateLoanMutation = useUpdateLoanMutation();

  // const loanContractUrlQuery = useQuery({
  //   queryKey: [QueryKey.LOAN, id, "contract"],
  //   queryFn: () =>
  //     motalClient2.installmentPlan.getContractDownloadUrl({ loanId: id! }),
  //   select: (data) => {
  //     console.log(data);
  //     return data;
  //   },
  //   enabled: !!id,
  // });

  // const paymentSchedulePreviewQuery = useCalculatePaymentScheduleQuery({
  //     downPaymentAmount: form.watch('downPaymentAmount'),
  //     downPaymentDate: form.watch('downPaymentDate'),
  //     firstRepaymentDate: form.watch('firstRepaymentDate'),
  //     repaymentTermAmount: form.watch('repaymentTermAmount'),
  //     repaymentTermCount: form.watch('repaymentTermCount'),
  //     repaymentTermFrequency: form.watch('repaymentTermFrequency'),
  //     repaymentTermFrequencyType: form.watch('repaymentTermFrequencyType'),
  // }, props.mode === 'create');

  const handleNextButtonClick = async () => {
    switch (activeStep) {
      case 0:
        if (id) {
          loanStep0Form.handleSubmit(async (data) => {
            //valid
            usePendingCustomerInfoMutation.mutate(
              { ...data, shopId: shopId || "", loanId: id || "" },
              {
                onSuccess: () => {
                  setActiveStep(1);
                },
              }
            );
          })();
        } else {
          loanStep0Form.handleSubmit(async (data) => {
            //valid
            const res = await motalClient2.installmentPlan.createLoanDraft({
              requestBody: { shopId: shopId || "" },
            });
            usePendingCustomerInfoMutation.mutate(
              { ...data, shopId: shopId || "", loanId: res?.id || "" },
              {
                onSuccess: () => {
                  setSearchParams({ id: res?.id || "" });

                  setActiveStep(1);
                },
              }
            );
          })();
        }
        break;
      case 1:
        usePendingLoanDocumentMutation.mutate(
          {
            loanId: id!,
            shopId: shopId!,
          },
          {
            onSuccess: () => {
              setActiveStep(2);
            },
          }
        );
        break;
      case 2:
        loanStep2Form.handleSubmit(async (data) => {
          useStepUpdateLoanDraftDeviceAndLoan.mutate(
            {
              loanId: id!,
              shopId: shopId!,
              ...data,
            },
            {
              onSuccess: () => {
                setActiveStep(3);
              },
            }
          );
        })();
        break;
      case 3:
        setActiveStep(4);
        break;
      case 4:
        usePendingLoanContractDocumentMutation.mutate(
          {
            loanId: id!,
            shopId: shopId!,
          },
          {
            onSuccess: () => {
              setActiveStep(5);
            },
          }
        );
        // setActiveStep(5)
        break;
      case 5:
        loanStepDownPaymentForm.handleSubmit(async (data) => {
          // if (tempPaymentFile) {
          //     const uploadUrl = await motalClient2.installmentPayment.getPaymentDocumentUploadUrl({ requestBody: { fileName: tempPaymentFile?.name, shopId: shopId } })
          //     const xhr = new XMLHttpRequest();
          //     xhr.open('PUT', uploadUrl.uploadUrl!, true);
          //     xhr.setRequestHeader('Content-Type', tempPaymentFile.type);
          //     xhr.onload = () => {
          //         if (xhr.status === 200) {
          //             downPaymentMutation.mutate({ ...data, slipObjectKey: uploadUrl.objectKey, customerCitizenId: loanQuery.data?.profile?.citizenId, customerPassportNumber: loanQuery.data?.profile?.passportNumber }, {
          //                 onSuccess: () => {
          //                     setActiveStep(6)
          //                 }
          //             })
          //             // motalClient2.payment.confirmPaymentDocumentUpload({ requestBody: { objectKey: uploadUrl.objectKey, } })
          //             // .then(() => {
          //             // })
          //             // .then(() => {
          //             //     toast.success('บันทึกสำเร็จ')
          //             //     loanQuery.refetch()
          //             // }).catch(() => {
          //             //     toast.error('เกิดข้อผิดพลาด')
          //             // })
          //             // setShowSuccessIcon((prev) => ({ ...prev, [file.name]: true }));
          //             // setTimeout(() => {
          //             //     setShowSuccessIcon((prev) => ({ ...prev, [file.name]: false }));
          //             // }, 500);
          //         } else {
          //             console.error('Upload failed', xhr.responseText);
          //         }
          //     };
          //     xhr.onerror = () => {
          //         console.error('Upload error', xhr.responseText);
          //     };
          //     xhr.send(tempPaymentFile);
          // }
          // else {
          //     downPaymentMutation.mutate({ ...data }, {
          //         onSuccess: () => {
          //             toast.success('บันทึกสำเร็จ')
          //             setActiveStep(6)
          //         }
          //     })
          // }
        })();
        break;
      case 6:
        nav(`/${shopId}/loan`);
        break;
      default:
        break;
    }
  };

  const steps = [
    "ข้อมูลลูกค้า",
    "เอกสารลูกค้า",
    "ข้อมูลสินเชื่อเครื่อง",
    //'รออนุมัติ',
    loanQuery.data?.registrationStatus ===
    InstallmentPlanDto.registrationStatus.REJECTED
      ? "ไม่อนุมัติ"
      : loanQuery.data?.registrationStatus ===
        InstallmentPlanDto.registrationStatus.APPROVED
      ? "อนุมัติ"
      : "รออนุมัติ",
    "เอกสารสัญญา",
    "จ่ายเงินดาวน์",
    "สำเร็จ",
  ] as const;

  const [activeStep, setActiveStep] = useState<number>(0);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
        <Container
          maxWidth={"md"}
          disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
        >
          <Box p={2} mb={16}>
            <Typography variant="h5" p={2}>
              {"สร้างสินเชื่อ"}
            </Typography>
            <Card sx={{ px: 2, py: 4, mb: 2 }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Card>
            <DisplayIf condition={activeStep === 0}>
              <Stack direction="row" gap={2} useFlexGap flexWrap="wrap">
                <Card sx={{ width: { xs: "100%" } }}>
                  <CardContent>
                    <Stack>
                      <FormControl fullWidth>
                        <CustomInputLabel>
                          {"รหัสอ้างอิง (ไม่จำเป็น)"}
                        </CustomInputLabel>
                        <TextField
                          {...loanStep0Form.register("referenceId", {
                            required: false,
                          })}
                          size="small"
                          variant="outlined"
                        />
                        {loanStep0Form.formState.errors.referenceId && (
                          <FormHelperText
                            error
                          >{`${loanStep0Form.formState.errors.referenceId.message}`}</FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                  </CardContent>
                </Card>
                <Card sx={{ width: { xs: "100%" } }}>
                  <CardContent sx={{ padding: 2 }}>
                    <Stack gap={2}>
                      <Typography variant="h6">ข้อมูลลูกค้า</Typography>
                      <Stack direction="row" gap={2}>
                        <FormControl fullWidth>
                          <CustomInputLabel>ชื่อ</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("firstName", {
                              required: "กรุณาระบุชื่อ",
                            })}
                            size="small"
                            variant="outlined"
                          />
                          {loanStep0Form.formState.errors.firstName && (
                            <FormHelperText
                              error
                            >{`${loanStep0Form.formState.errors.firstName.message}`}</FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth>
                          <CustomInputLabel>นามสกุล</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("lastName", {
                              required: "กรุณาระบุนามสกุล",
                            })}
                            size="small"
                            variant="outlined"
                          />
                          {loanStep0Form.formState.errors.lastName && (
                            <FormHelperText
                              error
                            >{`${loanStep0Form.formState.errors.lastName.message}`}</FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                      <Stack direction={{ lg: "row" }} gap={2}>
                        <FormControl fullWidth>
                          <CustomInputLabel>
                            หมายเลขประจำตัวประชาชน
                          </CustomInputLabel>
                          <Controller
                            control={loanStep0Form.control}
                            rules={{
                              pattern: {
                                value: /^[0-9]{13}$/,
                                message: "หมายเลขประจำตัวประชาชนไม่ถูกต้อง",
                              },
                              validate: (value) => {
                                if (
                                  !value &&
                                  !loanStep0Form.watch("passportNumber")
                                ) {
                                  return "กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต";
                                }
                                if (
                                  !validateThaiId(value?.toString() || "") &&
                                  !loanStep0Form.watch("passportNumber")
                                ) {
                                  return "หมายเลขประจำตัวประชาชนไม่ถูกต้อง";
                                }
                                return true;
                              },
                            }}
                            name="citizenId"
                            render={({ field }) => (
                              <TextField
                                value={field.value ? field.value : ""}
                                onChange={(e) => field.onChange(e.target.value)}
                                size="small"
                                variant="outlined"
                              />
                            )}
                          />
                          {loanStep0Form.formState.errors.citizenId && (
                            <FormHelperText
                              error
                            >{`${loanStep0Form.formState.errors.citizenId.message}`}</FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth>
                          <CustomInputLabel>หมายเลขพาสปอร์ต</CustomInputLabel>
                          <Controller
                            control={loanStep0Form.control}
                            rules={{
                              pattern: {
                                value: /^[A-Za-z0-9]*$/,
                                message: "หมายเลขพาสปอร์ตไม่ถูกต้อง",
                              },
                              validate: (value) => {
                                if (
                                  !value &&
                                  !loanStep0Form.watch("citizenId")
                                ) {
                                  return "กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต";
                                }
                                return true;
                              },
                            }}
                            name="passportNumber"
                            render={({ field }) => (
                              <TextField
                                value={field.value ? field.value : ""}
                                onChange={(e) =>
                                  field.onChange(
                                    e.target.value ? e.target.value : NaN
                                  )
                                }
                                size="small"
                                variant="outlined"
                              />
                            )}
                          />
                          {loanStep0Form.formState.errors.passportNumber && (
                            <FormHelperText
                              error
                            >{`${loanStep0Form.formState.errors.passportNumber.message}`}</FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                      <FormControl fullWidth>
                        <CustomInputLabel>
                          ที่อยู่ตามบัตรประชาชน
                        </CustomInputLabel>
                        <TextField
                          {...loanStep0Form.register("citizenAddress")}
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>ที่อยู่ปัจจุบัน</CustomInputLabel>
                        <TextField
                          {...loanStep0Form.register("currentAddress")}
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>
                      <Stack direction={{ lg: "row" }} gap={2}>
                        <FormControl fullWidth>
                          <CustomInputLabel>อาชีพ</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("occupation")}
                            size="small"
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <CustomInputLabel>สถานที่ทำงาน</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("workPlace")}
                            size="small"
                            variant="outlined"
                          />
                        </FormControl>
                      </Stack>
                      <Stack direction={{ lg: "row" }} gap={2}>
                        <FormControl fullWidth>
                          <CustomInputLabel>เบอร์โทรศัพท์</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("phoneNumber", {
                              required: "กรุณาระบุเบอร์โทรศัพท์",
                              minLength: { value: 9, message: "ไม่ถูกต้อง" },
                              maxLength: { value: 10, message: "ไม่ถูกต้อง" },
                            })}
                            size="small"
                            variant="outlined"
                          />
                          {loanStep0Form.formState.errors.phoneNumber && (
                            <FormHelperText
                              error
                            >{`${loanStep0Form.formState.errors.phoneNumber.message}`}</FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth>
                          <CustomInputLabel>อีเมล</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("email")}
                            size="small"
                            variant="outlined"
                          />
                        </FormControl>
                      </Stack>
                      <Stack direction={{ lg: "row" }} gap={2}>
                        <FormControl fullWidth>
                          <CustomInputLabel>LINE ID</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("lineId")}
                            size="small"
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <CustomInputLabel>Facebook</CustomInputLabel>
                          <TextField
                            {...loanStep0Form.register("facebookUrl")}
                            size="small"
                            variant="outlined"
                          />
                        </FormControl>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </DisplayIf>
            <DisplayIf condition={activeStep === 1}>
              <Stack direction="row" gap={2} useFlexGap flexWrap="wrap">
                <Card sx={{ my: 2, width: { xs: "100%", lg: "100%" } }}>
                  <CardContent sx={{ padding: 2 }}>
                    <Stack gap={2}>
                      <Typography variant="h6">ไฟล์</Typography>
                      <Grid direction={"column"} container spacing={2}>
                        <Grid item xs={3}>
                          {/* <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.CITIZEN_ID_CARD)} title="บัตรประชาชน" /> */}
                        </Grid>
                        <Grid item xs={3}>
                          {/* <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.PHOTO_WITH_CARD)} title="ภาพถ่ายกับบัตร" /> */}
                        </Grid>
                        <Grid item xs={3}>
                          {/* <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.FACEBOOK_SCREEN)} title="หน้า Facebook" /> */}
                        </Grid>
                        <Grid item xs={3}>
                          {/* <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.OTHERS) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.OTHERS)} title="เอกสารประกอบอื่นๆ" /> */}
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </DisplayIf>
            <DisplayIf condition={activeStep === 2}>
              <Card
                sx={{ my: 2, width: { xs: "100%", lg: "calc(98% + 16px)" } }}
              >
                <CardContent sx={{ padding: 2 }}>
                  <Stack gap={2}>
                    <Typography variant="h6">ข้อมูลสินเชื่อ</Typography>
                    <Controller
                      control={loanStep2Form.control}
                      name="installmentPlanFundSource"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          value={field.value ? field.value : ""}
                          onChange={(e) =>
                            e ? field.onChange(e) : field.onChange()
                          }
                        >
                          <FormControlLabel
                            value={InstallmentPlanDto.fundSource.SHOP}
                            label="อนุมัติสินเชื่อจากร้านค้า"
                            control={<Radio />}
                          />
                          <FormControlLabel
                            disabled
                            value={InstallmentPlanDto.fundSource.FINANCE}
                            label="ขออนุมัติสินเชื่อจากผู้ให้บริการ (Coming Soon)"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      )}
                    />
                    <Stack direction={{ lg: "row" }} gap={2}>
                      <FormControl fullWidth>
                        <CustomInputLabel>ยอดเงินดาวน์</CustomInputLabel>
                        <Controller
                          control={loanStep2Form.control}
                          rules={{ min: 0, required: true }}
                          name="downPaymentAmount"
                          render={({ field }) => (
                            <TextField
                              type="number"
                              placeholder="0"
                              value={field.value ? field.value : ""}
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value
                                    ? parseInt(e.target.value)
                                    : NaN
                                )
                              }
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />
                        {loanStep2Form.formState.errors.downPaymentAmount && (
                          <FormHelperText error>ไม่ถูกต้อง</FormHelperText>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>ยอดเงินผ่อนต่องวด</CustomInputLabel>
                        <Controller
                          control={loanStep2Form.control}
                          rules={{ min: 0, required: true }}
                          name="installmentAmount"
                          render={({ field }) => (
                            <TextField
                              type="number"
                              placeholder="0"
                              value={field.value ? field.value : ""}
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value
                                    ? parseInt(e.target.value)
                                    : NaN
                                )
                              }
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />
                        {loanStep2Form.formState.errors.installmentAmount && (
                          <FormHelperText error>
                            กรุณาระบุยอดเงินผ่อนต่องวด
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>จำนวนงวด</CustomInputLabel>
                        <Controller
                          rules={{
                            required: "กรุณาระบุจำนวนงวด",
                            min: { value: 0, message: "มากกว่า 0" },
                            max: { value: 24, message: "ไม่เกิน 24 งวด" },
                          }}
                          // rules={
                          //     props.mode === 'update'
                          //         ? {}
                          //         :
                          //         { required: "กรุณาระบุจำนวนงวด", min: { value: 1, message: "1 - 24 เท่านั้น" }, max: { value: 24, message: "1 - 24 เท่านั้น" } }

                          // }
                          control={loanStep2Form.control}
                          name="installmentCount"
                          render={({ field }) => (
                            <TextField
                              type="number"
                              value={field.value ? field.value : ""}
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value
                                    ? parseInt(e.target.value)
                                    : NaN
                                )
                              }
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />
                        {loanStep2Form.formState.errors.installmentCount && (
                          <FormHelperText error>
                            {
                              loanStep2Form.formState.errors.installmentCount
                                .message
                            }
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>
                          ราคาเช่าซื้อรวม (คำนวณ)
                        </CustomInputLabel>
                        {/* <Controller control={form.control} name="totalAmount" render={({ field }) => ( */}
                        <TextField
                          type="number"
                          value={
                            (loanStep2Form.watch("downPaymentAmount") || 0) +
                            (loanStep2Form.watch("installmentAmount") || 0) *
                              (loanStep2Form.watch("installmentCount") || 0)
                          }
                          size="small"
                          variant="outlined"
                        />

                        {/* )} /> */}
                      </FormControl>
                    </Stack>
                    <Stack direction={{ lg: "row" }} gap={2}>
                      <FormControl fullWidth>
                        <CustomInputLabel>
                          วันที่จ่ายเงินดาวน์ (วัน/เดือน/ปี ค.ศ.)
                        </CustomInputLabel>
                        <Controller
                          rules={{ required: "กรุณาระบุ" }}
                          control={loanStep2Form.control}
                          name="downPaymentDate"
                          render={({ field }) => (
                            <DatePicker
                              format="DD/MM/YYYY"
                              value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                              onChange={(date) =>
                                field.onChange(
                                  date ? date.format("YYYY-MM-DD") : ""
                                )
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                            />
                          )}
                        />
                        {loanStep2Form.formState.errors.downPaymentDate && (
                          <FormHelperText error>
                            {
                              loanStep2Form.formState.errors.downPaymentDate
                                .message
                            }
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>
                          วันที่เริ่มผ่อนงวดแรก (วัน/เดือน/ปี ค.ศ.)
                        </CustomInputLabel>
                        <Controller
                          rules={{ required: "กรุณาระบุ" }}
                          control={loanStep2Form.control}
                          name="installmentStartDate"
                          render={({ field }) => (
                            <DatePicker
                              format="DD/MM/YYYY"
                              value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                              onChange={(date) =>
                                field.onChange(
                                  date ? date.format("YYYY-MM-DD") : ""
                                )
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                            />
                          )}
                        />
                        {loanStep2Form.formState.errors
                          .installmentStartDate && (
                          <FormHelperText error>
                            {
                              loanStep2Form.formState.errors
                                .installmentStartDate.message
                            }
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>
                          ระยะเวลาชำระต่องวดทุกๆ
                        </CustomInputLabel>
                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={`${loanStep2Form.watch(
                              "installmentFrequency"
                            )},${loanStep2Form.watch(
                              "installmentFrequencyType"
                            )}`}
                            onChange={(e) => {
                              const value = (e.target.value as string).split(
                                ","
                              );
                              loanStep2Form.setValue(
                                "installmentFrequency",
                                parseInt(value[0])
                              );
                              loanStep2Form.setValue(
                                "installmentFrequencyType",
                                value[1] as StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType
                              );
                            }}
                            // label="Age"
                            // onChange={handleChange}
                          >
                            <MenuItem value={"7,DAY"}>7 วัน</MenuItem>
                            <MenuItem value={"15,DAY"}>15 วัน</MenuItem>
                            <MenuItem value={"30,DAY"}>30 วัน</MenuItem>
                            <MenuItem value={"1,MONTH"}>1 เดือน</MenuItem>
                          </Select>
                        </FormControl>
                        {/* <Stack direction='row' gap={1}>
                                                    <Stack direction='column' gap={1}>
                                                        <Controller
                                                            rules={{ required: 'กรุณาระบุ', min: { value: 1, message: 'มากกว่า 0' } }
                                                            }
                                                            // rules={
                                                            //     props.mode === 'update'
                                                            //         ? {} :
                                                            //         { required: 'กรุณาระบุ', min: { value: 1, message: form.watch('repaymentTermFrequencyType') === LoanEvent.repaymentTermFrequencyType.MONTH ? '1-3 เท่านั้น' : '1-31 เท่านั้น' }, max: { value: form.watch('repaymentTermFrequencyType') === LoanEvent.repaymentTermFrequencyType.MONTH ? 3 : 31, message: form.watch('repaymentTermFrequencyType') === LoanEvent.repaymentTermFrequencyType.MONTH ? '1-3 เท่านั้น' : '1-31 เท่านั้น' } }
                                                            // }
                                                            control={loanStep2Form.control} name="installmentFrequency" render={({ field }) => (
                                                                <TextField type="number"
                                                                    value={field.value ?? NaN}
                                                                    onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                                    size='small' variant="outlined" />
                                                            )} />
                                                        <FormHelperText error>{loanStep2Form.formState.errors.installmentFrequency?.message}</FormHelperText>

                                                    </Stack>
                                                    <Controller control={loanStep2Form.control} name="installmentFrequencyType" render={({ field }) => (
                                                        <Select

                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            {...field}
                                                            size='small'
                                                        >
                                                            <MenuItem value={StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType.MONTH}>เดือน</MenuItem>
                                                            <MenuItem value={StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType.DAY}>วัน</MenuItem>
                                                        </Select>
                                                    )} />
                                                </Stack> */}
                      </FormControl>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              <Card sx={{ width: { xs: "100%" } }}>
                <CardContent sx={{ padding: 2 }}>
                  <Stack gap={2}>
                    <Typography variant="h6">ข้อมูลเครื่อง</Typography>
                    <Controller
                      control={loanStep2Form.control}
                      name="usedType"
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value={ProductDto.usedType.NEW}
                            label="สินค้าใหม่"
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value={ProductDto.usedType.USED}
                            label="สินค้าใช้แล้ว"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      )}
                    />
                    <FormControl fullWidth>
                      <CustomInputLabel>แบรนด์</CustomInputLabel>
                      <TextField
                        {...loanStep2Form.register("brand")}
                        size="small"
                        variant="outlined"
                      />
                    </FormControl>
                    <Stack direction="row" gap={2}>
                      <FormControl fullWidth>
                        <CustomInputLabel>รุ่น</CustomInputLabel>
                        <TextField
                          {...loanStep2Form.register("model")}
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomInputLabel>สี</CustomInputLabel>
                        <TextField
                          {...loanStep2Form.register("color")}
                          size="small"
                          variant="outlined"
                        />
                      </FormControl>
                    </Stack>
                    <FormControl fullWidth>
                      <CustomInputLabel>
                        รายละเอียด (อุปกรณ์เสริม, อื่นๆ)
                      </CustomInputLabel>
                      <TextField
                        {...loanStep2Form.register("description")}
                        size="small"
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <CustomInputLabel>อีมี่ (IMEI)</CustomInputLabel>
                      <TextField
                        {...loanStep2Form.register("deviceImei", {
                          validate: (value) => {
                            if (
                              !value &&
                              !loanStep2Form.watch("deviceSerialNumber")
                            ) {
                              return "กรุณาระบุ IMEI หรือ Serial Number";
                            }
                            return true;
                          },
                        })}
                        size="small"
                        variant="outlined"
                      />
                      {loanStep2Form.formState.errors.deviceImei && (
                        <FormHelperText error>
                          {loanStep2Form.formState.errors.deviceImei.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <CustomInputLabel>Serial Number</CustomInputLabel>
                      <TextField
                        {...loanStep2Form.register("deviceSerialNumber", {
                          validate: (value) => {
                            if (!value && !loanStep2Form.watch("deviceImei")) {
                              return "กรุณาระบุ IMEI หรือ Serial Number";
                            }
                            return true;
                          },
                        })}
                        size="small"
                        variant="outlined"
                      />
                      {loanStep2Form.formState.errors.deviceSerialNumber && (
                        <FormHelperText error>
                          {
                            loanStep2Form.formState.errors.deviceSerialNumber
                              .message
                          }
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </CardContent>
              </Card>

              {/* <Card sx={{ my: 2, width: { xs: '100%', lg: '49%' } }}>
                                <CardContent sx={{ padding: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant="h6">ข้อมูลต้นทุน</Typography>
                                        <Stack direction={{ lg: 'row' }} gap={2}>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>ต้นทุนเครื่อง</CustomInputLabel>
                                                <Controller control={form.control} name="device.purchaseCost" render={({ field }) => (
                                                    <TextField type="number"
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small' variant="outlined" />
                                                )} />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>{`ค่าคอม (ไม่จำเป็น)`}</CustomInputLabel>
                                                <Controller control={form.control} name="commissionAmount" render={({ field }) => (
                                                    <TextField type="number"
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small' variant="outlined" />
                                                )} />
                                            </FormControl>
                                        </Stack>

                                    </Stack>
                                </CardContent>
                            </Card> */}
            </DisplayIf>
            <DisplayIf condition={activeStep === 3}>
              <Card sx={{ my: 2 }}>
                <CardContent sx={{ padding: 2 }}>
                  <Typography variant="h6">
                    {loanQuery.data?.registrationStatus ===
                    InstallmentPlanDto.registrationStatus.REJECTED
                      ? "ไม่อนุมัติ"
                      : loanQuery.data?.registrationStatus ===
                        InstallmentPlanDto.registrationStatus.APPROVED
                      ? "อนุมัติ"
                      : "รออนุมัติ"}
                  </Typography>
                </CardContent>
              </Card>
            </DisplayIf>
            <DisplayIf condition={activeStep === 4}>
              <Stack direction="row" gap={2} useFlexGap flexWrap="wrap">
                <Card sx={{ my: 2, p: 2, width: { xs: "100%", lg: "100%" } }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      // const contractUrl =
                      //   loanContractUrlQuery.data?.downloadUrl;
                      // if (contractUrl) {
                      //   window.open(contractUrl, "_blank");
                      // } else {
                      //   toast.error("ไม่พบสัญญา");
                      // }
                    }}
                  >
                    Download สัญญา <DownloadIcon />
                  </Button>
                </Card>

                <Card sx={{ my: 2, width: { xs: "100%", lg: "100%" } }}>
                  <CardContent sx={{ padding: 2 }}>
                    <Stack gap={2}>
                      <Typography variant="h6">หลักฐานการทำสัญญา</Typography>

                      <Stack
                        direction="row"
                        spacing={3}
                        sx={{ display: "flex" }}
                      >
                        {/* <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.CONTRACT) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.CONTRACT)} title="สัญญา" sx={{ width: '33%' }} />
                                                <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.DEVICE_IMAGE) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.DEVICE_IMAGE)} title="ภาพถ่ายเครื่อง" sx={{ width: '33%' }} />
                                                <FileUploadWithPreview data={loanQuery.data?.documents?.filter(d => d.type === InstallmentPlanDocumentDto.type.IMEI_OR_SERIAL) || []} onRemove={handleDocumentFileRemove} onFileSelect={handleDocumentFileSelect(LoanDocumentUploadConfirmRequest.type.IMEI_OR_SERIAL)} title="ภาพถ่าย Imei หรือ Serial" sx={{ width: '33%' }} /> */}
                      </Stack>
                      {/* <FormControl fullWidth>
                                                <CustomInputLabel id="type-label">รูป QR Code</CustomInputLabel>
                                                 <Avatar variant="square" sx={{ width: 100, height: 100 }} src={''} />
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    variant="outlined"
                                                    tabIndex={-1}
                                                    color='primary'
                                                    startIcon={<FileUploadIcon />}
                                                >
                                                    อัพโหลดรูป QR Code
                                                    <VisuallyHiddenInput type="file" onChange={async (event) => {
                                                        const file = event.target.files?.[0]; // Add null check
                                                        console.log('file-name', file?.name)
                                                        if (file) {
                                                            // setNewChannelImage(file);
                                                            // await uploadFile({ file });
                                                        }

                                                    }} />
                                                </Button>
                                            </FormControl> */}
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </DisplayIf>
            <DisplayIf condition={activeStep === 5}>
              <Card sx={{ my: 2 }}>
                <CardContent sx={{ padding: 2 }}>
                  <Typography variant="h6">จ่ายเงินดาวน์</Typography>
                  <Stack direction="column" gap={2}>
                    <Typography variant="h6">ข้อมูลการจ่ายเงิน</Typography>
                    <Stack direction={{ lg: "row" }} gap={2}>
                      {/* <Stack>
                                                <CustomInputLabel>ประเภท</CustomInputLabel>
                                                <Box sx={{ pt: 0.5 }}>
                                                    <Controller
                                                        control={control}
                                                        name="type"
                                                        render={({ field }) => (
                                                            <PaymentTypeChip selectable={props.mode === 'create'} type={field.value!} onSelect={field.onChange} />
                                                        )} />
                                                </Box>
                                            </Stack> */}
                      {/* <FormControl fullWidth>
                        <CustomInputLabel>จำนวนเงิน</CustomInputLabel>
                        <Controller
                          name="amount"
                          control={loanStepDownPaymentForm.control}
                          rules={{
                            required: "ต้องการจำนวนเงิน",
                            min: { value: 1, message: "ไม่ถูกต้อง" },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="number"
                              {...field}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />
                        {loanStepDownPaymentForm.formState.errors.amount && (
                          <Typography color="error">
                            {
                              loanStepDownPaymentForm.formState.errors.amount
                                .message
                            }
                          </Typography>
                        )}
                      </FormControl> */}
                    </Stack>
                    {/* <FormControl fullWidth>
                      <CustomInputLabel>วันที่ทำรายการ</CustomInputLabel>
                      <Controller
                        control={loanStepDownPaymentForm.control}
                        rules={{ required: "กรุณาระบุวันที่ทำรายการ" }}
                        name="transactionAt"
                        render={({ field }) => (
                          <DatePicker
                            format="DD/MM/YYYY"
                            value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                            onChange={(date) =>
                              field.onChange(
                                date
                                  ? date.format("YYYY-MM-DDT00:00:00.000+07:00")
                                  : ""
                              )
                            }
                            slotProps={{
                              textField: { size: "small", variant: "outlined" },
                            }}
                          />
                        )}
                      />
                      {loanStepDownPaymentForm.formState.errors
                        .transactionAt && (
                        <Typography color="error">
                          {
                            loanStepDownPaymentForm.formState.errors
                              .transactionAt.message
                          }
                        </Typography>
                      )}
                    </FormControl> */}
                    {/* <Stack direction={{ lg: 'row' }} gap={2}>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>ช่องทางรับชำระ</CustomInputLabel>
                                                <Controller control={loanStepDownPaymentForm.control}
                                                    rules={{ required: 'กรุณาระบุช่องทางรับชำระ' }}
                                                    name="shopPaymentChannelId" render={({ field }) => (
                                                        <Select
                                                            labelId="paymentChannel-label"
                                                            id="paymentChannel-id"
                                                            size='small'

                                                            {...field}
                                                        >
                                                            {paymentChannelsQuery.data?.filter(p => p.id === field.value)
                                                            .map(p => {
                                                                return (
                                                                    <MenuItem value={p.id}><Box width='100%'><PaymentPreviewFileCard paymentChannel={p} hideIcon /></Box></MenuItem>
                                                                )
                                                            })}
                                                            {paymentChannelsQuery.data?.filter(p => p.status === ShopPaymentChannelDto.status.ACTIVE)
                                                                .map(p => {
                                                                    return (
                                                                        <MenuItem value={p.shopPaymentChannelId}><Box width='100%'><RenderPaymentChannel paymentChannel={p} hideIcon /></Box></MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    )} />
                                                {loanStepDownPaymentForm.formState.errors.shopPaymentChannelId && <Typography color="error">{loanStepDownPaymentForm.formState.errors.shopPaymentChannelId.message}</Typography>}

                                            </FormControl>
                                        </Stack> */}
                    {/* <FileUploadWithPreview data={tempPaymentFile ? [{ downloadUrl: tempPaymentFile ? URL.createObjectURL(tempPaymentFile) : undefined }] : []} onFileSelect={handlePaymentFileSelect} title="หลักฐานการจ่ายเงิน" sx={{ width: '33%' }} /> */}

                    <FormControl fullWidth>
                      <CustomInputLabel>โน้ต</CustomInputLabel>
                      <TextField
                        {...loanStepDownPaymentForm.register("note")}
                        size="small"
                        variant="outlined"
                      />
                    </FormControl>
                  </Stack>
                </CardContent>
              </Card>
            </DisplayIf>
            <DisplayIf condition={activeStep === 6}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon
                  sx={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "400px",
                    maxHeight: "400px",
                  }}
                  fontSize="large"
                  color="success"
                />
                <Typography sx={{ fontSize: "80px", fontWeight: "600" }}>
                  success
                </Typography>
              </Box>
            </DisplayIf>
          </Box>
        </Container>
      </LocalizationProvider>
      <Box
        sx={{
          p: 2,
          bgcolor: "white",
          width: { xs: "100%", sm: "calc(100% - 240px)" },
          position: "fixed",
          bottom: 0,
          zIndex: 99,
        }}
      >
        <Stack direction="row" gap={1} justifyContent="center">
          <Button
            disabled={!(activeStep > 0 && ![3, 4, 6].includes(activeStep))}
            fullWidth
            onClick={() =>
              useBackButtonMutation.mutate({ loanId: id || "", shopId: shopId })
            }
            variant="outlined"
            size="large"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: 200,
              },
            }}
          >
            ย้อนกลับ
          </Button>

          {activeStep !== 3 && (
            <Button
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: 200,
                },
              }}
              fullWidth
              disabled={queryClient.isMutating() > 0}
              onClick={handleNextButtonClick}
              variant="contained"
            >
              ต่อไป
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};
const DisplayIf = ({
  condition,
  children,
}: {
  condition: boolean;
  children: React.ReactNode | React.ReactNode[];
}) => {
  return <Box sx={{ display: condition ? "block" : "none" }}>{children}</Box>;
};
