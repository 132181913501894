import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useBackendApiClient } from "../../motalvip-apis";

export const useDocumentDeleteMutation = () => {
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: ['deleteDocument'],
        mutationFn: (id: string) =>
            backendApiClient.document.deleteDocument({
                id,
            }),
        onSuccess: () => {
            toast.success("สำเร็จ");
            queryClient.invalidateQueries({ queryKey: ['documentsQuery'] });
        },
        retry: 0,
    });
};