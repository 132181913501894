/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentDto } from './DocumentDto';
import type { FinancierShopsGroup } from './FinancierShopsGroup';
import type { PhoneNumber } from './PhoneNumber';
export type FinancierShopDetailResponse = {
    id?: string;
    name: string;
    address: string;
    shopReference: string;
    lineId?: string;
    facebookUrl: string;
    phoneNumbers: Array<PhoneNumber>;
    status?: FinancierShopDetailResponse.status;
    expireAt?: string;
    avatarUrl?: string;
    financierShopsGroup?: FinancierShopsGroup;
    financierShopContractUrl?: Array<DocumentDto>;
};
export namespace FinancierShopDetailResponse {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
}

