/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetShopProductsPosResponse = {
    shopProductId?: string;
    productId?: number;
    variantId?: number;
    name?: string;
    description?: string;
    sku?: string;
    imageUrl?: string;
    representImageUrl?: string;
    type?: GetShopProductsPosResponse.type;
    price?: number;
    isSecondhand?: boolean;
    note?: string;
    stock?: number;
    serialNumber?: string;
    imei?: string;
    isMobileSoldOut?: boolean;
};
export namespace GetShopProductsPosResponse {
    export enum type {
        GENERAL = 'GENERAL',
        MOBILE = 'MOBILE',
    }
}

