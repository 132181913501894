/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCredential } from './AccountCredential';
import type { ContractRemarkDetail } from './ContractRemarkDetail';
export type ContractDetail = {
    pawnedAt?: string;
    pawnedAmount?: number;
    contractFee?: number;
    installmentType?: ContractDetail.installmentType;
    term?: number;
    interestRate?: number;
    machinePasswordType?: ContractDetail.machinePasswordType;
    machinePassword?: string;
    remarks?: Array<ContractRemarkDetail>;
    accountCredentials?: Array<AccountCredential>;
    pawningStatus?: ContractDetail.pawningStatus;
    totalFineAmount?: number;
    brand?: string;
    model?: string;
    color?: string;
    flaw?: string;
    accessory?: string;
    imei?: string;
    serialNumber?: string;
};
export namespace ContractDetail {
    export enum installmentType {
        EFFECTIVE_RATE = 'EFFECTIVE_RATE',
        FIXED_RATE = 'FIXED_RATE',
    }
    export enum machinePasswordType {
        CODE = 'CODE',
        PATTERN = 'PATTERN',
    }
    export enum pawningStatus {
        ONGOING = 'ONGOING',
        REDEEMED = 'REDEEMED',
        FORECLOSURE = 'FORECLOSURE',
    }
}

