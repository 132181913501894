import { UserButton } from "@clerk/clerk-react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Breadcrumbs,
  CssBaseline,
  Drawer,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ShopDto } from "../../client/motalvip-apis/generated";
import { useInstallmentPlanAggregationCalculateMutation } from "../../client/query-client/useInstallmentPlanAggregationCalculateMutation";
import { ShopUnavailableScreen } from "../../screen/ShopUnavailableScreen";
import { ShopFinancierDrawer } from "./ShopFinancierDrawer";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";

const drawerWidth = 260;

interface Props {
  window?: () => Window;
}

export const ShopFinancierLayout = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const nav = useNavigate();
  const shopFinancierCtx = useShopFinancierContext();

  const { shopId } = useParams();
  const ipc = useInstallmentPlanAggregationCalculateMutation(shopId || "");
  useEffect(() => {
    ipc.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundImage: "linear-gradient(to right, #263238, #37474f)",
            }}
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  color: "white",
                  mr: 1,
                  display: { sm: "none" },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Breadcrumbs aria-label="breadcrumb" sx={{ color: "white" }}>
                    <Link
                      underline="hover"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      color="inherit"
                      onClick={() => nav("/")}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="white"
                        sx={{ textShadow: "2px 2px 2px #263238" }}
                      >
                        MotalVip
                      </Typography>
                    </Link>
                    <Stack direction="row" gap={1}>
                      <Link
                        underline="hover"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        color="inherit"
                        onClick={() => {
                          shopFinancierCtx.setSelectedMenu("");
                          nav(`/shop-finance/${shopFinancierCtx.shopId}`);
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 0.5,
                            textShadow: "2px 2px 2px #263238",
                            color: "white",
                          }}
                        >
                          {shopFinancierCtx.shop?.name}
                        </Typography>
                      </Link>
                    </Stack>
                  </Breadcrumbs>
                </Box>
                <UserButton />
              </Stack>
            </Toolbar>
          </AppBar>

          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "white",
                },
              }}
            >
              <ShopFinancierDrawer
                shopId={shopFinancierCtx.shopId!.toString()}
              />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "white",
                },
              }}
              open
            >
              <ShopFinancierDrawer
                shopId={shopFinancierCtx.shopId!.toString()}
              />
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
              backgroundColor: "#fafafa",
              minHeight: "100vh",
            }}
          >
            <Toolbar />
            {shopFinancierCtx.shop?.status ===
              ShopDto.status.WAITING_FOR_APPROVE ||
            shopFinancierCtx.shop?.status === ShopDto.status.EXPIRED ? (
              <ShopUnavailableScreen
                message={
                  shopFinancierCtx.shop?.status === ShopDto.status.EXPIRED
                    ? "ติดต่ออะตอมเทค"
                    : undefined
                }
              />
            ) : (
              <Outlet />
            )}
          </Box>
        </Box>
      }
    </>
  );
};
