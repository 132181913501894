
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Card, CardContent, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSearchHistory } from "../../client/query-client";
import { DataCell } from "../../component/DataCell";
import { LoanStatusChip } from "../../component/display/LoanStatusChip";
import { InstallmentPlanDto } from '../../client/motalvip-apis/generated';


export const BacklistSearchScreen = () => {
    const [search, setSearch] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const blacklistQuery = useSearchHistory(1, 10, searchText, searchText.length >= 3);

    return (
        <Box sx={{ p: 2 }}>
            <Stack direction={{ lg: 'row' }} gap={2} justifyContent='space-between' alignItems='flex-start'>

                <TextField
                    sx={{ backgroundColor: 'white' }}
                    variant="outlined"
                    value={search}
                    helperText={showError ? 'กรุณากรอกข้อมูลอย่างน้อย 3 อักษร' : ''}
                    error={showError}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="ค้าหา ชื่อ-นามสกุล, เลขบัตร, พาสปอร์ต, IMEI, Serial Number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
                <Button

                    variant='contained'
                    size='medium'
                    onClick={() => {
                        if (search.length >= 3) {
                            setSearchText(search)
                            setShowError(false)
                        }
                        else {
                            setShowError(true)
                        }
                    }
                    }
                    // onClick={() => useBlacklistSearchQuery.fet}
                    sx={{ height: '56px', width: { xs: '100%', lg: '15%' } }}
                >
                    ค้นหา
                </Button>
                <Button

                    variant='outlined'
                    size='medium'
                    onClick={() => {
                        setSearch('')
                        setSearchText('')
                        setShowError(false)
                    }}
                    // onClick={() => useBlacklistSearchQuery.fet}
                    sx={{ height: '56px', width: { xs: '100%', lg: '15%' } }}
                >
                    ยกเลิก
                </Button>
            </Stack>


            <Box sx={{ mt: 2 }} display={'flex'} flexWrap={'wrap'} gap={2}>
                {blacklistQuery.data
                    && blacklistQuery.data?.content?.length === 0
                    && <Stack width='100%' justifyContent='center' m={4}><Typography textAlign='center' >ไม่พบข้อมูล</Typography></Stack>}
                {blacklistQuery.data && blacklistQuery.data?.content?.map((item) => (
                    <Card key={item.installmentPlanId} title={item.installmentPlanStatus} sx={{ width: { xs: '100%', md: '48%' } }}>
                        <CardContent>
                            <Grid container>
                                {/* <Grid item xs={5}><DataCell label='ชื่อ-นามสกุล :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.customer?.firstName} ${item.customer?.lastName}`} /></Grid> */}
                                {/* <Grid item xs={5}><DataCell label='เลขประจำตัวประชาชน :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.customer?.citizenId}`} /></Grid>
                                <Grid item xs={5}><DataCell label='เลขพาสปอร์ต :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.customer?.passportId}`} /></Grid>
                                <Grid item xs={5}><DataCell label='อีเมล :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.customer?.email}`} /></Grid>
                                <Grid item xs={5}><DataCell label='เบอร์โทรศัพท์ลูกค้า :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.customer?.phoneNumber}`} /></Grid> */}
                                <Grid item xs={5}><DataCell label='สถานะสินเชื่อ :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        padding: 1, border: '1px solid #e0e0e0', height: 52
                                    }}><LoanStatusChip status={item.installmentPlanStatus as unknown as InstallmentPlanDto.status} />
                                    </Box>
                                </Grid>
                                <Grid item xs={5}><DataCell label='ร้านค้า :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.shopName}`} /></Grid>
                                <Grid item xs={5}><DataCell label='เบอร์โทรศัพท์ร้านค้า :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={`${item.shopPhoneNumber}`} /></Grid>
                                <Grid item xs={5}><DataCell label='งวดปัจจุบัน :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={item?.installmentPlanActiveTermNumber ? `${item?.installmentPlanActiveTermNumber} / ${item.installmentPlanInstallmentCount}` : "-"} /></Grid>
                                <Grid item xs={5}><DataCell label='IMEI :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={item?.productImei
                                    ? item.productImei.length > 5
                                        ? item.productImei.slice(0, -5) + 'xxxxx'
                                        : 'xxxxx'
                                    : ''} /></Grid>
                                <Grid item xs={5}><DataCell label='Serial Number :' backgroundColor='#eeeeee' /></Grid>
                                <Grid item xs={7}><DataCell label={item.productSerialNumber as string} /></Grid>
                            </Grid>
                        </CardContent>

                    </Card>
                ))
                }
            </Box>
        </Box >
    )
}