import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { InstallmentPaymentSumReport } from "../../../../client/motalvip-apis/generated";
import { paymentChannelConfig } from "../../../../config/PaymentChannelAssetConfig";

type InstallmentPaymentSumReportCardProps = {
  report: InstallmentPaymentSumReport;
};

const InstallmentPaymentSumReportCard: React.FC<
  InstallmentPaymentSumReportCardProps
> = ({ report }) => {
  const config = paymentChannelConfig.find(
    (config) =>
      // @ts-ignore
      config.type === report.paymentChannelType &&
      (config.bankCode === report.bankCode || config.bankCode === undefined)
  );

  return (
    <Card
      sx={{
        display: "flex",
        padding: 0.5,
      }}
    >
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography variant="h6" component="div">
          {!config ? "ไม่ได้กำหนด" : config!.name}
        </Typography>
        {report.paymentChannelType ===
          InstallmentPaymentSumReport.paymentChannelType.BANK_ACCOUNT && (
          <>
            <Typography variant="subtitle1" color="text.secondary">
              {report.accountName}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {report.accountId}
            </Typography>
          </>
        )}
        <Stack
          style={{
            flexDirection: "column",
            gap: 0,
          }}
        >
          {(
            [
              ["ยอดรวม", report.sumTotal, report.rowCount],
              ["งวด", report.termPaymentSum, report.termPaymentCount],
              ["ดาวน์", report.downPaymentSum, report.downPaymentCount],
              ["ปรับ", report.finePaymentSum, report.finePaymentCount],
            ] as const
          ).map(([label, value, count]) => (
            <div key={label} style={{ display: "flex", gap: "0.25rem" }}>
              <span style={{ opacity: 0.6 }}>{label}</span>{" "}
              {Number(value?.toFixed(2))?.toLocaleString() || 0} บาท
              <span
                style={{
                  opacity: 0.5,
                }}
              >
                (จำนวน {Number(count?.toFixed(2))?.toLocaleString() || 0})
              </span>
            </div>
          ))}
        </Stack>
      </CardContent>
      {config && (
        <img
          src={config.logo}
          alt={report.paymentChannelType}
          style={{
            padding: 10,
            borderRadius: 5,
            width: 56,
            height: 56,
            backgroundColor: config.color,
          }}
        />
      )}
    </Card>
  );
};

export default InstallmentPaymentSumReportCard;
