import { useNavigate, useParams } from "react-router-dom"
import { useProductEditMutation, useProductQuery, useProductVariantEditMutation } from "../../../client/query-client/useProductQuery"
import { useCategoriesQuery } from "../../../client/query-client/useCategoryQuery"
import { SubmitHandler, useForm } from "react-hook-form"
import { CreateProductFormType, MobileType } from "../CreateProductForm/types"
import { useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { DocumentDto, GetShopProductsResponse } from "../../../client/motalvip-apis/generated"
import { Box, Button, Card, Stack, Typography } from "@mui/material"
import { ProductInformation } from "../CreateProductForm/ProductInformation"
import { ProductGeneral } from "../CreateProductForm/ProductGeneral"
import { getProductVariants } from "../CreateProductForm"
import { useUploadFile } from "../hooks/useUploadFile"

export const EditProductForm = () => {
    const { productId, shopId } = useParams()
    const { data: product, isLoading: isLoadingProduct } = useProductQuery({ productId: Number(productId!), shopId: shopId! })
    const { data: categories, isLoading: isLoadingCategories } = useCategoriesQuery(shopId!)
    const { mutateAsync: editProductVariant, isPending: isPendingProductVariant, error: productVariantError } = useProductVariantEditMutation()
    const { mutateAsync: editProduct, isPending: isPendingProduct, error: productError } = useProductEditMutation()

    const hasProductOption = !!(product?.options && product.options.length > 0)
    const isMobile = product?.type === GetShopProductsResponse.type.MOBILE
    const disableButton = isPendingProduct || isPendingProductVariant

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
        resetField,
        reset
    } = useForm<CreateProductFormType>()
    const navigate = useNavigate()
    const { upload } = useUploadFile(DocumentDto.referenceType.POS)

    const setInitialForm = useCallback(() => {
        reset()

        if (product) {
            setValue('name', product.name!)
            setValue('description', product.description!)
            setValue('categoryId', product.categoryId!)
            setValue('mobileType', product.isSecondhand ? MobileType.SECONDHAND : MobileType.FIRSTHAND)
            setValue('productImage', product.imageUrl!)
        }
        if (!hasProductOption && product) {
            product?.price && setValue('productPrice', String(product.price))
            setValue('productSku', product.sku!)
            setValue('productNote', product.note!)
        }
        if (hasProductOption && product.options) {
            const productMultiVariant: CreateProductFormType['productMultiVariant'] = {}
            const productSingleVariant: CreateProductFormType['productSingleVariant'] = {}
            const optionValue1: string[] = []
            const optionValue2: string[] = []
            product.options.forEach(option => {
                if (option.price && option.sku) {
                    if (option.optionValue1 && option.optionValue2) {
                        optionValue1.push(option.optionValue1)
                        optionValue2.push(option.optionValue2)
                        if (!productMultiVariant[option.optionValue1]) {
                            productMultiVariant[option.optionValue1] = {
                                detail: {},
                                productImage: option.representImageUrl!
                            }
                        }
                        productMultiVariant[option.optionValue1].detail[option.optionValue2] = {
                            price: String(option.price),
                            skuCode: option.sku,
                            note: option.note,
                            variantId: option.variantId,
                        }
                    } else if (option.optionValue1) {
                        optionValue1.push(option.optionValue1)
                        productSingleVariant[option.optionValue1] = {
                            price: String(option.price),
                            skuCode: option.sku,
                            note: option.note,
                            variantId: option.variantId,
                            productImage: null as any
                        }
                    }
                }
            })
            if (optionValue1.length > 0 && optionValue2.length > 0) {
                setValue('productMultiVariant', productMultiVariant)
                setValue('optionValue1', [...new Set(optionValue1)])
                setValue('optionValue2', [...new Set(optionValue2)])
                setValue('optionName1', product.optionName1!)
                setValue('optionName2', product.optionName2!)
            } else if (optionValue1.length > 0) {
                setValue('productSingleVariant', productSingleVariant)
                setValue('optionValue1', [...new Set(optionValue1)])
                setValue('optionName1', product.optionName1!)
            }
            setValue('hasOption', true)
        }
    }, [product, setValue, hasProductOption, reset])

    useEffect(() => {
        setInitialForm()
    }, [product, setInitialForm])

    const onSubmit: SubmitHandler<CreateProductFormType> = async (data, event) => {
        if (!event) return

        const elements = event.target.elements
        const loadingId = toast.loading("กำลังแก้ไขสินค้า โปรดรอสักครู่...")

        try {
            if (productId && product) {
                let productImageId = null
                if (data.productImage && typeof data.productImage !== 'string') {
                    const { refId } = await upload(data.productImage)
                    productImageId = refId
                }
                if (hasProductOption && data.hasOption) {
                    const productVariants = await getProductVariants(data, elements, upload)
                    await editProductVariant({
                        req: {
                            name: data.name,
                            description: data.description,
                            categoryId: data.categoryId,
                            type: product.type!,
                            shopId: shopId!,
                            ...(isMobile ? { isSecondhand: data.mobileType === MobileType.SECONDHAND } : undefined),
                            productVariants: productVariants,
                            optionName1: data.optionName1,
                            optionName2: data.optionName2,
                            ...(productImageId && { documentRefId: productImageId })
                        },
                        productId: Number(productId)
                    })
                } else {
                    await editProduct({
                        req: {
                            name: data.name,
                            description: data.description,
                            categoryId: data.categoryId,
                            price: Number(data.productPrice),
                            sku: data.productSku || elements['productSku'].placeholder,
                            note: data.productNote,
                            type: product.type!,
                            shopId: shopId!,
                            productId: product.productId,
                            ...(productImageId && { documentRefId: productImageId })
                        },
                        productId: Number(productId)
                    })
                }
                navigate(`/${shopId}/pos/product-list`)
            }
        } catch (e) {
            console.error(e)
        }
        toast.dismiss(loadingId)
    }

    const cancelEdit = () => {
        navigate(`/${shopId}/pos/product-list`)
    }

    if (isLoadingProduct || isLoadingCategories) {
        return <Box p={2}><Typography variant='body1'>Loading...</Typography></Box>
    }

    if (!product) {
        return null
    }

    if (!categories || (categories && categories?.length <= 0)) {
        return (<Card sx={{ p: 2 }}>
            <Typography variant="body1">ไม่สามารถดูสินค้าได้ เนื่องจากไม่มีหมวดหมู่สินค้า โปรดสร้างหมวดหมู่สินค้า</Typography>
        </Card>)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
                <ProductInformation categories={categories!} errors={errors} control={control} watch={watch} isMobile={isMobile} setValue={setValue} editMode />
                <ProductGeneral control={control} errors={errors} setValue={setValue} watch={watch} register={register} resetField={resetField} editMode hasProductOption={hasProductOption} isPending={isPendingProduct || isPendingProductVariant} productVariantError={(productVariantError as any)?.body} productError={(productError as any)?.body} />
            </Stack>
            <Box display="flex" justifyContent="space-around" py={2}>
                <Button variant="outlined" size="large" onClick={cancelEdit} disabled={disableButton}>ยกเลิก</Button>
                <Button variant="contained" size="large" type="submit" disabled={disableButton}>บันทึก</Button>
            </Box>
        </form>
    )
}