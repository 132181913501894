import { Box, Typography } from "@mui/material"
import { EditProductForm } from "../../../component/product/EditProductForm"

export const EditProductScreen = () => {
    return (
        <Box p={2}>
            <Typography variant="h5" p={2}>แก้ไขสินค้า</Typography>
            <EditProductForm />
        </Box>
    )
}