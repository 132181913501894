import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Stack, Typography } from "@mui/material";
import toast from "react-hot-toast";
import ContractDocument from "../../component/Contract/ContractDocument";
import { useEffect, useRef, useState } from "react";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import { InstallmentPlanDocumentDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDocumentDto";
import MultipleFileUploadWithPreview from "../../component/MultipleFileUploadWithPreview";
import {
  useCreateContractDocumentMutation,
  useConfirmContractDocumentMutation,
  useRemoveLoanDocumentMutation,
  useUpdateLoanDraftContractDocumentMutation,
} from "../../client/query-client/shopFinancier/contract";
import { useUploadFileMutation } from "../../client/query-client/useUploadFileMutation";
import DialogConfirm from "../../component/DialogConfirm";
import { Spin } from "../../component/common/Spin";
import ConsentFormDocument from "../../component/Contract/ConsentFormDocument";

interface IFileUpload {
  document: InstallmentPlanDocumentDto;
  confirmed: boolean;
}
interface IProps {
  onSubmit: () => void;
  shopId: string | undefined;
  contract: InstallmentPlanDto | undefined;
}

const ContractDocuments: React.FC<IProps> = (props) => {
  const [fileUploads, setFileUploads] = useState<IFileUpload[]>([]);
  const [selectedDocumentRemove, setSelectedDocumentRemove] =
    useState<IFileUpload | null>(null);

  const contractRef = useRef<HTMLDivElement>(null);
  const consentFormRef = useRef<HTMLDivElement>(null);

  const createDocument = useCreateContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const uploadFileMutation = useUploadFileMutation();
  const confirmDocument = useConfirmContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const removeCustomerDocument = useRemoveLoanDocumentMutation(
    props.contract?.id || ""
  );

  const updateLoanDraftContractDocument =
    useUpdateLoanDraftContractDocumentMutation({
      contractId: props.contract?.id || "",
      shopId: props.shopId || "",
    });

  const generatePDF = async (
    element: HTMLDivElement | null,
    fileName: string
  ): Promise<void> => {
    try {
      const images = element?.querySelectorAll("img") || [];
      await Promise.all(
        Array.from(images).map(
          (img) =>
            new Promise((resolve) => {
              if (img.complete) {
                resolve(true);
              } else {
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
              }
            })
        )
      );

      const html2pdf = (await import("html2pdf.js")).default;
      const instance = html2pdf();
      const opt = {
        filename: `${fileName}.pdf`,
        margin: 10,
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
          font: "Kanit",
        },
        pagebreak: { mode: "avoid-all" },
      };

      await instance.set(opt).from(element).toPdf().save();
    } catch (error) {
      toast.error("เกิดข้อผิดพลาดขณะดาวน์โหลด");
    }
  };

  const handleFileChange = async (files: File[] | null): Promise<void> => {
    try {
      if (files && files.length > 0) {
        const promises = files.map(async (file) => {
          const document = await createDocument.mutateAsync({
            originalName: file.name,
            type: InstallmentPlanDocumentDto.type.CONTRACT,
          });

          await uploadFileMutation.mutateAsync({
            file: file,
            uploadUrl: document.uploadUrl || "",
          });

          setFileUploads((prev) => [...prev, { document, confirmed: false }]);
        });

        await Promise.all(promises);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDocumentRemove = async (
    documentId: string | undefined
  ): Promise<void> => {
    try {
      if (!documentId) return;

      await removeCustomerDocument.mutateAsync({
        loanDocumentId: documentId,
      });
      setFileUploads((prev) =>
        prev.filter((doc) => doc.document.id !== documentId)
      );
      setSelectedDocumentRemove(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const promises = fileUploads.map(async (data) => {
        if (!data.confirmed) {
          await confirmDocument.mutateAsync({
            loanId: props.contract?.id || "",
            objectKey: data.document.objectKey,
            type: InstallmentPlanDocumentDto.type.CONTRACT,
          });
        }
      });

      await Promise.all(promises);
      toast.success("บันทึกสำเร็จ");
      props.onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      props.contract &&
      props.contract.documents &&
      props.contract?.documents?.length > 0
    ) {
      const docs = props.contract.documents
        .filter((doc) => doc.type === InstallmentPlanDocumentDto.type.CONTRACT)
        .map((doc) => {
          return {
            document: doc,
            type: doc.type,
            confirmed: true,
          };
        });

      setFileUploads(docs);
    }
  }, [props.contract]);

  return (
    <Spin
      spinning={
        createDocument.isPending ||
        uploadFileMutation.isPending ||
        removeCustomerDocument.isPending
      }
    >
      <DialogConfirm
        isOpen={!!selectedDocumentRemove}
        title="ลบไฟล์"
        message="คุณต้องการลบไฟล์ใช่หรือไม่?"
        onClose={() => setSelectedDocumentRemove(null)}
        onConfirm={() =>
          handleDocumentRemove(selectedDocumentRemove?.document.id)
        }
        disable={removeCustomerDocument.isPending}
      />

      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Typography variant="h6">หลักฐานการทำสัญญา</Typography>
        <Stack sx={{ flexDirection: "row", gap: 1 }}>
          <Button
            variant="contained"
            onClick={() =>
              generatePDF(
                consentFormRef.current,
                props.contract?.transactionId
                  ? `${props.contract?.transactionId}_pdpa`
                  : "pdpa"
              )
            }
          >
            Download PDPA <DownloadIcon />
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              generatePDF(
                contractRef.current,
                props.contract?.transactionId
                  ? `${props.contract?.transactionId}_contract`
                  : "contract"
              )
            }
          >
            Download สัญญา <DownloadIcon />
          </Button>
        </Stack>
      </Stack>
      {props.contract && (
        <Stack mt={3} gap={3}>
          <ConsentFormDocument
            reference={consentFormRef}
            contract={props.contract}
          />
          <ContractDocument reference={contractRef} contract={props.contract} />
        </Stack>
      )}

      <Box mt={3}>
        <MultipleFileUploadWithPreview
          title="อัปโหลดสัญญา *"
          documents={fileUploads.flatMap((file) => file.document)}
          onChange={(files) => handleFileChange(files)}
          onRemove={(id) => {
            const documentRemove = fileUploads.find(
              (doc) => doc.document.id === id
            );
            if (documentRemove) {
              setSelectedDocumentRemove(documentRemove);
            }
          }}
          maxFiles={3}
        />
      </Box>

      <Stack direction="row" flexWrap="wrap" gap={3} sx={{ mt: 3 }}>
        <Button
          fullWidth
          variant="outlined"
          type="button"
          size="large"
          disabled
          sx={{ width: { xs: "100%", md: "200px" } }}
        >
          ย้อนกลับ
        </Button>
        <Button
          variant="contained"
          type="button"
          size="large"
          onClick={() => handleSubmit()}
          disabled={
            fileUploads.length === 0 ||
            confirmDocument.isPending ||
            updateLoanDraftContractDocument.isPending
          }
          sx={{ width: { xs: "100%", md: "200px" } }}
        >
          ส่งเอกสารสัญญา
        </Button>
      </Stack>
    </Spin>
  );
};

export default ContractDocuments;
