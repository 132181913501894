/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderItemRequest } from './OrderItemRequest';
export type CreateOrderRequest = {
    customerName: string;
    customerPhoneNumber: string;
    customerIdentityNumber?: string;
    customerPassportNumber?: string;
    customerEmail: string;
    discountType?: CreateOrderRequest.discountType;
    discountInput?: number;
    paymentMethod: CreateOrderRequest.paymentMethod;
    isVat?: boolean;
    note?: string;
    shopId: string;
    orderItems: Array<OrderItemRequest>;
};
export namespace CreateOrderRequest {
    export enum discountType {
        AMOUNT = 'AMOUNT',
        PERCENTAGE = 'PERCENTAGE',
    }
    export enum paymentMethod {
        CASH = 'CASH',
        CREDIT_CARD = 'CREDIT_CARD',
        BANK_TRANSFER = 'BANK_TRANSFER',
        INSTALLMENT = 'INSTALLMENT',
    }
}

