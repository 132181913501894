/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentSummarizeResponse = {
    capitalAmountLeft?: number;
    currentInterest?: number;
    accumulatedInterestPaid?: number;
    accumulatedFineAmountPaid?: number;
    profitAndLoss?: number;
    contractFee?: number;
    installmentType?: PaymentSummarizeResponse.installmentType;
    dueDate?: string;
};
export namespace PaymentSummarizeResponse {
    export enum installmentType {
        EFFECTIVE_RATE = 'EFFECTIVE_RATE',
        FIXED_RATE = 'FIXED_RATE',
    }
}

