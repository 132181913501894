import { Paper } from "@mui/material";
import { ComponentPropsWithoutRef } from "react";

export const PaperBackground = ({
  elevation,
  sx,
  children,
  ...rest
}: ComponentPropsWithoutRef<typeof Paper>) => {
  return (
    <Paper
      elevation={2}
      sx={{ p: 2, backgroundColor: "white", maxWidth: "100%", marginTop: 0.25 }}
      {...rest}
    >
      {children}
    </Paper>
  );
};
