import {
  Stack,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  FormLabel,
  Button,
  useTheme,
  TextField,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
  ProductDto,
  FinancierShopsGroupInstallmentOption,
  InstallmentPlanDto,
  InstallmentPlanDocumentDto,
} from "../../client/motalvip-apis/generated";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { TogglableLabel } from "../../component/Contract/TogglableLabel";
import {
  SearchableBrandField,
  SearchableMode,
} from "../../component/Contract/SearchableBrandField";
import { SearchableModelField } from "../../component/Contract/SearchableModelField";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";
import { renderSelectPlaceholder } from "../../util/renderSelectPlaceholder";
import {
  useConfirmContractDocumentMutation,
  useCreateContractDocumentMutation,
  useRemoveLoanDocumentMutation,
  useUpdateLoanDraftDeviceAndLoanMutation,
} from "../../client/query-client/shopFinancier/contract";
import { useUploadFileMutation } from "../../client/query-client/useUploadFileMutation";
import DialogConfirm from "../../component/DialogConfirm";
import FileUploadWithPreview from "../../component/FileUploadWithPreview";
import { Spin } from "../../component/common/Spin";

export interface IProductDetailForm
  extends Omit<
    StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
    "installmentCount"
  > {
  installmentCount?: FinancierShopsGroupInstallmentOption;
}

interface IFileUpload {
  document: InstallmentPlanDocumentDto;
  confirmed: boolean;
}

interface IProps {
  onSubmit: () => void;
  onBack: () => void;
  shopId: string | undefined;
  contract: InstallmentPlanDto | undefined;
}

const ProductDetails: React.FC<IProps> = (props): JSX.Element => {
  const [mode, setMode] = useState<"combobox" | "freetext">("combobox");
  const [fileUploads, setFileUploads] = useState<IFileUpload | null>(null);
  const [selectedDocumentRemove, setSelectedDocumentRemove] =
    useState<IFileUpload | null>(null);

  const theme = useTheme();
  const shopFinancier = useShopFinancierContext();
  const shopGroup = shopFinancier.shop?.financierShopsGroup;

  const updateDeviceAndLoan = useUpdateLoanDraftDeviceAndLoanMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const createDocument = useCreateContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const uploadFileMutation = useUploadFileMutation();
  const confirmDocument = useConfirmContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const removeCustomerDocument = useRemoveLoanDocumentMutation(
    props.contract?.id || ""
  );

  const schema = yup.object({
    purchaseCost: yup
      .number()
      .required("กรุณากรอกราคาสินค้า")
      .min(1, "ราคาสินค้าต้องมากกว่า 0")
      .max(1000000, "ราคาสินค้าต้องไม่เกิน 1,000,000")
      .typeError("กรุณากรอกราคาสินค้า"),
    loanbalance: yup.number(),
    downPaymentPercent: yup.number().required("กรุณาเลือกเปอร์เซ็นต์เงินดาวน์"),
    downPaymentAmount: yup.number().required(),
    downPaymentDate: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^\d{4}-\d{2}-\d{2}$/, "รูปแบบวันที่ไม่ถูกต้อง (YYYY-MM-DD)")
      .test("is-valid-date", "วันที่ไม่ถูกต้อง", (value) => {
        if (!value) return true;
        const date = new Date(value);
        return !isNaN(date.getTime());
      }),
    installmentCount: yup
      .object({
        monthAmount: yup.number().required("กรุณาเลือกจำนวนงวด"),
        interestRate: yup.number().required("กรุณาเลือกจำนวนงวด"),
      })
      .optional()
      .transform((value) => (!value ? undefined : value))
      .test("is-not-empty", "กรุณาเลือกจำนวนงวด", (value) => !!value),
    installmentAmount: yup
      .number()
      .transform((value) => (!value ? undefined : value)),
    installmentStartDate: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^\d{4}-\d{2}-\d{2}$/, "รูปแบบวันที่ไม่ถูกต้อง (YYYY-MM-DD)")
      .test("is-valid-date", "วันที่ไม่ถูกต้อง", (value) => {
        if (!value) return true;
        const date = new Date(value);
        return !isNaN(date.getTime());
      }),
    installmentFrequency: yup
      .number()
      .transform((value) => (!value ? undefined : value))
      .integer("ความถี่การผ่อนต้องเป็นตัวเลขจำนวนเต็ม")
      .min(1, "ความถี่การผ่อนต้องมากกว่า 0"),
    installmentFrequencyType: yup
      .mixed<StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType>()
      .oneOf(
        Object.values(
          StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType
        ),
        "ประเภทความถี่การผ่อนไม่ถูกต้อง"
      ),
    usedType: yup
      .mixed<StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType>()
      .oneOf(
        Object.values(StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType),
        "ประเภทการใช้งานไม่ถูกต้อง"
      ),
    brand: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ยี่ห้อสินค้าต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณาระบุยี่ห้อสินค้า",
        (value) => !!value && value.trim().length > 0
      ),
    model: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "รุ่นสินค้าต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณาระบุรุ่นสินค้า",
        (value) => !!value && value.trim().length > 0
      ),
    color: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(50, "สีสินค้าต้องไม่เกิน 50 ตัวอักษร"),
    description: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(500, "รายละเอียดไม่เกิน 500 ตัวอักษร"),
    deviceImei: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "IMEI ต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "IMEI-or-SerialNumber",
        "กรุณาระบุ IMEI หรือ Serial Number",
        function (value) {
          if (!value && !this.parent.deviceSerialNumber) {
            return this.createError({
              path: "deviceImei",
              message: "กรุณาระบุ IMEI หรือ Serial Number",
            });
          }
          return true;
        }
      ),
    deviceSerialNumber: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "หมายเลขเครื่องต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "IMEI-or-SerialNumber",
        "กรุณาระบุ IMEI หรือ Serial Number",
        function (value) {
          if (!value && !this.parent.deviceImei) {
            return this.createError({
              path: "deviceSerialNumber",
              message: "กรุณาระบุ IMEI หรือ Serial Number",
            });
          }
          return true;
        }
      ),
    interestRate: yup
      .number()
      .transform((value) => (!value ? undefined : value)),
    note: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(255, "หมายเหตุต้องไม่เกิน 255 ตัวอักษร"),
  });

  const initialFormValue: IProductDetailForm = {
    purchaseCost: 0,
    loanId: "",
    shopId: "",
    loanBalance: 0,
    downPaymentPercent: 0,
    downPaymentAmount: 0,
    installmentAmount: 0,
    installmentCount: undefined,
    downPaymentDate: dayjs().format("YYYY-MM-DD"),
    installmentStartDate: dayjs().format("YYYY-MM-DD"),
    installmentFrequency: 1,
    installmentFrequencyType:
      StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType
        .MONTH,
    usedType: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType.NEW,
    brand: "",
    model: "",
    color: "",
    description: "",
    deviceImei: "",
    deviceSerialNumber: "",
    note: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<IProductDetailForm>({
    defaultValues: {
      ...initialFormValue,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IProductDetailForm): Promise<void> => {
    try {
      const { installmentCount, ...rest } = data;

      if (fileUploads && !fileUploads?.confirmed) {
        await confirmDocument.mutateAsync({
          loanId: props.contract?.id || "",
          objectKey: fileUploads?.document.objectKey,
          type: InstallmentPlanDocumentDto.type.DEVICE_IMAGE,
        });
      }

      await updateDeviceAndLoan.mutateAsync({
        ...rest,
        installmentCount: installmentCount?.monthAmount,
        interestRate: installmentCount?.interestRate,
        installmentPlanFundSource:
          StepUpdateLoanDraftDeviceAndLoanApprovalRequest
            .installmentPlanFundSource.FINANCE,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onModeChanged = (): void => {
    setMode((prev) => (prev === "combobox" ? "freetext" : "combobox"));
    setValue("brand", undefined);
    setValue("model", undefined);
    setValue("color", undefined);
  };

  const handleFileChange = async (file: File | null): Promise<void> => {
    try {
      if (file) {
        const document = await createDocument.mutateAsync({
          originalName: file.name,
          type: InstallmentPlanDocumentDto.type.DEVICE_IMAGE,
        });

        await uploadFileMutation.mutateAsync({
          file: file,
          uploadUrl: document.uploadUrl || "",
        });

        setFileUploads({ document, confirmed: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDocumentRemove = async (
    data: IFileUpload | null
  ): Promise<void> => {
    if (!data) return;

    try {
      await removeCustomerDocument.mutateAsync({
        loanDocumentId: data.document?.id,
      });
      setFileUploads(null);
      setSelectedDocumentRemove(null);
    } catch (error) {
      console.error(error);
    }
  };

  const purchaseCostValue = watch("purchaseCost");
  const downPaymentPercentValue = watch("downPaymentPercent");
  const downPaymentAmountValue = watch("downPaymentAmount");
  const installmentCountValue = watch("installmentCount");

  const totalPrice = useMemo(() => {
    const purchaseCost = Number(purchaseCostValue);
    const installmentCount = installmentCountValue;
    const interestRate = Number(installmentCount?.interestRate);

    let loanBalance = 0;

    if (!purchaseCost || isNaN(purchaseCost) || purchaseCost <= 0) {
      loanBalance = 0;
    } else if (!installmentCount) {
      loanBalance = purchaseCost - downPaymentAmountValue;
    } else {
      loanBalance =
        (purchaseCost - downPaymentAmountValue) * (1 + interestRate / 100);
    }

    return Number(loanBalance.toFixed(2));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseCostValue, installmentCountValue, downPaymentAmountValue]);

  useEffect(() => {
    const purchaseCost = Number(purchaseCostValue);
    const downPaymentPercent = Number(downPaymentPercentValue);

    let downPaymentAmount = 0;

    if (!purchaseCost || isNaN(purchaseCost) || isNaN(downPaymentPercent)) {
      downPaymentAmount = 0;
    } else {
      downPaymentAmount = purchaseCost * (downPaymentPercent / 100);
    }

    setValue("downPaymentAmount", Number(downPaymentAmount.toFixed(2)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseCostValue, downPaymentPercentValue]);

  useEffect(() => {
    const purchaseCost = Number(purchaseCostValue);
    const downPaymentAmount = Number(downPaymentAmountValue);

    let loanBalance = 0;

    if (!purchaseCost || isNaN(purchaseCost) || purchaseCost <= 0) {
      loanBalance = 0;
    } else {
      loanBalance = purchaseCost - downPaymentAmount;
    }

    setValue("loanBalance", Number(loanBalance.toFixed(2)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseCostValue, installmentCountValue, downPaymentAmountValue]);

  useEffect(() => {
    const monthAmount = Number(installmentCountValue?.monthAmount);
    let installmentAmount = 0;

    if (!totalPrice || isNaN(totalPrice) || isNaN(monthAmount)) {
      installmentAmount = 0;
    } else if (monthAmount <= 0) {
      installmentAmount = 0;
    } else {
      installmentAmount = totalPrice / monthAmount;
    }

    setValue("installmentAmount", Number(installmentAmount.toFixed(2)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, installmentCountValue?.monthAmount]);

  useEffect(() => {
    if (props.contract && props.contract.product?.brand) {
      reset({
        purchaseCost: props.contract.product?.purchaseCost,
        loanId: props.contract.id,
        shopId: props.contract.shopId,
        loanBalance: props.contract.loanBalance,
        downPaymentPercent: props.contract.downPaymentPercent,
        downPaymentAmount: props.contract.downPaymentAmount,
        installmentAmount: props.contract.installmentAmount,
        installmentCount: {
          monthAmount: props.contract.installmentCount,
          interestRate: props.contract.interestRate,
        },
        downPaymentDate: props.contract.downPaymentDate,
        installmentStartDate: props.contract.installmentStartDate,
        installmentFrequency: props.contract.installmentFrequency,
        installmentFrequencyType: props.contract.installmentFrequencyType,
        usedType: props.contract.product?.usedType,
        brand: props.contract.product?.brand,
        model: props.contract.product?.model,
        color: props.contract.product?.color,
        description: props.contract.product?.description,
        deviceImei: props.contract.product?.imei,
        deviceSerialNumber: props.contract.product?.serialNumber,
      });

      const doc = props.contract.documents?.find(
        (doc) => doc.type === InstallmentPlanDocumentDto.type.DEVICE_IMAGE
      );
      if (doc) {
        setFileUploads({
          document: doc,
          confirmed: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contract]);

  return (
    <Spin
      spinning={
        createDocument.isPending ||
        uploadFileMutation.isPending ||
        removeCustomerDocument.isPending
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogConfirm
          isOpen={!!selectedDocumentRemove}
          title="ลบไฟล์"
          message="คุณต้องการลบไฟล์ใช่หรือไม่?"
          onClose={() => setSelectedDocumentRemove(null)}
          onConfirm={() => handleDocumentRemove(selectedDocumentRemove)}
          disable={removeCustomerDocument.isPending}
        />

        <Stack gap={2}>
          <Typography variant="h6">ข้อมูลสินเชื่อ</Typography>
          <Stack direction={{ lg: "row" }} gap={2}>
            <FormControl fullWidth error={!!errors.purchaseCost}>
              <FormLabel>ราคาสินค้า</FormLabel>
              <Controller
                name="purchaseCost"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.purchaseCost}
                    helperText={errors?.purchaseCost?.message}
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Stack>

          <Stack direction={{ lg: "row" }} gap={2}>
            <FormControl fullWidth error={!!errors.downPaymentPercent}>
              <FormLabel id="type-label">เปอร์เซ็นต์เงินดาวน์</FormLabel>
              <Controller
                name="downPaymentPercent"
                control={control}
                render={({ field: { value, ...field } }) => (
                  <Select
                    {...field}
                    labelId="type-label"
                    id="type"
                    size="small"
                    variant="outlined"
                    error={!!errors?.downPaymentPercent}
                    displayEmpty
                    value={value || ""}
                    renderValue={(selected) => {
                      return renderSelectPlaceholder({
                        selected: selected && selected + "%",
                        placeholder: "กรุณาเลือกเปอร์เซ็นต์เงินดาวน์",
                        color: theme.palette.grey[500],
                      });
                    }}
                  >
                    {shopGroup?.downPaymentAmountList &&
                    shopGroup?.downPaymentAmountList.length > 0 ? (
                      shopGroup?.downPaymentAmountList.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}%
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>ไม่มีข้อมูล</MenuItem>
                    )}
                  </Select>
                )}
              />
              {errors?.downPaymentPercent && (
                <FormHelperText error>
                  {errors.downPaymentPercent.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={!!errors.installmentCount}>
              <FormLabel id="type-label">จำนวนงวด</FormLabel>
              <Controller
                name="installmentCount"
                control={control}
                render={({ field: { value, ...field } }) => (
                  <Select
                    {...field}
                    labelId="type-label"
                    id="type"
                    size="small"
                    variant="outlined"
                    error={!!errors?.installmentCount}
                    displayEmpty
                    value={value ? JSON.stringify(value) : ""}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      const selectedObject =
                        shopGroup?.installmentCountList?.find(
                          (item) => JSON.stringify(item) === selectedValue
                        );
                      setValue("installmentCount", selectedObject);
                    }}
                    renderValue={(selected) => {
                      const selectedObject =
                        shopGroup?.installmentCountList?.find(
                          (item) => JSON.stringify(item) === selected
                        );

                      return renderSelectPlaceholder({
                        selected:
                          selectedObject &&
                          `${selectedObject.monthAmount} เดือน`,
                        placeholder: "กรุณาเลือกจำนวนงวด",
                        color: theme.palette.grey[500],
                      });
                    }}
                  >
                    {shopGroup?.installmentCountList &&
                    shopGroup?.installmentCountList.length > 0 ? (
                      shopGroup?.installmentCountList.map((item, index) => (
                        <MenuItem key={index} value={JSON.stringify(item)}>
                          {item.monthAmount} เดือน
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>ไม่มีข้อมูล</MenuItem>
                    )}
                  </Select>
                )}
              />
              {errors?.installmentCount && (
                <FormHelperText error>
                  {errors.installmentCount.message ||
                    errors.installmentCount.monthAmount?.message ||
                    errors.installmentCount.interestRate?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack direction={{ lg: "row" }} gap={2}>
            <FormControl fullWidth error={!!errors.installmentAmount}>
              <FormLabel>ยอดเงินผ่อนต่องวด</FormLabel>
              <Controller
                name="installmentAmount"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.installmentAmount}
                    helperText={errors?.installmentAmount?.message}
                    size="small"
                    sx={{ backgroundColor: theme.palette.grey[200] }}
                    value={
                      field.value ? Number(field.value).toFixed(2) : "0.00"
                    }
                    disabled
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>เงินดาวน์</FormLabel>
              <TextField
                type="number"
                value={downPaymentAmountValue.toFixed(2)}
                size="small"
                variant="outlined"
                sx={{ backgroundColor: theme.palette.grey[200] }}
                disabled
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>ราคาเช่าซื้อรวม</FormLabel>
              <TextField
                type="number"
                value={totalPrice?.toFixed(2)}
                size="small"
                variant="outlined"
                sx={{ backgroundColor: theme.palette.grey[200] }}
                disabled
              />
            </FormControl>
          </Stack>
          <Stack direction={{ lg: "row" }} gap={2}>
            <FormControl fullWidth>
              <FormLabel>วันที่จ่ายเงินดาวน์ (วัน/เดือน/ปี ค.ศ.)</FormLabel>
              <Controller
                control={control}
                name="downPaymentDate"
                render={({ field }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                    onChange={(date) =>
                      field.onChange(date ? date.format("YYYY-MM-DD") : "")
                    }
                    slotProps={{
                      textField: { size: "small", variant: "outlined" },
                    }}
                  />
                )}
              />
              {errors.downPaymentDate && (
                <FormHelperText error>
                  {errors.downPaymentDate.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                }}
              >
                วันที่เริ่มผ่อนงวดแรก (วัน/เดือน/ปี ค.ศ.)
              </FormLabel>
              <Controller
                control={control}
                name="installmentStartDate"
                render={({ field }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                    onChange={(date) =>
                      field.onChange(date ? date.format("YYYY-MM-DD") : "")
                    }
                    slotProps={{
                      textField: { size: "small", variant: "outlined" },
                    }}
                  />
                )}
              />
              {errors.installmentStartDate && (
                <FormHelperText error>
                  {errors.installmentStartDate.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>ระยะเวลาชำระต่องวดทุกๆ</FormLabel>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={`${watch("installmentFrequency")},${watch(
                    "installmentFrequencyType"
                  )}`}
                  onChange={(e) => {
                    const value = (e.target.value as string).split(",");
                    setValue("installmentFrequency", parseInt(value[0]));
                    setValue(
                      "installmentFrequencyType",
                      value[1] as StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType
                    );
                  }}
                  disabled
                >
                  <MenuItem value={"7,DAY"}>7 วัน</MenuItem>
                  <MenuItem value={"15,DAY"}>15 วัน</MenuItem>
                  <MenuItem value={"30,DAY"}>30 วัน</MenuItem>
                  <MenuItem value={"1,MONTH"}>1 เดือน</MenuItem>
                </Select>
              </FormControl>
            </FormControl>
          </Stack>
        </Stack>
        <Stack gap={2} mt={3}>
          <Typography variant="h6">ข้อมูลเครื่อง</Typography>
          <Controller
            control={control}
            name="usedType"
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value={ProductDto.usedType.NEW}
                  label="สินค้าใหม่"
                  control={<Radio />}
                />
                <FormControlLabel
                  value={ProductDto.usedType.USED}
                  label="สินค้าใช้แล้ว"
                  control={<Radio />}
                />
              </RadioGroup>
            )}
          />
          {mode === "combobox" ? (
            <FormControl required>
              <TogglableLabel
                toggleFormModeText="เลือกจากรายการ"
                onModeChanged={onModeChanged}
                label="แบรนด์และรุ่น"
              />

              <div
                style={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <SearchableBrandField
                  label="แบรนด์"
                  onSelected={(brand) => {
                    setValue("brand", brand);
                  }}
                  control={control}
                  error={errors.brand?.message}
                  mode={SearchableMode.COMBOBOX}
                />
                <SearchableModelField
                  label="รุ่น"
                  onSelected={(model) => {
                    setValue("model", model);
                  }}
                  disabled={!watch("brand")}
                  brand={watch("brand")!}
                  mode={SearchableMode.COMBOBOX}
                  control={control}
                  error={errors.model?.message}
                />
              </div>
            </FormControl>
          ) : (
            <>
              <FormControl fullWidth error={!!errors.brand}>
                <TogglableLabel
                  toggleFormModeText="รุ่นอื่นๆโปรดระบุ"
                  onModeChanged={onModeChanged}
                  label="แบรนด์และรุ่น"
                />
                <Controller
                  name="brand"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="แบรนด์"
                      error={!!errors?.brand}
                      helperText={errors?.brand?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              <Stack direction="row" gap={2}>
                <FormControl fullWidth error={!!errors.model}>
                  <Controller
                    name="model"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="รุ่น"
                        error={!!errors?.model}
                        helperText={errors?.model?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth error={!!errors.color}>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="สี"
                        error={!!errors?.color}
                        helperText={errors?.color?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </>
          )}

          <FormControl fullWidth error={!!errors.description}>
            <FormLabel>รายละเอียด (อุปกรณ์เสริม, อื่นๆ)</FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  size="small"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth error={!!errors.deviceImei}>
            <FormLabel required>อีมี่ (IMEI)</FormLabel>
            <Controller
              name="deviceImei"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.deviceImei}
                  helperText={errors?.deviceImei?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth error={!!errors.deviceSerialNumber}>
            <FormLabel required>Serial Number</FormLabel>
            <Controller
              name="deviceSerialNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.deviceSerialNumber}
                  helperText={errors?.deviceSerialNumber?.message}
                  size="small"
                />
              )}
            />
          </FormControl>

          <FileUploadWithPreview
            title="รูปสินค้า"
            document={fileUploads?.document}
            onChange={(file) => handleFileChange(file)}
            onRemove={() => {
              setSelectedDocumentRemove(fileUploads);
            }}
          />

          <FormControl fullWidth error={!!errors.note}>
            <FormLabel>หมายเหตุ</FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  error={!!errors?.note}
                  helperText={errors?.note?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" flexWrap="wrap" gap={3} sx={{ mt: 3 }}>
          <Button
            fullWidth
            variant="outlined"
            type="button"
            size="large"
            onClick={() => props.onBack()}
            sx={{ width: { xs: "100%", md: "200px" } }}
          >
            ย้อนกลับ
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="large"
            disabled={
              confirmDocument.isPending || updateDeviceAndLoan.isPending
            }
            sx={{ width: { xs: "100%", md: "200px" } }}
          >
            ส่งคำขออนุมัติ
          </Button>
        </Stack>
      </form>
    </Spin>
  );
};

export default ProductDetails;
