import { Box, FormControl, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { InstallmentPlanDto, ProductDto } from "../../client/motalvip-apis/generated";
import CustomInputLabel from "../CustomInputLabel";

// const DEFAULT_LIMIT = 300;
// const DEBOUNCE_DELAY = 250;

// const enum SearchableMode {
//   COMBOBOX,
//   FREETEXT,
// }

const Label = ({ label }: { label: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 1,
      }}
    >
      <CustomInputLabel>{label}</CustomInputLabel>
    </Box>
  );
};

// const SearchableBrandField = ({
//   onSelected,
//   label,
//   mode,
// }: {
//   onSelected?: (device: string) => void;
//   label: string;
//   mode: SearchableMode;
// }) => {
//   const { data } = useSearchMasterDeviceBrands({
//     limit: DEFAULT_LIMIT,
//     brand: "",
//     page: 0,
//   });

//   const mapped = useMemo(() => {
//     const mapped =
//       data?.data.map((d) => {
//         return d.displayName;
//       }) ?? [];
//     // This is to get rid of duplicate in case there is a bug in the backend
//     // that return duplicate data.
//     const set = new Set(mapped);
//     const arr = Array.from(set);
//     const upperCaseFirstLetter = (str: string) =>
//       str.charAt(0).toUpperCase() + str.slice(1);
//     const upped = arr.map(upperCaseFirstLetter);
//     return upped;
//   }, [data?.data]);

//   const onComboListSelected = (value?: string | null) => {
//     if (!value) return;
//     if (!data?.data) return;

//     onSelected?.(value);
//   };

//   switch (mode) {
//     case SearchableMode.FREETEXT: {
//       return (
//         <Autocomplete
//           freeSolo
//           onInputChange={(_event, newInputValue) => {
//             onComboListSelected(newInputValue);
//           }}
//           options={mapped}
//           onChange={(_e, v) => {
//             onComboListSelected(v);
//           }}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label={label}
//               size="small"
//               variant="outlined"
//             />
//           )}
//         />
//       );
//     }
//     case SearchableMode.COMBOBOX:
//     default: {
//       return (
//         <>
//           <Autocomplete
//             onInputChange={(_event, newInputValue) => {
//               onComboListSelected(newInputValue);
//             }}
//             disablePortal
//             options={mapped}
//             sx={{ maxWidth: 300, flex: 1 }}
//             onChange={(_e, v) => {
//               onComboListSelected(v);
//             }}
//             renderInput={(params) => <TextField {...params} label={label} />}
//           />
//         </>
//       );
//     }
//   }
// };

// const SearchableModelField = ({
//   onSelected,
//   label,
//   brand,
//   disabled,
//   mode,
// }: {
//   onSelected?: (device: string) => void;
//   label: string;
//   brand: string;
//   disabled: boolean;
//   mode: SearchableMode;
// }) => {
//   const [inputValue, setInputValue] = useState("");
//   const debouncedSearch = useDebouncedValue(inputValue, DEBOUNCE_DELAY);
//   const { data } = useSearchMasterDeviceModels({
//     limit: DEFAULT_LIMIT,
//     model: debouncedSearch,
//     brand,
//     page: 0,
//   });
//   const mapped = data?.data.map((d) => d.displayName) ?? [];

//   const onComboListSelected = (value?: string | null) => {
//     if (!value) return;
//     if (!data?.data) return;

//     onSelected?.(value);
//   };

//   switch (mode) {
//     case SearchableMode.FREETEXT: {
//       return (
//         <Autocomplete
//           freeSolo
//           options={mapped}
//           filterOptions={(options) => {
//             return options;
//           }}
//           onChange={(_e, v) => {
//             onComboListSelected(v);
//           }}
//           onInputChange={(_event, newInputValue) => {
//             setInputValue(newInputValue);
//             onComboListSelected(newInputValue);
//           }}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label={label}
//               size="small"
//               variant="outlined"
//             />
//           )}
//           disabled={disabled}
//         />
//       );
//     }
//     case SearchableMode.COMBOBOX:
//     default: {
//       return (
//         <Autocomplete
//           disablePortal
//           options={mapped}
//           sx={{ maxWidth: 300, flex: 1 }}
//           filterOptions={(options) => {
//             return options;
//           }}
//           onChange={(_e, v) => {
//             onComboListSelected(v);
//           }}
//           onInputChange={(_event, newInputValue) => {
//             setInputValue(newInputValue);
//             onComboListSelected(newInputValue);
//           }}
//           renderInput={(params) => <TextField {...params} label={label} />}
//           disabled={disabled}
//         />
//       );
//     }
//   }
// };

// TODO proper rewrite after we clear all the data.
export const InstallmentMasterDeviceModelField = ({
  form,
}: {
  form: UseFormReturn<InstallmentPlanDto, any, undefined>;
}) => {
  // const [mode, setMode] = useState<"combobox" | "freetext">("freetext");

  // const onModeChanged = () => {
  //   setMode((prev) => (prev === "combobox" ? "freetext" : "combobox"));
  //   form.setValue("product.brand", undefined);
  //   form.setValue("product.model", undefined);
  //   form.setValue("product.color", undefined);
  // };

  return (
    <>
      <Stack direction="row" gap={2}>
        <FormControl fullWidth>
          <CustomInputLabel>แบรนด์ *</CustomInputLabel>
          <TextField
            size="small"
            variant="outlined"
            required
            onChange={(e) => {
              form.setValue("product.brand", e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <CustomInputLabel>OS</CustomInputLabel>
          <Controller
            control={form.control}
            name="product.osType"
            render={({ field }) => (
              <Select
                id="demo-simple-select-helper"
                {...field}
                size="small"
              >
                <MenuItem
                  value={
                    ProductDto.osType.ANDROID
                  }
                >
                  Android
                </MenuItem>
                <MenuItem
                  value={
                    ProductDto.osType.IOS
                  }
                >
                  iOS
                </MenuItem>
                <MenuItem
                  value={
                    ProductDto.osType.OTHERS
                  }
                >
                  อื่นๆ
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Stack>
      <Stack direction="row" gap={2}>
        <FormControl fullWidth>
          <Label label="รุ่น *" />
          <TextField
            required
            size="small"
            variant="outlined"
            onChange={(e) => {
              form.setValue("product.model", e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Label label="สี" />
          <TextField
            {...form.register("product.color")}
            size="small"
            variant="outlined"
          />
        </FormControl>
      </Stack>
    </>
  );
};
