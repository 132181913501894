import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ShopFinancierMenuItem } from "./ShopFinancierMenuItem";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";

type ShopDrawerProps = {
  shopId: string;
};

export const ShopFinancierDrawer = (props: ShopDrawerProps) => {
  const shopFinancierCtx = useShopFinancierContext();

  return (
    <div>
      <Toolbar />
      <Stack direction="row" alignItems="center" gap={2} sx={{ pt: 2, px: 2 }}>
        <Avatar
          alt={shopFinancierCtx.shop?.financier?.name}
          src={shopFinancierCtx.shop?.financier?.avatarUrl}
        />
        <Typography variant="subtitle1">
          {shopFinancierCtx.shop?.financier?.name || "ไม่มีชื่อ"}
        </Typography>
      </Stack>
      <List sx={{ p: 0, color: "#9e9e9e" }}>
        <Box
          sx={{
            pt: 2,
            pb: 1,
            px: 3,
          }}
        >
          <Typography variant="body2">ดำเนินงาน</Typography>
        </Box>
        {shopFinancierCtx.menuData?.map((menu) => {
          return (
            <ListItem key={menu.label} disablePadding>
              <ShopFinancierMenuItem
                menuId={menu.key}
                link={menu.key.replace(":shopId", props.shopId)}
                icon={menu.icon}
                label={menu.label}
                children={menu.children?.map((c) => {
                  return {
                    menuId: c.key,
                    link: c.key.replace(":shopId", props.shopId),
                    label: c.label,
                    isRequiredOwner: c.isRequiredOwner,
                  };
                })}
                disabled={menu.key === "/:shopId/coming-soon"}
              />
            </ListItem>
          );
        })}
        <Box sx={{ p: 1 }} />
        <Divider sx={{ backgroundColor: "#e0e0e0" }} />
        <Box
          sx={{
            pt: 2,
            pb: 1,
            px: 3,
          }}
        >
          <Typography variant="body2">ตั้งค่า</Typography>
        </Box>
        {shopFinancierCtx.shopMenuData?.map((menu) => {
          return (
            <ListItem key={menu.label} disablePadding>
              <ShopFinancierMenuItem
                menuId={menu.key}
                link={menu.key.replace(":shopId", props.shopId)}
                icon={menu.icon}
                label={menu.label}
                children={menu.children?.map((c) => {
                  return {
                    menuId: c.key,
                    link: c.key.replace(":shopId", props.shopId),
                    label: c.label,
                    isRequiredOwner: c.isRequiredOwner,
                  };
                })}
                disabled={menu.key === "/shop-finance/:shopId/coming-soon"}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
