import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";

export const useListDevices = ({
  enabled,
  page,
  shopId,
  search,
}: {
  shopId: string | null;
  enabled: boolean;
  page: number;
  search?: string;
}) => {
  const backendClient = useBackendApiClient();
  return useQuery({
    placeholderData: keepPreviousData,
    enabled: Boolean(shopId) && enabled,
    queryKey: [
      "deviceManagement",
      "listDevices",
      shopId,
      enabled,
      page,
      search,
    ],
    queryFn: () => {
      return backendClient.deviceManagement.listDevices({
        shopId: shopId!,
        page,
        search,
      });
    },
  });
};
