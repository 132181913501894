/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GatewayPaymentDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    amount: number;
    reference: string;
    customerFullName?: string;
    customerPhone?: string;
    status: GatewayPaymentDto.status;
    createdBy?: string;
    deletedBy?: string;
};
export namespace GatewayPaymentDto {
    export enum status {
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
    }
}

