import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/th";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams, useSearchParams } from "react-router-dom";
import {
  InstallmentPlanDocumentDto,
  InstallmentTerm,
} from "../../../client/motalvip-apis/generated";
import { useCreateInstallmentPlanDocumentMutation } from "../../../client/query-client/useCreateInstallmentPlanDocumentMutation";
import { useDeleteInstallmentPlanDocumentMutation } from "../../../client/query-client/useDeleteInstallmentPlanDocumentMutation";
import { useInstallmentPlanQuery } from "../../../client/query-client/useInstallmentPlanQuery";
import { useUploadFileMutation } from "../../../client/query-client/useUploadFileMutation";
import LocationTracker from "../../../component/common/LocationTracker";
import { PaymentHistory } from "../../../component/common/PaymentHistory";
import { Spin } from "../../../component/common/Spin";
import { PaymentTable } from "../../../component/Installment/InstallmentPaymentTable";
import { InstallmentPlanDeleteDialog } from "../../../component/Installment/InstallmentPlanDeleteDialog";
import { InstallmentPlanDocumentCard } from "../../../component/Installment/InstallmentPlanDocumentCard";
import { InstallmentTermTable } from "../../../component/Installment/InstallmentTermTable";
import { LoanOverview } from "../../../component/Installment/tabs/LoanOverview";
import { PaperBackground } from "../../../component/Shared/PaperBackground";

interface FileUploadStatus {
  installmentPlanDocumentId: string;
  uploaded: boolean;
}
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ maxWidth: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

type Props = {};
export const InstallmentPlanDetailScreen = (props: Props) => {
  const { shopId, installmentPlanId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<number>(
    Number(searchParams.get("tab")) || 0
  );

  const installmentPlanQuery = useInstallmentPlanQuery(installmentPlanId || "");
  const createInstallmentPlanDocumentMutation =
    useCreateInstallmentPlanDocumentMutation(installmentPlanId || "");
  const deleteInstallmentPlanDocumentMutation =
    useDeleteInstallmentPlanDocumentMutation(installmentPlanId || "");
  const uploadFileMutation = useUploadFileMutation();

  const [fileUploadStatuses, setFileUploadStatuses] = useState<
    FileUploadStatus[]
  >([]);
  const [activeInstallmentTerm, setActiveInstallmentTerm] =
    useState<InstallmentTerm>();

  const [installmentTerms, setInstallmentTerms] = useState<InstallmentTerm[]>();

  // Installment Delete Dialog
  const [openInstallmentPlanDeleteDialog, setOpenInstallmentPlanDeleteDialog] =
    useState<boolean>(false);

  useEffect(() => {
    setInstallmentTerms(
      installmentPlanQuery.data?.installmentTerms?.sort((a, b) => {
        return (a.termNumber || 0) - (b.termNumber || 0);
      })
    );
  }, [installmentPlanQuery.data?.installmentTerms]);
  useEffect(() => {
    setActiveInstallmentTerm(
      installmentPlanQuery.data?.installmentTerms?.find(
        (i) => i.termNumber === installmentPlanQuery.data.activeTermNumber
      )
    );
  }, [
    installmentPlanQuery.data?.installmentTerms,
    installmentPlanQuery.data?.activeTermNumber,
  ]);

  useEffect(() => {
    if (selectedTab !== Number(searchParams.get("tab"))) {
      const searchParams = new URLSearchParams();
      searchParams.set("tab", selectedTab.toString());
      setSearchParams(searchParams, { replace: true });
    }
  }, [selectedTab, searchParams, setSearchParams]);

  const onDrop = async (files: File[]) => {
    await Promise.all(
      files.map(
        (file) =>
          new Promise<void>(async (resolve, reject) => {
            const res = await createInstallmentPlanDocumentMutation.mutateAsync(
              {
                originalName: file.name,
                type: InstallmentPlanDocumentDto.type.OTHERS,
                installmentPlanId: installmentPlanId || "",
              },
              {
                onError: reject,
              }
            );
            await uploadFileMutation.mutateAsync(
              {
                file: file,
                uploadUrl: res.uploadUrl || "",
              },
              {
                onError: reject,
              }
            );
            setFileUploadStatuses((prev) => [
              ...prev,
              { installmentPlanDocumentId: res.id || "", uploaded: true },
            ]);
            resolve();
          })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Spin spinning={installmentPlanQuery.isLoading}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
          <Box p={2} mb={16} maxWidth="100vw">
            <Box sx={{ justifyContent: "space-between" }}>
              <Stack direction="column">
                <Typography
                  variant="h5"
                  sx={{ pb: 2 }}
                >{`รายการติดตามเคส ลำดับ ${installmentPlanQuery.data?.sequenceNumber}`}</Typography>
              </Stack>
            </Box>

            <Paper elevation={2} sx={{ backgroundColor: "white" }}>
              <Tabs
                variant="scrollable"
                value={selectedTab}
                onChange={onTabChange}
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="ข้อมูลสินเชื่อ" />
                <Tab label="ตารางงวดการผ่อน" />
                <Tab label="ประวัติการจ่ายเงิน" />
                <Tab label="ประวัติการจ่ายเงินผ่าน Payment Gateway" />
                <Tab label="ไฟล์แนบ" />
                <Tab label="ติดตามตำแหน่ง" />
              </Tabs>
            </Paper>

            <TabPanel value={selectedTab} index={0}>
              <LoanOverview
                installmentPlanQuery={installmentPlanQuery.data}
                activeInstallmentTerm={activeInstallmentTerm}
                installmentPlanId={installmentPlanId}
                shopId={shopId}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <PaperBackground>
                <InstallmentTermTable
                  payment={installmentPlanQuery.data?.payments}
                  data={installmentTerms}
                  showPayment={true}
                />
              </PaperBackground>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <PaperBackground>
                <PaymentTable
                  data={installmentPlanQuery.data?.payments}
                  lastestPaymentId={
                    installmentPlanQuery.data?.activeInstallmentPaymentId
                  }
                />
              </PaperBackground>
            </TabPanel>
            <TabPanel index={3} value={selectedTab}>
              <PaperBackground>
                <PaymentHistory type={"LOAN"} />
              </PaperBackground>
            </TabPanel>

            <TabPanel value={selectedTab} index={4}>
              <Box sx={{ p: 2, bgcolor: "white" }}>
                <Stack gap={2}>
                  <Box
                    {...getRootProps()}
                    sx={{
                      width: "100%",
                      border: "2px dashed darkgray",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      padding: 4,
                      textAlign: "center",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#b3e5fc",
                        color: "#03a9f4",
                        borderColor: "#03a9f4",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "120px",
                    }}
                  >
                    <input {...getInputProps()} />
                    <Stack direction="column" alignItems="center" gap={1}>
                      <FileUploadIcon />
                      <Typography>อัพโหลดไฟล์</Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Stack
                      sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                      direction={"row"}
                      gap={2}
                    >
                      {installmentPlanQuery.data?.documents?.map((doc) => (
                        <InstallmentPlanDocumentCard
                          document={doc}
                          uploaded={
                            fileUploadStatuses.find(
                              (f) => f.installmentPlanDocumentId === doc.id
                            )?.uploaded || true
                          }
                          onDelete={() => {
                            deleteInstallmentPlanDocumentMutation.mutate({
                              loanDocumentId: doc.id,
                            });
                          }}
                          onDownload={() => {}}
                        />
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
              <Box sx={{ p: 2, bgcolor: "white" }}>
                <LocationTracker
                  imei={installmentPlanQuery.data?.product.imei}
                  serialNumber={installmentPlanQuery.data?.product.serialNumber}
                />
              </Box>
            </TabPanel>
          </Box>
          <InstallmentPlanDeleteDialog
            open={openInstallmentPlanDeleteDialog}
            onClose={() => setOpenInstallmentPlanDeleteDialog(false)}
            installmentPlanDto={installmentPlanQuery.data}
          />
        </LocalizationProvider>
      </Spin>
    </>
  );
};
