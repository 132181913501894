/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Financier } from './Financier';
import type { User } from './User';
export type FinancierUser = {
    id?: string;
    financierId?: string;
    userId?: string;
    commissionAmount?: number;
    role?: FinancierUser.role;
    financier?: Financier;
    user?: User;
};
export namespace FinancierUser {
    export enum role {
        OWNER = 'OWNER',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
        SHOP_AGENT = 'SHOP_AGENT',
    }
}

