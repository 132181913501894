import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";

export interface ShopMenuItemProps {
  menuId: string;
  link: string;
  icon: React.ReactNode;
  label: string;
  children?: {
    menuId: string;
    link: string;
    label: string;
    isRequiredOwner: boolean;
  }[];
  disabled?: boolean;
}

export const ShopFinancierMenuItem = (props: ShopMenuItemProps) => {
  const shopFinancierCtx = useShopFinancierContext();
  const [open, setOpen] = useState(true);
  const nav = useNavigate();

  return (
    <Stack direction="column" width="100%">
      <Stack
        direction="column"
        width="100%"
        sx={{
          px: "4px",
        }}
      >
        <ListItemButton
          sx={{
            mx: 0,
            my: "2px",
            py: "8px",
            borderRadius: 2,
            border:
              props.menuId === shopFinancierCtx.selectedMenu
                ? "1px solid #e0e0e0"
                : "1px solid #e0e0e0",
            backgroundColor:
              props.menuId === shopFinancierCtx.selectedMenu
                ? "#263238"
                : "white",
            color:
              props.menuId === shopFinancierCtx.selectedMenu
                ? "white"
                : "black",
            "& .MuiListItemIcon-root": {
              color:
                props.menuId === shopFinancierCtx.selectedMenu
                  ? "white"
                  : "black",
            },
            "&:hover": {
              "& .MuiListItemIcon-root": {
                color:
                  props.menuId === shopFinancierCtx.selectedMenu
                    ? "white"
                    : "black",
              },
              color:
                props.menuId === shopFinancierCtx.selectedMenu
                  ? "white"
                  : "black",
              backgroundColor:
                props.menuId === shopFinancierCtx.selectedMenu
                  ? "#263238"
                  : "#eceff1",
            },
          }}
          disabled={props.disabled}
          onClick={() => {
            if (!props.children) {
              shopFinancierCtx.setSelectedMenu(props.menuId);
              shopFinancierCtx.setSelectedSubMenu("");
              nav(props.link);
            } else {
              setOpen(!open);
            }
          }}
        >
          <ListItemIcon
            sx={{
              px: 0,
              minWidth: 36,
            }}
          >
            {props.icon}
          </ListItemIcon>
          <ListItemText sx={{ p: 0 }} primary={props.label} />
          {props.children ? (
            open ? (
              <ExpandMoreOutlinedIcon />
            ) : (
              <ExpandLessIcon />
            )
          ) : (
            <></>
          )}
        </ListItemButton>
      </Stack>
      {props.children && !props.disabled && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {props.children.map((p, index) => {
            return (
              <Stack
                key={index}
                direction="column"
                width="100%"
                sx={{
                  py: 0,
                  m: 0,
                  px: "4px",
                }}
              >
                <ListItemButton
                  sx={{
                    mx: 0,
                    my: "1px",
                    px: 2,
                    py: 0,
                    minWidth: 36,
                    borderRadius: 2,
                    color:
                      p.menuId === shopFinancierCtx.selectedSubMenu
                        ? "white"
                        : "black",
                    backgroundColor:
                      p.menuId === shopFinancierCtx.selectedSubMenu
                        ? "#263238"
                        : props.menuId === shopFinancierCtx.selectedMenu
                        ? "#eceff1"
                        : "white",
                    "&:hover": {
                      color:
                        p.menuId === shopFinancierCtx.selectedSubMenu
                          ? "white"
                          : "black",
                      backgroundColor:
                        p.menuId === shopFinancierCtx.selectedSubMenu
                          ? "#263238"
                          : "#eceff1",
                    },
                  }}
                  disabled={props.disabled}
                  onClick={() => {
                    shopFinancierCtx.setSelectedMenu(props.menuId);
                    shopFinancierCtx.setSelectedSubMenu(p.menuId);
                    nav(p.link);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      px: 0,
                      minWidth: 36,
                    }}
                  ></ListItemIcon>
                  <ListItemText sx={{ p: 0 }} primary={p.label} />
                </ListItemButton>
              </Stack>
            );
          })}
        </Collapse>
      )}
    </Stack>
  );
};
