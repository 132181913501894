import { useMemo } from "react";
import { useSearchMasterDeviceBrands } from "../../client/query-client";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  useTheme,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { IProductDetailForm } from "../../layout/ShopFinancierContract/ProductDetails";

const DEFAULT_LIMIT = 300;

export const enum SearchableMode {
  COMBOBOX,
  FREETEXT,
}

interface IProps {
  onSelected?: (value: string) => void;
  label: string;
  control: Control<IProductDetailForm>;
  error?: string;
  mode: SearchableMode;
  disabled?: boolean;
}

export const SearchableBrandField: React.FC<IProps> = ({
  onSelected,
  label,
  control,
  error,
  mode,
  disabled,
}): JSX.Element => {
  const { data } = useSearchMasterDeviceBrands({
    limit: DEFAULT_LIMIT,
    brand: "",
    page: 0,
  });

  const theme = useTheme();

  const mapped = useMemo(() => {
    const mapped =
      data?.data.map((d) => {
        return d.displayName;
      }) ?? [];
    const set = new Set(mapped);
    const arr = Array.from(set);
    const upperCaseFirstLetter = (str: string) =>
      str.charAt(0).toUpperCase() + str.slice(1);
    const upped = arr.map(upperCaseFirstLetter);
    return upped;
  }, [data?.data]);

  const onComboListSelected = (value?: string | null) => {
    if (!value) return;
    onSelected?.(value);
  };

  return (
    <FormControl fullWidth error={!!error}>
      <Controller
        name="brand"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal={mode !== SearchableMode.FREETEXT}
            freeSolo={mode === SearchableMode.FREETEXT}
            value={field.value ? field.value : null}
            fullWidth
            options={mapped}
            onChange={(_e, v) => {
              onComboListSelected(v);
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label={label}
                  size="small"
                  variant="outlined"
                />
                {!!error && <FormHelperText>{error}</FormHelperText>}
              </>
            )}
            renderOption={(props, option, state) => (
              <li {...props} key={`${option}-${state.index}`}>
                {option}
              </li>
            )}
            sx={disabled ? { backgroundColor: theme.palette.grey[200] } : {}}
            disabled={disabled}
          />
        )}
      />
    </FormControl>
  );
};
