/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateShopProductsRequest } from '../models/CreateShopProductsRequest';
import type { CreateShopProductsVariantRequest } from '../models/CreateShopProductsVariantRequest';
import type { EditDisplayRequest } from '../models/EditDisplayRequest';
import type { GetShopProductMobilesResponse } from '../models/GetShopProductMobilesResponse';
import type { GetShopProductsDetailResponse } from '../models/GetShopProductsDetailResponse';
import type { PaginatedGetShopProductsPosResponse } from '../models/PaginatedGetShopProductsPosResponse';
import type { PaginatedGetShopProductsResponse } from '../models/PaginatedGetShopProductsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopProductsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns GetShopProductsDetailResponse OK
     * @throws ApiError
     */
    public getShopProductById({
        shopId,
        productId,
    }: {
        shopId: string,
        productId: number,
    }): CancelablePromise<GetShopProductsDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shop-products/{productId}',
            path: {
                'productId': productId,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public editShopProductSingle({
        productId,
        requestBody,
    }: {
        productId: number,
        requestBody: CreateShopProductsRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shop-products/{productId}',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public deleteShopProductById({
        shopId,
        productId,
    }: {
        shopId: string,
        productId: number,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/shop-products/{productId}',
            path: {
                'productId': productId,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public editShopProductVariant({
        productId,
        requestBody,
    }: {
        productId: number,
        requestBody: CreateShopProductsVariantRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shop-products/{productId}/variant',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public editDisplayShopProduct({
        productId,
        requestBody,
    }: {
        productId: number,
        requestBody: EditDisplayRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shop-products/{productId}/display',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedGetShopProductsResponse OK
     * @throws ApiError
     */
    public getAllShopProducts({
        shopId,
        search,
        sortBy,
        sort = 'desc',
        page = 1,
        limit = 20,
        type,
        isSecondhand,
        isDisplay,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
        type?: 'GENERAL' | 'MOBILE',
        isSecondhand?: boolean,
        isDisplay?: boolean,
    }): CancelablePromise<PaginatedGetShopProductsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shop-products',
            query: {
                'shopId': shopId,
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
                'type': type,
                'isSecondhand': isSecondhand,
                'isDisplay': isDisplay,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public createShopProduct({
        requestBody,
    }: {
        requestBody: CreateShopProductsRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shop-products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public createShopProductVariant({
        requestBody,
    }: {
        requestBody: CreateShopProductsVariantRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shop-products/variant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GetShopProductMobilesResponse OK
     * @throws ApiError
     */
    public getShopProductMobiles({
        shopProductId,
    }: {
        shopProductId: string,
    }): CancelablePromise<Array<GetShopProductMobilesResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shop-products/{shopProductId}/mobiles',
            path: {
                'shopProductId': shopProductId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedGetShopProductsPosResponse OK
     * @throws ApiError
     */
    public getAllShopProductsPos({
        shopId,
        categoryId,
        search,
        sortBy,
        sort = 'desc',
        page = 1,
        limit = 20,
        type,
        isSecondhand,
        isDisplay,
    }: {
        shopId: string,
        categoryId?: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
        type?: 'GENERAL' | 'MOBILE',
        isSecondhand?: boolean,
        isDisplay?: boolean,
    }): CancelablePromise<PaginatedGetShopProductsPosResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shop-products/pos',
            query: {
                'shopId': shopId,
                'categoryId': categoryId,
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
                'type': type,
                'isSecondhand': isSecondhand,
                'isDisplay': isDisplay,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
