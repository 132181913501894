import { Card, Stack, Typography } from "@mui/material";
import {
  InstallmentPaymentDto,
  InstallmentPlanDto,
} from "../../client/motalvip-apis/generated";
import { useInstallmentPlanStatusMutation } from "../../client/query-client";
import { useShopContext } from "../../context/ShopContextProvider";
import { CustomProgressBar } from "../common/CustomProgressBar";
import { LoanStatusChip } from "../display/LoanStatusChip";

type Props = {
  installmentPlan?: InstallmentPlanDto;
};

export default function InstallmentPlanInfoCard(props: Props) {
  const totalAmount = props.installmentPlan?.totalAmount || 0;
  const payments = props.installmentPlan?.payments?.filter(
    (payment) =>
      payment.type === InstallmentPaymentDto.type.TERM_PAYMENT ||
      payment.type === InstallmentPaymentDto.type.REPAYMENT_FULL
  );
  const totalTermPaidAmount =
    payments && payments.length > 0
      ? payments.reduce((sum, i) => sum + (i.paidDueAmount || 0), 0) || 0
      : 0;
  const totalPaidAmount =
    totalTermPaidAmount + (props.installmentPlan?.downPaymentAmount || 0);
  const remainingAmount = totalAmount - totalPaidAmount;
  const totalPaidAmountPercentage =
    (totalPaidAmount / (props.installmentPlan?.totalAmount || 1)) * 100;
  const totalFineAmount =
    props.installmentPlan?.payments?.reduce(
      (sum, i) => sum + (i.paidFineAmount || 0),
      0
    ) || 0;
  const totalCost =
    (props.installmentPlan?.product.purchaseCost || 0) +
    (props.installmentPlan?.commissionAmount || 0) +
    (props.installmentPlan?.otherCostAmount || 0) +
    (props.installmentPlan?.documentFeeAmount || 0);
  const totalProfit = totalPaidAmount + totalFineAmount - totalCost;
  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const purchaseCost = props.installmentPlan?.product.purchaseCost || 0;
  const commissionAmount = props.installmentPlan?.commissionAmount || 0;
  const otherCostAmount = props.installmentPlan?.otherCostAmount || 0;
  const documentFeeAmount = props.installmentPlan?.documentFeeAmount || 0;

  const installmentPlanStatusMutation = useInstallmentPlanStatusMutation(
    props.installmentPlan
  );
  const onStatusSelected = (status: InstallmentPlanDto.status) => {
    installmentPlanStatusMutation.mutate(status);
  };

  const { isOwner } = useShopContext();

  return (
    <>
      <Card sx={{ width: "100%", border: "2px solid #eeeeee" }}>
        <Stack direction={"row"} justifyContent="space-between">
          <Stack direction="column" sx={{ px: 2, pt: 2 }}>
            <Typography variant="h5">สินเชื่อ</Typography>
            <Typography>เหลือที่ต้องจ่ายอีก</Typography>
            <Typography variant="h4" sx={{ color: "#0b57d0" }}>
              ฿ {currencyFormat.format(remainingAmount)}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            sx={{ px: 2, pt: 2, flex: 1 }}
            gap={1}
            alignItems={"end"}
            justifyContent="flex-end"
          >
            <Typography
              variant="h6"
              lineHeight={1}
            >{`จ่ายแล้ว ฿${currencyFormat.format(
              totalPaidAmount
            )}`}</Typography>
            <Typography
              variant="h6"
              lineHeight={1}
              sx={{
                color: "grey",
              }}
            >
              {" "}
              {totalPaidAmountPercentage.toFixed(2)}%
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ padding: 2 }}>
          <CustomProgressBar
            variant="determinate"
            value={totalPaidAmountPercentage}
          />
        </Stack>
        <Stack
          direction={{ sm: "column" }}
          justifyContent="space-between"
          gap={1}
          sx={{ padding: 2 }}
        >
          <Stack direction={{ sm: "column", md: "row" }} gap={1}>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>สถานะ</Typography>
              <LoanStatusChip
                selectable
                status={props.installmentPlan?.status}
                onSelect={onStatusSelected}
              />
            </Stack>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ปัจจุบันงวดที่</Typography>
              <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                {props.installmentPlan?.activeTermNumber} /{" "}
                {props.installmentPlan?.installmentCount}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={{ sm: "column", md: "row" }} gap={1}>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ยอดรวมทั้งหมด</Typography>
              <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                ฿{" "}
                {currencyFormat.format(props.installmentPlan?.totalAmount || 0)}
              </Typography>
            </Stack>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ค่าปรับรวม</Typography>
              <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                ฿ {currencyFormat.format(totalFineAmount)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={{ sm: "column", md: "row" }} gap={1}>
            {isOwner && (
              <Stack
                direction={"column"}
                gap={1}
                sx={{
                  width: "100%",
                  border: "2px solid #eeeeee",
                  borderRadius: 3,
                  padding: 2,
                }}
              >
                <Typography>กำไร / ขาดทุน</Typography>
                <Typography
                  variant="h5"
                  sx={{ color: totalProfit > 0 ? "#43a047" : "#f44336" }}
                >
                  ฿ {currencyFormat.format(totalProfit)}
                </Typography>
              </Stack>
            )}
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ต้นทุนเครื่อง</Typography>
              <Typography variant="h5" sx={{ color: "#f44336" }}>
                ฿ {currencyFormat.format(purchaseCost)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={{ sm: "column", md: "row" }} gap={1}>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ค่าคอม</Typography>
              <Typography variant="h5" sx={{ color: "#f44336" }}>
                ฿ {currencyFormat.format(commissionAmount)}
              </Typography>
            </Stack>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ค่าใช้จ่ายอื่นๆ</Typography>
              <Typography variant="h5" sx={{ color: "#f44336" }}>
                ฿ {currencyFormat.format(otherCostAmount)}
              </Typography>
            </Stack>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                border: "2px solid #eeeeee",
                borderRadius: 3,
                padding: 2,
              }}
            >
              <Typography>ค่าเอกสาร</Typography>
              <Typography variant="h5" sx={{ color: "#f44336" }}>
                ฿ {currencyFormat.format(documentFeeAmount)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
