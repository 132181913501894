import { useMutation } from "@tanstack/react-query";
import { QueryKey } from "..";
import { useBackendApiClient } from "../../motalvip-apis";

export const useGenerateDownSavingPaymentQR = () => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.PAYMENT, "down-saving", "generate-qr"],
    mutationFn: apiClient.public.generateDownSavingQrPaymentRequest.bind(
      apiClient.public
    ),
  });
};
