/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type QrPaymentStatusCheckResponse = {
    status: QrPaymentStatusCheckResponse.status;
};
export namespace QrPaymentStatusCheckResponse {
    export enum status {
        SUCCESS = 'SUCCESS',
        DECLINE = 'DECLINE',
        PENDING = 'PENDING',
    }
}

