import styled from "@emotion/styled"
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

export const TextArea = styled(TextareaAutosize)`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 4px;
    color: #000000DE;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    font-family: Kanit;
    resize: none;
  
    &:hover {
      border: 1px solid #767676;
    }
    
    &:focus-visible {
      border: 1px solid rgba(0, 0, 0, 0.23);
      outline-color: black;
    }
`
