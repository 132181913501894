import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";
import { DocumentDto } from "../../motalvip-apis/generated";

export const useDocumentsQuery = (type: DocumentDto.referenceType, id: string) => {
    const backendClient = useBackendApiClient();
    return useQuery({
        queryKey: ['documentsQuery'],
        queryFn: () => {
            return backendClient.document.getDocuments({ referenceType: type, referenceId: id })
        },
        enabled: !!id,
    })
}