/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AdminService } from './services/AdminService';
import { CategoryService } from './services/CategoryService';
import { ClerkWebhookService } from './services/ClerkWebhookService';
import { DeviceManagementService } from './services/DeviceManagementService';
import { DocumentService } from './services/DocumentService';
import { DownSavingService } from './services/DownSavingService';
import { FinancierService } from './services/FinancierService';
import { GatewayPaymentService } from './services/GatewayPaymentService';
import { HistoryService } from './services/HistoryService';
import { InstallmentPaymentService } from './services/InstallmentPaymentService';
import { InstallmentPlanService } from './services/InstallmentPlanService';
import { InventoryService } from './services/InventoryService';
import { LoanRegistrationService } from './services/LoanRegistrationService';
import { OrderService } from './services/OrderService';
import { ProfileService } from './services/ProfileService';
import { PublicService } from './services/PublicService';
import { ReportService } from './services/ReportService';
import { ShopService } from './services/ShopService';
import { ShopPawningCreationService } from './services/ShopPawningCreationService';
import { ShopPawningReportService } from './services/ShopPawningReportService';
import { ShopPawningTransactionService } from './services/ShopPawningTransactionService';
import { ShopProductsService } from './services/ShopProductsService';
import { ShopQuotasService } from './services/ShopQuotasService';
import { ShopSettingsService } from './services/ShopSettingsService';
import { TokenService } from './services/TokenService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class BackendApiClient {
    public readonly admin: AdminService;
    public readonly category: CategoryService;
    public readonly clerkWebhook: ClerkWebhookService;
    public readonly deviceManagement: DeviceManagementService;
    public readonly document: DocumentService;
    public readonly downSaving: DownSavingService;
    public readonly financier: FinancierService;
    public readonly gatewayPayment: GatewayPaymentService;
    public readonly history: HistoryService;
    public readonly installmentPayment: InstallmentPaymentService;
    public readonly installmentPlan: InstallmentPlanService;
    public readonly inventory: InventoryService;
    public readonly loanRegistration: LoanRegistrationService;
    public readonly order: OrderService;
    public readonly profile: ProfileService;
    public readonly public: PublicService;
    public readonly report: ReportService;
    public readonly shop: ShopService;
    public readonly shopPawningCreation: ShopPawningCreationService;
    public readonly shopPawningReport: ShopPawningReportService;
    public readonly shopPawningTransaction: ShopPawningTransactionService;
    public readonly shopProducts: ShopProductsService;
    public readonly shopQuotas: ShopQuotasService;
    public readonly shopSettings: ShopSettingsService;
    public readonly token: TokenService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8080',
            VERSION: config?.VERSION ?? '0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.admin = new AdminService(this.request);
        this.category = new CategoryService(this.request);
        this.clerkWebhook = new ClerkWebhookService(this.request);
        this.deviceManagement = new DeviceManagementService(this.request);
        this.document = new DocumentService(this.request);
        this.downSaving = new DownSavingService(this.request);
        this.financier = new FinancierService(this.request);
        this.gatewayPayment = new GatewayPaymentService(this.request);
        this.history = new HistoryService(this.request);
        this.installmentPayment = new InstallmentPaymentService(this.request);
        this.installmentPlan = new InstallmentPlanService(this.request);
        this.inventory = new InventoryService(this.request);
        this.loanRegistration = new LoanRegistrationService(this.request);
        this.order = new OrderService(this.request);
        this.profile = new ProfileService(this.request);
        this.public = new PublicService(this.request);
        this.report = new ReportService(this.request);
        this.shop = new ShopService(this.request);
        this.shopPawningCreation = new ShopPawningCreationService(this.request);
        this.shopPawningReport = new ShopPawningReportService(this.request);
        this.shopPawningTransaction = new ShopPawningTransactionService(this.request);
        this.shopProducts = new ShopProductsService(this.request);
        this.shopQuotas = new ShopQuotasService(this.request);
        this.shopSettings = new ShopSettingsService(this.request);
        this.token = new TokenService(this.request);
        this.user = new UserService(this.request);
    }
}

