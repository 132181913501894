/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancierEmployeeDetailShopsGroup } from './FinancierEmployeeDetailShopsGroup';
export type FinancierEmployeeDetailResponse = {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: FinancierEmployeeDetailResponse.role;
    commissionAmount?: number;
    financierShopsGroups?: Array<FinancierEmployeeDetailShopsGroup>;
};
export namespace FinancierEmployeeDetailResponse {
    export enum role {
        OWNER = 'OWNER',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
        SHOP_AGENT = 'SHOP_AGENT',
    }
}

