import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { StepUpdateLoanDraftCustomerInfoRequest } from "../../client/motalvip-apis/generated/models/StepUpdateLoanDraftCustomerInfoRequest";
import {
  useCreateLoanDraftMutation,
  useUpdateLoanDraftCustomerInfoMutation,
} from "../../client/query-client/shopFinancier/contract";
import { validateThaiId } from "../../util/thai-id-validator";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import { useEffect } from "react";

interface IForm
  extends Omit<StepUpdateLoanDraftCustomerInfoRequest, "loanId" | "shopId"> {}

interface IProps {
  onSubmit: (contractId: string | undefined) => void;
  shopId: string | undefined;
  contract: InstallmentPlanDto | undefined;
}

const CustomerDetails: React.FC<IProps> = (props): JSX.Element => {
  const createLoanDraft = useCreateLoanDraftMutation(props.shopId || "");
  const updateCustomerInfo = useUpdateLoanDraftCustomerInfoMutation({
    shopId: props.shopId || "",
    contractId: props.contract?.id || "",
  });

  const schema = yup.object({
    referenceId: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "รหัสอ้างอิงต้องไม่เกิน 100 ตัวอักษร"),
    firstName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ชื่อต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอกชื่อ",
        (value) => !!value && value.trim().length > 0
      ),
    lastName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "นามสกุลต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอกนามสกุล",
        (value) => !!value && value.trim().length > 0
      ),
    citizenId: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^[0-9]{13}$/, "หมายเลขประจำตัวประชาชนไม่ถูกต้อง")
      .test(
        "citizenId-or-passport",
        "กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต",
        function (value) {
          if (!value && !this.parent.passportNumber) {
            return this.createError({
              path: "citizenId",
              message: "กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต",
            });
          }
          if (value && !validateThaiId(value)) {
            return this.createError({
              path: "citizenId",
              message: "หมายเลขประจำตัวประชาชนไม่ถูกต้อง",
            });
          }
          return true;
        }
      ),
    passportNumber: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^[A-Za-z0-9]*$/, "หมายเลขพาสปอร์ตไม่ถูกต้อง")
      .test(
        "passport-or-citizenId",
        "กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต",
        function (value) {
          if (!value && !this.parent.citizenId) {
            return this.createError({
              path: "passportNumber",
              message: "กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต",
            });
          }
          return true;
        }
      ),
    citizenAddress: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(500, "ที่อยู่ตามบัตรประชาชนต้องไม่เกิน 500 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอกที่อยู่ตามบัตรประชาชน",
        (value) => !!value && value.trim().length > 0
      ),
    currentAddress: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(500, "ที่อยู่ปัจจุบันต้องไม่เกิน 500 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอกที่อยู่ปัจจุบัน",
        (value) => !!value && value.trim().length > 0
      ),
    occupation: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "อาชีพต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอกอาชีพ",
        (value) => !!value && value.trim().length > 0
      ),
    workPlace: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(500, "สถานที่ทำงานต้องไม่เกิน 500 ตัวอักษร"),
    phoneNumber: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^[0-9]+$/, "กรุณากรอกตัวเลขเท่านั้น")
      .min(9, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      .max(10, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      .test(
        "is-not-empty",
        "กรุณากรอกเบอร์โทรศัพท์",
        (value) => !!value && value.trim().length > 0
      ),
    email: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .email("รูปแบบอีเมลไม่ถูกต้อง")
      .test(
        "is-not-empty",
        "กรุณากรอกอีเมล",
        (value) => !!value && value.trim().length > 0
      ),
    lineId: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(50, "LINE ID ต้องไม่เกิน 50 ตัวอักษร"),
    facebookName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ชื่อ Facebook ต้องไม่เกิน 100 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอกชื่อ Facebook",
        (value) => !!value && value.trim().length > 0
      ),
    facebookUrl: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .url("รูปแบบ URL ไม่ถูกต้อง")
      .max(500, "Facebook URL ต้องไม่เกิน 500 ตัวอักษร")
      .test(
        "is-not-empty",
        "กรุณากรอก Facebook URL",
        (value) => !!value && value.trim().length > 0
      ),
    firstReferencePersonFirstName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ชื่อต้องไม่เกิน 100 ตัวอักษร"),
    firstReferencePersonLastName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "นามสกุลต้องไม่เกิน 100 ตัวอักษร"),
    firstReferencePersonRelation: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ความสัมพันธ์ต้องไม่เกิน 100 ตัวอักษร"),
    firstReferencePersonPhoneNumber: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^[0-9]+$/, "กรุณากรอกตัวเลขเท่านั้น")
      .min(9, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      .max(10, "เบอร์โทรศัพท์ไม่ถูกต้อง"),
    secondReferencePersonFirstName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ชื่อต้องไม่เกิน 100 ตัวอักษร"),
    secondReferencePersonLastName: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "นามสกุลต้องไม่เกิน 100 ตัวอักษร"),
    secondReferencePersonRelation: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(100, "ความสัมพันธ์ต้องไม่เกิน 100 ตัวอักษร"),
    secondReferencePersonPhoneNumber: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .matches(/^[0-9]+$/, "กรุณากรอกตัวเลขเท่านั้น")
      .min(9, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      .max(10, "เบอร์โทรศัพท์ไม่ถูกต้อง"),
    note: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(255, "หมายเหตุต้องไม่เกิน 255 ตัวอักษร"),
  });

  const initialFormValue: IForm = {
    referenceId: "",
    firstName: "",
    lastName: "",
    citizenId: "",
    passportNumber: "",
    currentAddress: "",
    citizenAddress: "",
    occupation: "",
    workPlace: "",
    email: "",
    lineId: "",
    facebookName: "",
    facebookUrl: "",
    firstReferencePersonFirstName: "",
    firstReferencePersonLastName: "",
    firstReferencePersonRelation: "",
    firstReferencePersonPhoneNumber: "",
    secondReferencePersonFirstName: "",
    secondReferencePersonLastName: "",
    secondReferencePersonRelation: "",
    secondReferencePersonPhoneNumber: "",
    note: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IForm>({
    defaultValues: {
      ...initialFormValue,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IForm): Promise<void> => {
    try {
      let contractId = props.contract?.id;
      if (!contractId) {
        const result = await createLoanDraft.mutateAsync({
          shopId: props.shopId || "",
        });
        contractId = result.id;
      }

      await updateCustomerInfo.mutateAsync({
        ...data,
        loanId: contractId || "",
        shopId: props.shopId || "",
      });

      props.onSubmit(contractId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props.contract) {
      const { profile } = props.contract;
      reset({
        referenceId: props.contract.referenceId,
        firstName: profile.firstName,
        lastName: profile.lastName,
        citizenId: profile.citizenId,
        passportNumber: profile.passportNumber,
        currentAddress: profile.currentAddress,
        citizenAddress: profile.citizenAddress,
        occupation: profile.occupation,
        workPlace: profile.workPlace,
        email: profile.email,
        lineId: profile.lineId,
        facebookName: profile.facebookName,
        facebookUrl: profile.facebookUrl,
        phoneNumber: profile.phoneNumbers?.[0].phoneNumber,
        firstReferencePersonFirstName: profile.firstReferencePersonFirstName,
        firstReferencePersonLastName: profile.firstReferencePersonLastName,
        firstReferencePersonRelation: profile.firstReferencePersonRelation,
        firstReferencePersonPhoneNumber:
          profile.firstReferencePersonPhoneNumber,
        secondReferencePersonFirstName: profile.secondReferencePersonFirstName,
        secondReferencePersonLastName: profile.secondReferencePersonLastName,
        secondReferencePersonRelation: profile.secondReferencePersonRelation,
        secondReferencePersonPhoneNumber:
          profile.secondReferencePersonPhoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contract]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.referenceId}>
            <FormLabel>รหัสอ้างอิง (ไม่จำเป็น)</FormLabel>
            <Controller
              name="referenceId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.referenceId}
                  helperText={errors?.referenceId?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">ข้อมูลลูกค้า</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.firstName}>
            <FormLabel required>ชื่อ</FormLabel>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.firstName}
                  helperText={errors?.firstName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.lastName}>
            <FormLabel required>นามสกุล</FormLabel>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.lastName}
                  helperText={errors?.lastName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.citizenId}>
            <FormLabel required>หมายเลขประจำตัวประชาชน</FormLabel>
            <Controller
              name="citizenId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.citizenId}
                  helperText={errors?.citizenId?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.passportNumber}>
            <FormLabel>หมายเลขพาสปอร์ต</FormLabel>
            <Controller
              name="passportNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.passportNumber}
                  helperText={errors?.passportNumber?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.citizenAddress}>
            <FormLabel required>ที่อยู่ตามบัตรประชาชน</FormLabel>
            <Controller
              name="citizenAddress"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.citizenAddress}
                  helperText={errors?.citizenAddress?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.currentAddress}>
            <FormLabel required>ที่อยู่ปัจจุบัน</FormLabel>
            <Controller
              name="currentAddress"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.currentAddress}
                  helperText={errors?.currentAddress?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.occupation}>
            <FormLabel required>อาชีพ</FormLabel>
            <Controller
              name="occupation"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.occupation}
                  helperText={errors?.occupation?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.workPlace}>
            <FormLabel>สถานที่ทำงาน</FormLabel>
            <Controller
              name="workPlace"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.workPlace}
                  helperText={errors?.workPlace?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.phoneNumber}>
            <FormLabel required>เบอร์โทรศัพท์</FormLabel>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.phoneNumber}
                  helperText={errors?.phoneNumber?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.email}>
            <FormLabel required>อีเมล</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.facebookName}>
            <FormLabel required>ชื่อ Facebook</FormLabel>
            <Controller
              name="facebookName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.facebookName}
                  helperText={errors?.facebookName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.facebookUrl}>
            <FormLabel required>Facebook URL</FormLabel>
            <Controller
              name="facebookUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.facebookUrl}
                  helperText={errors?.facebookUrl?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.lineId}>
            <FormLabel>LINE ID</FormLabel>
            <Controller
              name="lineId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.lineId}
                  helperText={errors?.lineId?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">บุคคลอ้างอิง 1</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.firstReferencePersonFirstName}>
            <FormLabel>ชื่อ</FormLabel>
            <Controller
              name="firstReferencePersonFirstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.firstReferencePersonFirstName}
                  helperText={errors?.firstReferencePersonFirstName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.firstReferencePersonLastName}>
            <FormLabel>นามสกุล</FormLabel>
            <Controller
              name="firstReferencePersonLastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.firstReferencePersonLastName}
                  helperText={errors?.firstReferencePersonLastName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.firstReferencePersonRelation}>
            <FormLabel>ความสัมพันธ์</FormLabel>
            <Controller
              name="firstReferencePersonRelation"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.firstReferencePersonRelation}
                  helperText={errors?.firstReferencePersonRelation?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            error={!!errors.firstReferencePersonPhoneNumber}
          >
            <FormLabel>เบอร์โทรศัพท์</FormLabel>
            <Controller
              name="firstReferencePersonPhoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.firstReferencePersonPhoneNumber}
                  helperText={errors?.firstReferencePersonPhoneNumber?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">บุคคลอ้างอิง 2</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            error={!!errors.secondReferencePersonFirstName}
          >
            <FormLabel>ชื่อ</FormLabel>
            <Controller
              name="secondReferencePersonFirstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.secondReferencePersonFirstName}
                  helperText={errors?.secondReferencePersonFirstName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.secondReferencePersonLastName}>
            <FormLabel>นามสกุล</FormLabel>
            <Controller
              name="secondReferencePersonLastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.secondReferencePersonLastName}
                  helperText={errors?.secondReferencePersonLastName?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.secondReferencePersonRelation}>
            <FormLabel>ความสัมพันธ์</FormLabel>
            <Controller
              name="secondReferencePersonRelation"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.secondReferencePersonRelation}
                  helperText={errors?.secondReferencePersonRelation?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            error={!!errors.secondReferencePersonPhoneNumber}
          >
            <FormLabel>เบอร์โทรศัพท์</FormLabel>
            <Controller
              name="secondReferencePersonPhoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.secondReferencePersonPhoneNumber}
                  helperText={errors?.secondReferencePersonPhoneNumber?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.note}>
            <FormLabel>หมายเหตุ</FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  error={!!errors?.note}
                  helperText={errors?.note?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Stack direction="row" flexWrap="wrap-reverse" gap={3} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          type="submit"
          size="large"
          sx={{ width: { xs: "100%", md: "200px" } }}
          disabled={createLoanDraft.isPending || updateCustomerInfo.isPending}
        >
          บันทึกร่างสัญญา
        </Button>
      </Stack>
    </form>
  );
};

export default CustomerDetails;
