import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  Box,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Autocomplete,
  Button,
  FormControl,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  MRT_SortingState,
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useShopFinancierContext } from "../../../context/ShopFinancierContextProvider";
import dayjs from "dayjs";
import { loanRegistrationStatusOptions } from "../../../component/display/StatusChip/LoanRegistrationStatusChip";
import { useSearchLoansRegisterQuery } from "../../../client/query-client/shopFinancier/contract";
import { ContractRegistrationColumnDef } from "../../../util/ContractColumnDef";

const ShopFinancierContractList: React.FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const transactionDateFrom =
    searchParams.get("transactionDateFrom") || undefined;
  const transactionDateTo = searchParams.get("transactionDateTo") || undefined;
  const filterStatusIndex = searchParams.getAll("filterStatusIndex");
  const pageIndex = parseInt(searchParams.get("pageIndex") || "0");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");

  const [pagination, setPagination] = useState({
    pageIndex: pageIndex, //initial page index
    pageSize: pageSize, //default page size
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: searchParams.get("sorting") || "createdAt",
      desc: searchParams.get("sort")
        ? searchParams.get("sort") === "desc"
        : true,
    },
  ]);

  const navigate = useNavigate();
  const shopCtx = useShopFinancierContext();
  const { shopId } = useParams();

  const loansQuery = useSearchLoansRegisterQuery({
    shopId: shopId || "",
    searchText: search.length >= 3 ? search : "",
    registrationStatus:
      filterStatusIndex.length > 0
        ? filterStatusIndex.map(
            (i) => loanRegistrationStatusOptions[parseInt(i)].value
          )
        : undefined,
    page: pageIndex,
    registrationDateFrom: transactionDateFrom,
    registrationDateTo: transactionDateTo,
    limit: pageSize,
    sortBy: sorting.length > 0 ? sorting[0].id : undefined,
    sort: sorting.length > 0 ? (sorting[0].desc ? "desc" : "asc") : undefined,
  });

  const table = useMaterialReactTable({
    columns: ContractRegistrationColumnDef,
    data: loansQuery.data?.content || [],
    layoutMode: "semantic",
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enableSorting: true,
    enableSortingRemoval: false,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnVirtualization: false,
    enableColumnOrdering: false,
    positionGlobalFilter: "left",
    enableTopToolbar: false,
    manualSorting: true,
    paginationDisplayMode: "pages",
    onSortingChange: setSorting,
    muiPaginationProps: {
      shape: "rounded",
      page: pageIndex + 1,
      rowsPerPageOptions: [10, 25, 50, 100],
      defaultPage: 0,
      showRowsPerPage: true,
      showFirstButton: false,
    },
    localization: {
      rowsPerPage: "จำนวนต่อหน้า",
    },
    manualPagination: true,
    rowCount: loansQuery.data?.pagination?.totalElements || 1,
    onPaginationChange: setPagination,
    initialState: {
      showGlobalFilter: true,
      pagination,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: loansQuery.isFetching,
      pagination: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      sorting,
      columnVisibility: { totalProfit: shopCtx.isOwner },
    },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <IconButton
        onClick={() =>
          navigate(
            `/shop-finance/${shopId}/contract/create?contractId=${row.original.id}`
          )
        }
      >
        <ArrowForwardIosIcon />
      </IconButton>
    ),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 12, //make actions column wider
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0", //add a border between columns
        backgroundColor: "#455a64",
        color: "white",
        fontWeight: "500",
        padding: 1,
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: "grey.100",
          },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #eeeeee", //add a border between columns
        padding: 1,
      },
    },
  });

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("pageIndex", pagination.pageIndex.toString());
    newSearchParams.set("pageSize", pagination.pageSize.toString());
    setSearchParams(newSearchParams);
  }, [pagination, searchParams, setSearchParams]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (sorting.length > 0) {
      newSearchParams.set("sorting", sorting[0].id);
      newSearchParams.set("sort", sorting[0].desc ? "desc" : "asc");
      setSearchParams(newSearchParams);
    }
  }, [sorting, searchParams, setSearchParams]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
      <Box p={2}>
        <Typography variant="h5" pb={2}>
          {"รายการสัญญา"}
        </Typography>
        <Stack gap={2} sx={{ maxWidth: "90vw" }}>
          <Stack
            direction={{ lg: "row" }}
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              fullWidth
              sx={{ backgroundColor: "white", flex: 2 }}
              value={search}
              onChange={(e) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("search", e.target.value);
                setSearchParams(newSearchParams);
              }}
              variant="outlined"
              placeholder="ค้นหา หมายเลขเคส, ชื่อ-นามสกุล, เลขบัตร, พาสปอร์ต, เบอร์โทร, IMEI, Serial Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              variant="contained"
              size="medium"
              onClick={() =>
                navigate(`/shop-finance/${shopId}/contract/create`)
              }
              sx={{ height: "56px", width: { xs: "100%", lg: "40%" } }}
            >
              เพิ่มรายการสัญญา
            </Button>
          </Stack>
          <Stack direction={{ lg: "row" }} gap={2}>
            <Autocomplete
              fullWidth
              multiple
              id="selected-registration-status"
              value={loanRegistrationStatusOptions.filter((o, i) =>
                filterStatusIndex.includes(i.toString())
              )}
              onChange={(_, newValue) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.delete("filterStatusIndex");
                newValue.forEach((o) => {
                  newSearchParams.append(
                    "filterStatusIndex",
                    loanRegistrationStatusOptions.indexOf(o).toString()
                  );
                });
                setSearchParams(newSearchParams);
              }}
              options={loanRegistrationStatusOptions}
              getOptionLabel={(o) => o.display}
              renderInput={(params) => (
                <TextField {...params} label="สถานะการสมัคร" placeholder="" />
              )}
            />
            <FormControl fullWidth>
              <DatePicker
                format="DD MMM YYYY"
                label="วันที่สมัคร (เริ่มต้น)"
                disableFuture
                value={
                  transactionDateFrom
                    ? dayjs(transactionDateFrom).locale("th")
                    : null
                } // Convert to Dayjs
                onChange={(date) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  if (date) {
                    newSearchParams.set(
                      "transactionDateFrom",
                      date.format("YYYY-MM-DD")
                    );
                  } else {
                    newSearchParams.delete("transactionDateFrom");
                  }
                  setSearchParams(newSearchParams);
                }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                format="DD/MM/YYYY"
                label="วันที่สมัคร (สิ้นสุด)"
                disableFuture
                value={transactionDateTo ? dayjs(transactionDateTo) : null} // Convert to Dayjs
                onChange={(date) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  if (date) {
                    newSearchParams.set(
                      "transactionDateTo",
                      date.format("YYYY-MM-DD")
                    );
                  } else {
                    newSearchParams.delete("transactionDateTo");
                  }
                  setSearchParams(newSearchParams);
                }}
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </FormControl>
          </Stack>
          <Box sx={{ maxWidth: "100%" }}>
            <MaterialReactTable table={table} />
          </Box>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

export default ShopFinancierContractList;
