/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DocumentDeleteNotUseDto = {
    id: Array<string>;
    referenceId: string;
    referenceType: DocumentDeleteNotUseDto.referenceType;
};
export namespace DocumentDeleteNotUseDto {
    export enum referenceType {
        DOWN_SAVING = 'DOWN_SAVING',
        FINANCIER_SHOP_CONTRACT = 'FINANCIER_SHOP_CONTRACT',
        INSTALLMENT_CONTRACT = 'INSTALLMENT_CONTRACT',
        POS = 'POS',
        SHOP_COMMISSION = 'SHOP_COMMISSION',
        SHOP_AGENT_COMMISSION = 'SHOP_AGENT_COMMISSION',
    }
}

