/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopLoanCalculationSettings = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    shopId: string;
    type: ShopLoanCalculationSettings.type;
    roundNumbers: boolean;
    jsonValue: string;
};
export namespace ShopLoanCalculationSettings {
    export enum type {
        ANDROID = 'ANDROID',
        IOS = 'IOS',
    }
}

