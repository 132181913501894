import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ComponentPropsWithoutRef, Fragment, useState } from "react";
import { GetGatewayPaymentQRResponse } from "../../../client/motalvip-apis/generated";
import { useGetPaymentQr } from "../../../client/query-client/useGetQR";
import { QrCropped } from "../QrCropped";

interface QRDialogProps {
  open: boolean;
  onClose: () => void;
  qrData: GetGatewayPaymentQRResponse | undefined;
  isLoading: boolean;
}

const QRDialog: React.FC<QRDialogProps> = ({
  open,
  isLoading,
  onClose,
  qrData,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>QR Code</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <p>Loading...</p>
        ) : qrData?.qrUrl ? (
          <QrCropped
            src={qrData.qrUrl}
            alt="QR Code"
            style={{ width: "100%" }}
          />
        ) : (
          <p>No QR Code available</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ViewQRDialogButton({
  children,
  paymentId,
  ...rest
}: ComponentPropsWithoutRef<typeof Button> & {
  paymentId: string;
}) {
  const [qrParams, setQrParams] = useState<
    Parameters<typeof useGetPaymentQr>[0] | undefined
  >();

  const { data: qrData, isLoading } = useGetPaymentQr(qrParams);

  return (
    <Fragment>
      <Button
        variant="contained"
        {...rest}
        onClick={() => setQrParams({ gatewayPaymentId: paymentId })}
      >
        {children}
      </Button>
      <QRDialog
        open={Boolean(qrParams)}
        onClose={() => setQrParams(undefined)}
        isLoading={isLoading}
        qrData={qrData}
      />
    </Fragment>
  );
}
