import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";

export const useViewMaskedDeviceManagementApiKey = (shopId?: string) => {
  const backendClient = useBackendApiClient();
  return useQuery({
    enabled: Boolean(shopId),
    queryKey: ["deviceManagement", "viewMaskedDeviceManagementApiKey", shopId],
    queryFn: () => {
      return backendClient.deviceManagement.viewMaskedDeviceManagementApiKey({
        shopId: shopId!,
      });
    },
  });
};
