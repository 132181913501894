/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderItemDTO } from './OrderItemDTO';
export type OrderDTO = {
    orderId?: string;
    orderNo?: string;
    customerName?: string;
    customerPhoneNumber?: string;
    customerIdentityNumber?: string;
    customerPassportNumber?: string;
    customerEmail?: string;
    subTotal?: number;
    discount?: number;
    total?: number;
    discountInput?: number;
    discountType?: OrderDTO.discountType;
    paymentMethod?: OrderDTO.paymentMethod;
    note?: string;
    isVat?: boolean;
    createdAt?: string;
    orderItems?: Array<OrderItemDTO>;
};
export namespace OrderDTO {
    export enum discountType {
        AMOUNT = 'AMOUNT',
        PERCENTAGE = 'PERCENTAGE',
    }
    export enum paymentMethod {
        CASH = 'CASH',
        CREDIT_CARD = 'CREDIT_CARD',
        BANK_TRANSFER = 'BANK_TRANSFER',
        INSTALLMENT = 'INSTALLMENT',
    }
}

