/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DocumentDto = {
    originalName?: string;
    id?: string;
    referenceId?: string;
    referenceType?: DocumentDto.referenceType;
    fileExtension?: string;
    status?: DocumentDto.status;
    uploadUrl?: string;
    downloadUrl?: string;
    createdByUserId?: string;
};
export namespace DocumentDto {
    export enum referenceType {
        DOWN_SAVING = 'DOWN_SAVING',
        FINANCIER_SHOP_CONTRACT = 'FINANCIER_SHOP_CONTRACT',
        INSTALLMENT_CONTRACT = 'INSTALLMENT_CONTRACT',
        POS = 'POS',
        SHOP_COMMISSION = 'SHOP_COMMISSION',
        SHOP_AGENT_COMMISSION = 'SHOP_AGENT_COMMISSION',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }
}

