/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NoteDto = {
    id?: string;
    referenceId?: string;
    referenceType?: NoteDto.referenceType;
    note?: string;
    createdByUserId?: string;
    createdAt?: string;
};
export namespace NoteDto {
    export enum referenceType {
        DOWN_SAVING = 'DOWN_SAVING',
        FINANCIER_SHOP_CONTRACT = 'FINANCIER_SHOP_CONTRACT',
        INSTALLMENT_CONTRACT = 'INSTALLMENT_CONTRACT',
        POS = 'POS',
        SHOP_COMMISSION = 'SHOP_COMMISSION',
        SHOP_AGENT_COMMISSION = 'SHOP_AGENT_COMMISSION',
    }
}

