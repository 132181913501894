import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useDialog } from "../../context/DialogContext";
import { useBackendApiClient } from "../motalvip-apis";
import {
  InstallmentPaymentDocumentDto,
  InstallmentPaymentDto,
  InstallmentPlanDto,
  ShopDto,
  ShopUserInvitationRequest,
  ShopUserRemoveRequest,
  StepUpdateLoanDraftApproveLoanRequest,
  StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
} from "../motalvip-apis/generated";

//query key enum
export enum QueryKey {
  CONTRACT = "CONTRACT",
  LOAN = "LOAN",
  LOANS = "LOANS",
  MASTER_BRAND = "MASTER_BRAND",
  MASTER_MODEL = "MASTER_MODEL",
  PAYMENT = "PAYMENT",
  SHOP = "SHOP",
  SHOPS = "SHOPS",
  SHOP_SETTINGS = "SHOP_SETTINGS",
  USER = "USER",
  SHOP_FINANCIER_DASHBOARD = "SHOP_FINANCIER_DASHBOARD",
  CATEGORY = "CATEGORY",
  PRODUCT = "PRODUCT",
  INVENTORY = "INVENTORY",
}

export const useUserMeQuery = (userId?: string) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [userId],
    queryFn: () => backendApiClient.user.me(),
  });
};

export const useUserShopsQuery = (userId?: string) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [userId],
    queryFn: () => backendApiClient.shop.getUserShops(),
    enabled: !!userId,
  });
};

export const useShopsQuerySuspense = () => {
  const apiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.SHOPS],
    queryFn: () => apiClient.shop.getUserShops(),
  });
};
export const useShopQuery = (shopId: string) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.SHOP, shopId, "query"],
    queryFn: () => apiClient.shop.getShopById({ shopId: shopId }),
    refetchOnWindowFocus: true,
  });
};

// export const usePhoneNumberQuery = (shopId: string) => {
//     const motalClient2 = useBackendApiClient()
//     return useQuery({ queryKey: [QueryKey.SHOP, 'phoneNumber', shopId], queryFn: () => motalClient2.shop.getShopPhoneNumbers({ shopId: shopId }) })
// }
export const useShopUsersQuery = (shopId: string) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.SHOP, "shopUsers", shopId],
    queryFn: () => apiClient.shop.getShopUsers({ shopId: shopId }),
  });
};

export const useShopUserInvitationMutation = () => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.SHOP],
    mutationFn: (req: ShopUserInvitationRequest) =>
      apiClient.shop.createShopUserInvitation({
        requestBody: {
          shopId: req.shopId,
          email: req.email,
          role: req.role,
        },
      }),
  });
};
export const useShopUserRemoveMutation = () => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.SHOP],
    mutationFn: (req: ShopUserRemoveRequest) =>
      apiClient.shop.removeShopUser({
        requestBody: {
          shopId: req.shopId,
          emailAddress: req.emailAddress,
        },
      }),
  });
};
export const useCreateShopMutation = () => {
  // const motalClient = useMotalClient()
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.SHOPS],
    mutationFn: (req: ShopDto) =>
      apiClient.shop.createShop({ requestBody: req }),
  });
};

export const useInstallmentPlanStatusMutation = (
  installmentPlanDto?: InstallmentPlanDto
) => {
  const queryClient = useQueryClient();
  const backendApiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [installmentPlanDto?.id],
    mutationFn: (status: InstallmentPlanDto.status) =>
      backendApiClient.installmentPlan.updateInstallmentPlanStatus({
        requestBody: {
          ...installmentPlanDto!,
          status: status,
        },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({ queryKey: [installmentPlanDto?.id] });
    },
    onError: (error) => {
      toast.success("เกิดความผิดพลาด");
    },
  });
};

export const useInstallmentPlanDeleteMutation = (
  installmentPlanId?: string
) => {
  const backendApiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [installmentPlanId],
    mutationFn: () =>
      backendApiClient.installmentPlan.deleteInstallmentPlan({
        installmentPlanId: installmentPlanId || "",
      }),
  });
};

export const useSearchHistory = (
  page: number,
  limit: number,
  search: string,
  enabled: boolean
) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    enabled: enabled,
    queryKey: [QueryKey.LOAN, "history", search],
    queryFn: () =>
      apiClient.history.searchHistory({
        search: search,
        page: page,
        limit: limit,
      }),
  });
};

export const useLoanHistoryQuery = (
  customerIdentifier: string,
  enabled: boolean
) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    enabled: enabled,
    queryKey: [QueryKey.LOAN, "history", customerIdentifier],
    queryFn: () =>
      apiClient.installmentPlan.getLoanCustomerHistory({
        requestBody: { customerIdentifier },
      }),
  });
};

export const useAddInstallmentPlanNote = (installmentPlanId?: string) => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ noteText }: { noteText: string }) => {
      if (!installmentPlanId) {
        return;
      }

      await apiClient.installmentPlan.addInstallmentPlanNote({
        installmentPlanId: installmentPlanId!,
        requestBody: {
          note: noteText,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => { },
      });
    },
  });
};

export const useDeleteInstallmentPlanNote = (installmentPlanId?: string) => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ noteId }: { noteId: string }) => {
      if (!installmentPlanId) {
        return;
      }

      await apiClient.installmentPlan.deleteInstallmentPlanNote({
        installmentPlanId: installmentPlanId!,
        noteId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => { },
      });
    },
  });
};

export const useApproveLoanMutation = () => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationFn: (request: StepUpdateLoanDraftApproveLoanRequest) =>
      apiClient.installmentPlan.stepUpdateLoanDraftApproveLoan({
        requestBody: request,
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
    },
    onError: (error) => {
      dialog.showDialog({
        title: "เกิดความผิดพลาด",
        description: "",
        onConfirm: () => { },
      });
    },
  });
};

export const useRejectLoanMutation = () => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationFn: (request: StepUpdateLoanDraftDeviceAndLoanApprovalRequest) =>
      apiClient.installmentPlan.stepUpdateLoanDraftRejectLoanApproval({
        requestBody: request,
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
    },
    onError: (error) => {
      dialog.showDialog({
        title: "เกิดความผิดพลาด",
        description: "",
        onConfirm: () => { },
      });
    },
  });
};

export const usePaymentsQuery = (
  loanId: string,
  shopId: string,
  limit: number,
  enabled: boolean = true
) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.PAYMENT, loanId],
    queryFn: () =>
      apiClient.installmentPayment.searchPayment({ loanId, shopId, limit }),
    enabled: !!loanId && !!shopId && enabled,
  });
};
export const usePaymentQuery = (paymentId: string, enabled: boolean = true) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.PAYMENT, paymentId],
    queryFn: () =>
      apiClient.installmentPayment.getPaymentById({ paymentId: paymentId }),
    enabled: !!paymentId && enabled,
  });
};

export const useInstallmentPaymentDocumentCreateMutation = (
  installmentPlanId: string
) => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [installmentPlanId],
    mutationFn: (req: InstallmentPaymentDocumentDto) =>
      apiClient.installmentPayment.createInstallmentPaymentDocument({
        requestBody: req,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => { },
      });
    },
  });
};

export const useSearchMasterDeviceModels = ({
  limit,
  model,
  page,
  brand,
}: {
  model: string;
  limit: number;
  page: number;
  brand: string;
}) => {
  const apiClient = useBackendApiClient();
  const result = useQuery({
    queryKey: [QueryKey.MASTER_MODEL, model, brand],
    queryFn: () =>
      apiClient.public.getMasterDeviceModels({ limit, model, page, brand }),
    placeholderData: keepPreviousData,
  });
  return result;
};

export const useSearchMasterDeviceBrands = ({
  limit,
  brand,
  page,
}: {
  brand: string;
  limit: number;
  page: number;
}) => {
  const apiClient = useBackendApiClient();
  const result = useQuery({
    queryKey: [QueryKey.MASTER_BRAND, brand],
    queryFn: () => {
      return apiClient.public.getMasterDeviceBrands({
        limit,
        query: brand,
        page,
      });
    },
    placeholderData: keepPreviousData,
  });
  return result;
};

export const usePaymentDeleteMutation = (installmentPlanId: string) => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [installmentPlanId],
    mutationFn: (req: { payment: InstallmentPaymentDto }) =>
      apiClient.installmentPayment.deletePayment({ requestBody: req.payment }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => { },
      });
    },
  });
};

// export const useSummaryQuery = (shopId: string, year: number) => {
//     const motalClient = useBackendApiClient()
//     return useQuery({ queryKey: [QueryKey.SHOP, shopId, year], queryFn: () => motalClient.shop.summary({ shopId, year }) })
// }

// export const usePaymentQueryByShopId = (shopId: string, page: number = 1) => {
//     const motalClient = useBackendApiClient()
//     return useQuery({ queryKey: [QueryKey.PAYMENT, shopId, page], queryFn: () => motalClient.payment.searchPayment({ shopId, page },) })
// }
export const useShopUpdateMutation = (shopId: string) => {
  const dialog = useDialog();
  const apiClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    // mutationKey: [QueryKey.SHOP, shopId,'update'],
    mutationFn: (req: ShopDto) =>
      apiClient.shop.updateShop({ requestBody: req }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.SHOP, shopId] });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => { },
      });
    },
  });
};
