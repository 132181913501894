import { Button, Box, Typography, Card } from "@mui/material"
import { ProductList } from "../../../component/product/ProductList"
import { useNavigate, useParams } from "react-router-dom"

export const ProductListScreen = () => {
    const { shopId } = useParams()
    const navigate = useNavigate()

    const handleClickCreateProduct = () => {
        navigate(`/${shopId}/pos/create-product`)
    }

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" py={2}>
                <Typography variant="h5" p={2}>สินค้า</Typography>
                <Button sx={{ mb: 2 }} variant="contained" size="large" onClick={handleClickCreateProduct}>เพิ่มสินค้าใหม่</Button>
            </Box>
            <Card sx={{ px: 2, py: 4 }}>
                <ProductList />
            </Card>
        </Box >
    )
}
