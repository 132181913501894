import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormLabel, Stack, TextField, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useCategoryCreateMutation } from "../../client/query-client/useCategoryQuery"
import { useParams } from "react-router-dom"

interface Props {
    open: boolean
    onClose: () => void
}

interface CreateCategoryForm {
    name: string
}

export const CreateCategoryDialog = ({ open, onClose }: Props) => {
    const { control, handleSubmit, formState: { errors } } = useForm<CreateCategoryForm>({
        mode: 'all'
    })
    const { shopId } = useParams()
    const { mutateAsync: createCategory, isPending } = useCategoryCreateMutation()

    const onSubmit = async ({ name }: CreateCategoryForm) => {
        if (name) {
            try {
                await createCategory({
                    shopId: shopId!,
                    name
                })
                onClose()
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>สร้างหมวดหมู่สินค้า</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack gap={2}>
                        <FormControl fullWidth error={!!errors.name} required>
                            <FormLabel>ชื่อหมวดหมู่สินค้า</FormLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "กรุณาระบุ" }}
                                render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                            />
                            {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                        </FormControl>
                        <Stack direction='row' gap={4} marginTop={2}>
                            <Button size='large' variant='outlined' type="button" onClick={onClose} fullWidth>
                                ยกเลิก
                            </Button>
                            <Button size='large' variant='contained' type="submit" disabled={isPending} fullWidth>
                                ยืนยัน
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    )
}
