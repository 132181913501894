import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../../motalvip-apis";
import { QueryKey } from "../..";
import {
  LoanCommissionShopSearchRequest,
  LoanReportRequest,
} from "../../../motalvip-apis/generated";

export const useLoanCommissionByShopQuery = (
  body: LoanCommissionShopSearchRequest
) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [
      QueryKey.SHOP_FINANCIER_DASHBOARD,
      body.shopId,
      body.sort,
      body.sortBy,
      body.page,
      body.limit,
      body.dateFrom,
      body.dateTo,
      "loanCommissionByShop",
    ],
    queryFn: () =>
      backendApiClient.installmentPlan.getLoanCommissionByShopId({
        requestBody: body,
      }),
    enabled: !!body.shopId,
  });
};

export const useReportLoanByShopIdQuery = (body: LoanReportRequest) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [
      QueryKey.SHOP_FINANCIER_DASHBOARD,
      body.shopId,
      body.dateFrom,
      body.dateTo,
      "reportLoanByShopId",
    ],
    queryFn: () =>
      backendApiClient.installmentPlan.getReportLoanByShopId({
        requestBody: { ...body },
      }),
    enabled: !!body.shopId,
  });
};
