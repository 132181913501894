import { Box, Button, Card, FormControl, FormLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { CategoryResponse, GetShopProductsResponse } from "../../../client/motalvip-apis/generated"
import { CreateProductFormType, MobileType, ProductTypeLabelMapper } from "./types"
import { TextArea } from "./TextArea"
import { ProductImage, VisuallyHiddenInput } from "./styled"
import { getImageUrl } from "../utils"
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface Props {
    categories: CategoryResponse[]
    errors: FieldErrors<CreateProductFormType>
    setValue: UseFormSetValue<CreateProductFormType>
    control: Control<CreateProductFormType, any>
    watch: UseFormWatch<CreateProductFormType>
    isMobile: boolean
    editMode?: boolean
}

export const ProductInformation = ({ categories, errors, control, setValue, watch, isMobile, editMode }: Props) => {
    const categoryId = watch('categoryId')
    const mobileType = watch('mobileType')
    const productImage = watch('productImage')

    return (
        <Card sx={{ px: 2, pb: 4, pt: 2 }}>
            <Typography variant="h6" marginBottom={2}>รายละเอียดสินค้า</Typography>
            <Stack gap={2}>
                <Stack gap={1} pb={2}>
                    <FormControl fullWidth error={!!errors.productImage} required>
                        <FormLabel>รูปสินค้า</FormLabel>
                        {productImage && <ProductImage src={getImageUrl(productImage)} alt="" />}
                        <Controller
                            name='productImage'
                            control={control}
                            rules={{
                                validate: (value) => (value ? true : "จำเป็นต้องใส่รูปสินค้า"),
                            }}
                            render={() => (
                                <Button
                                    component="label"
                                    variant="outlined"
                                    tabIndex={-1}
                                    color="primary"
                                    sx={{ width: 150, borderRadius: 100 }}
                                    startIcon={<CloudUploadIcon />}
                                >
                                    อัพโหลด
                                    <VisuallyHiddenInput
                                        type="file"
                                        role="button"
                                        onChange={(event) => {
                                            const file = event.target.files?.[0];
                                            if (file) {
                                                setValue('productImage', file)
                                            }
                                        }}

                                    />
                                </Button>
                            )}
                        />
                        {errors.productImage && <Typography color="error">{errors.productImage.message}</Typography>}
                    </FormControl>
                </Stack>
                <FormControl fullWidth error={!!errors.name} required>
                    <Box display='flex' justifyContent="space-between">
                        <FormLabel>ชื่อสินค้า</FormLabel>
                        <Typography variant="caption">(2-200 ตัวอักษร)</Typography>
                    </Box>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: "กรุณาระบุ" }}
                        render={({ field }) => <TextField {...field} size='small' variant="outlined" placeholder="ใส่ชื่อสินค้า" inputProps={{ maxLength: 200 }} />}
                    />
                    {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                </FormControl>
                <FormControl fullWidth error={!!errors.description}>
                    <Box display='flex' justifyContent="space-between">
                        <FormLabel>รายละเอียดสินค้า</FormLabel>
                        <Typography variant="caption">(0-2000 ตัวอักษร)</Typography>
                    </Box>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => <TextArea {...field} placeholder="ใส่ข้อมูลที่นี่..." maxLength={2000} />}
                    />
                    {errors.description && <Typography color="error">{errors.description.message}</Typography>}
                </FormControl>
                {((!editMode && categories) || (editMode && categories && categoryId)) && <FormControl required>
                    <FormLabel>หมวดหมู่สินค้า</FormLabel>
                    <Controller
                        name="categoryId"
                        control={control}
                        render={({ field }) =>
                            <Select
                                sx={{
                                    flex: 1,
                                }}
                                size="small"
                                {...field}
                            >
                                {categories.map((category) => (
                                    <MenuItem value={category.id} key={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>}
                {!editMode && <FormControl required>
                    <FormLabel>ประเภทสินค้าหลัก (มือถือ/สินค้าทั่วไป)</FormLabel>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <Select
                                sx={{
                                    flex: 1,
                                }}
                                size="small"
                                {...field}
                            >
                                {Object.values(GetShopProductsResponse.type).map((productType) => (
                                    <MenuItem value={productType} key={productType}>
                                        {ProductTypeLabelMapper[productType]}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>}
                {editMode && <Stack>
                    <Box display='flex' gap={1} alignItems='baseline'>
                        <Typography variant="body1" fontWeight={600}>ประเภทสินค้าหลัก</Typography>
                        <Typography variant="caption">(ไม่สามารถแก้ไขได้)</Typography>
                    </Box>
                    <Typography variant="body1">{isMobile ? 'มือถือ' : 'สินค้าทั่วไป'}</Typography>
                </Stack>}
                {((!editMode && isMobile) || (editMode && mobileType)) && <FormControl required>
                    <FormLabel>ประเภทสินค้าย่อย (มือ1/มือ2)</FormLabel>
                    <Controller
                        name="mobileType"
                        control={control}
                        render={({ field }) =>
                            <Select
                                sx={{
                                    flex: 1,
                                }}
                                size="small"
                                {...field}
                            >
                                <MenuItem value={MobileType.FIRSTHAND} key={MobileType.FIRSTHAND}>
                                    มือ1
                                </MenuItem>
                                <MenuItem value={MobileType.SECONDHAND} key={MobileType.SECONDHAND}>
                                    มือ2
                                </MenuItem>
                            </Select>
                        }
                    />
                </FormControl>}
            </Stack>
        </Card>
    )
}