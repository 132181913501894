/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DownSavingTransactionResponse = {
    id: string;
    shopId: string;
    transactionAt: string;
    downSavingId: string;
    amount: number;
    fineAmount: number;
    direction: DownSavingTransactionResponse.direction;
    shopPaymentChannelId: string;
    createdByUserId?: string;
};
export namespace DownSavingTransactionResponse {
    export enum direction {
        DEPOSIT = 'DEPOSIT',
        WITHDRAW = 'WITHDRAW',
    }
}

