import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShopDto } from "../../client/motalvip-apis/generated";
import { useShopQuery } from "../../client/query-client";
import { usePaymentChannelQuery } from "../../client/query-client/usePaymentChannelQuery";
import { Spin } from "../../component/common/Spin";
import { dayDiff } from "../../util/DateUtil";
import { menuData, shopMenuData } from "./menuData";
import { MenuItem, ShopFinancierContextType } from "./types";

const ShopFinancierContext = createContext<ShopFinancierContextType>({
  setSelectedMenu: () => {},
  setSelectedSubMenu: () => {},
  remainingDays: 0,
  isOwner: false,
});

interface IProps {
  children: ReactNode;
}

export const ShopFinancierContextProvider = ({ children }: IProps) => {
  const location = useLocation();
  const { shopId } = useParams();
  const navigate = useNavigate();

  const findSelectedMenu = (
    menuItems: MenuItem[],
    shopId: string | undefined,
    pathname: string
  ): string => {
    return (
      menuItems.find(
        (menu) =>
          (menu.key &&
            shopId &&
            menu.key.replace(":shopId", shopId) === pathname) ||
          (menu.children &&
            menu.children.find(
              (sub) =>
                sub.key &&
                shopId &&
                sub.key.replace(":shopId", shopId) === pathname
            ))
      )?.key || ""
    );
  };

  const findSelectedSubMenu = (
    menuItems: MenuItem[],
    shopId: string | undefined,
    pathname: string
  ): string => {
    return (
      menuItems
        .find((menu) => menu.children)
        ?.children?.find(
          (sub) =>
            sub.key && shopId && sub.key.replace(":shopId", shopId) === pathname
        )?.key || ""
    );
  };

  const [selectedMenu, setSelectedMenu] = useState<string>(
    findSelectedMenu(menuData, shopId, location.pathname) ||
      findSelectedMenu(shopMenuData, shopId, location.pathname) ||
      ""
  );
  const [selectedSubMenu, setSelectedSubMenu] = useState<string>(
    findSelectedSubMenu(menuData, shopId, location.pathname) ||
      findSelectedSubMenu(shopMenuData, shopId, location.pathname) ||
      ""
  );

  const shopQuery = useShopQuery(shopId || "");
  const paymentChannelsQuery = usePaymentChannelQuery(shopId || "");
  const currentDate = new Date();

  const shop = shopQuery.data;
  const isOwner = shop?.shopUserRole === ShopDto.shopUserRole.OWNER;
  const remainingDays = shop?.expireAt
    ? dayDiff(new Date(shop.expireAt), currentDate)
    : 0;

  const availableMenus = menuData.filter((e) =>
    e.isRequiredOwner ? isOwner : true
  );
  const availableShopMenus = shopMenuData.filter((e) =>
    e.isRequiredOwner ? isOwner : true
  );

  useEffect(() => {
    if (
      !shopQuery.isLoading &&
      (!shopQuery.data || !shopQuery.data?.financier)
    ) {
      navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopQuery.isLoading, shopQuery.data]);

  return (
    <ShopFinancierContext.Provider
      value={{
        shopId,
        shop,
        selectedMenu,
        setSelectedMenu,
        selectedSubMenu,
        setSelectedSubMenu,
        remainingDays,
        menuData: availableMenus,
        shopMenuData: availableShopMenus,
        paymentChannels: paymentChannelsQuery.data,
        isOwner,
        isLoading: shopQuery.isLoading || paymentChannelsQuery.isLoading,
      }}
    >
      <Spin spinning={shopQuery.isLoading || paymentChannelsQuery.isLoading}>
        {children}
      </Spin>
    </ShopFinancierContext.Provider>
  );
};

export const useShopFinancierContext = () => useContext(ShopFinancierContext);
