import { Stack, Typography, Box, Divider } from "@mui/material";
import FileUploadWithPreview from "../../component/FileUploadWithPreview";
import { InstallmentPlanDocumentDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDocumentDto";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import { useMemo } from "react";
import MultipleFileUploadWithPreview from "../../component/MultipleFileUploadWithPreview";

interface IProps {
  contract: InstallmentPlanDto | undefined;
}

const TabContractDocumentDetails: React.FC<IProps> = (props): JSX.Element => {
  const documentPreviews = useMemo(() => {
    const documents = props.contract?.documents ?? [];

    return documents.reduce<
      Record<InstallmentPlanDocumentDto.type, typeof documents>
    >((acc, doc) => {
      if (!acc[doc.type]) {
        acc[doc.type] = [];
      }
      acc[doc.type].push(doc);
      return acc;
    }, {} as Record<InstallmentPlanDocumentDto.type, typeof documents>);
  }, [props.contract?.documents]);

  return (
    <Stack gap={2} useFlexGap flexWrap="wrap">
      <Typography variant="h6">หลักฐานประกอบสัญญา</Typography>
      <Box>
        <FileUploadWithPreview
          title="บัตรประชาชน"
          document={
            documentPreviews[
              InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD
            ]?.[0]
          }
          onChange={() => {}}
          onRemove={() => {}}
          disableDelete
        />
      </Box>

      <Divider />

      <Box>
        <FileUploadWithPreview
          title="รูปถ่ายคู่บัตรประชาชน"
          document={
            documentPreviews[
              InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD
            ]?.[0]
          }
          onChange={() => {}}
          onRemove={() => {}}
          disableDelete
        />
      </Box>

      <Divider />

      <Box>
        <FileUploadWithPreview
          title="หน้า Facebook"
          document={
            documentPreviews[
              InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN
            ]?.[0]
          }
          onChange={() => {}}
          onRemove={() => {}}
          disableDelete
        />
      </Box>

      {documentPreviews[InstallmentPlanDocumentDto.type.OTHERS]?.[0] && (
        <>
          <Divider />
          <Box>
            <FileUploadWithPreview
              title="เอกสารประกอบอื่นๆ"
              document={
                documentPreviews[InstallmentPlanDocumentDto.type.OTHERS][0]
              }
              onChange={() => {}}
              onRemove={() => {}}
              disableDelete
            />
          </Box>
        </>
      )}

      {documentPreviews[InstallmentPlanDocumentDto.type.CONTRACT]?.length >
        0 && (
        <>
          <Divider />
          <Box>
            <MultipleFileUploadWithPreview
              title="เอกสารสัญญา"
              documents={
                documentPreviews[InstallmentPlanDocumentDto.type.CONTRACT]
              }
              onChange={() => {}}
              onRemove={() => {}}
              disableDelete
              disableUpload
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default TabContractDocumentDetails;
