/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VariantDTO } from './VariantDTO';
export type GetShopProductsDetailResponse = {
    productId?: number;
    name?: string;
    description?: string;
    sku?: string;
    imageUrl?: string;
    price?: number;
    brand?: string;
    note?: string;
    type?: GetShopProductsDetailResponse.type;
    isSecondhand?: boolean;
    categoryId?: string;
    optionName1?: string;
    optionName2?: string;
    options?: Array<VariantDTO>;
    createdAt?: string;
};
export namespace GetShopProductsDetailResponse {
    export enum type {
        GENERAL = 'GENERAL',
        MOBILE = 'MOBILE',
    }
}

