import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useBackendApiClient } from "../../client/motalvip-apis";
import { ShopPaymentChannelDto } from "../../client/motalvip-apis/generated";
import { paymentChannelConfig } from "../../config/PaymentChannelAssetConfig";
import { CustomSwitch } from "../common/CustomSwitch";

type Props = {
  paymentChannel: ShopPaymentChannelDto;
  onDelete?: () => void;
  hideIcon?: boolean;
  size?: 'small' | 'large';
};

const PaymentChannelRecord = (props: Props) => {
  const [paymentChannel, setPaymentChannel] = useState<ShopPaymentChannelDto>(
    props.paymentChannel
  );
  const config = paymentChannelConfig.find(
    (config) =>
      config.type === paymentChannel.type &&
      (!config.bankCode || config.bankCode === props.paymentChannel.bankCode)
  );
  const backendApi = useBackendApiClient();

  const togglePaymentChannelMutation = useMutation({
    mutationFn: ({
      paymentChannelId,
      checked,
    }: {
      paymentChannelId: string;
      checked: boolean;
    }) => {
      return checked
        ? backendApi.shop.enableShopPaymentChannel({ paymentChannelId })
        : backendApi.shop.disableShopPaymentChannel({ paymentChannelId });
    },
    onSuccess: (res) => {
      setPaymentChannel(res);
      toast.success("บันทึกสำเร็จ");
    },
    onError: (error) => {
      toast.error("เกิดข้อผิดพลาด");
    },
    retry: 0,
  });

  const handleCheckedChange = (paymentChannelId: string, checked: boolean) => {
    togglePaymentChannelMutation.mutate({ paymentChannelId, checked });
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        p: props.size === 'small' ? 0 : 2,
        backgroundColor:
          paymentChannel.status === ShopPaymentChannelDto.status.ACTIVE
            ? config?.color
            : "#dddddd",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <Box p={1}>
            <img width={props.size === 'small' ? 18 : 28} src={config?.logo} alt="" />
          </Box>
          <Stack direction={props.size === 'small' ? 'row' : 'column'} justifyContent="flex-start" alignItems="flex-start">
            {paymentChannel.type === ShopPaymentChannelDto.type.AUTOMATIC_QR ? (
              <Typography
                sx={{
                  color: "white",
                }}
              >
                {"QR Code (Payment Gateway)"}
              </Typography>
            ) : paymentChannel.type === ShopPaymentChannelDto.type.CASH ? (
              <Typography
                sx={{
                  color:
                    paymentChannel.status ===
                      ShopPaymentChannelDto.status.ACTIVE
                      ? "white"
                      : "gray",
                }}
              >
                {"เงินสด"}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color:
                    paymentChannel.status ===
                      ShopPaymentChannelDto.status.ACTIVE
                      ? "white"
                      : "gray",
                }}
              >
                {paymentChannel.accountId || "-"}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                color:
                  paymentChannel.status === ShopPaymentChannelDto.status.ACTIVE
                    ? "white"
                    : "gray",
              }}
            >
              {paymentChannel.accountName || "-"}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row">
          {props.onDelete && (
            <IconButton
              sx={{ color: "white" }}
              onClick={() => {
                if (props.onDelete) props.onDelete();
              }}
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          {paymentChannel.status === ShopPaymentChannelDto.status.INACTIVE && (
            <Typography sx={{ marginRight: "3px", color: "gray" }}>
              เลิกใช้งาน
            </Typography>
          )}
          {paymentChannel.status === ShopPaymentChannelDto.status.ACTIVE &&
            paymentChannel.type === ShopPaymentChannelDto.type.PROMPT_PAY_QR &&
            !props.hideIcon && (
              <IconButton onClick={handleClickOpen}>
                <QrCodeIcon sx={{ color: "white" }} />
              </IconButton>
            )}
          {!props.hideIcon && (
            <CustomSwitch
              checked={
                paymentChannel.status === ShopPaymentChannelDto.status.ACTIVE
              }
              onChange={(e) =>
                handleCheckedChange(paymentChannel.id || "", e.target.checked)
              }
              color="success"
            />
          )}
        </Stack>
      </Stack>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <img
            src={paymentChannel.qrCodeImageUrl}
            alt=""
            style={{ objectFit: "contain", maxWidth: "80vh", width: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default PaymentChannelRecord;
