import { Box } from "@mui/material";

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: React.FC<IProps> = (props): JSX.Element => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3, pl: 1, pb: 0 }}>{children}</Box>}
    </div>
  );
};

export default CustomTabPanel;
