import { Chip } from "@mui/material"
import { DownSavingResponse } from "../../client/motalvip-apis/generated"

interface DownSavingStatusChipProps {
    status: DownSavingResponse.status
}

export const DownSavingStatusChip = (props: DownSavingStatusChipProps) => {

    const { status } = props

    return (
        <div>
            {status === DownSavingResponse.status.ACTIVE && <Chip style={{
                border: '3px solid #1e88e5',
                backgroundColor: 'white',
                color: '#1e88e5',
                width: 140
            }} variant="outlined" size="small" label={'กำลังออม'} />}
            {status === DownSavingResponse.status.WITHDRAWN && <Chip style={{
                border: '3px solid rgb(106, 106, 106)',
                backgroundColor: 'white',
                color: 'rgb(106, 106, 106)',
                width: 140
            }} variant="outlined" size="small" label={'ถอนเงิน'} />}
            {status === DownSavingResponse.status.CLOSED && <Chip style={{
                border: '3px solid rgb(0, 161, 32)',
                backgroundColor: 'white',
                color: 'rgb(0, 161, 32)',
                width: 140
            }} variant="outlined" size="small" label={'เปิดสินเชื่อ'} />}
            {status === DownSavingResponse.status.CANCELLED && <Chip style={{
                border: '3px solid rgb(106, 106, 106)',
                backgroundColor: 'white',
                color: 'rgb(106, 106, 106)',
                width: 140
            }} variant="outlined" size="small" label={'ยกเลิก'} />}
        </div>
    )
}