import { MRT_ColumnDef } from "material-react-table";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { InstallmentCommissionResponse } from "../../client/motalvip-apis/generated";
import { getFullName } from "../../util/getFullName";

const currencyFormat = Intl.NumberFormat("en-Us", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const TableColumnLoanCommission =
  (): MRT_ColumnDef<InstallmentCommissionResponse>[] => {
    return [
      {
        header: "ลำดับ",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 100,
        Header: ({ column }) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={100}
          >
            <Typography
              noWrap
              fontSize={14}
              fontWeight={500}
              textAlign="center"
            >
              {column.columnDef.header}
            </Typography>
          </Box>
        ),
        Cell: ({ row, table }) => {
          const pagination = table.getState().pagination;
          const pageIndex = pagination.pageIndex;
          const pageSize = pagination.pageSize;
          const rowNumber = pageIndex * pageSize + row.index + 1;

          return <Typography align="center">{rowNumber}</Typography>;
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "loanBalanceTotalAmount",
        accessorFn: (row: InstallmentCommissionResponse) => {
          if (row?.loanBalanceTotalAmount) {
            return (
              <Typography>
                {currencyFormat.format(row.loanBalanceTotalAmount)}
              </Typography>
            );
          } else {
            return "0";
          }
        },
        header: "ยอดจัด",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        id: "commissionTotalAmount",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {currencyFormat.format(row.commissionTotalAmount || 0)}
            </Typography>
          );
        },
        header: "คอมมิชชั่น",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        id: "additionalCommissionFeeTotalAmount",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {currencyFormat.format(
                row.additionalCommissionFeeTotalAmount || 0
              )}
            </Typography>
          );
        },
        header: "คอมมิชชั่นเพิ่มเติม",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        id: "contractFeeTotalAmount",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {currencyFormat.format(row.contractFeeTotalAmount || 0)}
            </Typography>
          );
        },
        header: "ค่าทำสัญญา",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {currencyFormat.format(
                (row.commissionTotalAmount || 0) +
                  (row.loanBalanceTotalAmount || 0) +
                  (row.additionalCommissionFeeTotalAmount || 0) -
                  (row.contractFeeTotalAmount || 0)
              )}
            </Typography>
          );
        },
        header: "ยอดที่ต้องได้รับ",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        id: "paidTotalAmount",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {currencyFormat.format(row.paidTotalAmount || 0)}
            </Typography>
          );
        },
        header: "ยอดที่ได้รับแล้ว",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        id: "paidBy",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {getFullName(
                {
                  firstName: row.paidByFinancierUser?.firstName,
                  lastName: row.paidByFinancierUser?.lastName,
                },
                " "
              )}
            </Typography>
          );
        },
        header: "ชำระโดย",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 140,
      },
      {
        id: "paidAt",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return row.paidAt ? (
            <Typography>
              {dayjs(row.paidAt).locale("th").format("DD MMM YYYY")}
            </Typography>
          ) : (
            ""
          );
        },
        header: "ชำระเมื่อ",
        enableHiding: false,
        enablePinning: false,
        enableSorting: true,
        size: 140,
      },
      {
        id: "createdAt",
        accessorFn: (row: InstallmentCommissionResponse) => {
          return (
            <Typography>
              {dayjs(row.createdAt).locale("th").format("DD MMM YYYY")}
            </Typography>
          );
        },
        header: "สร้างเมื่อ",
        enableHiding: false,
        enablePinning: false,
        enableSorting: true,
        size: 140,
      },
    ];
  };

export default TableColumnLoanCommission;
