/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from './User';
export type InstallmentPlanActivitiesDto = {
    installmentPlanId: string;
    actionBy: string;
    actionByUser?: User;
    status?: InstallmentPlanActivitiesDto.status;
    registrationStatus?: InstallmentPlanActivitiesDto.registrationStatus;
    remark?: string;
    createdAt?: string;
};
export namespace InstallmentPlanActivitiesDto {
    export enum status {
        DRAFT = 'DRAFT',
        PENDING_CONTRACT_DOCUMENTS = 'PENDING_CONTRACT_DOCUMENTS',
        PENDING_CONFIRM_DOWN_PAYMENT = 'PENDING_CONFIRM_DOWN_PAYMENT',
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
    export enum registrationStatus {
        PENDING_CUSTOMER_INFO = 'PENDING_CUSTOMER_INFO',
        PENDING_CUSTOMER_DOCUMENTS = 'PENDING_CUSTOMER_DOCUMENTS',
        PENDING_LOAN_AND_DEVICE_INFO = 'PENDING_LOAN_AND_DEVICE_INFO',
        PENDING_APPROVAL = 'PENDING_APPROVAL',
        APPROVED = 'APPROVED',
        REJECTED = 'REJECTED',
        COMPLETED = 'COMPLETED',
        CANCEL = 'CANCEL',
    }
}

