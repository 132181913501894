/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessExceptionResponse = {
    code?: BusinessExceptionResponse.code;
    description?: string;
};
export namespace BusinessExceptionResponse {
    export enum code {
        ACCESS_DENIED = 'ACCESS_DENIED',
        API_CALL_ERROR = 'API_CALL_ERROR',
        CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
        DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND',
        DEVICE_UNLOCK_FAILED = 'DEVICE_UNLOCK_FAILED',
        DEVICE_NOT_REGISTERED = 'DEVICE_NOT_REGISTERED',
        FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR',
        FINANCIER_INCORRECT = 'FINANCIER_INCORRECT',
        FINANCIER_NOT_FOUND = 'FINANCIER_NOT_FOUND',
        FINANCIER_PAYMENT_CHANNEL_INVALID_CONDITION = 'FINANCIER_PAYMENT_CHANNEL_INVALID_CONDITION',
        FINANCIER_PAYMENT_CHANNEL_NOT_FOUND = 'FINANCIER_PAYMENT_CHANNEL_NOT_FOUND',
        FINANCIER_USER_INVALID_CONDITION = 'FINANCIER_USER_INVALID_CONDITION',
        INSTALLMENT_PAYMENT_CANNOT_DELETE = 'INSTALLMENT_PAYMENT_CANNOT_DELETE',
        INSTALLMENT_PAYMENT_NOT_FOUND = 'INSTALLMENT_PAYMENT_NOT_FOUND',
        INSTALLMENT_PLAN_NOT_FOUND = 'INSTALLMENT_PLAN_NOT_FOUND',
        INSTALLMENT_PLAN_REFERENCE_ID_DUPLICATED = 'INSTALLMENT_PLAN_REFERENCE_ID_DUPLICATED',
        INSTALLMENT_PLAN_STATUS_MISMATCH = 'INSTALLMENT_PLAN_STATUS_MISMATCH',
        INSTALLMENT_TERM_NOT_FOUND = 'INSTALLMENT_TERM_NOT_FOUND',
        LOAN_INVALID_INFORMATION = 'LOAN_INVALID_INFORMATION',
        LOAN_REGISTRATION_MISSING_REQUIRED_FIELD = 'LOAN_REGISTRATION_MISSING_REQUIRED_FIELD',
        LOAN_REGISTRATION_STATUS_MISMATCH = 'LOAN_REGISTRATION_STATUS_MISMATCH',
        LOAN_STATUS_MISMATCH = 'LOAN_STATUS_MISMATCH',
        MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
        DEVICE_MANAGEMENT_KEY_NOT_FOUND = 'DEVICE_MANAGEMENT_KEY_NOT_FOUND',
        NUOVOPAY_API_KEY_INVALID = 'NUOVOPAY_API_KEY_INVALID',
        MISSING_REQUIRED_FIELDS = 'MISSING_REQUIRED_FIELDS',
        PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
        PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
        SHOP_NOT_FOUND = 'SHOP_NOT_FOUND',
        SHOP_SETTINGS_NOT_FOUND = 'SHOP_SETTINGS_NOT_FOUND',
        SHOP_PAYMENT_CHANNEL_INVALID_CONDITION = 'SHOP_PAYMENT_CHANNEL_INVALID_CONDITION',
        SHOP_PAYMENT_CHANNEL_NOT_FOUND = 'SHOP_PAYMENT_CHANNEL_NOT_FOUND',
        SHOP_USER_INVALID_CONDITION = 'SHOP_USER_INVALID_CONDITION',
        SHOP_REFERENCE_DUPLICATE = 'SHOP_REFERENCE_DUPLICATE',
        TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
        UNHANDLED = 'UNHANDLED',
        USER_INCORRECT = 'USER_INCORRECT',
        USER_IS_DUPLICATED = 'USER_IS_DUPLICATED',
        USER_NOT_FOUND = 'USER_NOT_FOUND',
        FINANCIER_SHOPS_GROUP_NOT_FOUND = 'FINANCIER_SHOPS_GROUP_NOT_FOUND',
        INCORRECT_INFORMATION = 'INCORRECT_INFORMATION',
        CATEGORY_NOT_FOUND = 'CATEGORY_NOT_FOUND',
        CATEGORY_HAS_PRODUCT = 'CATEGORY_HAS_PRODUCT',
        INSTALLMENT_PLAN_CLOSED = 'INSTALLMENT_PLAN_CLOSED',
        SHOP_PRODUCT_NOT_FOUND = 'SHOP_PRODUCT_NOT_FOUND',
        INVENTORY_NOT_FOUND = 'INVENTORY_NOT_FOUND',
        IMEI_LIST_EMPTY = 'IMEI_LIST_EMPTY',
        IMEI_LIST_AMOUNT_NOT_MATCH = 'IMEI_LIST_AMOUNT_NOT_MATCH',
        IMEI_LIST_NOT_MATCH_WITH_STOCK = 'IMEI_LIST_NOT_MATCH_WITH_STOCK',
        INVALID_GATEWAY_PAYMENT_TYPE = 'INVALID_GATEWAY_PAYMENT_TYPE',
        SHOP_PRODUCT_MOBILE_NOT_FOUND = 'SHOP_PRODUCT_MOBILE_NOT_FOUND',
        INVENTORY_NOT_ENOUGH = 'INVENTORY_NOT_ENOUGH',
        PAWNING_ID_INVALID = 'PAWNING_ID_INVALID',
        INSTALLMENT_COMMISSION_INCORRECT = 'INSTALLMENT_COMMISSION_INCORRECT',
        COMMISSION_INCORRECT = 'COMMISSION_INCORRECT',
        PAWNING_CANNOT_CALCULATE_INTEREST = 'PAWNING_CANNOT_CALCULATE_INTEREST',
        ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
        PAWNING_STATUS_INVALID = 'PAWNING_STATUS_INVALID',
        SHOP_QUOTA_NOT_FOUND = 'SHOP_QUOTA_NOT_FOUND',
        SHOP_QUOTA_ALREADY_EXISTS = 'SHOP_QUOTA_ALREADY_EXISTS',
        SHOP_QUOTA_INVALID_UPDATE = 'SHOP_QUOTA_INVALID_UPDATE',
        SHOP_QUOTA_VERIFY_FAILED = 'SHOP_QUOTA_VERIFY_FAILED',
        SHOP_QUOTA_INSUFFICIENT = 'SHOP_QUOTA_INSUFFICIENT',
        SHOP_SLIP_VERIFICATION_FAILED = 'SHOP_SLIP_VERIFICATION_FAILED',
        SHOP_SLIP_INTERNAL_ERROR = 'SHOP_SLIP_INTERNAL_ERROR',
        SHOP_SLIP_MISSING_INPUT = 'SHOP_SLIP_MISSING_INPUT',
    }
}

