/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Financier } from './Financier';
import type { FinancierShopsGroup } from './FinancierShopsGroup';
import type { User } from './User';
export type Shop = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    mongoId?: string;
    name?: string;
    address?: string;
    facebookUrl?: string;
    lineId?: string;
    status?: Shop.status;
    avatarObjectKey?: string;
    requestAt?: string;
    expireAt?: string;
    approvedAt?: string;
    approvedBy?: string;
    financierShopsGroupId?: string;
    financierId?: string;
    shopReference?: string;
    financierShopsGroup?: FinancierShopsGroup;
    financier?: Financier;
    approvedByObj?: User;
};
export namespace Shop {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
}

