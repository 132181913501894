/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FinancierEmployeeDetailUpdateRequest = {
    firstName: string;
    lastName: string;
    role: FinancierEmployeeDetailUpdateRequest.role;
    commissionAmount?: number;
};
export namespace FinancierEmployeeDetailUpdateRequest {
    export enum role {
        OWNER = 'OWNER',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
        SHOP_AGENT = 'SHOP_AGENT',
    }
}

