/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SerialRequest } from './SerialRequest';
export type InventoryRequest = {
    amount: number;
    type: InventoryRequest.type;
    shopId: string;
    shopProductId: string;
    cost: number;
    serialNumberList?: Array<SerialRequest>;
};
export namespace InventoryRequest {
    export enum type {
        INCREASE = 'INCREASE',
        DECREASE = 'DECREASE',
    }
}

