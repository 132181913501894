import { Theme } from "@emotion/react";
import {
  SxProps,
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import { DocumentDto } from "../client/motalvip-apis/generated";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  title: string;
  documents: DocumentDto[] | undefined;
  onChange: (files: File[]) => void;
  onRemove: (id: string | undefined) => void;
  accept?: string;
  maxFileSize?: number; // in bytes
  maxFiles?: number; // maximum number of files allowed
  sx?: SxProps<Theme>;
  disableDelete?: boolean;
  disableUpload?: boolean;
}

const MultipleFileUploadWithPreview: React.FC<IProps> = ({
  title,
  documents = [],
  onChange,
  onRemove,
  accept = "image/*",
  maxFileSize = 5 * 1024 * 1024,
  maxFiles = 10,
  sx,
  disableDelete,
  disableUpload,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [selectedPreview, setSelectedPreview] = useState<DocumentDto | null>(
    null
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);

    const selectedFiles = Array.from(event.target.files || []);

    // Check if adding these files would exceed the maximum
    if (documents && documents.length + selectedFiles.length > maxFiles) {
      setError(`จำนวนไฟล์สูงสุดที่อัปโหลดได้คือ ${maxFiles} ไฟล์`);
      return;
    }

    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > maxFileSize
    );
    if (oversizedFiles.length > 0) {
      setError(`ขนาดไฟล์ต้องไม่เกิน ${maxFileSize / 1024 / 1024} MB`);
      return;
    }

    onChange(selectedFiles);
  };

  return (
    <Box sx={sx}>
      <Dialog onClose={() => setSelectedPreview(null)} open={!!selectedPreview}>
        <IconButton
          aria-label="close"
          onClick={() => setSelectedPreview(null)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <img
            src={selectedPreview?.downloadUrl}
            alt=""
            style={{
              objectFit: "contain",
              maxWidth: "80vh",
              width: "100%",
            }}
          />
        </DialogContent>
      </Dialog>

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {title}
      </Typography>

      {error && (
        <Box sx={{ color: "error.main", mb: 2 }}>
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}

      {!disableUpload && (
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{ mb: 2 }}
          disabled={documents && documents.length >= maxFiles}
        >
          อัปโหลดไฟล์
          <input
            type="file"
            hidden
            multiple
            accept={accept}
            onClick={(e) => {
              (e.target as HTMLInputElement).value = "";
            }}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </Button>
      )}

      <Stack direction="row" gap={2}>
        {documents &&
          documents.map((document, index) => (
            <Card
              key={index}
              sx={{
                position: "relative",
                height: 140,
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                image={document.downloadUrl}
                alt={document.originalName}
                onClick={() => setSelectedPreview(document)}
              />
              {!disableDelete && (
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => onRemove(document.id)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Card>
          ))}
      </Stack>
    </Box>
  );
};

export default MultipleFileUploadWithPreview;
