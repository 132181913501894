/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FinancierEmployeeResponse = {
    id?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: FinancierEmployeeResponse.role;
    commissionAmount?: number;
};
export namespace FinancierEmployeeResponse {
    export enum role {
        OWNER = 'OWNER',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
        SHOP_AGENT = 'SHOP_AGENT',
    }
}

