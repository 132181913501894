/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractDetail } from '../models/ContractDetail';
import type { ContractDocuments } from '../models/ContractDocuments';
import type { CustomerDetail } from '../models/CustomerDetail';
import type { GeneratePawningContractResponse } from '../models/GeneratePawningContractResponse';
import type { GetPawningContractTemplateResponse } from '../models/GetPawningContractTemplateResponse';
import type { GetUploadContractDocumentUrlRequest } from '../models/GetUploadContractDocumentUrlRequest';
import type { PaginatedGetPawningContractResponse } from '../models/PaginatedGetPawningContractResponse';
import type { PawningContractResponse } from '../models/PawningContractResponse';
import type { SubmitPawningContractRequest } from '../models/SubmitPawningContractRequest';
import type { UploadUrlResponse } from '../models/UploadUrlResponse';
import type { UpsertPawningContractTemplateRequest } from '../models/UpsertPawningContractTemplateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopPawningCreationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public submitPawningContract({
        shopId,
        pawningId,
        requestBody,
    }: {
        shopId: string,
        pawningId: string,
        requestBody: SubmitPawningContractRequest,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shops/{shopId}/pawning/{pawningId}',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GetPawningContractTemplateResponse OK
     * @throws ApiError
     */
    public getPawningTemplate({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<GetPawningContractTemplateResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/template',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public upsertPawningTemplate({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: UpsertPawningContractTemplateRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shops/{shopId}/pawning/template',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedGetPawningContractResponse OK
     * @throws ApiError
     */
    public getPawningContracts({
        shopId,
        page = 1,
        perPage = 10,
        pawningStatus,
        isFilterAppointmentOnly,
        search,
        dueDateFilter,
    }: {
        shopId: string,
        page?: number,
        perPage?: number,
        pawningStatus?: 'ONGOING' | 'REDEEMED' | 'FORECLOSURE',
        isFilterAppointmentOnly?: boolean,
        search?: string,
        dueDateFilter?: 'THREE_DAY_UNTIL_DUE' | 'ONE_DAY_UNTIL_DUE' | 'DUE' | 'THREE_DAY_AFTER_DUE' | 'AFTER_DUE',
    }): CancelablePromise<PaginatedGetPawningContractResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning',
            path: {
                'shopId': shopId,
            },
            query: {
                'page': page,
                'perPage': perPage,
                'pawningStatus': pawningStatus,
                'isFilterAppointmentOnly': isFilterAppointmentOnly,
                'search': search,
                'dueDateFilter': dueDateFilter,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public createPawningContract({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public uploadPawningContractDocument({
        shopId,
        pawningId,
        requestBody,
    }: {
        shopId: string,
        pawningId: string,
        requestBody: GetUploadContractDocumentUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/upload-document-url',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GeneratePawningContractResponse OK
     * @throws ApiError
     */
    public generateContract({
        shopId,
        pawningId,
        requestBody,
    }: {
        shopId: string,
        pawningId: string,
        requestBody: SubmitPawningContractRequest,
    }): CancelablePromise<GeneratePawningContractResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/generate-contract',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ContractDocuments OK
     * @throws ApiError
     */
    public getAPawningContractDocuments({
        shopId,
        pawningId,
    }: {
        shopId: string,
        pawningId: string,
    }): CancelablePromise<ContractDocuments> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/documents',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns CustomerDetail OK
     * @throws ApiError
     */
    public getAPawningCustomerDetail({
        shopId,
        pawningId,
    }: {
        shopId: string,
        pawningId: string,
    }): CancelablePromise<CustomerDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/customer-detail',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ContractDetail OK
     * @throws ApiError
     */
    public getAPawningContractDetail({
        shopId,
        pawningId,
    }: {
        shopId: string,
        pawningId: string,
    }): CancelablePromise<ContractDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/contract-detail',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public remark({
        shopId,
        pawningId,
        remarkId,
    }: {
        shopId: string,
        pawningId: string,
        remarkId: string,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/remark/{remarkId}',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
                'remarkId': remarkId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
