import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { useCategoryDeleteMutation } from "../../client/query-client/useCategoryQuery"
import { BusinessExceptionResponse, CategoryResponse } from "../../client/motalvip-apis/generated"
import { useParams } from "react-router-dom"

interface Props {
    open: boolean
    onClose: () => void
    category: CategoryResponse | null
}

export const DeleteCategoryDialog = ({ open, onClose, category }: Props) => {
    const { shopId } = useParams()
    const { mutateAsync: deleteCategory, isPending, error, reset } = useCategoryDeleteMutation()
    const isCategoryNotFoundError = [
        BusinessExceptionResponse.code.CATEGORY_HAS_PRODUCT,
        BusinessExceptionResponse.code.CATEGORY_NOT_FOUND
    ].includes((error as any)?.body?.code)

    const onConfirm = async () => {
        if (category?.id) {
            try {
                await deleteCategory({ categoryId: category.id, shopId: shopId! })
                onClose()
            } catch (e) { }
        }
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    return (
        <Dialog open={open && !!category} onClose={handleClose}>
            <DialogTitle>การลบหมวดหมู่สินค้า</DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="div">
                    ยืนยันที่จะลบหมวดหมู่สินค้า "{category?.name}" ออกจากระบบหรือไม่
                </Typography>
                {isCategoryNotFoundError && <Typography color="error">ไม่สามารถลบได้ เนื่องจากมีสินค้าที่ยังใช้หมวดหมู่สินค้านี้อยู่</Typography>}
                <Stack direction='row' gap={4} marginTop={4}>
                    <Button size='large' variant='outlined' type="button" onClick={handleClose} fullWidth>
                        ยกเลิก
                    </Button>
                    <Button size='large' variant='contained' color='error' type="button" onClick={onConfirm} disabled={isPending} fullWidth>
                        ยืนยัน
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
