/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryResponse } from '../models/CategoryResponse';
import type { CreateCategoryRequest } from '../models/CreateCategoryRequest';
import type { PaginatedCategoryDto } from '../models/PaginatedCategoryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CategoryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns string OK
     * @throws ApiError
     */
    public editCategory({
        categoryId,
        requestBody,
    }: {
        categoryId: string,
        requestBody: CreateCategoryRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/categories/{categoryId}',
            path: {
                'categoryId': categoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public deleteCategory({
        shopId,
        categoryId,
    }: {
        shopId: string,
        categoryId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/categories/{categoryId}',
            path: {
                'categoryId': categoryId,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns CategoryResponse OK
     * @throws ApiError
     */
    public getCategories({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Array<CategoryResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/categories',
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public createCategory({
        requestBody,
    }: {
        requestBody: CreateCategoryRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedCategoryDto OK
     * @throws ApiError
     */
    public getCategoriesSearch({
        shopId,
        search,
        sortBy = 'created_at',
        sort = 'desc',
        page = 1,
        limit = 20,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedCategoryDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/categories/search',
            query: {
                'shopId': shopId,
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
