import { Stack, Card, CardContent, Box, Typography, Grid } from "@mui/material";
import { LoanShopFinancierReportDto } from "../../client/motalvip-apis/generated";

interface IProps {
  report: LoanShopFinancierReportDto | undefined;
}

const LoanSummaryWidget: React.FC<IProps> = (props): JSX.Element => {
  const { report } = props;

  const currencyFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Stack gap={{ xs: 2, md: 3 }} sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: 3 }}>
              <Stack justifyContent="center" alignItems="center">
                <Typography variant="h6" sx={{ mb: 1 }}>
                  ยอดที่ต้องได้รับ
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#1976d2", fontWeight: "bold" }}
                >
                  {currencyFormat.format(report?.requiredTotal || 0)}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: 3 }}>
              <Stack justifyContent="center" alignItems="center">
                <Typography variant="h6" sx={{ mb: 1 }}>
                  ยอดที่ได้รับแล้ว
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#4caf50", fontWeight: "bold" }}
                >
                  {currencyFormat.format(report?.receivedTotal || 0)}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: "100%", boxShadow: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                  ยอดจัด
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#9e9e9e" }}
                >
                  {currencyFormat.format(report?.loanBalanceTotal || 0)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: "100%", boxShadow: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                  คอมมิชชั่น
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#9e9e9e" }}
                >
                  {currencyFormat.format(report?.commissionTotal || 0)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: "100%", boxShadow: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                  คอมมิชชั่นเพิ่มเติม
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#9e9e9e" }}
                >
                  {currencyFormat.format(
                    report?.additionalCommissionTotal || 0
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: "100%", boxShadow: 3, borderRadius: 2 }}>
            <CardContent sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                  ค่าทำสัญญา
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#ef9a9a" }}
                >
                  {currencyFormat.format(report?.contractFeeTotal || 0)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default LoanSummaryWidget;
