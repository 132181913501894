import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Button, Card, CardContent, FormControl, FormHelperText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { CustomInputLabel } from "../../../component/common/CustomInputLabel"
import { DocumentDto, DownSavingCreateRequest, ShopPaymentChannelDto } from "../../../client/motalvip-apis/generated";
import { Controller, useForm } from "react-hook-form";
import { useDownSavingCreateMutation } from "../../../client/query-client/downSaving/useDownSavingCreateMutation";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import toast from "react-hot-toast";
import { Spin } from "../../../component/common/Spin";
import { usePaymentChannelQuery } from "../../../client/query-client/usePaymentChannelQuery";
import { useDropzone } from "react-dropzone";
import { useCreateDocumentMutation } from "../../../client/query-client/document/useDocumentCreateMutation";
import { useEffect, useState } from "react";
import { DocumentPreviewCard } from "../../../component/display/DocumentPreviewCard";
import { useUploadFileMutation } from "../../../client/query-client/useUploadFileMutation";

interface FileWithPreview {
    file: DocumentDto;
    uploaded: boolean;
}

export const DownSavingCreateScreen = () => {

    const navigate = useNavigate();
    const { shopId } = useParams<{ shopId: string }>();
    const [fileUploadStatuses, setFileUploadStatuses] = useState<
        FileWithPreview[]
    >([]);
    const paymentChannelsQuery = usePaymentChannelQuery(shopId || '')

    const { control, getValues, setValue, formState, watch } = useForm<DownSavingCreateRequest>({
        defaultValues: {
            shopId: shopId,
            startDate: dayjs().format('YYYY-MM-DD'),
            contractReferenceId: '',
            productPrice: 0,
            downPercentage: 30,
            savingFrequency: DownSavingCreateRequest.savingFrequency.DAILY
        },
        mode: 'all'
    });
    const createDownSavingMutation = useDownSavingCreateMutation()
    const createDocumentMutation = useCreateDocumentMutation()
    const uploadFileMutation = useUploadFileMutation()

    const onSubmit = () => {
        createDownSavingMutation.mutate(getValues(), {
            onSuccess: (res) => {
                toast.success('สร้างออมดาวน์สำเร็จ')
                navigate(`/${shopId}/down-saving/${res.id}`);
            }
        })
    }
    const onDrop = async (files: File[]) => {
        await Promise.all(
            files.map(
                (file) =>
                    new Promise<void>(async (resolve, reject) => {
                        const res = await createDocumentMutation.mutateAsync(
                            {
                                originalName: file.name,
                                referenceType: DocumentDto.referenceType.DOWN_SAVING
                            },
                            {
                                onError: reject,
                            }
                        );
                        await uploadFileMutation.mutateAsync({ file: file, uploadUrl: res.uploadUrl || '' }, { onError: reject, });
                        setFileUploadStatuses((prev) => [
                            ...prev,
                            { file: res || "", uploaded: true },
                        ]);
                        resolve();
                    })
            )
        );
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const watchSavingStartAmount = watch('savingStartAmount')

    useEffect(() => {
        setValue("documentIds", fileUploadStatuses.filter(doc => doc.uploaded).map((doc) => doc.file.id || ''));
    }, [fileUploadStatuses, setValue])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
            <Spin spinning={createDownSavingMutation.isPending}>
                <Box p={2}>
                    <Typography variant="h5" pb={2}>{'สร้างออมดาวน์'}</Typography>
                    <Card sx={{ mb: 2, width: { xs: "100%" } }}>
                        <CardContent sx={{ padding: 2, paddingBottom: 1 }}>
                            <Stack gap={2}>
                                <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>วันที่เริ่มออม (วัน/เดือน/ปี ค.ศ.) *</CustomInputLabel>
                                        <Controller control={control}
                                            name="startDate"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <DatePicker {...field}
                                                    format="DD/MM/YYYY"
                                                    value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                    onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                    slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                            )} />
                                        {formState.errors.startDate && (
                                            <FormHelperText error>กรุณาระบุวันที่นำเข้า</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>{'หมายเลขอ้างอิง (ห้ามซ้ำ)'}</CustomInputLabel>
                                        <Controller control={control}
                                            name="contractReferenceId"
                                            rules={{ required: false }}
                                            render={({ field }) => (
                                                <TextField {...field} size='small' variant="outlined" />
                                            )} />
                                        {formState.errors.contractReferenceId && (
                                            <FormHelperText error>กรุณาระบุหมายเลขอ้างอิง</FormHelperText>
                                        )}
                                    </FormControl>
                                </Stack>


                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack
                        sx={{ mb: 2 }}
                        direction={{ xs: "column", lg: "row" }}
                        gap={2}
                        useFlexGap
                        alignItems="flex-start"
                    >
                        <Card sx={{ width: "100%" }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ข้อมูลลูกค้า</Typography>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'ชื่อจริง *'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerFirstName"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                            {formState.errors.customerFirstName && (
                                                <FormHelperText error>ต้องการชื่อ</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'นามสกุล *'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerLastName"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                            {formState.errors.customerLastName && (
                                                <FormHelperText error>ต้องการนามสกุล</FormHelperText>
                                            )}
                                        </FormControl>

                                    </Stack>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'เลขบัตรประชาชน'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerCitizenId"
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'เบอร์โทรศัพท์'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerPhoneNumber"
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'อีเมล'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerEmail"
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'Facebook URL'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerFacebookUrl"
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>

                                    </Stack>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'Line ID'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerLineId"
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'ที่อยู่'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="customerAddress"
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: "100%" }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ข้อมูลออมดาวน์</Typography>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'แบรนเครื่อง'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="productBrand"
                                                rules={{}}
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'รุ่นเครื่อง'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="productModel"
                                                rules={{}}
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" />
                                                )} />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'ราคาสินค้า *'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="productPrice"
                                                rules={{
                                                    required: { value: true, message: 'ต้องระบุราคาสินค้า' },
                                                    min: { value: 1, message: 'ขั้นต่ำ 1' }

                                                }}
                                                render={({ field }) => (
                                                    <TextField {...field} size='small' variant="outlined" type="number" />
                                                )} />
                                            {formState.errors.productPrice && (
                                                <FormHelperText error>{formState.errors.productPrice.message}</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'เปอร์เซ็นต์ดาวน์ *'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="downPercentage"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select {...field} size='small' variant="outlined">
                                                        <MenuItem value={10}>10%</MenuItem>
                                                        <MenuItem value={15}>15%</MenuItem>
                                                        <MenuItem value={20}>20%</MenuItem>
                                                        <MenuItem value={25}>25%</MenuItem>
                                                        <MenuItem value={30}>30%</MenuItem>
                                                        <MenuItem value={35}>35%</MenuItem>
                                                        <MenuItem value={40}>40%</MenuItem>
                                                        <MenuItem value={45}>45%</MenuItem>
                                                        <MenuItem value={50}>50%</MenuItem>
                                                        <MenuItem value={55}>55%</MenuItem>
                                                        <MenuItem value={60}>60%</MenuItem>
                                                        <MenuItem value={65}>65%</MenuItem>
                                                        <MenuItem value={70}>70%</MenuItem>
                                                        <MenuItem value={75}>75%</MenuItem>
                                                    </Select>
                                                )} />
                                            {formState.errors.downPercentage && (
                                                <FormHelperText error>ต้องระบุเปอร์เซ็นต์ดาวน์</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'ยอดดาวน์ (คำนวณ)'}</CustomInputLabel>
                                            <TextField
                                                inputProps={{ readOnly: true }}
                                                type="number"
                                                value={(watch("productPrice") || 0) * (watch('downPercentage') || 0) / 100}
                                                size='small'
                                                variant="outlined"
                                            />
                                        </FormControl>

                                    </Stack>
                                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent='space-between' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'ยอดออมเริ่มต้น *'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="savingStartAmount"
                                                rules={{
                                                    min: { value: 0, message: 'ต้องเป็นเลขบวกเท่านั้น' }
                                                }}
                                                render={({ field }) => (
                                                    <TextField {...field} type="number" size='small' variant="outlined" />
                                                )} />
                                            {formState.errors.productPrice && (
                                                <FormHelperText error>ต้องระบุยอดออมเริ่มต้น</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'ยอดออมขั้นต่ำต่อครั้ง *'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="savingAmount"
                                                rules={{
                                                    required: true,
                                                    min: { value: 1, message: 'ต้องมากกว่า 0' }
                                                }}
                                                render={({ field }) => (
                                                    <TextField {...field} type="number" size='small' variant="outlined" />
                                                )} />
                                            {formState.errors.productPrice && (
                                                <FormHelperText error>ต้องระบุยอดออม</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{'เก็บออมทุกๆ'}</CustomInputLabel>
                                            <Controller control={control}
                                                name="savingFrequency"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Select {...field} size='small' variant="outlined">
                                                        <MenuItem value={DownSavingCreateRequest.savingFrequency.DAILY}>1 วัน</MenuItem>
                                                        <MenuItem value={DownSavingCreateRequest.savingFrequency.THREE_DAYS}>3 วัน</MenuItem>
                                                        <MenuItem value={DownSavingCreateRequest.savingFrequency.WEEKLY}>7 วัน</MenuItem>
                                                        <MenuItem value={DownSavingCreateRequest.savingFrequency.BIWEEKLY}>14 วัน</MenuItem>
                                                        <MenuItem value={DownSavingCreateRequest.savingFrequency.MONTHLY}>1 เดือน</MenuItem>
                                                    </Select>
                                                )} />
                                        </FormControl>
                                    </Stack>
                                    <Stack>
                                        {(watchSavingStartAmount || 0) > 0 && (
                                            <FormControl
                                                sx={{ width: { xs: "100%", lg: "25%" } }}
                                                error={
                                                    formState.errors.shopPaymentChannelId?.message
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <CustomInputLabel>
                                                    ช่องทางการรับเงินออมเริ่มต้น
                                                </CustomInputLabel>
                                                <Controller
                                                    control={control}
                                                    name="shopPaymentChannelId"
                                                    rules={{
                                                        required: {
                                                            value: (watchSavingStartAmount || 0) > 0,
                                                            message: "ต้องการช่องทางรับเงิน",
                                                        },
                                                    }}
                                                    render={({ field }) => {
                                                        return (
                                                            <Select
                                                                {...field}
                                                                labelId="paymentChannel-label"
                                                                id="paymentChannel"
                                                                size="small"
                                                                value={
                                                                    (watchSavingStartAmount || 0) > 0
                                                                        ? field.value
                                                                        : undefined
                                                                }
                                                            >
                                                                {paymentChannelsQuery.data?.map((p) => (
                                                                    <MenuItem key={p.id} value={`${p.id}`}>
                                                                        {p.type ===
                                                                            ShopPaymentChannelDto.type.BANK_ACCOUNT
                                                                            ? `${p.bankCode} : ${p.accountId} : ${p.accountName}`
                                                                            : p.type ===
                                                                                ShopPaymentChannelDto.type.CASH
                                                                                ? "เงินสด"
                                                                                : p.type ===
                                                                                    ShopPaymentChannelDto.type.PROMPT_PAY_QR
                                                                                    ? `คิวอาร์ : ${p.accountName}`
                                                                                    : p.type ===
                                                                                        ShopPaymentChannelDto.type.PROMPT_PAY
                                                                                        ? `พ้อมเพย์ : ${p.accountId} : ${p.accountName}`
                                                                                        : ""}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        );
                                                    }}
                                                />
                                                {formState.errors.shopPaymentChannelId && (
                                                    <Typography color="error">
                                                        {formState.errors.shopPaymentChannelId.message}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        )}
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack>
                        <Card sx={{ width: { xs: "100%" } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Stack gap={2} width={{ xs: '100%', lg: '50%' }}>
                                        <Typography variant="h6">โน้ต</Typography>
                                        <FormControl>
                                            <Controller control={control}
                                                name="note"
                                                render={({ field }) => (
                                                    <TextField {...field} multiline size='small' variant="outlined"
                                                        rows={4}
                                                        maxRows={4} />
                                                )} />
                                        </FormControl>
                                    </Stack>
                                    <Stack gap={2}>
                                        <Typography variant="h6">ไฟล์แนบ</Typography>
                                        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
                                            <Box
                                                {...getRootProps()}
                                                sx={{
                                                    width: "100%",
                                                    border: "2px dashed darkgray",
                                                    borderRadius: "8px",
                                                    backgroundColor: "white",
                                                    padding: 4,
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor: "#b3e5fc",
                                                        color: "#03a9f4",
                                                        borderColor: "#03a9f4",
                                                    },
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "120px",
                                                }}
                                            >
                                                <input {...getInputProps()} />
                                                <Stack direction="column" alignItems="center" gap={1}>
                                                    <FileUploadIcon />
                                                    <Typography>อัพโหลดไฟล์</Typography>
                                                </Stack>
                                            </Box>

                                            <Box sx={{ width: "100%" }}>
                                                <Stack
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        width: "100%",
                                                    }}
                                                    direction={"row"}
                                                    gap={2}
                                                >
                                                    {fileUploadStatuses.map((doc) => (
                                                        <DocumentPreviewCard
                                                            document={doc.file}
                                                            uploaded={doc.uploaded}
                                                            onDelete={() => { }}
                                                            onDownload={() => { }}
                                                        />
                                                    ))}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Box sx={{ p: 2, mb: 4, width: { xs: '100%' } }} >
                        <Stack direction='row' gap={1} justifyContent='center'>
                            <Button variant='outlined' size='large'
                                onClick={() => navigate(-1)}
                                sx={{
                                    width: {
                                        xs: '100%', sm: '100%', md: 200
                                    },
                                }}>ยกเลิก</Button>
                            <Button variant='contained' size='large'
                                onClick={onSubmit}
                                disabled={!formState.isDirty || !formState.isValid}
                                sx={{
                                    width: {
                                        xs: '100%', sm: '100%', md: 200
                                    },
                                }}>บันทึก</Button>
                        </Stack>
                    </Box>
                </Box>
            </Spin>
        </LocalizationProvider>
    )
}