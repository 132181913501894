import { checkNullableData } from "./checkNullableData";

export const getFullName = (
  data?: {
    firstName?: string;
    lastName?: string;
  },
  emptyText?: string
): string => {
  if (!data) {
    return emptyText || "-";
  }

  const { firstName, lastName } = data;

  if (checkNullableData(firstName)) {
    return emptyText || "-";
  }

  const safeFirstName = checkNullableData(firstName) ? "" : firstName;
  const safeLastName = checkNullableData(lastName) ? "" : lastName;

  return `${safeFirstName} ${safeLastName}`.trim();
};
