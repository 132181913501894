import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";

interface IProps {
  reference: React.RefObject<HTMLDivElement>;
  contract: InstallmentPlanDto;
}

const ConsentFormDocument: React.FC<IProps> = (props): JSX.Element => {
  const { shop } = useShopFinancierContext();

  return (
    <Paper elevation={2} sx={{ width: "100%" }} ref={props.reference}>
      <Box sx={{ width: "100%", height: "920px", p: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <Box sx={{ width: 100 }}>
              {shop?.financier?.avatarUrl && (
                <img
                  src={shop?.financier?.avatarUrl}
                  alt="logo"
                  style={{ width: "100%" }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={8} textAlign="center">
            <Typography variant="subtitle1">
              หนังสือให้ความยินยอมเกี่ยวกับข้อมูลส่วนบุคคล
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            mt: 7,
          }}
        >
          <Stack direction="row" flexGrow={1}>
            <Typography fontSize={14}>ข้าพเจ้า</Typography>
            <Box sx={{ borderBottom: "1px solid #000", flexGrow: 1 }} />
          </Stack>
          <Stack direction="row" gap={1}>
            <Box width={16} height={16} sx={{ border: 2 }}></Box>
            <Typography fontSize={14}>ยินยอม</Typography>
          </Stack>
          <Stack direction="row" gap={1} ml={1}>
            <Box width={16} height={16} sx={{ border: 2 }}></Box>
            <Typography fontSize={14}>ไม่ยินยอม</Typography>
          </Stack>
        </Box>

        <Typography fontSize={14} paragraph lineHeight={2} mt={1}>
          {`ให้ ${shop?.financier?.name} และ/หรือธุรกิจในเครือ ซึ่งรวมเรียกว่า
          ”ผู้รับข้อมูล”เก็บรวบรวมใช้ เเละเปิดเผยข้อมูลส่วนบุคคล ที่ทำให้
          สามารถระบุตัวตนของข้าพเจ้าได้ ซึ่งรวมทั้งข้อมูลชีวภาพ เช่น
          ภาพลายนิ้วมือ ภาพใบหน้า หรือข้อมูลใดๆ
          ของข้าพเจ้าที่ได้ให้ไว้หรือมีอยู่กับ ผู้รับข้อมูล
          หรือที่ผู้รับข้อมูลได้รับหรือเข้าถึงได้จากเเหล่งอื่น
          (ต่อไปนี้จะรวมเรียกว่า”ข้อมูล”) โดยมีวัตถุประสงค์เพื่อ`}
        </Typography>

        <Typography
          fontSize={14}
          paragraph
          lineHeight={2}
          sx={{ textIndent: "30px" }}
        >
          (1) การทำธุรกรรม สัญญาซื้อขาย สัญญาเช่าซื้อ สัญญาเช่า
          หรือข้อมูลใดๆที่เกี่ยวข้องกับการค้าหรือประกอบ
          ธุรกิจได้กระทำการระหว่างกัน
        </Typography>

        <Typography
          fontSize={14}
          paragraph
          lineHeight={2}
          sx={{ textIndent: "30px" }}
        >
          (2) ติดต่อสื่อสารรายการส่งเสริมการขาย การเสนอผลิตภัณฑ์ บริการ
          กิจกรรมส่งเสริมการตลาด ข่าวสาร สิทธิ ประโยนช์ต่างๆ
          ของผู้รับข้อมูลให้เเก่ข้าพเจ้า
        </Typography>

        <Typography
          fontSize={14}
          paragraph
          lineHeight={2}
          sx={{ textIndent: "30px" }}
        >
          (3) การวิเคราะห์วิจัยผลิตภัณฑ์ หรือ บริการ
          การพัฒนาและการปรับปรุงการให้บริการ การวิจัยทางสถิติของผู้รับข้อมูล
          ทั้งนี้ข้าพเจ้ายินยอมให้ผู้รับข้อมูลสามารถเก็บรวบรวม
          ใช้และเปิดเผยข้อมูลของข้าพเจ้าภายในกรอบ วัตถุประสงค์ที่ระบุไว้ ข้างต้น
          รวมทั้ง ข้าพเจ้ายินยอมให้บริษัทฯ ส่งหรือ
          โอนข้อมูลของข้าพเจ้าไปยังผู้รับข้อมูลในประเทศปลายทางที่อาจมี
          มาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอได้เพื่อวัตถุประสงค์ที่ระบุใน
          หนังสือ ฉบับนี้ ภายใต้ขอบเขตที่ระบุไว้
          ปรากฏบนเว็บไซต์ของผู้รับข้อมูลที่นโยบายคุ้มครองข้อมูลส่วนบุคคล และ
          กฎหมายที่อนุญาตให้กระทำได้
        </Typography>

        <Typography
          fontSize={14}
          paragraph
          lineHeight={2}
          sx={{ textIndent: "30px" }}
        >
          {`หนังสือให้ความยินยอมฉบับนี้
          กระทำขึ้นโดยความสมัครใจโดยใช้ชัดเเจ้งของข้าพเจ้า และข้าพเจ้าได้รับทราบ
          วัตถุประสงค์และรายละเอียดต่างๆ ที่เกี่ยวข้อง และข้าพเจ้ารับทราบว่า
          หากข้าพเจ้าประสงค์จะเพิกถอนความยินยอมนี้ หรือ มีความประสงค์จะติดต่อ
          หรือยื่นข้อร้องใดๆ ที่เกี่ยวกับการละเมิดสิทธิของเจ้าของข้อมูลส่วนบุคคล
          ข้าพเจ้าสามารถแจ้ง ความประสงค์ หรือติดต่อบริษัทฯ ได้ที่ ${
            shop?.financier?.lineId
              ? `Line ID: ${shop?.financier?.lineId} หรือ `
              : ""
          }เบอร์ติดต่อ ${shop?.financier?.phoneNumbers[0].phoneNumber}`}
        </Typography>

        <Box mt={10} pl={10}>
          <Stack direction="row" alignItems="top" gap={1}>
            <Typography fontSize={14}>ลงชื่อ</Typography>
            <Stack spacing={1} alignItems="center" sx={{ width: "175px" }}>
              <Box sx={{ borderBottom: 1, width: "100%", height: "20px" }} />
              <Typography variant="caption" fontSize={14}>
                ({"\u00A0".repeat(50)})
              </Typography>
            </Stack>
            <Typography fontSize={14}>ผู้ให้ความยินยอม</Typography>
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};

export default ConsentFormDocument;
