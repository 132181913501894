import { useMutation } from "@tanstack/react-query";
import { QueryKey } from ".";
import { useBackendApiClient } from "../motalvip-apis";

export const useGenerateLoanQRPayment = () => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.PAYMENT, "loan", "generate-qr"],
    mutationFn: apiClient.public.generateLoanQrPaymentRequest.bind(
      apiClient.public
    ),
  });
};
