import { GetShopProductsResponse } from "../../../client/motalvip-apis/generated"

export const ProductTypeLabelMapper = {
    [GetShopProductsResponse.type.MOBILE]: 'มือถือ',
    [GetShopProductsResponse.type.GENERAL]: 'สินค้าทั่วไป'
}

export enum MobileType {
    FIRSTHAND = 1,
    SECONDHAND = 2
}

export interface ProductVariantValue {
    sku: string
    price: number
    optionValue1: string
    optionValue2?: string
    note?: string
    variantId?: number
    representDocumentRefId?: string
}

interface VariantDetail {
    price: string
    skuCode: string
    note?: string
    variantId?: number
}

interface SingleVariant extends VariantDetail {
    productImage: File | string
}

export interface MultiVariant {
    detail: {
        [option: string]: VariantDetail
    },
    productImage: File | string
}

export interface CreateProductFormType {
    name: string
    description: string
    type: GetShopProductsResponse.type
    categoryId: string
    mobileType: MobileType
    optionValue1: string[]
    optionValue2: string[]
    optionName1: string
    optionName2: string
    productMultiVariant: Record<string, MultiVariant>
    productSingleVariant: Record<string, SingleVariant>
    productPrice: string
    productSku: string
    productNote: string
    productImage: File | string
    hasOption: boolean
}
