import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { InstallmentPlanActivitiesDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanActivitiesDto";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import { formatThaiDate } from "../../util/formatThaiDate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getFullName } from "../../util/getFullName";

dayjs.extend(utc);
dayjs.extend(timezone);

const TabInstallmentActivity: React.FC<{
  activityLogs: InstallmentPlanActivitiesDto[] | undefined;
}> = ({ activityLogs }) => {
  const convertToThaiWord = (
    registrationStatus: InstallmentPlanDto.registrationStatus | undefined,
    status: InstallmentPlanDto.status | undefined
  ) => {
    if (
      registrationStatus ===
        InstallmentPlanDto.registrationStatus.PENDING_APPROVAL &&
      status === InstallmentPlanDto.status.DRAFT
    ) {
      return "รออนุมัติ";
    } else if (
      registrationStatus === InstallmentPlanDto.registrationStatus.REJECTED &&
      status === InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS
    ) {
      return "ส่งกลับเอกสารสัญญา";
    }

    switch (registrationStatus) {
      case InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_INFO:
        return "ข้อมูลลูกค้า";
      case InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_DOCUMENTS:
        return "เอกสารลูกค้า";
      case InstallmentPlanDto.registrationStatus.PENDING_LOAN_AND_DEVICE_INFO:
        return "ข้อมูลสินเชื่อเครื่อง";
      case InstallmentPlanDto.registrationStatus.REJECTED:
        return "ส่งกลับร่างสัญญา";
      case InstallmentPlanDto.registrationStatus.PENDING_APPROVAL:
        return "อนุมัติ";
      case InstallmentPlanDto.registrationStatus.APPROVED:
        switch (status) {
          case InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS:
            return "เอกสารสัญญา";
          case InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT:
            return "จ่ายเงินดาวน์";
          default:
            return "สำเร็จ";
        }
      case InstallmentPlanDto.registrationStatus.COMPLETED:
        return "สำเร็จ";
      case InstallmentPlanDto.registrationStatus.CANCEL:
        return "ยกเลิกสัญญา";
    }
  };
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 3 }}>
        ประวัติการใช้งาน
      </Typography>

      <Box>
        {activityLogs &&
          activityLogs.map((log, index) => {
            const formattedTime = dayjs(log.createdAt)
              .tz("Asia/Bangkok")
              .format("HH:mm:ss");

            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                sx={{ mb: index < activityLogs.length - 1 ? 0 : 2 }}
              >
                {/* Time and date column */}
                <Box sx={{ width: 80, textAlign: "right" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="bold"
                  >
                    {formattedTime}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatThaiDate(log.createdAt || new Date())}
                  </Typography>
                </Box>

                {/* Timeline visual elements */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: 20,
                  }}
                >
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      bgcolor: "orange",
                    }}
                  />
                  {index < activityLogs.length - 1 && (
                    <Box
                      sx={{
                        width: 2,
                        height: 50,
                        bgcolor: "orange",
                        flexGrow: 1,
                      }}
                    />
                  )}
                </Box>

                {/* Content column */}
                <Box sx={{ pt: 0, flex: 1 }}>
                  <Typography variant="body1" fontWeight="bold">
                    {convertToThaiWord(log.registrationStatus, log.status)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: log.remark ? 0 : 2 }}
                  >
                    {`เปลี่ยนแปลงโดย: ${getFullName(
                      {
                        firstName: log.actionByUser?.firstName,
                        lastName: log.actionByUser?.lastName,
                      },
                      "ไม่ทราบชื่อ"
                    )}`}
                  </Typography>

                  {log.remark && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, whiteSpace: "pre-line" }}
                    >
                      {`หมายเหตุ: ${log.remark}`}
                    </Typography>
                  )}
                </Box>
              </Stack>
            );
          })}
      </Box>
    </Box>
  );
};

// const TabInstallmentActivity: React.FC = () => {
//   const activityLogsData: ActivityLog[] = [
//     {
//       installmentPlanId: "string",
//       actionBy: "string",
//       actionByUser: {
//         id: "string",
//         createdAt: "2025-03-08T19:12:55.155Z",
//         updatedAt: "2025-03-08T19:12:55.155Z",
//         mongoId: "string",
//         clerkUserId: "string",
//         primaryEmail: "string",
//         firstName: "string",
//         lastName: "string",
//         citizenId: "string",
//         taxId: "string",
//         status: "PENDING",
//       },
//       status: "DRAFT",
//       registrationStatus: "PENDING_CUSTOMER_INFO",
//       remark: "string",
//     },
//     {
//       installmentPlanId: "string",
//       actionBy: "string",
//       actionByUser: {
//         id: "string",
//         createdAt: "2025-03-08T19:12:55.155Z",
//         updatedAt: "2025-03-08T19:12:55.155Z",
//         mongoId: "string",
//         clerkUserId: "string",
//         primaryEmail: "string",
//         firstName: "string",
//         lastName: "string",
//         citizenId: "string",
//         taxId: "string",
//         status: "PENDING",
//       },
//       status: "DRAFT",
//       registrationStatus: "PENDING_CUSTOMER_INFO",
//       remark: "string",
//     },
//     {
//       installmentPlanId: "string",
//       actionBy: "string",
//       actionByUser: {
//         id: "string",
//         createdAt: "2025-03-08T19:12:55.155Z",
//         updatedAt: "2025-03-08T19:12:55.155Z",
//         mongoId: "string",
//         clerkUserId: "string",
//         primaryEmail: "string",
//         firstName: "string",
//         lastName: "string",
//         citizenId: "string",
//         taxId: "string",
//         status: "PENDING",
//       },
//       status: "DRAFT",
//       registrationStatus: "PENDING_CUSTOMER_INFO",
//       remark: "string",
//     },
//   ];

//   return <ActivityLogTimeline activityLogs={activityLogsData} />;
// };

export default TabInstallmentActivity;
