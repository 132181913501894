import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { QueryKey } from ".";
import { InventoryService } from "../motalvip-apis/generated";


export const useInventoriesQuery = (params: Parameters<InventoryService['getAllInventory']>[0]) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.INVENTORY, 'inventories'],
        queryFn: () => backendApiClient.inventory.getAllInventory(params)
    });
};
