import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { QueryKey } from ".";
import { useBackendApiClient } from "../motalvip-apis";
import { BackendApiClient } from "../motalvip-apis/generated";

export const useGetPaymentQr = (
  params?: Parameters<BackendApiClient["gatewayPayment"]["getQr"]>[0]
) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    enabled: Boolean(params),
    queryKey: [QueryKey.PAYMENT, params, "payment_gateway_qr"],
    queryFn: () => apiClient.gatewayPayment.getQr(params!),
    placeholderData: keepPreviousData,
  });
};
