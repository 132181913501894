import { useMutation } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";

export const useDeleteGatewayPayment = ({
  onSuccess,
}: {
  onSuccess?: VoidFunction;
}) => {
  const backendApiClient = useBackendApiClient();
  return useMutation({
    mutationFn: (id: string) =>
      backendApiClient.gatewayPayment.deleteGatewayPayment({
        id,
      }),
    onSuccess,
    retry: 0,
  });
};
