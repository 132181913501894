import { Autocomplete, Box, Button, Card, Chip, FormControl, FormLabel, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Control, Controller, FieldErrors, UseFormRegister, UseFormResetField, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { CreateProductFormType } from "./types"
import { TextArea } from "./TextArea"
import { v4 as uuidv4 } from 'uuid'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ProductImage, VisuallyHiddenInput } from "./styled"
import { getImageUrl } from "../utils"

interface ProductError {
    error: string
}

interface Props {
    control: Control<CreateProductFormType, any>
    errors: FieldErrors<CreateProductFormType>
    setValue: UseFormSetValue<CreateProductFormType>
    register: UseFormRegister<CreateProductFormType>
    watch: UseFormWatch<CreateProductFormType>
    resetField: UseFormResetField<CreateProductFormType>
    productVariantError: Record<number, string> | null
    productError: ProductError | null
    editMode?: boolean
    hasProductOption?: boolean // use only edit form
    isPending?: boolean
}

export const ProductGeneral = ({ control, errors, setValue, register, watch, editMode, hasProductOption, isPending, productVariantError, productError }: Props) => {
    const [hasOption, setHasOption] = useState(false)
    const optionName1 = watch('optionName1')
    const optionName2 = watch('optionName2')
    const optionValue1 = watch('optionValue1')
    const optionValue2 = watch('optionValue2')
    const productSingleVariant = watch('productSingleVariant')
    const productMultiVariant = watch('productMultiVariant')
    const hasOptionValue1 = optionValue1?.length > 0
    const hasOptionValue2 = optionValue2?.length > 0

    useEffect(() => {
        setValue('hasOption', hasOption)
    }, [hasOption, setValue])

    useEffect(() => {
        if (optionName1 && editMode && hasProductOption) {
            setHasOption(true)
        }
    }, [editMode, optionName1, hasProductOption])

    return (
        <>
            {!(editMode && !optionName1) && <Card sx={{ px: 2, pb: 4, pt: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6" marginBottom={2}>คุณสมบัติสินค้า</Typography>
                    {!editMode && <Switch checked={hasOption} onChange={(_, checked) => { setHasOption(checked) }} />}
                </Stack>
                <Typography variant="body1">สามารถเปิดใช้งานคุณสมบัติ เพื่อเพิ่มตัวเลือกสินค้าได้ (สูงสุด 2 ตัวเลือก เช่น สี, ขนาด)</Typography>
                {hasOption && (
                    <>
                        <Stack gap={2} mt={2}>
                            <FormControl fullWidth error={!!errors.optionName1} required>
                                <Box display='flex' justifyContent="space-between">
                                    <FormLabel>ชื่อคุณสมบัติ</FormLabel>
                                    <Typography variant="caption">(2-200 ตัวอักษร)</Typography>
                                </Box>
                                <Controller
                                    name="optionName1"
                                    control={control}
                                    rules={{
                                        required: "กรุณาระบุ",
                                    }}
                                    render={({ field }) => <TextField {...field} size='small' variant="outlined" placeholder="ใส่ชื่อคุณสมบัติ" inputProps={{ maxLength: 200 }} />}
                                />
                                {errors.optionName1 && <Typography color="error">{errors.optionName1.message}</Typography>}
                            </FormControl>
                            {optionName1 && <FormControl sx={{ width: '100%' }} required>
                                <FormLabel>{optionName1}</FormLabel>
                                <Controller
                                    name="optionValue1"
                                    control={control}
                                    rules={{
                                        validate: (value) => (value?.length > 0 ? true : "จำเป็นต้องใส่อย่างน้อย 1"),
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            size="small"
                                            multiple
                                            freeSolo
                                            value={optionValue1 || []}
                                            options={[]}
                                            onChange={(_, newValue) => {
                                                setValue('optionValue1', newValue)
                                                if (!optionName2 && productSingleVariant) {
                                                    const productVariant = { ...productSingleVariant }
                                                    Object.keys(productSingleVariant).forEach(variantKey => {
                                                        if (!newValue.includes(variantKey)) {
                                                            delete productVariant[variantKey]
                                                        }
                                                    })
                                                    setValue('productSingleVariant', productVariant)
                                                } else if (optionName2 && productMultiVariant) {
                                                    const productVariant = { ...productMultiVariant }
                                                    Object.keys(productMultiVariant).forEach(variantKey => {
                                                        if (!newValue.includes(variantKey)) {
                                                            delete productVariant[variantKey]
                                                        }
                                                    })
                                                    setValue('productMultiVariant', productVariant)
                                                }
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip {...getTagProps({ index })} key={index} label={option} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField sx={{ width: '100%' }} {...params} variant="outlined" placeholder='ใส่ค่าของคุณสมบัติ' />
                                            )}
                                        />
                                    )}
                                />
                                {errors.optionValue1 && <Typography color="error">{errors.optionValue1.message}</Typography>}
                            </FormControl>}
                        </Stack>
                        {optionName1 && (
                            <Stack gap={2} mt={2}>
                                {!(editMode && !optionName2) && <FormControl fullWidth error={!!errors.optionName2}>
                                    <Box display='flex' justifyContent="space-between">
                                        <FormLabel>ชื่อคุณสมบัติ</FormLabel>
                                        <Typography variant="caption">(2-200 ตัวอักษร)</Typography>
                                    </Box>
                                    <Controller
                                        name="optionName2"
                                        control={control}
                                        render={({ field }) => <TextField {...field} size='small' variant="outlined" placeholder="ใส่ชื่อคุณสมบัติ" inputProps={{ maxLength: 200 }} />}
                                    />
                                    {errors.optionName2 && <Typography color="error">{errors.optionName2.message}</Typography>}
                                </FormControl>}
                                {optionName2 && <FormControl sx={{ width: '100%' }} required>
                                    <FormLabel>{optionName2}</FormLabel>
                                    <Controller
                                        name="optionValue2"
                                        control={control}
                                        rules={{
                                            validate: (value) => (value?.length > 0 ? true : "จำเป็นต้องใส่อย่างน้อย 1"),
                                        }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                size="small"
                                                multiple
                                                freeSolo
                                                value={optionValue2 || []}
                                                options={[]}
                                                onChange={(_, newValue) => {
                                                    setValue('optionValue2', newValue)
                                                    if (optionName2 && productMultiVariant) {
                                                        const productVariant = { ...productMultiVariant }
                                                        Object.keys(productMultiVariant).forEach(variantKey => {
                                                            const subItem = productMultiVariant[variantKey]
                                                            if (subItem) {
                                                                Object.keys(subItem).forEach(itemKey => {
                                                                    if (!newValue.includes(itemKey)) {
                                                                        if (productVariant[variantKey]?.detail?.[itemKey]) {
                                                                            delete productVariant[variantKey].detail[itemKey]
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        setValue('productMultiVariant', productVariant)
                                                    }
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip {...getTagProps({ index })} key={index} label={option} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField sx={{ width: '100%' }} {...params} variant="outlined" placeholder='ใส่ค่าของคุณสมบัติ' />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.optionValue2 && <Typography color="error">{errors.optionValue2.message}</Typography>}
                                </FormControl>}
                                {
                                    hasOptionValue1 && (
                                        optionValue1.map((value1, option1Index) => {
                                            const productVariantImage =
                                                hasOptionValue2 ? getImageUrl(productMultiVariant?.[value1]?.productImage)
                                                    : getImageUrl(productSingleVariant?.[value1]?.productImage)

                                            return (
                                                <Box marginBottom={2} key={hasOptionValue2 ? value1 : `multi-variant-${value1}`}>
                                                    <Typography variant="body1" fontWeight={600} marginBottom={2}>{`${optionName1}: ${value1}`}</Typography>
                                                    <Stack gap={1} pb={2}>
                                                        <FormControl fullWidth error={hasOptionValue2 ? !!errors.productMultiVariant?.[value1]?.productImage : !!errors.productSingleVariant?.[value1]?.productImage} required>
                                                            <FormLabel sx={{ marginBottom: 1 }}>{`รูปสินค้าของคุณสมบัติ ${value1}`}</FormLabel>
                                                            {hasOptionValue2 && productVariantImage && <ProductImage src={productVariantImage} alt="" />}
                                                            {!hasOptionValue2 && productVariantImage && <ProductImage src={productVariantImage} alt="" />}
                                                            <Controller
                                                                name={hasOptionValue2 ? `productMultiVariant.${value1}.productImage` : `productSingleVariant.${value1}.productImage`}
                                                                control={control}
                                                                rules={{
                                                                    validate: (value) => (value ? true : "จำเป็นต้องใส่รูปสินค้า"),
                                                                }}
                                                                render={() => (
                                                                    <Button
                                                                        component="label"
                                                                        variant="outlined"
                                                                        tabIndex={-1}
                                                                        color="primary"
                                                                        sx={{ width: 150, borderRadius: 100 }}
                                                                        startIcon={<CloudUploadIcon />}
                                                                    >
                                                                        อัพโหลด
                                                                        <VisuallyHiddenInput
                                                                            type="file"
                                                                            role="button"
                                                                            onChange={(event) => {
                                                                                const file = event.target.files?.[0];
                                                                                if (file) {
                                                                                    if (hasOptionValue2) {
                                                                                        setValue('productMultiVariant', {
                                                                                            ...productMultiVariant,
                                                                                            [value1]: {
                                                                                                ...productMultiVariant?.[value1],
                                                                                                productImage: file
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        setValue('productSingleVariant', {
                                                                                            ...productSingleVariant,
                                                                                            [value1]: {
                                                                                                ...productSingleVariant?.[value1],
                                                                                                productImage: file
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }}

                                                                        />
                                                                    </Button>
                                                                )}
                                                            />
                                                            {!hasOptionValue2 && errors?.productSingleVariant?.[value1]?.['productImage'] && <Typography color="error">{errors?.productSingleVariant?.[value1]?.['productImage']?.message}</Typography>}
                                                            {hasOptionValue2 && errors?.productMultiVariant?.[value1]?.['productImage'] && <Typography color="error">{errors?.productMultiVariant?.[value1]?.['productImage']?.message}</Typography>}
                                                        </FormControl>
                                                    </Stack>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    {hasOptionValue2 && <TableCell>{optionName2}</TableCell>}
                                                                    <TableCell>ราคา*</TableCell>
                                                                    <TableCell>sku_code</TableCell>
                                                                    <TableCell>note</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!hasOptionValue2 && (
                                                                    <TableRow
                                                                        key={optionName2}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell>
                                                                            <TextField size='small' variant="outlined" placeholder="กรอกราคา" type="number" inputProps={{ step: "any" }}
                                                                                {...register(`productSingleVariant.${value1}.price`, {
                                                                                    required: 'กรุณาระบุ',
                                                                                    valueAsNumber: true,
                                                                                    min: { value: 0, message: "Value must be positive" }
                                                                                })}
                                                                                error={!!errors.productSingleVariant?.[value1]?.price}
                                                                            />
                                                                            {errors.productSingleVariant?.[value1]?.price?.message
                                                                                && <Typography color="error">{errors.productSingleVariant?.[value1]?.price?.message}</Typography>}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <TextField size='small' variant="outlined" placeholder={isPending ? '' : uuidv4()} inputProps={{ maxLength: 200 }}
                                                                                {...register(`productSingleVariant.${value1}.skuCode`)}
                                                                                error={!!errors.productSingleVariant?.[value1]?.skuCode || !!productVariantError?.[option1Index]}
                                                                            />
                                                                            {errors.productSingleVariant?.[value1]?.skuCode?.message
                                                                                && <Typography color="error">{errors.productSingleVariant?.[value1]?.skuCode?.message}</Typography>}
                                                                            {productVariantError?.[option1Index] && <Typography color="error">{productVariantError[option1Index]}</Typography>}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <TextArea placeholder="ใส่ข้อมูลที่นี่..." maxLength={2000} {...register(`productSingleVariant.${value1}.note`)} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                                {hasOptionValue2 && optionValue2.map((value2, option2Index) => (
                                                                    <TableRow
                                                                        key={value2}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell>
                                                                            {value2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <TextField size='small' variant="outlined" placeholder="กรอกราคา" type="number" inputProps={{ step: "any" }}
                                                                                {...register(`productMultiVariant.${value1}.detail.${value2}.price`, {
                                                                                    required: 'กรุณาระบุ',
                                                                                    valueAsNumber: true,
                                                                                    min: { value: 0, message: "Value must be positive" }
                                                                                })}
                                                                                error={!!errors.productMultiVariant?.[value1]?.detail?.[value2]?.price}
                                                                            />
                                                                            {errors.productMultiVariant?.[value1]?.detail?.[value2]?.price?.message
                                                                                && <Typography color="error">{errors.productMultiVariant?.[value1]?.detail?.[value2]?.price?.message}</Typography>}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <TextField size='small' variant="outlined" placeholder={isPending ? '' : uuidv4()} inputProps={{ maxLength: 200 }}
                                                                                {...register(`productMultiVariant.${value1}.detail.${value2}.skuCode`)}
                                                                                error={!!errors.productMultiVariant?.[value1]?.detail?.[value2]?.skuCode || !!productVariantError?.[(2 * option1Index) + option2Index]}
                                                                            />
                                                                            {errors.productMultiVariant?.[value1]?.detail?.[value2]?.skuCode?.message
                                                                                && <Typography color="error">{errors.productMultiVariant?.[value1]?.detail?.[value2]?.skuCode?.message}</Typography>}
                                                                            {productVariantError?.[(2 * option1Index) + option2Index] && <Typography color="error">{productVariantError[(2 * option1Index) + option2Index]}</Typography>}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <TextArea placeholder="ใส่ข้อมูลที่นี่..." maxLength={2000} {...register(`productMultiVariant.${value1}.detail.${value2}.note`)} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            )
                                        })
                                    )
                                }
                            </Stack>
                        )}
                    </>
                )}
            </Card>}
            {((!hasOption && !editMode) || (!hasProductOption && editMode)) && (
                <Card sx={{ px: 2, pb: 4, pt: 2 }}>
                    <Typography variant="h6" marginBottom={2}>รายละเอียดสินค้าเพิ่มเติม</Typography>
                    <Stack gap={2}>
                        <FormControl fullWidth error={!!errors.productPrice} required>
                            <FormLabel>ราคาสินค้า</FormLabel>
                            <Controller
                                name="productPrice"
                                control={control}
                                rules={{
                                    required: "กรุณาระบุ",
                                    min: { value: 0, message: "Value must be positive" }
                                }}
                                render={({ field }) => <TextField {...field} size='small' variant="outlined" placeholder="ใส่ราคาสินค้า" type="number" inputProps={{ step: "any" }} />}
                            />
                            {errors.productPrice && <Typography color="error">{errors.productPrice.message}</Typography>}
                        </FormControl>
                        <FormControl fullWidth error={!!errors.productSku || !!productError?.error}>
                            <Box display='flex' justifyContent="space-between">
                                <FormLabel>SKU code</FormLabel>
                                <Typography variant="caption">(2-200 ตัวอักษร)</Typography>
                            </Box>
                            <Controller
                                name="productSku"
                                control={control}
                                render={({ field }) => <TextField {...field} size='small' variant="outlined" placeholder={isPending ? '' : uuidv4()} inputProps={{ maxLength: 200 }} />}
                            />
                            {errors.productSku && <Typography color="error">{errors.productSku.message}</Typography>}
                            {productError?.error && <Typography color="error">{productError.error}</Typography>}
                        </FormControl>
                        <FormControl fullWidth error={!!errors.productNote}>
                            <Box display='flex' justifyContent="space-between">
                                <FormLabel>note</FormLabel>
                                <Typography variant="caption">(0-2000 ตัวอักษร)</Typography>
                            </Box>
                            <Controller
                                name="productNote"
                                control={control}
                                render={({ field }) => <TextArea {...field} placeholder="ใส่ข้อมูลที่นี่..." maxLength={2000} />}
                            />
                            {errors.productNote && <Typography color="error">{errors.productNote.message}</Typography>}
                        </FormControl>
                    </Stack>
                </Card>
            )}
        </>
    )
}
