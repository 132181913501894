import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import { ShopPaymentChannelDto } from "../../../../../client/motalvip-apis/generated";
import { useGenerateDownSavingPaymentQR } from "../../../../../client/query-client/downSaving/useGenerateDownSavingPaymentQR";
import { usePaymentChannelQuery } from "../../../../../client/query-client/usePaymentChannelQuery";
import { QrCropped } from "../../../../../component/common/QrCropped";

export const CreateDownSavingQRPaymentDialog = ({
  open,
  onClose,
  title,
  shopId,
  downSavingId,
}: {
  shopId: string;
  downSavingId: string;
  open: boolean;
  onClose: VoidFunction;
  title: string | undefined;
}) => {
  const create = useGenerateDownSavingPaymentQR();
  const [downSavingAmount, setDownSavingAmount] = useState("0");
  const [fineAmount, setFineAmount] = useState("0");
  const [qrUrl, setQrUrl] = useState<string | null>(null);

  const onSubmit = async () => {
    if (!downSavingId) return;
    if (!selectedChannelId) {
      toast.error("กรุณาเลือก Payment Gateway");
      return;
    }
    const parsedAmount = parseFloat(downSavingAmount);
    const parsedFineAmount = parseFloat(fineAmount);
    if (isNaN(parsedAmount) || isNaN(parsedFineAmount)) {
      toast.error("กรุณากรอกค่าออมและค่าปรับให้ครบ");
      return;
    }
    if (parsedAmount === 0 && parsedFineAmount === 0) {
      toast.error("กรุณากรอกค่าออมหรือค่าปรับอย่างน้อย 1 รายการ");
      return;
    }

    const loadingId = toast.loading("กำลังสร้าง QR Code โปรดรอสักครู่...");
    try {
      const result = await create.mutateAsync({
        requestBody: {
          downSavingId,
          shopPaymentChannelId: selectedChannelId || "",
          amount: {
            amount: parsedAmount,
            fineAmount: parsedFineAmount,
          },
        },
      });
      setQrUrl(result.qrCodeUrl);
    } catch (e) {
      toast.error("เกิดข้อผิดพลาดในการสร้าง QR Code");
      console.error(e);
      return;
    } finally {
      toast.dismiss(loadingId);
    }
  };

  const onCloseDialog = () => {
    onClose();
    setQrUrl(null);
  };

  const { data: paymentChannels } = usePaymentChannelQuery(shopId || "");

  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(
    null
  );

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogTitle>{title}</DialogTitle>

      <Box
        sx={{
          bgcolor: "white",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {qrUrl ? (
          <QrCropped src={qrUrl} style={{ alignSelf: "center" }} />
        ) : (
          <>
            <FormControl fullWidth>
              <InputLabel id="shop-payment-channel-select-label">
                เลือก Payment Gateway
              </InputLabel>
              <Select
                labelId="shop-payment-channel-select-label"
                id="shop-payment-channel-select"
                value={selectedChannelId}
                label="เลือก Payment Gateway"
                onChange={(e) => {
                  const value = e.target.value as string;
                  if (!value) return;
                  setSelectedChannelId(value);
                }}
              >
                {paymentChannels
                  ?.filter(
                    (channel) =>
                      channel.type === ShopPaymentChannelDto.type.AUTOMATIC_QR
                  )
                  .map((channel) => (
                    <MenuItem key={channel.id} value={channel.id}>
                      {channel.accountName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              label="ค่าออม"
              type="number"
              value={downSavingAmount}
              onChange={(e) => {
                setDownSavingAmount(e.target.value);
              }}
              fullWidth
            />
            <TextField
              label="ค่าปรับ"
              type="number"
              value={fineAmount}
              onChange={(e) => setFineAmount(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={!selectedChannelId}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    </Dialog>
  );
};
