/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddInstallmentNoteRequest } from '../models/AddInstallmentNoteRequest';
import type { InstallmentImeiSerialSearchResponse } from '../models/InstallmentImeiSerialSearchResponse';
import type { InstallmentPaymentCsvReportGenerationResult } from '../models/InstallmentPaymentCsvReportGenerationResult';
import type { InstallmentPlanActivitiesDto } from '../models/InstallmentPlanActivitiesDto';
import type { InstallmentPlanDocumentDto } from '../models/InstallmentPlanDocumentDto';
import type { InstallmentPlanDto } from '../models/InstallmentPlanDto';
import type { LoanByShopIdSearchRequest } from '../models/LoanByShopIdSearchRequest';
import type { LoanCommissionShopAgentSearchRequest } from '../models/LoanCommissionShopAgentSearchRequest';
import type { LoanCommissionShopSearchRequest } from '../models/LoanCommissionShopSearchRequest';
import type { LoanCountByShopAgentResponse } from '../models/LoanCountByShopAgentResponse';
import type { LoanCustomerHistoryRequest } from '../models/LoanCustomerHistoryRequest';
import type { LoanDocumentRemoveRequest } from '../models/LoanDocumentRemoveRequest';
import type { LoanDocumentUploadConfirmRequest } from '../models/LoanDocumentUploadConfirmRequest';
import type { LoanDraftCreateRequest } from '../models/LoanDraftCreateRequest';
import type { LoanPatchRequest } from '../models/LoanPatchRequest';
import type { LoanRegisterCustomerSearchRequest } from '../models/LoanRegisterCustomerSearchRequest';
import type { LoanRegisterSearchRequest } from '../models/LoanRegisterSearchRequest';
import type { LoanReportRequest } from '../models/LoanReportRequest';
import type { LoanSearchRequest } from '../models/LoanSearchRequest';
import type { LoanShopAgentReportRequest } from '../models/LoanShopAgentReportRequest';
import type { LoanShopFinancierReportDto } from '../models/LoanShopFinancierReportDto';
import type { LoanWaitingPaidSearchRequest } from '../models/LoanWaitingPaidSearchRequest';
import type { PaginatedInstallmentCommissionResponse } from '../models/PaginatedInstallmentCommissionResponse';
import type { PaginatedInstallmentPlanDto } from '../models/PaginatedInstallmentPlanDto';
import type { StepUpdateLoanDraftApproveLoanRequest } from '../models/StepUpdateLoanDraftApproveLoanRequest';
import type { StepUpdateLoanDraftBack } from '../models/StepUpdateLoanDraftBack';
import type { StepUpdateLoanDraftCancelRequest } from '../models/StepUpdateLoanDraftCancelRequest';
import type { StepUpdateLoanDraftConfirmDownPaymentRequest } from '../models/StepUpdateLoanDraftConfirmDownPaymentRequest';
import type { StepUpdateLoanDraftContractDocumentRequest } from '../models/StepUpdateLoanDraftContractDocumentRequest';
import type { StepUpdateLoanDraftCustomerDocumentRequest } from '../models/StepUpdateLoanDraftCustomerDocumentRequest';
import type { StepUpdateLoanDraftCustomerInfoRequest } from '../models/StepUpdateLoanDraftCustomerInfoRequest';
import type { StepUpdateLoanDraftDeviceAndLoanApprovalRequest } from '../models/StepUpdateLoanDraftDeviceAndLoanApprovalRequest';
import type { stepUpdateLoanDraftRejectLoanContractRequest } from '../models/stepUpdateLoanDraftRejectLoanContractRequest';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstallmentPlanService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public updateInstallmentPlanStatus({
        requestBody,
    }: {
        requestBody: InstallmentPlanDto,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/{installmentPlanId}/status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public addInstallmentPlanNote({
        installmentPlanId,
        requestBody,
    }: {
        installmentPlanId: string,
        requestBody: AddInstallmentNoteRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/{installmentPlanId}/note',
            path: {
                'installmentPlanId': installmentPlanId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanActivitiesDto OK
     * @throws ApiError
     */
    public getInstallmentActivitiesByInstallmentPlanId({
        installmentPlanId,
    }: {
        installmentPlanId: string,
    }): CancelablePromise<Array<InstallmentPlanActivitiesDto>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/{installmentPlanId}/installment-activities',
            path: {
                'installmentPlanId': installmentPlanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public updateInstallmentPlanCost({
        requestBody,
    }: {
        requestBody: InstallmentPlanDto,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/update-cost',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentCsvReportGenerationResult OK
     * @throws ApiError
     */
    public generateCsvInstallmentPlansReport({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<InstallmentPaymentCsvReportGenerationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-financier/{shopId}/report/installment-plans/csv',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanShopFinancierReportDto OK
     * @throws ApiError
     */
    public getReportLoanByShopId({
        requestBody,
    }: {
        requestBody: LoanReportRequest,
    }): CancelablePromise<LoanShopFinancierReportDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-financier/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public searchLoanByShopId({
        requestBody,
    }: {
        requestBody: LoanByShopIdSearchRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-financier/loan/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentCommissionResponse OK
     * @throws ApiError
     */
    public getLoanCommissionByShopId({
        requestBody,
    }: {
        requestBody: LoanCommissionShopSearchRequest,
    }): CancelablePromise<PaginatedInstallmentCommissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-financier/loan-commission',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanShopFinancierReportDto OK
     * @throws ApiError
     */
    public getReportLoanByShopAgentId({
        requestBody,
    }: {
        requestBody: LoanShopAgentReportRequest,
    }): CancelablePromise<LoanShopFinancierReportDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-agent-financier/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanCountByShopAgentResponse OK
     * @throws ApiError
     */
    public getLoanCountByShopAgentId({
        requestBody,
    }: {
        requestBody: LoanShopAgentReportRequest,
    }): CancelablePromise<Array<LoanCountByShopAgentResponse>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-agent-financier/report/loan-count',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentCommissionResponse OK
     * @throws ApiError
     */
    public getLoanCommissionByShopAgentId({
        requestBody,
    }: {
        requestBody: LoanCommissionShopAgentSearchRequest,
    }): CancelablePromise<PaginatedInstallmentCommissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/shop-agent-financier/loan-commission',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public searchLoans({
        requestBody,
    }: {
        requestBody: LoanSearchRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public searchLoansRegister({
        requestBody,
    }: {
        requestBody: LoanRegisterSearchRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/register/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public searchLoansRegister1({
        requestBody,
    }: {
        requestBody: LoanRegisterCustomerSearchRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/register/customer/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public recalculateInstallmentPlan({
        installmentPlanId,
    }: {
        installmentPlanId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/recalculate/{installmentPlanId}',
            path: {
                'installmentPlanId': installmentPlanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public updateFinancierInstallmentPlanStatus({
        requestBody,
    }: {
        requestBody: InstallmentPlanDto,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/financier/{installmentPlanId}/status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentCommissionResponse OK
     * @throws ApiError
     */
    public getLoanShopWaitingPaidByFinancierId({
        requestBody,
    }: {
        requestBody: LoanWaitingPaidSearchRequest,
    }): CancelablePromise<PaginatedInstallmentCommissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/financier/shop/loan-waiting-paid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanShopFinancierReportDto OK
     * @throws ApiError
     */
    public getLoanShopWaitingPaidReportByFinancierId({
        requestBody,
    }: {
        requestBody: LoanWaitingPaidSearchRequest,
    }): CancelablePromise<LoanShopFinancierReportDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/financier/shop/loan-waiting-paid/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentCommissionResponse OK
     * @throws ApiError
     */
    public getLoanShopAgentWaitingPaidByFinancierId({
        requestBody,
    }: {
        requestBody: LoanWaitingPaidSearchRequest,
    }): CancelablePromise<PaginatedInstallmentCommissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/financier/shop-agent/loan-waiting-paid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanShopFinancierReportDto OK
     * @throws ApiError
     */
    public getLoanShopAgentWaitingPaidReportByFinancierId({
        requestBody,
    }: {
        requestBody: LoanWaitingPaidSearchRequest,
    }): CancelablePromise<LoanShopFinancierReportDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/financier/shop-agent/loan-waiting-paid/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public createLoanDraft({
        requestBody,
    }: {
        requestBody: LoanDraftCreateRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public shopStepUpdateLoanDraftCancelLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftCancelRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/shop/cancel-loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftRejectLoanContract({
        requestBody,
    }: {
        requestBody: stepUpdateLoanDraftRejectLoanContractRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/reject-loan-contract',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftRejectLoanApproval({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/reject-loan-approval',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftDeviceAndLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/loan-and-device',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public financierStepUpdateLoanDraftCancelLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftCancelRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/financier/cancel-loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftCustomerInfo({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftCustomerInfoRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/customer-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftCustomerDocument({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftCustomerDocumentRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/customer-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftContractDocument({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftContractDocumentRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/contract-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftConfirmDownPayment({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftConfirmDownPaymentRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/confirm-down-payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftBack({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftBack,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/back',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftApproveLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftApproveLoanRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/draft/approve-loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDocumentDto OK
     * @throws ApiError
     */
    public createInstallmentPlanDocument({
        requestBody,
    }: {
        requestBody: InstallmentPlanDocumentDto,
    }): CancelablePromise<InstallmentPlanDocumentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmLoanDocumentUpload({
        requestBody,
    }: {
        requestBody: LoanDocumentUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/document-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public getLoanCustomerHistory({
        requestBody,
    }: {
        requestBody: LoanCustomerHistoryRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/customer-history',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public createInstallmentPlan({
        requestBody,
    }: {
        requestBody: InstallmentPlanDto,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/confirm-create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public calculateInstallmentPayments({
        requestBody,
    }: {
        requestBody: InstallmentPlanDto,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/plan/calculate-installment-payments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public patchLoan({
        requestBody,
    }: {
        requestBody: LoanPatchRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/installments/plan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public getInstallmentPlanById({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/plan/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentImeiSerialSearchResponse OK
     * @throws ApiError
     */
    public searchByImeiOrSerial({
        imei,
        serial,
    }: {
        imei?: string,
        serial?: string,
    }): CancelablePromise<InstallmentImeiSerialSearchResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/plan/search/imei-serial',
            query: {
                'imei': imei,
                'serial': serial,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public deleteInstallmentPlan({
        installmentPlanId,
    }: {
        installmentPlanId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/installments/plan/{installmentPlanId}',
            path: {
                'installmentPlanId': installmentPlanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteInstallmentPlanNote({
        installmentPlanId,
        noteId,
    }: {
        installmentPlanId: string,
        noteId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/installments/plan/{installmentPlanId}/note/{noteId}',
            path: {
                'installmentPlanId': installmentPlanId,
                'noteId': noteId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public removeLoanDocument({
        requestBody,
    }: {
        requestBody: LoanDocumentRemoveRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/installments/plan/document-remove',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
