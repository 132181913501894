import { LinkOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useListDevices } from "../client/query-client/devicemanagement/useListDevices";
import { useViewMaskedDeviceManagementApiKey } from "../client/query-client/devicemanagement/useViewMaskedDeviceManagementApiKey";
import LocationTracker from "../component/common/LocationTracker";
import { DeviceTable } from "../component/devicemanagement/DeviceTable";
import { useShopContext } from "../context/ShopContextProvider";
import { useDebouncedValue } from "../util/useDebouncedValue";

export function LocationTrackingScreen() {
  const { shopId } = useShopContext();
  const {
    data: maskedApiKeyResponse,
    isError,
    isLoading: isLoadingApiKey,
  } = useViewMaskedDeviceManagementApiKey(shopId);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedValue(search, 250);
  const { data: devices, isLoading: isDevicesLoading } = useListDevices({
    shopId: shopId ?? null,
    enabled: Boolean(maskedApiKeyResponse?.maskedApiKey),
    page,
    search: debouncedSearch,
  });

  const onLocationRequest = useCallback(
    (value: { imei?: string; serial: string }) => {
      setIsLocationDialogOpen(true);
      setSelectedImeiAndSerial(value);
    },
    []
  );

  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const [selectedImeiAndSerial, setSelectedImeiAndSerial] = useState<{
    imei?: string;
    serial: string;
  } | null>(null);

  if (isLoadingApiKey) {
    return (
      <Box sx={{ p: 2, mb: 8 }}>
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }

  if (isError && !maskedApiKeyResponse?.maskedApiKey) {
    return (
      <Box sx={{ p: 2, mb: 8 }}>
        <Typography variant="h5">เช็ค api key ไม่ได้</Typography>
      </Box>
    );
  }

  if (!maskedApiKeyResponse?.maskedApiKey) {
    return (
      <Box sx={{ p: 2, mb: 8 }}>
        <Typography variant="h5">
          กรุณาใส่ API Key เพื่อติดตามตำแหน่ง
        </Typography>
        <div
          style={{
            height: "8px",
          }}
        />
        <Link href={`settings/device-management`}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            ไปที่หน้าตั้งค่า <LinkOutlined />
          </Button>
        </Link>
      </Box>
    );
  }

  return (
    <>
      <Card sx={{ p: 2, mb: 8 }}>
        <Typography variant="h3">Location Tracking</Typography>
        {isDevicesLoading ? (
          <div>...loading</div>
        ) : (
          <DeviceTable
            onSearchChanged={setSearch}
            search={search}
            total={devices?.totalCount || 0}
            page={page}
            onPageChanged={(p) => {
              setPage(p);
            }}
            devices={devices?.devices || []}
            onLocationRequest={onLocationRequest}
          />
        )}
      </Card>
      <Dialog
        open={isLocationDialogOpen}
        onClose={setIsLocationDialogOpen}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Location</DialogTitle>
        <DialogContent>
          <LocationTracker
            imei={selectedImeiAndSerial?.imei}
            serialNumber={selectedImeiAndSerial?.serial}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsLocationDialogOpen(false);
              setSelectedImeiAndSerial(null);
            }}
            color="inherit"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
