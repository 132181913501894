import { ChevronRight } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useBackendApiClient } from "../../client/motalvip-apis";
import { Device } from "../../client/motalvip-apis/generated";
import { useShopContext } from "../../context/ShopContextProvider";
import {
  formatLongReadableThaiDate,
  parseNuovoDate,
} from "../../util/DateUtil";

export interface DeviceTableProps {
  devices: Device[];
  onLocationRequest: (device: { imei?: string; serial: string }) => void;
  page: number;
  total: number;
  search?: string;
  onPageChanged: (page: number) => void;
  onSearchChanged: (search: string) => void;
}

// For now, nuovopay always returns 100 devices per page
const rowsPerPage = 100;

export const DeviceTable: React.FC<DeviceTableProps> = ({
  page,
  total,
  onPageChanged,
  search,
  onSearchChanged,
  devices,
  onLocationRequest,
}) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChanged(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChanged(event.target.value);
    onPageChanged(0);
  };

  const client = useBackendApiClient();

  const { shopId } = useShopContext();

  const redirectToLoanPage = useCallback(
    async ({ imei, serial }: { imei?: string; serial: string }) => {
      if (!shopId) {
        toast.error("รหัสร้านค้าไม่ถูกต้อง");
        return;
      }
      const result = await client.installmentPlan.searchByImeiOrSerial({
        imei,
        serial,
      });
      const id = result.installmentPlanDto?.id;
      if (!id) {
        toast.error("ไม่พบข้อมูลเคส");
        return;
      }
      window.location.href = `/${shopId}/loan/${id}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shopId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableData = useMemo(() => {
    return devices.map((device) => (
      <TableRow key={device.id}>
        <TableCell>{device.id}</TableCell>
        <TableCell>{device.imei_no}</TableCell>
        <TableCell>{device.imei_no2}</TableCell>
        <TableCell>{device.serial_no}</TableCell>
        <TableCell>{device.phone_number || "N/A"}</TableCell>
        <TableCell>{device.model}</TableCell>
        <TableCell>{device.make}</TableCell>
        <TableCell>{device.os_version}</TableCell>
        <TableCell>{device.sdk_version}</TableCell>
        <TableCell>{device.status}</TableCell>
        <TableCell>{device.locked ? "Yes" : "No"}</TableCell>
        <TableCell>{device.next_lock_date || "N/A"}</TableCell>
        <TableCell>{device.unlock_code || "N/A"}</TableCell>
        <TableCell>{device.validity_of_unlock_code || "N/A"}</TableCell>
        <TableCell>
          {device.enrolled_on
            ? formatLongReadableThaiDate(parseNuovoDate(device.enrolled_on))
            : "N/A"}
        </TableCell>
        <TableCell>
          {device.last_connected_at
            ? formatLongReadableThaiDate(
                parseNuovoDate(device.last_connected_at)
              )
            : "N/A"}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            onClick={() => {
              onLocationRequest({
                imei: device.imei_no,
                serial: device.serial_no,
              });
            }}
          >
            Show Location
          </Button>
        </TableCell>
        <TableCell>
          <IconButton
            sx={{
              width: 60,
              height: 60,
            }}
            onClick={async () => {
              const loadingId = toast.loading("รอสักครู่...");
              await redirectToLoanPage({
                imei: device.imei_no,
                serial: device.serial_no,
              });
              toast.dismiss(loadingId);
            }}
          >
            <ChevronRight
              sx={{
                width: 40,
                height: 40,
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [devices, onLocationRequest, redirectToLoanPage]);

  return (
    <div>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { id: "id", label: "ID" },
                { id: "imei_no", label: "IMEI No" },
                { id: "imei_no2", label: "IMEI No2" },
                { id: "serial_no", label: "Serial No" },
                { id: "phone_number", label: "Phone Number" },
                { id: "model", label: "Model" },
                { id: "make", label: "Make" },
                { id: "os_version", label: "OS Version" },
                { id: "sdk_version", label: "SDK Version" },
                { id: "status", label: "Status" },
                { id: "locked", label: "Locked" },
                { id: "next_lock_date", label: "Next Lock Date" },
                { id: "unlock_code", label: "Unlock Code" },
                {
                  id: "validity_of_unlock_code",
                  label: "Validity of Unlock Code",
                },
                {
                  id: "enrolled_on",
                  label: "Enrolled On",
                },
                {
                  id: "last_connected_at",
                  label: "Last Connected",
                },
              ].map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableData}</TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </div>
  );
};
