import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  CardMedia,
  Alert,
  CircularProgress,
  TextField,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useBackendApiClient } from "../../../client/motalvip-apis";

export const ShopSlipVerifyScreen = () => {
  const { shopId } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [amount, setAmount] = useState<string>(""); // <- amount input
  const [result, setResult] = useState<"success" | "error" | null>(null);
  const [message, setMessage] = useState<string>("");

  const motalClient = useBackendApiClient();

  const verifyMutation = useMutation({
    mutationFn: async (formData: FormData) => {
      return motalClient.request.request({
        method: "POST",
        url: "/v2/shop-quotas/verify-slip",
        body: formData,
      });
    },
    onSuccess: (res: any) => {
      console.log("resp: ", res);

      const status = res?.status;
      const slipMessage = res?.message ?? "";

      if (status === "VERIFIED") {
        setResult("success");
        setMessage(slipMessage);
      } else {
        setResult("error");
        setMessage(slipMessage || "❌ ไม่สามารถยืนยันสลิปได้");
      }
    },
    onError: (err: any) => {
      console.error("verify-slip error: ", err);

      const fallbackMessage = "❌ เกิดข้อผิดพลาดในการตรวจสอบสลิป";
      const errorMessage =
        err?.response?.data?.body?.message ||
        err?.response?.data?.message ||
        err?.message ||
        fallbackMessage;

      setResult("error");
      setMessage(errorMessage);
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setResult(null);
      setMessage("");
    }
  };

  const handleVerifySlip = () => {
    if (file && shopId) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("shopId", shopId);
      if (amount.trim() !== "") {
        formData.append("amount", amount.trim());
      }
      verifyMutation.mutate(formData);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" mb={2}>
        เช็คสลิปปลอม
      </Typography>

      <Card sx={{ p: 3, maxWidth: 600 }}>
        <Stack spacing={2}>
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            onClick={() => fileInputRef.current?.click()}
          >
            เลือกไฟล์สลิป
          </Button>

          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            hidden
          />

          {previewUrl && (
            <CardMedia
              component="img"
              height="400"
              image={previewUrl}
              alt="preview"
              sx={{ borderRadius: 2, objectFit: "contain" }}
            />
          )}

          <TextField
            label="จำนวนเงิน (optional)"
            placeholder=""
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            inputProps={{ min: "0", step: "0.01" }}
          />

          <Button
            variant="contained"
            disabled={!file || verifyMutation.isPending}
            onClick={handleVerifySlip}
            startIcon={
              verifyMutation.isPending ? (
                <CircularProgress size={20} color="inherit" />
              ) : undefined
            }
          >
            ตรวจสอบสลิป
          </Button>

          {result === "success" && (
            <Alert severity="success" icon={<CheckCircleIcon color="success" />}>
              {message}
            </Alert>
          )}

          {result === "error" && (
            <Alert severity="error" icon={<CancelIcon color="error" />}>
              {message}
            </Alert>
          )}
        </Stack>
      </Card>
    </Box>
  );
};