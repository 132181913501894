import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { saveAs } from 'file-saver';
import { useState } from "react";
import { DocumentDto } from "../../client/motalvip-apis/generated";

export const DocumentPreviewCard = (props: {
    document: DocumentDto
    uploaded: boolean,
    onDelete?: (document: DocumentDto) => void,
    onDownload?: (document: DocumentDto) => void,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column' }} >
            {props.uploaded ?
                <>
                    <FilePreviewDialog
                        url={props.document?.downloadUrl || ''}
                        onClose={() => setOpen(false)} open={open}
                        fileName={(props.document.originalName || '')} />
                    <FileDeleteDialog
                        url={props.document?.downloadUrl || ''}
                        onClose={() => setOpenDelete(false)}
                        onDelete={() => props.onDelete && props.onDelete(props.document)}
                        open={openDelete} fileName={props.document.originalName || ''} />
                    <CardActionArea onClick={() => {
                        if (props.document.fileExtension?.endsWith('.pdf')) {
                            window.open(props.document?.downloadUrl, '_blank');
                        } else {
                            setOpen(true);
                        }
                    }}>
                        <CardContent>
                            <Avatar sx={{ width: 100, height: 100 }} variant="rounded" src={props.document?.downloadUrl} style={{ objectFit: 'cover' }}>
                                <Typography gutterBottom component="div" >
                                    {props.document.originalName}
                                </Typography>
                            </Avatar>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Stack direction='row' sx={{ justifyContent: 'space-between', width: '100%' }}>
                            <IconButton onClick={async () => {
                                try {
                                    const blobResponse = await fetch(props.document.downloadUrl!);
                                    if (blobResponse.ok) {
                                        const blob = await blobResponse.blob();
                                        saveAs(blob, props.document.originalName);
                                    }
                                } catch (error) {
                                    console.error('Error downloading file', error);
                                }
                            }}>
                                <FileDownloadIcon />
                            </IconButton>
                            <IconButton onClick={() => setOpenDelete(true)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Stack>
                    </CardActions>
                </>
                : <Box
                    sx={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 2,
                    }}
                >
                    <CircularProgress />
                </Box>
            }
        </Card>
    );
}

export const FilePreviewDialog = (props: { url: string, onClose: () => void, open: boolean, fileName: string }) => {
    return (
        <Dialog open={props.open} onClose={() => props.onClose()} >
            <DialogTitle>{props.fileName}</DialogTitle>
            <DialogContent>
                <img src={props.url} alt="File Preview" style={{ width: "100%", height: "auto" }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export const FileDeleteDialog = (props: {
    url: string,
    onClose: () => void,
    onDelete: () => void,
    open: boolean,

    fileName: string
}) => {
    return (
        <Dialog open={props.open} onClose={() => props.onClose()} >
            <DialogTitle>ยืนยันลบไฟล์</DialogTitle>
            <DialogContent>
                ต้องการลบไฟล์ {props.fileName} ใช่หรือไม่?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>ไม่ใช่</Button>
                <Button onClick={() => {
                    props.onDelete()
                    props.onClose()
                }}>ใช่</Button>
            </DialogActions>
        </Dialog>
    );
}