/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentDeleteNotUseDto } from '../models/DocumentDeleteNotUseDto';
import type { DocumentDto } from '../models/DocumentDto';
import type { NoteDto } from '../models/NoteDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DocumentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns DocumentDto OK
     * @throws ApiError
     */
    public getDocuments({
        referenceType,
        referenceId,
    }: {
        referenceType: 'DOWN_SAVING' | 'FINANCIER_SHOP_CONTRACT' | 'INSTALLMENT_CONTRACT' | 'POS' | 'SHOP_COMMISSION' | 'SHOP_AGENT_COMMISSION',
        referenceId: string,
    }): CancelablePromise<Array<DocumentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/documents',
            query: {
                'referenceType': referenceType,
                'referenceId': referenceId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DocumentDto OK
     * @throws ApiError
     */
    public createDocument({
        requestBody,
    }: {
        requestBody: DocumentDto,
    }): CancelablePromise<DocumentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/documents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns NoteDto OK
     * @throws ApiError
     */
    public getNotes({
        referenceType,
        referenceId,
    }: {
        referenceType: 'DOWN_SAVING' | 'FINANCIER_SHOP_CONTRACT' | 'INSTALLMENT_CONTRACT' | 'POS' | 'SHOP_COMMISSION' | 'SHOP_AGENT_COMMISSION',
        referenceId: string,
    }): CancelablePromise<Array<NoteDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/documents/notes',
            query: {
                'referenceType': referenceType,
                'referenceId': referenceId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns NoteDto OK
     * @throws ApiError
     */
    public createNote({
        requestBody,
    }: {
        requestBody: NoteDto,
    }): CancelablePromise<NoteDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/documents/notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteDocumentNotUse({
        requestBody,
    }: {
        requestBody: DocumentDeleteNotUseDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/documents/delete/not-use',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DocumentDto OK
     * @throws ApiError
     */
    public confirmUpload({
        requestBody,
    }: {
        requestBody: DocumentDto,
    }): CancelablePromise<DocumentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/documents/confirm-upload',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteDocument({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/documents/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
