/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DownSavingCancelRequest } from '../models/DownSavingCancelRequest';
import type { DownSavingCreateRequest } from '../models/DownSavingCreateRequest';
import type { DownSavingResponse } from '../models/DownSavingResponse';
import type { DownSavingTransactionCreateRequest } from '../models/DownSavingTransactionCreateRequest';
import type { DownSavingTransactionResponse } from '../models/DownSavingTransactionResponse';
import type { PaginatedDownSavingResponse } from '../models/PaginatedDownSavingResponse';
import type { SearchDownSavingSummaryResponse } from '../models/SearchDownSavingSummaryResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DownSavingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedDownSavingResponse OK
     * @throws ApiError
     */
    public searchDownSavings({
        shopId,
        searchText,
        startDateFrom,
        startDateTo,
        scheduledDateFrom,
        scheduledDateTo,
        status,
        page,
        limit,
    }: {
        shopId?: string,
        searchText?: string,
        startDateFrom?: string,
        startDateTo?: string,
        scheduledDateFrom?: string,
        scheduledDateTo?: string,
        status?: Array<'ACTIVE' | 'CLOSED' | 'CANCELLED' | 'WITHDRAWN'>,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedDownSavingResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/down-savings',
            query: {
                'shopId': shopId,
                'searchText': searchText,
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'scheduledDateFrom': scheduledDateFrom,
                'scheduledDateTo': scheduledDateTo,
                'status': status,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingResponse OK
     * @throws ApiError
     */
    public createDownSaving({
        requestBody,
    }: {
        requestBody: DownSavingCreateRequest,
    }): CancelablePromise<DownSavingResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/down-savings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingResponse OK
     * @throws ApiError
     */
    public cancelDownSaving({
        requestBody,
    }: {
        requestBody: DownSavingCancelRequest,
    }): CancelablePromise<DownSavingResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/down-savings/{downSavingId}/cancel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingTransactionResponse OK
     * @throws ApiError
     */
    public getTransactions({
        downSavingId,
    }: {
        downSavingId: string,
    }): CancelablePromise<Array<DownSavingTransactionResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/down-savings/transactions',
            query: {
                'downSavingId': downSavingId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingTransactionResponse OK
     * @throws ApiError
     */
    public createDownSavingTransaction({
        requestBody,
    }: {
        requestBody: DownSavingTransactionCreateRequest,
    }): CancelablePromise<DownSavingTransactionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/down-savings/transactions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingResponse OK
     * @throws ApiError
     */
    public getDownSaving({
        downSavingId,
    }: {
        downSavingId: string,
    }): CancelablePromise<DownSavingResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/down-savings/{downSavingId}',
            path: {
                'downSavingId': downSavingId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns SearchDownSavingSummaryResponse OK
     * @throws ApiError
     */
    public searchDownSavingSummary({
        shopId,
        searchText,
        startDateFrom,
        startDateTo,
        scheduledDateFrom,
        scheduledDateTo,
        status,
        page,
        limit,
    }: {
        shopId?: string,
        searchText?: string,
        startDateFrom?: string,
        startDateTo?: string,
        scheduledDateFrom?: string,
        scheduledDateTo?: string,
        status?: Array<'ACTIVE' | 'CLOSED' | 'CANCELLED' | 'WITHDRAWN'>,
        page?: number,
        limit?: number,
    }): CancelablePromise<SearchDownSavingSummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/down-savings/summary',
            query: {
                'shopId': shopId,
                'searchText': searchText,
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'scheduledDateFrom': scheduledDateFrom,
                'scheduledDateTo': scheduledDateTo,
                'status': status,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
