/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetPawningContractResponse = {
    pawningContractId?: string;
    pawnedAt?: string;
    customerFirstName?: string;
    customerLastName?: string;
    customerPhoneNo?: string;
    machineTitle?: string;
    appointmentDate?: string;
    appointmentAmount?: number;
    pawningStatus?: GetPawningContractResponse.pawningStatus;
};
export namespace GetPawningContractResponse {
    export enum pawningStatus {
        ONGOING = 'ONGOING',
        REDEEMED = 'REDEEMED',
        FORECLOSURE = 'FORECLOSURE',
    }
}

