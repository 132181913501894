import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";
import { DocumentDto } from "../../motalvip-apis/generated";
import { useDialog } from "../../../context/DialogContext";

export const useCreateDocumentMutation = () => {
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const backendClient = useBackendApiClient();
    return useMutation({
        mutationKey: ['createDocument'],
        mutationFn: (data: DocumentDto) => {
            return backendClient.document.createDocument({ requestBody: data })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['documentsQuery'] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
        retry: 0,
    })
}