import dayjs from "dayjs";

export function dayDiff(
  dateStart: Date,
  dateEnd: Date | string = new Date()
): number {
  if (typeof dateEnd === "string") dateEnd = new Date();

  let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime());
  return Math.ceil(delta / (1000 * 3600 * 24));
}

export function formatLongReadableThaiDate(date: Date) {
  const formatted = date.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return formatted;
}

export function formatShortReadableThaiDate(
  date: Date | string,
  withTime = false
) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  let dateString = "DD MMM YYYY";
  if (withTime) dateString += " HH:mm";
  const formatted = dayjs(date).locale("th").format(dateString);
  return formatted;
}

/**
 * Nuovo date has a special format. This method parses date in the format of "DD-MM-YY HH:mm:ss UTC" to a Date object.
 */
export function parseNuovoDate(date: string) {
  const [datePart, timePart] = date.split(" ");
  const [day, month, year] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");
  const utcDate = new Date(
    Date.UTC(
      2000 + parseInt(year), // assuming year 20xx
      parseInt(month) - 1, // month is 0-indexed
      parseInt(day),
      parseInt(hours),
      parseInt(minutes),
      parseInt(seconds)
    )
  );

  return utcDate;
}
