/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AcceptPaymentHistoryResponse = {
    transactionId?: string;
    interestAmount?: number;
    capitalAmount?: number;
    fineAmount?: number;
    referenceDate?: string;
    paymentChannelType?: AcceptPaymentHistoryResponse.paymentChannelType;
};
export namespace AcceptPaymentHistoryResponse {
    export enum paymentChannelType {
        CASH = 'CASH',
        PROMPT_PAY = 'PROMPT_PAY',
        PROMPT_PAY_QR = 'PROMPT_PAY_QR',
        BANK_ACCOUNT = 'BANK_ACCOUNT',
        AUTOMATIC_QR = 'AUTOMATIC_QR',
        TRUE_WALLET = 'TRUE_WALLET',
    }
}

