import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { ProductDeleteDialog } from "../../screen/shop/product/types"
import { useProductDeleteMutation } from "../../client/query-client/useProductQuery"

interface Props {
    dialogData: ProductDeleteDialog
    onClose: () => void
}

export const DeleteProductDialog = ({ dialogData, onClose }: Props) => {
    const { shopId } = useParams()
    const { mutateAsync: deleteProduct, isPending } = useProductDeleteMutation()

    const onConfirm = async () => {
        if (dialogData.product?.productId) {
            try {
                await deleteProduct({ productId: dialogData.product.productId, shopId: shopId! })
                onClose()
            } catch (e) { }
        }
    }

    const onCancel = () => {
        onClose()
    }

    return (
        <Dialog open={dialogData.isOpen && !!dialogData.product?.productId} onClose={onClose}>
            <DialogTitle>การลบสินค้า</DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="div">
                    ยืนยันที่จะลบสินค้า "{dialogData.product?.productName}" ออกจากระบบหรือไม่
                </Typography>
                <Stack direction='row' gap={4} marginTop={4}>
                    <Button size='large' variant='outlined' type="button" onClick={onCancel} fullWidth>
                        ยกเลิก
                    </Button>
                    <Button size='large' variant='contained' color='error' type="button" onClick={onConfirm} disabled={isPending} fullWidth>
                        ยืนยัน
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
