import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";

interface IProps {
  isOpen: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  disable?: boolean;
}

const DialogConfirm: React.FC<IProps> = (props): JSX.Element => {
  const {
    isOpen,
    title,
    message,
    onClose,
    onConfirm,
    confirmText = "ยืนยัน",
    cancelText = "ยกเลิก",
    disable = false,
  } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          autoFocus
          disabled={disable}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm;
