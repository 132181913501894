/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from './User';
export type ShopResponse = {
    id: string;
    name: string;
    status: ShopResponse.status;
    createdAt: string;
    approvedAt?: string;
    approvedBy?: User;
};
export namespace ShopResponse {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
}

