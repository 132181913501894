/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetInventoryResponse } from '../models/GetInventoryResponse';
import type { GetShopProductMobilesResponse } from '../models/GetShopProductMobilesResponse';
import type { InventoryRequest } from '../models/InventoryRequest';
import type { PaginatedGetInventoryResponse } from '../models/PaginatedGetInventoryResponse';
import type { PaginatedGetShopProductMobilesResponse } from '../models/PaginatedGetShopProductMobilesResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InventoryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns GetInventoryResponse OK
     * @throws ApiError
     */
    public getInventoryDetail({
        shopId,
        inventoryId,
    }: {
        shopId: string,
        inventoryId: string,
    }): CancelablePromise<GetInventoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/inventories/{inventoryId}',
            path: {
                'inventoryId': inventoryId,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public editInventory({
        inventoryId,
        requestBody,
    }: {
        inventoryId: string,
        requestBody: InventoryRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/inventories/{inventoryId}',
            path: {
                'inventoryId': inventoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedGetInventoryResponse OK
     * @throws ApiError
     */
    public getAllInventory({
        shopId,
        categoryId,
        search,
        sortBy = 'created_at',
        sort = 'desc',
        page = 1,
        limit = 20,
        type,
        isSecondhand,
        isSoldOut,
    }: {
        shopId: string,
        categoryId?: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
        type?: 'GENERAL' | 'MOBILE',
        isSecondhand?: boolean,
        isSoldOut?: boolean,
    }): CancelablePromise<PaginatedGetInventoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/inventories',
            query: {
                'shopId': shopId,
                'categoryId': categoryId,
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
                'type': type,
                'isSecondhand': isSecondhand,
                'isSoldOut': isSoldOut,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedGetShopProductMobilesResponse OK
     * @throws ApiError
     */
    public getInventoryMobiles({
        inventoryId,
        search,
        sortBy = 'created_at',
        sort = 'desc',
        page = 1,
        limit = 20,
        isSoldOut,
        isActive,
    }: {
        inventoryId: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
        isSoldOut?: boolean,
        isActive?: boolean,
    }): CancelablePromise<PaginatedGetShopProductMobilesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/inventories/{inventoryId}/mobiles',
            path: {
                'inventoryId': inventoryId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
                'isSoldOut': isSoldOut,
                'isActive': isActive,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GetShopProductMobilesResponse OK
     * @throws ApiError
     */
    public getInventoryMobilesByInventoryId({
        inventoryId,
    }: {
        inventoryId: string,
    }): CancelablePromise<Array<GetShopProductMobilesResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/inventories/{inventoryId}/mobiles-available',
            path: {
                'inventoryId': inventoryId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
