/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentTerm = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    termNumber?: number;
    dueAmount?: number;
    fineAmount?: number;
    paidDueAmount?: number;
    paidFineAmount?: number;
    dueDate?: string;
    status?: InstallmentTerm.status;
    installmentPlanId?: string;
    shopId?: string;
    financierId?: string;
    financierEmployeeCommissionAmount?: number;
    financierEmployeeId?: string;
};
export namespace InstallmentTerm {
    export enum status {
        PENDING = 'PENDING',
        ACTIVE = 'ACTIVE',
        CLOSED = 'CLOSED',
        INACTIVE = 'INACTIVE',
        PAID = 'PAID',
    }
}

