import dayjs from "dayjs";
import "dayjs/locale/th";

dayjs.locale("th");

export const formatThaiDate = (input: string | Date): string => {
  const date = dayjs(input);
  if (!date.isValid()) return "Invalid date";
  return `${date.format("DD/MM")}/${date.year() + 543}`;
};
