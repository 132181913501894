import { DocumentDto } from "../../../client/motalvip-apis/generated";
import { useConfirmDocumentMutation } from "../../../client/query-client/document/useDocumentConfirmMutation";
import { useCreateDocumentMutation } from "../../../client/query-client/document/useDocumentCreateMutation";
import { useUploadFileMutation } from "../../../client/query-client/useUploadFileMutation";
import { v4 as uuidv4 } from 'uuid'

export interface UploadFileResponse {
    document: DocumentDto
    refId: string
}

export function useUploadFile(referenceType: DocumentDto.referenceType) {
    const uploadFileMutation = useUploadFileMutation()
    const createDocumentMutation = useCreateDocumentMutation()
    const confirmDocumentMutation = useConfirmDocumentMutation()

    const upload = (file: File) => new Promise<UploadFileResponse>(async (resolve, reject) => {
        const refId = uuidv4().substring(0, 21)
        const createRes = await createDocumentMutation.mutateAsync(
            {
                originalName: file.name,
                referenceType: referenceType,
                referenceId: refId
            },
            {
                onError: reject,
            }
        );
        await uploadFileMutation.mutateAsync(
            { file: file, uploadUrl: createRes.uploadUrl || "" },
            { onError: reject }
        );
        const confirmRes = await confirmDocumentMutation.mutateAsync(
            createRes,
            { onError: reject }
        )
        resolve({ document: confirmRes, refId });
    })

    return {
        upload
    }
}

