import { Typography } from '@mui/material'

interface RenderPlaceholderProps<T> {
    selected: T | undefined | null
    placeholder: string
    color: string
}

export const renderSelectPlaceholder = <T,>({
    selected,
    placeholder,
    color,
}: RenderPlaceholderProps<T>) => {
    if (!selected) {
        return <Typography sx={{ color }}>{placeholder}</Typography>
    }

    return selected
}
