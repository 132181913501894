import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";

export const useDownSavingTransactionsQuery = (downSavingId: string) => {
    const backendApiClient = useBackendApiClient()
    return useQuery({
        queryKey: ['transactions', downSavingId],
        queryFn: () => backendApiClient.downSaving.getTransactions({ downSavingId }),
        enabled: !!downSavingId,
    });
}