/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetInventoryResponse = {
    shopProductId?: string;
    productName?: string;
    sku?: string;
    type?: GetInventoryResponse.type;
    isSecondhand?: boolean;
    inventoryId?: string;
    stock?: number;
    createdAt?: string;
};
export namespace GetInventoryResponse {
    export enum type {
        GENERAL = 'GENERAL',
        MOBILE = 'MOBILE',
    }
}

