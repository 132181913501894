import { ReactNode } from "react";

export function mapStatusToText(status?: string): ReactNode {
  switch (status) {
    case "SUCCESS":
      return "สำเร็จ";
    case "CANCEL":
      return "ยกเลิก";
    case "PENDING":
      return "รอดำเนินการ";
    case "COMPLETED":
      return "เสร็จสิ้น";
    case "OVERDUE":
      return "เลยกำหนด";
    default:
      return "ไม่ทราบสถานะ";
  }
}
