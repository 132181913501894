import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  InstallmentPlanDto,
  InstallmentTerm,
} from "../../client/motalvip-apis/generated";
import { formatThaiDate } from "../../util/formatThaiDate";

interface IProps {
  contract: InstallmentPlanDto | undefined;
}

const TabInstallmentPlanList: React.FC<IProps> = (props) => {
  const installmentTerms = props.contract?.installmentTerms;

  const renderStatusChip = (status?: InstallmentTerm.status) => {
    switch (status) {
      case InstallmentTerm.status.PAID:
        return <Chip label="ชำระแล้ว" color="success" size="small" />;
      case InstallmentTerm.status.ACTIVE:
        return <Chip label="ครบกำหนด" color="warning" size="small" />;
      case InstallmentTerm.status.INACTIVE:
        return <Chip label="เกินกำหนด" color="error" size="small" />;
      case InstallmentTerm.status.CLOSED:
        return <Chip label="ปิดงวด" color="info" size="small" />;
      case InstallmentTerm.status.PENDING:
      default:
        return (
          <Chip
            label="ยังไม่ครบกำหนด"
            sx={{ backgroundColor: "#4B5563", color: "white" }}
            size="small"
          />
        );
    }
  };

  // Format currency
  const formatCurrency = (amount?: number) => {
    if (amount === undefined) return "-";
    return new Intl.NumberFormat("th-TH").format(amount);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ backgroundColor: "#F9FAFB" }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>งวดที่</TableCell>
            <TableCell>ยอดชำระงวด</TableCell>
            <TableCell>วันครบกำหนด</TableCell>
            <TableCell>สถานะ</TableCell>
            <TableCell>ยอดชำระเงิน</TableCell>
            <TableCell>action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {installmentTerms &&
            installmentTerms
              .sort((a, b) => (a?.termNumber || 0) - (b?.termNumber || 0))
              .map((term, index) => (
                <TableRow
                  key={term.id || index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>{term.termNumber || index + 1}</TableCell>
                  <TableCell>{formatCurrency(term.dueAmount)}</TableCell>
                  <TableCell>
                    {formatThaiDate(term.dueDate || new Date())}
                  </TableCell>
                  <TableCell>{renderStatusChip(term.status)}</TableCell>
                  <TableCell>{formatCurrency(term.paidDueAmount)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TabInstallmentPlanList;
