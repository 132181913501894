import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import { MenuItem } from "./types";

export const menuData: MenuItem[] = [
  {
    key: "/shop-finance/:shopId/dashboard",
    label: "แดชบอร์ด",
    icon: <InsertChartOutlinedOutlinedIcon />,
    isRequiredOwner: true,
  },
  {
    key: "/shop-finance/:shopId/contract",
    label: "สัญญา",
    icon: <ReceiptLongOutlinedIcon />,
    isRequiredOwner: false,
    children: [
      {
        key: "/shop-finance/:shopId/contract",
        label: "รายการสัญญา",
        isRequiredOwner: false,
      },
      {
        key: "/shop-finance/:shopId/contract/create",
        label: "เพิ่มสัญญา",
        isRequiredOwner: true,
      },
    ],
  },
];

export const shopMenuData: MenuItem[] = [
  {
    key: "/shop-finance/:shopId/settings/shop",
    label: "ร้านค้า",
    icon: <StoreOutlinedIcon />,
    isRequiredOwner: true,
    children: [
      {
        key: "/shop-finance/:shopId/settings/shop",
        label: "ข้อมูลหลัก",
        isRequiredOwner: true,
      },
      {
        key: "/shop-finance/:shopId/settings/payment-channel",
        label: "ช่องทางการจ่ายเงิน",
        isRequiredOwner: true,
      },
    ],
  },
  {
    key: "/shop-finance/:shopId/settings/user",
    label: "ผู้ใช้งาน",
    icon: <PeopleOutlinedIcon />,
    isRequiredOwner: true,
  },
];
