import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { Loan, LoanDocumentResponse, PaymentResponse } from "../client/motal-apis/generated";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";
import { InstallmentPlanDto } from "../../../client/motalvip-apis/generated";
import {
  useApproveLoanMutation,
  useLoanHistoryQuery,
  useRejectLoanMutation,
} from "../../../client/query-client";
import { useInstallmentPlanQuery } from "../../../client/query-client/useInstallmentPlanQuery";
import { CustomerHistory } from "../../../component/approve/CustomerHistory";
import { DataCell } from "../../../component/DataCell";
import { PaymentTable } from "../../../component/Installment/InstallmentPaymentTable";

const CustomInputLabel = styled(InputLabel)({
  position: "relative",
  transform: "none",
  marginBottom: "5px",
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ maxWidth: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

type LoanApprovalScreenProps = {
  viewMode?: boolean;
};
export const LoanApprovalScreen = (props: LoanApprovalScreenProps) => {
  const theme = useTheme();
  const nav = useNavigate();
  const { shopId, id } = useParams();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const loanId = props.viewMode ? id : searchParams.get("loanId");
  const tab = searchParams.get("tab");

  const loanQuery = useInstallmentPlanQuery(loanId || "");
  const loanHisotoryQuery = useLoanHistoryQuery(
    loanQuery.data?.profile?.citizenId ||
      loanQuery.data?.profile?.passportNumber ||
      "",
    loanQuery.isFetched
  );
  const approveMutation = useApproveLoanMutation();
  const rejectMutation = useRejectLoanMutation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isConfirmApprove, setConfirmApprove] = useState(true);
  const [selectedTab, setSelectedTab] = useState<number>(Number(tab) || 0);
  const [rejectReason, setRejectReason] = useState<string>("");
  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onSubmit = () => {
    if (isConfirmApprove) {
      approveMutation.mutate({ shopId: shopId, loanId: loanId || "" });
    } else {
      // @ts-ignore
      rejectMutation.mutate({
        shopId: shopId || "",
        loanId: loanId || "",
        purchaseCost: loanQuery.data?.product?.purchaseCost || 0,
        downPaymentAmount: loanQuery.data?.downPaymentAmount || 0,
        // rejectReason: rejectReason,
      });
    }
    setOpenConfirmModal(false);
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const gridNumber = useMediaQuery(theme.breakpoints.down("md")) ? 9 : 3;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
        <Box p={2} mb={16} maxWidth="100vw">
          <Box sx={{ justifyContent: "space-between" }}>
            <Stack direction="column">
              <Typography variant="h5" pb={2}>
                {props.viewMode
                  ? "รายการติดตามสินเชื่อ"
                  : `อนุมัติสินเชื่อ ลำดับ ${loanQuery.data?.sequenceNumber}`}
              </Typography>
            </Stack>
          </Box>
          {!props.viewMode &&
            (loanQuery.data?.registrationStatus ===
            InstallmentPlanDto.registrationStatus.APPROVED ? (
              <Box
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  border: "2px solid #43a047",
                  backgroundColor: "#e8f5e9",
                  color: "#43a047",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">{`อนุมัติแล้ว`}</Typography>
              </Box>
            ) : loanQuery.data?.registrationStatus ===
              InstallmentPlanDto.registrationStatus.REJECTED ? (
              <Box
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  border: "2px solid #e53935",
                  backgroundColor: "#ffcdd2",
                  color: "#e53935",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">{`ปฏิเสธแล้ว`}</Typography>
              </Box>
            ) : (
              <></>
            ))}
          <Paper elevation={2} sx={{ backgroundColor: "white" }}>
            <Tabs
              variant={
                useMediaQuery(theme.breakpoints.down("md"))
                  ? "scrollable"
                  : "fullWidth"
              }
              value={selectedTab}
              onChange={onTabChange}
              scrollButtons
            >
              <Tab label="ข้อมูลสินเชื่อ" />
              <Tab label="ตารางการผ่อนชำระ" />
              {!props.viewMode && <Tab label="ประวัติ" />}
              {props.viewMode && <Tab label="ประวัติการชำระ" />}
            </Tabs>
          </Paper>

          <TabPanel value={selectedTab} index={0}>
            {props.viewMode && (
              <Box sx={{ mt: 2 }}>
                <Stack
                  direction="row"
                  gap={1}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <Button variant="outlined" size="large" fullWidth>
                    ทำนัดชำระ
                  </Button>
                  <Button
                    onClick={() =>
                      nav(`/${shopId}/loan/${loanId}/create-payment`)
                    }
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    ชำระ
                  </Button>
                </Stack>
              </Box>
            )}
            {props.viewMode && (
              <Box sx={{ mt: 2 }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    display: "flex",
                    gap: 2,
                    p: 2,
                    bgcolor: "white",
                  }}
                >
                  {/* <InstallmentSummaryCard installmentAmount={loanQuery.data?.installmentAmount || 0} installmentPaidAmount={loanQuery.data?.installmentAmount || 0} installmentScheduledDate={dayjs(loanQuery.data?.activeTermDueDate).format('DD/MM/YYYY') || ''} installmentFineAmount={'0'} /> */}
                  {/* <LoanSummaryCard loanTotalAmount={loanQuery.data?.totalAmount || 0} loanPaidAmount={loanQuery.data?.payments?.filter(e => ![InstallmentPaymentDto.type.DOWN_PAYMENT, InstallmentPaymentDto.type.FINE].includes(e.type!)).reduce((a, b) => a + (b.totalAmount || 0), 0) || 0} loanFineTotalAmount={loanQuery.data?.payments?.filter(e => ![InstallmentPaymentDto.type.FINE].includes(e.type!)).reduce((a, b) => a + (b.totalAmount || 0), 0) || 0} loanActiveTermNumber={loanQuery.data?.activeTermNumber || 0} loanTermCount={loanQuery.data?.installmentCount || 0} /> */}
                </Stack>
              </Box>
            )}
            {props.viewMode && (
              <Card
                sx={{ my: 2, width: { xs: "100%", lg: "calc(98% + 16px)" } }}
              >
                <CardContent sx={{ padding: 2, paddingBottom: 1 }}>
                  <Stack direction={{ lg: "row" }} gap={{ xs: 2, lg: 8 }}>
                    <Box>
                      <FormControl sx={{ width: 100 }}>
                        <CustomInputLabel>งวดปัจจุบัน</CustomInputLabel>
                        <Stack direction="row" alignItems="center">
                          <Typography sx={{ lineHeight: "32px" }}>
                            {loanQuery.data?.activeTermNumber
                              ? `${loanQuery.data.activeTermNumber} / ${loanQuery.data.installmentCount}`
                              : "-"}
                          </Typography>
                        </Stack>
                      </FormControl>
                    </Box>
                    <Box>
                      <CustomInputLabel>กำหนดชำระ</CustomInputLabel>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          sx={{ lineHeight: "32px" }}
                          color={
                            (loanQuery.data?.activeDueDateDiff || 0) >= 0
                              ? "#43a047"
                              : "#f44336"
                          }
                        >
                          {loanQuery.data?.activeTermDueDate
                            ? `${dayjs(loanQuery.data.activeTermDueDate).format(
                                "DD/MM/YYYY"
                              )} (${
                                loanQuery.data?.activeDueDateDiff === 0
                                  ? "ถึงกำหนด"
                                  : loanQuery.data?.activeDueDateDiff! > 0
                                  ? `อีก ${loanQuery.data?.activeDueDateDiff} วัน`
                                  : `เกิน ${-loanQuery.data
                                      ?.activeDueDateDiff!} วัน`
                              })`
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box>
                      <CustomInputLabel>ลิ้งสำหรับลูกค้า</CustomInputLabel>
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="flex-end"
                        justifyContent="flex-start"
                      >
                        <Typography sx={{ lineHeight: "32px" }}>
                          {id
                            ? `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?loanId=${id}`
                            : "-"}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?loanId=${id}`
                              )
                              .then(() => {
                                toast.success("คัดลอกสำเร็จ");
                              });
                          }}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            )}
            <Paper elevation={2} sx={{ p: 2, backgroundColor: "white" }}>
              <Grid container>
                <Grid item xs={3}>
                  <DataCell label="วันที่สมัคร" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={`${dayjs(loanQuery.data?.registrationDate)
                      .locale("th")
                      .format("DD MMM YYYY")}`}
                  ></DataCell>
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="รหัสอ้างอิง" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell label={loanQuery.data?.referenceId ?? "N/A"} />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="ยอดรวม" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={9}>
                  <DataCell
                    label={`${currencyFormat.format(
                      loanQuery.data?.totalAmount || 0
                    )}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="ยอดเงินดาวน์" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={`${currencyFormat.format(
                      loanQuery.data?.downPaymentAmount || 0
                    )}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell
                    label="วันที่จ่ายเงินดาวน์"
                    backgroundColor="#eeeeee"
                  />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={`${dayjs(loanQuery.data?.downPaymentDate || 0)
                      .locale("th")
                      .format("DD MMM YYYY")}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="ยอดผ่อนต่อเดือน" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={`${currencyFormat.format(
                      loanQuery.data?.installmentAmount || 0
                    )}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="จำนวนงวด" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={`${loanQuery.data?.installmentCount || 0} งวด (ทุก ${
                      loanQuery.data?.installmentFrequency
                    } ${
                      loanQuery.data?.installmentFrequencyType ===
                      InstallmentPlanDto.installmentFrequencyType.MONTH
                        ? "เดือน"
                        : "วัน"
                    })`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="ชื่อลูกค้า" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={9}>
                  <DataCell
                    label={`${loanQuery.data?.profile?.firstName} ${loanQuery.data?.profile?.lastName}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="เลขบัตรประชาชน" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={loanQuery.data?.profile?.citizenId ?? "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="เลขพาสปอร์ต" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={loanQuery.data?.profile?.passportNumber ?? "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="ที่อยู่ปัจจุบัน" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={9}>
                  <DataCell
                    label={loanQuery.data?.profile?.currentAddress ?? "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell
                    label="ที่อยู่ปัจจุบันตามบัตร"
                    backgroundColor="#eeeeee"
                  />
                </Grid>
                <Grid item xs={9}>
                  <DataCell
                    label={loanQuery.data?.profile?.citizenAddress ?? "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="อีเมล" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell label={loanQuery.data?.profile?.email ?? "N/A"} />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="เบอร์โทรศัพท์" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={
                      loanQuery.data?.profile?.phoneNumbers
                        ?.map((e) => e.phoneNumber)
                        .join(", ") ?? "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="หน้า Facebook" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={loanQuery.data?.profile?.facebookUrl ?? "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="ไอดี LINE" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell label={loanQuery.data?.profile?.lineId ?? "N/A"} />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="อาชีพ" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={loanQuery.data?.profile?.occupation ?? "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DataCell label="สถานที่ทำงาน" backgroundColor="#eeeeee" />
                </Grid>
                <Grid item xs={gridNumber}>
                  <DataCell
                    label={loanQuery.data?.profile?.workPlace ?? "N/A"}
                  />
                </Grid>
              </Grid>
              <Typography mt={4} variant="h5">
                เอกสารการสมัคร
              </Typography>
              <Box sx={{ my: 2 }}>
                <Stack
                  sx={{ display: "flex", flexWrap: "wrap" }}
                  direction={"row"}
                  gap={2}
                >
                  {/* {loanQuery.data?.loanDocuments?.map(loanDocument => {
                                        return <ImageCardPreview title={
                                            loanDocument.type === InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD ? 'บัตรประชาชน'
                                                : loanDocument.type === InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD ? 'ภาพถ่ายคู่กับบัตร'
                                                    : loanDocument.type === InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN ? 'หน้า Facebook'
                                                        : 'อื่นๆ'
                                        } url={loanDocument.downloadUrls || ''} />
                                    })} */}
                </Stack>
              </Box>
            </Paper>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Paper
              elevation={2}
              sx={{ p: 2, backgroundColor: "white", maxWidth: "100%" }}
            >
              {/* <InstallmentPaymentTable payment={loanQuery.data?.payments} data={loanQuery.data?.installmentPayments?.sort((a, b) => { return a.termNumber ?? 0 })} /> */}
            </Paper>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {props.viewMode ? (
              <Paper
                elevation={2}
                sx={{ p: 2, backgroundColor: "white", maxWidth: "100%" }}
              >
                <PaymentTable data={loanQuery.data?.payments} />
              </Paper>
            ) : (
              <Paper
                elevation={2}
                sx={{ p: 2, backgroundColor: "white", maxWidth: "100%" }}
              >
                <CustomerHistory
                  data={loanHisotoryQuery.data?.content?.filter(
                    (c) => c.id !== loanId
                  )}
                />
              </Paper>
            )}
          </TabPanel>
        </Box>

        <Box
          sx={{
            display:
              loanQuery.data &&
              loanQuery.data?.registrationStatus ===
                InstallmentPlanDto.registrationStatus.PENDING_APPROVAL
                ? ""
                : "none",
            p: 2,
            bgcolor: "white",
            width: { xs: "100vw", sm: "calc(100% - 240px)" },
            position: "fixed",
            bottom: 0,
            zIndex: 99,
          }}
        >
          <Stack direction="row" gap={1} justifyContent="center">
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setConfirmApprove(false);
                setOpenConfirmModal(true);
              }}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: 200,
                },
              }}
            >
              ปฏิเสธ
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setConfirmApprove(true);
                setOpenConfirmModal(true);
              }}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: 200,
                },
              }}
            >
              อนุมัติ
            </Button>
          </Stack>
        </Box>
        <Dialog
          onClose={() => setOpenConfirmModal(false)}
          open={openConfirmModal}
          PaperProps={{
            sx: { minWidth: { md: theme.breakpoints.values.sm, xs: "90%" } },
          }}
        >
          <DialogContent>
            <Stack direction="column" gap={2}>
              <Typography>
                {`ยืนยัน${isConfirmApprove ? "อนุมัติ" : "ปฏิเสธ"}`}
              </Typography>
              {isConfirmApprove ? (
                <></>
              ) : (
                <FormControl fullWidth>
                  <CustomInputLabel>เหตุผลการปฏิเสธ</CustomInputLabel>
                  <TextField
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    placeholder="เหตุผล"
                    size="small"
                  />
                </FormControl>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={1}
                sx={{ w: "100%" }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setOpenConfirmModal(false)}
                >
                  ยกเลิก
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onSubmit}
                  color="primary"
                >
                  ยืนยัน
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};
