import { ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useShopContext } from "../../context/ShopContextProvider"
import { useState } from "react"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

export interface ShopMenuItemProps {
    menuId: string
    link: string
    icon: React.ReactNode
    label: string
    children?: {
        menuId: string
        link: string
        label: string
        isRequiredOwner: boolean
    }[]
    disabled?: boolean
}

export const ShopMenuItem = (props: ShopMenuItemProps) => {
    const shopCtx = useShopContext()
    const [open, setOpen] = useState(true)
    const nav = useNavigate();

    return (
        <Stack direction='column' width='100%'>
            <Stack direction='column' width='100%'
                sx={{
                    px: '4px',

                }}
            >
                <ListItemButton
                    sx={{
                        mx: 1,
                        px: 4,
                        my: '0px',
                        py: '0px',
                        borderRadius: 16,
                        border: props.menuId === shopCtx.selectedMenu
                            ? '0px solid #e0e0e0'
                            : '0px solid #e0e0e0',
                        backgroundColor: props.menuId === shopCtx.selectedMenu
                            ? '#263238'
                            : 'white',
                        color: props.menuId === shopCtx.selectedMenu
                            ? 'white'
                            : 'black',
                        '& .MuiListItemIcon-root': {
                            color: props.menuId === shopCtx.selectedMenu ? 'white' : 'black',
                        },
                        '&:hover': {
                            '& .MuiListItemIcon-root': {
                                color: props.menuId === shopCtx.selectedMenu ? 'white' : 'black',
                            },
                            color: props.menuId === shopCtx.selectedMenu ? 'white' : 'black',
                            backgroundColor: props.menuId === shopCtx.selectedMenu ? '#263238' : '#eceff1',
                        },
                    }}
                    disabled={props.disabled}
                    onClick={() => {
                        if (!props.children) {
                            shopCtx.setSelectedMenu(props.menuId)
                            nav(props.link)
                        } else {
                            setOpen(!open)
                        }
                    }}
                >
                    <ListItemIcon
                        sx={{
                            px: 0,
                            minWidth: 36
                        }}
                    >
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText
                        sx={{ p: 0 }}
                        primary={props.label}
                    />
                    {props.children ? open ? <ExpandMoreOutlinedIcon /> : <ExpandLessIcon /> : <></>}
                </ListItemButton >
            </Stack>
        </Stack>
    )
}