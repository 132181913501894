/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptPaymentRequest } from '../models/AcceptPaymentRequest';
import type { CalculateInterestResponse } from '../models/CalculateInterestResponse';
import type { ForeclosureRequest } from '../models/ForeclosureRequest';
import type { PaginatedAcceptPaymentHistoryResponse } from '../models/PaginatedAcceptPaymentHistoryResponse';
import type { PawningContractResponse } from '../models/PawningContractResponse';
import type { PaymentAppointmentRequest } from '../models/PaymentAppointmentRequest';
import type { PaymentSummarizeResponse } from '../models/PaymentSummarizeResponse';
import type { RedeemRequest } from '../models/RedeemRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopPawningTransactionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public redeem({
        shopId,
        pawningId,
        requestBody,
    }: {
        shopId: string,
        pawningId: string,
        requestBody: RedeemRequest,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/redeem',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public appointment({
        shopId,
        pawningId,
        requestBody,
    }: {
        shopId: string,
        pawningId: string,
        requestBody: PaymentAppointmentRequest,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/payment-appointment',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public foreclosure({
        pawningId,
        shopId,
        requestBody,
    }: {
        pawningId: string,
        shopId: string,
        requestBody: ForeclosureRequest,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/foreclosure',
            path: {
                'pawningId': pawningId,
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public acceptPayment({
        shopId,
        pawningId,
        requestBody,
    }: {
        shopId: string,
        pawningId: string,
        requestBody: AcceptPaymentRequest,
    }): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/accept-payment',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaymentSummarizeResponse OK
     * @throws ApiError
     */
    public paymentSummarize({
        shopId,
        pawningId,
    }: {
        shopId: string,
        pawningId: string,
    }): CancelablePromise<PaymentSummarizeResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/payment-summarize',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns CalculateInterestResponse OK
     * @throws ApiError
     */
    public calculateInterest({
        shopId,
        pawningId,
        calculatedAt,
    }: {
        shopId: string,
        pawningId: string,
        calculatedAt?: string,
    }): CancelablePromise<CalculateInterestResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/calculate-interest',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            query: {
                'calculatedAt': calculatedAt,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedAcceptPaymentHistoryResponse OK
     * @throws ApiError
     */
    public getAcceptPaymentHistory({
        shopId,
        pawningId,
        page = 1,
        perPage = 10,
    }: {
        shopId: string,
        pawningId: string,
        page?: number,
        perPage?: number,
    }): CancelablePromise<PaginatedAcceptPaymentHistoryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/{pawningId}/accept-payment-history',
            path: {
                'shopId': shopId,
                'pawningId': pawningId,
            },
            query: {
                'page': page,
                'perPage': perPage,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
