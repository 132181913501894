import { Box, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import SearchIcon from '@mui/icons-material/Search'
import { useInventoriesQuery } from "../../client/query-client/useInventoryQuery";
import { useQueryState } from "nuqs";
import { GetInventoryResponse, GetShopProductsResponse } from "../../client/motalvip-apis/generated";
import dayjs from "dayjs";
import React from "react"
import styled from "@emotion/styled"

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 10

const ProductType = GetShopProductsResponse.type

const ProductTypeLabelMapper = {
    [ProductType.MOBILE]: 'มือถือ',
    [ProductType.GENERAL]: 'สินค้าทั่วไป'
}

const TableRowStyled = styled(TableRow)`
    cursor: pointer;

    &:hover {
        background-color: #F7F7F7;
    }
`

interface Props {
    onOpenDeleteDialog: (inventory: GetInventoryResponse) => void
}

export const InventoryList = ({ onOpenDeleteDialog }: Props) => {
    const { shopId } = useParams()
    const navigate = useNavigate()

    // query
    const [page, setPage] = useQueryState<number>("page", {
        defaultValue: DEFAULT_PAGE,
        parse: (v) => Number(v) || DEFAULT_PAGE,
    })
    const [rowsPerPage, setRowsPerPage] = useQueryState<number>("limit", {
        defaultValue: DEFAULT_LIMIT,
        parse: (v) => Number(v) || DEFAULT_LIMIT,
    })
    const [search, setSearch] = useQueryState("search")
    const [filterType, setFilterType] = useQueryState("filterType")
    const [filterSecondHand, setFilterSecondHand] = useQueryState("filterSecondHand")
    const shouldFilterType = !!(filterType && filterType !== 'all')
    const shouldFilterSecondHand = !!(filterSecondHand && filterSecondHand !== 'all' && filterType !== ProductType.GENERAL)

    const { data: inventoryData, isLoading, refetch: refetchInventory } = useInventoriesQuery({
        shopId: shopId!,
        search: search!,
        limit: rowsPerPage,
        ...(shouldFilterType && { type: filterType as GetShopProductsResponse.type }),
        ...(shouldFilterSecondHand && { isSecondhand: filterSecondHand === 'true' }),
        page,
    })

    const handleChangePage = async (_event: unknown, newPage: number) => {
        await setPage(newPage + 1)
        refetchInventory()
    }

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        await setRowsPerPage(parseInt(event.target.value, 10));
        await setPage(1)
        refetchInventory()
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const handleSearchSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        refetchInventory()
    }

    const handleFilterSecondHandChange = async (event: SelectChangeEvent<any>) => {
        await setFilterSecondHand(event.target.value)
        refetchInventory()
    }

    const handleFilterTypeChange = async (event: SelectChangeEvent<any>) => {
        await setFilterType(event.target.value)
        await setFilterSecondHand('all')
        refetchInventory()
    }

    const handleViewInventory = (inventoryId: string) => {
        navigate(`/${shopId}/pos/edit-inventory/${inventoryId}`)
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <Box>
            <Stack
                sx={{
                    width: "100%",
                    marginBottom: 4,
                }}
                gap="12px"
                direction={{ sm: 'column', xs: 'column', md: 'row' }}
            >
                <form
                    style={{
                        display: "flex",
                        gap: "16px",
                        width: "100%",
                    }}
                    onSubmit={handleSearchSubmit}
                >
                    <TextField
                        sx={{
                            margin: 0,
                            padding: 0,
                        }}
                        label="ค้นหาด้วยชื่อสินค้า หรือรหัส SKU"
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <IconButton
                        sx={{
                            width: '56px'
                        }}
                        type="submit"
                    >
                        <SearchIcon />
                    </IconButton>
                </form>
                <Stack
                    sx={{
                        width: "100%",
                    }}
                    gap="16px"
                    direction="row"
                    justifyContent={{ sm: 'start', xs: 'start', md: 'end' }}
                >
                    <FormControl sx={{
                        width: '200px'
                    }}>
                        <InputLabel id="product-type-label">ประเภทสินค้า</InputLabel>
                        <Select
                            labelId="product-type-label"
                            id="product-type-select"
                            sx={{
                                flex: 1,
                            }}
                            label='ประเภทสินค้า'
                            value={filterType}
                            onChange={handleFilterTypeChange}
                        >
                            <MenuItem value="all">ทั้งหมด</MenuItem>
                            {Object.values(ProductType).map((productType) => (
                                <MenuItem value={productType} key={productType}>
                                    {ProductTypeLabelMapper[productType]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {filterType === ProductType.MOBILE &&
                        <FormControl sx={{
                            width: '200px'
                        }}>
                            <InputLabel id="status-label">ประเภทมือถือ</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status-select"
                                label='ประเภทมือถือ'
                                sx={{
                                    flex: 1,
                                }}
                                value={filterSecondHand}
                                onChange={handleFilterSecondHandChange}
                            >
                                <MenuItem value="all">ทั้งหมด</MenuItem>
                                <MenuItem value="false">มือ1</MenuItem>
                                <MenuItem value="true">มือ2</MenuItem>
                            </Select>
                        </FormControl>
                    }
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="product list table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ชื่อสินค้า</TableCell>
                            <TableCell align='center'>รหัส SKU</TableCell>
                            <TableCell align='center'>ประเภทสินค้า (มือ1/มือ2)</TableCell>
                            <TableCell align='center'>สต๊อกสินค้า</TableCell>
                            <TableCell align='center'>วันที่สร้าง</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(inventoryData?.content && inventoryData.content.length > 0) ? (
                            inventoryData!.content!.map(inventory => {
                                const isMobile = inventory.type === ProductType.MOBILE

                                return (
                                    <TableRowStyled key={inventory.inventoryId} onClick={() => {
                                        inventory.inventoryId && handleViewInventory(inventory.inventoryId)
                                    }}>
                                        <TableCell>{inventory.productName}</TableCell>
                                        <TableCell align='center'>{inventory.sku}</TableCell>
                                        <TableCell align='center'>{isMobile ? (inventory.isSecondhand ? 'มือ2' : 'มือ1') : '-'}</TableCell>
                                        <TableCell align='center'>{inventory.stock}</TableCell>
                                        <TableCell align='center'>
                                            {dayjs(inventory.createdAt).format('DD/MM/YYYY')}
                                        </TableCell>
                                    </TableRowStyled>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Box sx={{ textAlign: "center", p: 3 }}>
                                        <Typography variant="h6">ไม่พบสต๊อกสินค้า</Typography>
                                        <Typography variant="body2">
                                            กรุณาลองค้นหาอีกครั้ง หรือตรวจสอบเงื่อนไขการค้นหา
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={inventoryData?.pagination?.totalElements ?? 0}
                page={page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box >
    )
}