import {
  Box,
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableCellProps,
  TypographyProps,
  Stack,
} from "@mui/material";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import { formatThaiDate } from "../../util/formatThaiDate";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";
import { ProductDto } from "../../client/motalvip-apis/generated";
import { useEffect, useRef } from "react";
import QRCode from "qrcode";

interface ITableCellProps extends TableCellProps {
  text: string;
  typographyProps?: TypographyProps;
}

export const TableCellComponent: React.FC<ITableCellProps> = ({
  text,
  typographyProps,
  ...props
}) => {
  return (
    <TableCell {...props}>
      <Typography {...typographyProps} fontSize={12} py="1px">
        {text}
      </Typography>
    </TableCell>
  );
};

interface IProps {
  reference: React.RefObject<HTMLDivElement>;
  contract: InstallmentPlanDto;
}

const ContractDocument: React.FC<IProps> = (props): JSX.Element => {
  const { contract } = props;
  const { shop } = useShopFinancierContext();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const drawQR = (canvas: HTMLCanvasElement, qrCode: string) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const size = 100;

    const opt = {
      width: size,
      height: size,
      margin: 2,
      color: {
        dark: "#000000",
        light: "#FFFFFF",
      },
    };

    QRCode.toCanvas(canvas, qrCode, opt, (error) => {
      if (error) {
        console.error("Error generating QR code:", error);
        return;
      }
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const qrCode = shop?.financier?.lineId
      ? `https://line.me/ti/p/~${shop?.financier?.lineId}`
      : null;

    if (canvas && qrCode) {
      drawQR(canvas, qrCode);
    }
  }, [shop?.financier?.lineId]);

  return (
    <Paper elevation={2} sx={{ p: 4 }} ref={props.reference}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Box sx={{ width: 100 }}>
            {shop?.financier?.avatarUrl && (
              <img
                src={shop?.financier?.avatarUrl}
                alt="logo"
                style={{ width: "100%" }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <Typography variant="subtitle1">หนังสือสัญญาซื้อขาย</Typography>
          <Typography variant="subtitle2">{shop?.financier?.name}</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <Typography variant="caption" display="block">
            วันที่ {formatThaiDate(new Date())}
          </Typography>
          {contract.transactionId && (
            <Typography variant="caption" display="block">
              สัญญาเลขที่ {contract.transactionId}
            </Typography>
          )}
          {contract.referenceId && (
            <Typography variant="caption" display="block">
              รหัสอ้างอิง {contract.referenceId}
            </Typography>
          )}

          <Typography
            variant="caption"
            display="block"
            sx={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            สินค้าจาก {`${shop?.shopReference}-${shop?.name}`}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Typography variant="body2" fontSize={12} sx={{ textIndent: "40px" }}>
          ทำที่ {shop?.financier?.address} ระหว่าง {shop?.financier?.name}{" "}
          ต่อไปในสัญญาฉบับนี้เรียกว่า "ผู้ให้เช่าซื้อ" ฝ่ายหนึ่ง กับ
        </Typography>
      </Box>

      <Box mt={1}>
        <TableContainer component={Box}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCellComponent
                  text="ชื่อ-สกุล"
                  width="20%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={`${contract.profile?.firstName} ${contract.profile?.lastName}`}
                  width="25%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text="หมายเลขโทรศัพท์"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={contract.profile?.phoneNumbers?.[0].phoneNumber || ""}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="เลขประจำตัวประชาชน"
                  width="25%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={contract.profile?.citizenId || ""}
                  width="30%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text="หมายเลขพาสปอร์ต"
                  width="25%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={contract.profile?.passportNumber || ""}
                  width="30%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="ที่อยู่ปัจจุบัน"
                  sx={{ border: "none", p: 0, verticalAlign: "top" }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={contract.profile?.currentAddress || ""}
                  colSpan={3}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="ที่ทำงาน"
                  sx={{ border: "none", p: 0, verticalAlign: "top" }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={contract.profile?.workPlace || ""}
                  colSpan={3}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2}>
        <Typography variant="body2" fontSize={12} sx={{ lineHeight: 1.6 }}>
          ชึ่งต่อไปนี้ในสัญญานี้เรียกว่า”ผู้เช่าซื้อ” อีกฝ่ายหนึ่ง
          ทั้งสองฝ่ายตกลงกันให้เช่าซื้อและเช่าซื้อทรัพย์สินตามรายการทรัพย์สินตามรายการทรัพย์สินที่เช่าซื้อใน
          บัญชีรายการเช่าซื้อด้านล่างนี้ (รวมทั้งส่วนควบ เครื่องอุปกรณ์
          อะไหล่สิ่งที่นำมาเเทนของเดิม สิ่งต่อมเติม หรือเพิ่มเติมด้วย)
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant="body2" fontSize={12} fontWeight="500">
          มีรายละเอียดของบัญชีทรัพย์สินที่ให้เช่าซื้อดังนี้
        </Typography>
        <TableContainer component={Box} mt="2px">
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCellComponent
                  text="โทรศัพท์ยี่ห้อ"
                  width="15%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", fontWeight: "500" }}
                />
                <TableCellComponent
                  text={contract.product?.brand || ""}
                  width="20%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="รุ่น"
                  width="15%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", fontWeight: "500" }}
                />
                <TableCellComponent
                  text={contract.product?.model || ""}
                  width="20%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text="สี"
                  width="10%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", fontWeight: "500" }}
                />
                <TableCellComponent
                  text={contract.product?.color || ""}
                  width="25%"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="Serial Number"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", fontWeight: "500" }}
                />
                <TableCellComponent
                  text={contract.product?.serialNumber || ""}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text="IMEI"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", fontWeight: "500" }}
                />
                <TableCellComponent
                  text={contract.product?.imei || ""}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="ประเภทเครื่อง"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", fontWeight: "500" }}
                />
                <TableCellComponent
                  text={
                    contract.product?.usedType &&
                    contract.product?.usedType === ProductDto.usedType.NEW
                      ? "มือ 1"
                      : "มือ 2"
                  }
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={1}>
        <Typography variant="body2" fontSize={12} fontWeight="500">
          โดยมีรายละเอียดราคาเช่าชื้อดังนี้
        </Typography>
        <TableContainer component={Box} mt="2px">
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCellComponent
                  text="1. ราคาที่ทำสัญญา"
                  sx={{ border: "none", p: 0 }}
                  width="45%"
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={`${(contract.totalAmount || 0).toLocaleString(
                    "th-TH"
                  )} บาท`}
                  sx={{ border: "none", p: 0 }}
                  width="15%"
                  typographyProps={{ variant: "body2", textAlign: "right" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="2. เงินดาวน์ (เงินล่วงหน้า)"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={`${contract.downPaymentAmount?.toLocaleString(
                    "th-TH"
                  )} บาท`}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", textAlign: "right" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="3. จำนวนงวดที่ผ่อนชำระ"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={`${contract.installmentCount} งวด`}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", textAlign: "right" }}
                />
                <TableCellComponent
                  text={`งวดแรกเริ่มเมื่อ ${formatThaiDate(
                    contract.installmentStartDate
                  )}`}
                  sx={{ border: "none", p: 0, pl: 1 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="4. ราคาเงินสดส่วนที่เหลือ"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={`${contract.installmentTotalAmount?.toLocaleString(
                    "th-TH"
                  )} บาท`}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", textAlign: "right" }}
                />
              </TableRow>
              <TableRow>
                <TableCellComponent
                  text="5. ชำระงวดละ"
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2" }}
                />
                <TableCellComponent
                  text={`${contract.installmentAmount?.toLocaleString(
                    "th-TH"
                  )} บาท`}
                  sx={{ border: "none", p: 0 }}
                  typographyProps={{ variant: "body2", textAlign: "right" }}
                />
                <TableCellComponent
                  text={`ชำระทุกวันที่ 1 ของเดือน`}
                  sx={{ border: "none", p: 0, pl: 1 }}
                  typographyProps={{ variant: "body2" }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={1}>
        <Typography variant="body2" fontSize={12} fontWeight="500">
          เงื่อนไขการผ่อนชำระสินค้า
        </Typography>
        <Box mt="2px">
          <Typography
            variant="body2"
            fontSize={10}
            sx={{ whiteSpace: "pre-line" }}
          >
            {shop?.financierShopsGroup?.contractDetail}
          </Typography>
          <Typography
            variant="body2"
            fontSize={10}
            sx={{ whiteSpace: "pre-line" }}
          >
            {shop?.financierShopsGroup?.remark}
          </Typography>
        </Box>
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={shop?.financier?.lineId ? 0 : 3}
      >
        <Stack>
          <Typography variant="body2" fontSize={12}>
            {`ช่องทางการติดต่อ ${shop?.financier?.name}`}
          </Typography>
          <Typography variant="body2" fontSize={12}>
            {`${
              shop?.financier?.lineId
                ? `Line ID: ${shop?.financier?.lineId} `
                : ""
            }เบอร์ติดต่อ ${shop?.financier?.phoneNumbers[0].phoneNumber}`}
          </Typography>
        </Stack>
        {shop?.financier?.lineId && (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="body2" fontSize={12}>
              สแกน QR CODE ไลน์
            </Typography>
            <Box sx={{ width: 100 }}>
              {shop?.financier?.lineId && (
                <canvas
                  ref={canvasRef}
                  style={{ width: "100%", height: "100%" }}
                ></canvas>
              )}
            </Box>
          </Stack>
        )}
      </Stack>

      <Box mt={shop?.financier?.lineId ? 0 : 3}>
        <Typography variant="body2" fontSize={12}>
          ผู้เช่าซื้อยินยอมตามเงื่อนไขที่กำหนดและได้ลงลายลักอักษณ์ไว้ในหนังสือฉบับนี้
        </Typography>
      </Box>

      <Box mt={3} display="flex" justifyContent="space-between">
        <Stack direction="row" alignItems="top" gap={1}>
          <Typography fontSize={14}>ลงชื่อ</Typography>
          <Stack spacing={1} alignItems="center" sx={{ width: "175px" }}>
            <Box sx={{ borderBottom: 1, width: "100%", height: "20px" }} />
            <Typography variant="caption" fontSize={14}>
              ({"\u00A0".repeat(50)})
            </Typography>
          </Stack>
          <Typography fontSize={14}>ผู้เช่าซื้อ</Typography>
        </Stack>
        <Stack direction="row" alignItems="top" gap={1}>
          <Typography fontSize={14}>ลงชื่อ</Typography>
          <Stack spacing={1} alignItems="center" sx={{ width: "175px" }}>
            <Box sx={{ borderBottom: 1, width: "100%", height: "20px" }} />
            <Typography variant="caption" fontSize={14}>
              ({"\u00A0".repeat(50)})
            </Typography>
          </Stack>
          <Typography fontSize={14}>ผู้ให้เช่าซื้อ</Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default ContractDocument;
