/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StepUpdateLoanDraftDeviceAndLoanApprovalRequest = {
    loanId?: string;
    shopId?: string;
    usedType?: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType;
    brand?: string;
    model?: string;
    color?: string;
    description?: string;
    warranty?: string;
    batteryPercent?: number;
    osType?: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.osType;
    purchaseCost: number;
    installmentPlanFundSource?: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentPlanFundSource;
    downPaymentPercent: number;
    downPaymentAmount: number;
    downPaymentDate?: string;
    installmentCount?: number;
    installmentAmount?: number;
    installmentStartDate?: string;
    installmentFrequency?: number;
    installmentFrequencyType?: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType;
    deviceImei?: string;
    deviceSerialNumber?: string;
    interestRate?: number;
    loanBalance?: number;
    note?: string;
};
export namespace StepUpdateLoanDraftDeviceAndLoanApprovalRequest {
    export enum usedType {
        NEW = 'NEW',
        USED = 'USED',
    }
    export enum osType {
        IOS = 'IOS',
        ANDROID = 'ANDROID',
        OTHERS = 'OTHERS',
    }
    export enum installmentPlanFundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum installmentFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

