
import { Box, Button, Card, CardContent, Dialog, FormControl, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DownSavingCancelRequest } from "../../../../../client/motalvip-apis/generated";
import { useDownSavingCancelMutation } from "../../../../../client/query-client/downSaving/useDownSavingCancelMutation";
import CustomInputLabel from "../../../../../component/CustomInputLabel";

interface DownSavingCancelDialogProps {
    downSavingId: string;
    open: boolean
    onClose: () => void;
}

export const DownSavingCancelDialog = (props: DownSavingCancelDialogProps) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const downSavingCancelMutation = useDownSavingCancelMutation()

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors }
    } = useForm<DownSavingCancelRequest>({
        defaultValues: {
            downSavingId: props.downSavingId,
            reason: ''
        },
        mode: 'all'
    });

    const onSubmit = () => {
        handleSubmit((data) => {
            downSavingCancelMutation.mutate(getValues(), {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ')
                    props.onClose()
                },
                onError: (error) => {
                    toast.error('เกิดข้อผิดพลาด')
                    props.onClose()
                }
            });
        })()
    }

    const onCloseDialog = () => {
        reset({
            downSavingId: '',
            reason: ''
        });
        props.onClose();
    }

    return (
        <Dialog
            fullWidth={isMobile}
            maxWidth={isMobile ? false : 'sm'}
            open={props.open}
            onClose={onCloseDialog}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <Box sx={{ bgcolor: 'white', width: isMobile ? '100%' : theme.breakpoints.values.sm }} >
                <Box sx={{ p: 2, justifyContent: 'space-between', bgcolor: '#263238' }}>
                    <Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
                        <Typography variant="h5" color='white'>{`ยกเลิกออมดาวน์`}</Typography>
                    </Stack>
                </Box>
                <Box sx={{ p: 2, width: '100%', bgcolor: 'white' }}>
                    <Card sx={{ my: 1, width: { md: '100%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack direction={'column'} gap={2}>
                                <Stack direction={{ sm: 'column' }} gap={2}>
                                    <FormControl fullWidth error={errors.reason?.message ? true : false}>
                                        <CustomInputLabel>เหตุผล</CustomInputLabel>
                                        <Controller
                                            name="reason"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: 'ต้องใส่เหตุผล' }
                                            }}
                                            render={({ field }) => <TextField type="text" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.reason && <Typography color="error">{errors.reason.message}</Typography>}
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                </Box>
                <Box sx={{ p: 2, pt: 0, bgcolor: 'white', width: '100%' }} >
                    <Stack direction='row' gap={1} justifyContent='center'>
                        <Button variant='outlined' size='large'
                            onClick={onCloseDialog}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>ยกเลิก</Button>
                        <Button variant='contained' size='large'
                            onClick={onSubmit}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>บันทึก</Button>
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    )
}