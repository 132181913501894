import { ShopPaymentChannelDto } from "../client/motalvip-apis/generated";

export type PaymentChannelConfig = {
  logo: string;
  color: string;
  name: string;
} & (
  | {
      type: Omit<
        ShopPaymentChannelDto.type,
        ShopPaymentChannelDto.type.BANK_ACCOUNT
      >;
      bankCode?: never;
    }
  | {
      type: ShopPaymentChannelDto.type.BANK_ACCOUNT;
      bankCode: ShopPaymentChannelDto.bankCode;
    }
);
export const paymentChannelConfig: PaymentChannelConfig[] = [
  {
    type: ShopPaymentChannelDto.type.BANK_ACCOUNT,
    bankCode: ShopPaymentChannelDto.bankCode.BBL,
    name: "ธนาคารกรุงเทพ",
    logo: "/bank-logo/bbl.svg",
    color: "#00529f",
  },
  {
    type: ShopPaymentChannelDto.type.BANK_ACCOUNT,
    bankCode: ShopPaymentChannelDto.bankCode.BAY,
    name: "ธนาคารกรุงศรี",
    logo: "/bank-logo/bay.svg",
    color: "#fec43b",
  },
  {
    type: ShopPaymentChannelDto.type.BANK_ACCOUNT,
    bankCode: ShopPaymentChannelDto.bankCode.SCB,
    name: "ธนาคารไทยพาณิชย์",
    logo: "/bank-logo/scb.svg",
    color: "#4e2e7f",
  },
  {
    type: ShopPaymentChannelDto.type.BANK_ACCOUNT,
    bankCode: ShopPaymentChannelDto.bankCode.KBANK,
    name: "ธนาคารกสิกรไทย",
    logo: "/bank-logo/kbank.svg",
    color: "#138f2d",
  },
  {
    type: ShopPaymentChannelDto.type.BANK_ACCOUNT,
    bankCode: ShopPaymentChannelDto.bankCode.KTB,
    name: "ธนาคารกรุงไทย",
    logo: "/bank-logo/ktb.svg",
    color: "#1ba5e1",
  },
  {
    type: ShopPaymentChannelDto.type.BANK_ACCOUNT,
    bankCode: ShopPaymentChannelDto.bankCode.TTB,
    name: "ธนาคารทหารไทย",
    logo: "/bank-logo/ttb.svg",
    color: "#ecf0f1",
  },
  {
    type: ShopPaymentChannelDto.type.PROMPT_PAY,
    logo: "/bank-logo/prompt-pay-logo.svg",
    name: "พร้อมเพย์",
    color: "#0A2A4D",
  },
  {
    type: ShopPaymentChannelDto.type.PROMPT_PAY_QR,
    name: "พร้อมเพย์",
    logo: "/bank-logo/pp.svg",
    color: "#0A2A4D",
  },
  {
    type: ShopPaymentChannelDto.type.CASH,
    name: "เงินสด",
    logo: "/cash.svg",
    color: "#333333",
  },
  {
    type: ShopPaymentChannelDto.type.AUTOMATIC_QR,
    name: "Payment Gateway",
    color: "#0A2A4D",
    logo: "/qr-code.svg",
  },
  {
    type: ShopPaymentChannelDto.type.TRUE_WALLET,
    name: "ทรูวอลเล็ต",
    color: "rgb(144, 35, 23)",
    logo: "/true-money.png",
  },
];
