import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  InstallmentPlanDocumentDto,
  InstallmentPlanDto,
  StepUpdateLoanDraftConfirmDownPaymentRequest,
} from "../../client/motalvip-apis/generated";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploadWithPreview from "../../component/FileUploadWithPreview";
import { useState } from "react";
import {
  useCreateContractDocumentMutation,
  useConfirmContractDocumentMutation,
  useRemoveLoanDocumentMutation,
  useUpdateLoanDraftConfirmDownPaymentMutation,
} from "../../client/query-client/shopFinancier/contract";
import { useUploadFileMutation } from "../../client/query-client/useUploadFileMutation";
import DialogConfirm from "../../component/DialogConfirm";
import { Spin } from "../../component/common/Spin";

interface IForm
  extends Omit<StepUpdateLoanDraftConfirmDownPaymentRequest, "loanId"> {}

interface IFileUpload {
  document: InstallmentPlanDocumentDto;
  confirmed: boolean;
}

interface IProps {
  onSubmit: () => void;
  onBack: () => void;
  shopId: string | undefined;
  contract: InstallmentPlanDto | undefined;
}

const DownPayment: React.FC<IProps> = (props): JSX.Element => {
  const [fileUploads, setFileUploads] = useState<IFileUpload | null>(null);
  const [selectedDocumentRemove, setSelectedDocumentRemove] =
    useState<IFileUpload | null>(null);

  const createDocument = useCreateContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const uploadFileMutation = useUploadFileMutation();
  const confirmDocument = useConfirmContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const removeCustomerDocument = useRemoveLoanDocumentMutation(
    props.contract?.id || ""
  );

  const updateConfirmDownPayment = useUpdateLoanDraftConfirmDownPaymentMutation(
    {
      contractId: props.contract?.id || "",
      shopId: props.shopId || "",
    }
  );

  const schema = yup.object({
    note: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(255, "หมายเหตุต้องไม่เกิน 255 ตัวอักษร"),
  });

  const initialFormValue: IForm = {
    note: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IForm>({
    defaultValues: {
      ...initialFormValue,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IForm): Promise<void> => {
    try {
      if (fileUploads && !fileUploads?.confirmed) {
        await confirmDocument.mutateAsync({
          loanId: props.contract?.id || "",
          objectKey: fileUploads?.document.objectKey,
          type: InstallmentPlanDocumentDto.type.GENERATED_CONTRACT,
        });
      }

      await updateConfirmDownPayment.mutateAsync({
        ...data,
        loanId: props.contract?.id || "",
      });

      props.onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = async (file: File | null): Promise<void> => {
    try {
      if (file) {
        const document = await createDocument.mutateAsync({
          originalName: file.name,
          type: InstallmentPlanDocumentDto.type.IMAGE_DOWN_PAYMENT,
        });

        await uploadFileMutation.mutateAsync({
          file: file,
          uploadUrl: document.uploadUrl || "",
        });

        setFileUploads({ document, confirmed: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDocumentRemove = async (
    data: IFileUpload | null
  ): Promise<void> => {
    if (!data) return;

    try {
      await removeCustomerDocument.mutateAsync({
        loanDocumentId: data.document?.id,
      });
      setFileUploads(null);
      setSelectedDocumentRemove(null);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Spin
      spinning={
        createDocument.isPending ||
        uploadFileMutation.isPending ||
        removeCustomerDocument.isPending
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogConfirm
          isOpen={!!selectedDocumentRemove}
          title="ลบไฟล์"
          message="คุณต้องการลบไฟล์ใช่หรือไม่?"
          onClose={() => setSelectedDocumentRemove(null)}
          onConfirm={() => handleDocumentRemove(selectedDocumentRemove)}
          disable={removeCustomerDocument.isPending}
        />

        <Stack direction="column" gap={2}>
          <Typography variant="h6">หลักฐานการชำระเงิน</Typography>

          <FileUploadWithPreview
            title="ไฟล์หลักฐาน"
            document={fileUploads?.document}
            onChange={(file) => handleFileChange(file)}
            onRemove={() => {
              setSelectedDocumentRemove(fileUploads);
            }}
          />

          <FormControl fullWidth error={!!errors.note}>
            <FormLabel>หมายเหตุ</FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  error={!!errors?.note}
                  helperText={errors?.note?.message}
                  size="small"
                />
              )}
            />
          </FormControl>

          <Stack direction="row" flexWrap="wrap" gap={3} sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="outlined"
              type="button"
              size="large"
              onClick={() => props.onBack()}
              sx={{ width: { xs: "100%", md: "200px" } }}
            >
              ย้อนกลับ
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={
                confirmDocument.isPending || updateConfirmDownPayment.isPending
              }
              sx={{ width: { xs: "100%", md: "200px" } }}
            >
              ยืนยันการชำระเงิน
            </Button>
          </Stack>
        </Stack>
      </form>
    </Spin>
  );
};

export default DownPayment;
