import { useMutation } from "@tanstack/react-query";
import { QueryKey } from ".";
import { useBackendApiClient } from "../motalvip-apis";
import {
  BackendApiClient,
  QrPaymentStatusCheckResponse,
} from "../motalvip-apis/generated";

export const useCheckPaymentStatus = ({
  onSuccess,
  onError,
}: {
  onSuccess: (resp: QrPaymentStatusCheckResponse) => void;
  onError: (e: Error) => void;
}) => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.PAYMENT, "checkPaymentStatus"],
    onError,
    onSuccess,
    mutationFn: (
      payload: Parameters<BackendApiClient["public"]["checkQrPaymentStatus"]>[0]
    ) => apiClient.public.checkQrPaymentStatus(payload),
  });
};
