import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDialog } from "../../../context/DialogContext";
import { useBackendApiClient } from "../../motalvip-apis";
import { DownSavingTransactionCreateRequest, DownSavingTransactionResponse } from "../../motalvip-apis/generated";

export const useDownSavingTransactionCreateMutation = () => {
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: ['downSavingTransaction'],
        mutationFn: (req: DownSavingTransactionCreateRequest) =>
            backendApiClient.downSaving.createDownSavingTransaction({
                requestBody: req,
            }),
        onSuccess: (res: DownSavingTransactionResponse) => {
            queryClient.invalidateQueries({ queryKey: ['transactions', res.downSavingId] });
            queryClient.invalidateQueries({ queryKey: [res.downSavingId] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
        retry: 0,
    });
}