import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  InstallmentPlanDocumentDto,
  InstallmentPlanDto,
  ProductDto,
  StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
} from "../../client/motalvip-apis/generated";
import { useState, useEffect, useMemo } from "react";
import {
  SearchableBrandField,
  SearchableMode,
} from "../../component/Contract/SearchableBrandField";
import { SearchableModelField } from "../../component/Contract/SearchableModelField";
import { TogglableLabel } from "../../component/Contract/TogglableLabel";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";
import { renderSelectPlaceholder } from "../../util/renderSelectPlaceholder";
import { IProductDetailForm } from "./ProductDetails";
import FileUploadWithPreview from "../../component/FileUploadWithPreview";

interface IFileUpload {
  document: InstallmentPlanDocumentDto;
  confirmed: boolean;
}
interface IProps {
  onSubmit: () => void;
  onBack: () => void;
  shopId: string | undefined;
  contract: InstallmentPlanDto | undefined;
}

const ContractApproval: React.FC<IProps> = (props): JSX.Element => {
  const [mode, setMode] = useState<"combobox" | "freetext">("freetext");
  const [fileUploads, setFileUploads] = useState<IFileUpload | null>(null);

  const theme = useTheme();
  const shopFinancier = useShopFinancierContext();
  const shopGroup = shopFinancier.shop?.financierShopsGroup;

  const initialFormValue: IProductDetailForm = {
    purchaseCost: 0,
    loanId: "",
    shopId: "",
    loanBalance: 0,
    downPaymentPercent: 0,
    downPaymentAmount: 0,
    installmentAmount: 0,
    installmentCount: undefined,
    downPaymentDate: dayjs().format("YYYY-MM-DD"),
    installmentStartDate: dayjs().format("YYYY-MM-DD"),
    installmentFrequency: 1,
    installmentFrequencyType:
      StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType
        .MONTH,
    usedType: StepUpdateLoanDraftDeviceAndLoanApprovalRequest.usedType.NEW,
    brand: "",
    model: "",
    color: "",
    description: "",
    deviceImei: "",
    deviceSerialNumber: "",
  };

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<IProductDetailForm>({
    defaultValues: {
      ...initialFormValue,
    },
  });

  const onModeChanged = (): void => {
    setMode((prev) => (prev === "combobox" ? "freetext" : "combobox"));
    setValue("brand", undefined);
    setValue("model", undefined);
    setValue("color", undefined);
  };

  const disableTextFieldProps = (isDisabled: boolean) => {
    return {
      sx: isDisabled ? { backgroundColor: theme.palette.grey[200] } : {},
      disabled: isDisabled,
    };
  };

  const purchaseCostValue = watch("purchaseCost");
  const downPaymentPercentValue = watch("downPaymentPercent");
  const downPaymentAmountValue = watch("downPaymentAmount");
  const installmentCountValue = watch("installmentCount");

  const totalPrice = useMemo(() => {
    const purchaseCost = Number(purchaseCostValue);
    const installmentCount = installmentCountValue;
    const interestRate = Number(installmentCount?.interestRate);

    let loanBalance = 0;

    if (!purchaseCost || isNaN(purchaseCost) || purchaseCost <= 0) {
      loanBalance = 0;
    } else if (!installmentCount) {
      loanBalance = purchaseCost - downPaymentAmountValue;
    } else {
      loanBalance =
        (purchaseCost - downPaymentAmountValue) * (1 + interestRate / 100);
    }

    return Number(loanBalance.toFixed(2));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseCostValue, installmentCountValue, downPaymentAmountValue]);

  useEffect(() => {
    const purchaseCost = Number(purchaseCostValue);
    const downPaymentPercent = Number(downPaymentPercentValue);

    let downPaymentAmount = 0;

    if (!purchaseCost || isNaN(purchaseCost) || isNaN(downPaymentPercent)) {
      downPaymentAmount = 0;
    } else {
      downPaymentAmount = purchaseCost * (downPaymentPercent / 100);
    }

    setValue("downPaymentAmount", Number(downPaymentAmount.toFixed(2)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseCostValue, downPaymentPercentValue]);

  useEffect(() => {
    const purchaseCost = Number(purchaseCostValue);
    const downPaymentAmount = Number(downPaymentAmountValue);

    let loanBalance = 0;

    if (!purchaseCost || isNaN(purchaseCost) || purchaseCost <= 0) {
      loanBalance = 0;
    } else {
      loanBalance = purchaseCost - downPaymentAmount;
    }

    setValue("loanBalance", Number(loanBalance.toFixed(2)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseCostValue, installmentCountValue, downPaymentAmountValue]);

  useEffect(() => {
    const monthAmount = Number(installmentCountValue?.monthAmount);
    let installmentAmount = 0;

    if (!totalPrice || isNaN(totalPrice) || isNaN(monthAmount)) {
      installmentAmount = 0;
    } else if (monthAmount <= 0) {
      installmentAmount = 0;
    } else {
      installmentAmount = totalPrice / monthAmount;
    }

    setValue("installmentAmount", Number(installmentAmount.toFixed(2)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, installmentCountValue?.monthAmount]);

  useEffect(() => {
    if (props.contract) {
      reset({
        purchaseCost: props.contract.product?.purchaseCost,
        loanId: props.contract.id,
        shopId: props.contract.shopId,
        loanBalance: props.contract.loanBalance,
        downPaymentPercent: props.contract.downPaymentPercent,
        downPaymentAmount: props.contract.downPaymentAmount,
        installmentAmount: props.contract.installmentAmount,
        installmentCount: {
          monthAmount: props.contract.installmentCount,
          interestRate: props.contract.interestRate,
        },
        downPaymentDate: props.contract.downPaymentDate,
        installmentStartDate: props.contract.installmentStartDate,
        installmentFrequency: props.contract.installmentFrequency,
        installmentFrequencyType: props.contract.installmentFrequencyType,
        usedType: props.contract.product?.usedType,
        brand: props.contract.product?.brand,
        model: props.contract.product?.model,
        color: props.contract.product?.color || "",
        description: props.contract.product?.description || "",
        deviceImei: props.contract.product?.imei || "",
        deviceSerialNumber: props.contract.product?.serialNumber || "",
      });

      const doc = props.contract.documents?.find(
        (doc) => doc.type === InstallmentPlanDocumentDto.type.DEVICE_IMAGE
      );
      if (doc) {
        setFileUploads({
          document: doc,
          confirmed: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contract]);

  return (
    <form>
      <Stack gap={2}>
        <Typography variant="h6">ข้อมูลสินเชื่อ</Typography>
        <Stack direction={{ lg: "row" }} gap={2}>
          <FormControl fullWidth error={!!errors.purchaseCost}>
            <FormLabel>ราคาสินค้า</FormLabel>
            <Controller
              name="purchaseCost"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.purchaseCost}
                  helperText={errors?.purchaseCost?.message}
                  size="small"
                  {...disableTextFieldProps(true)}
                />
              )}
            />
          </FormControl>
        </Stack>

        <Stack direction={{ lg: "row" }} gap={2}>
          <FormControl fullWidth error={!!errors.downPaymentPercent}>
            <FormLabel id="type-label">เปอร์เซ็นต์เงินดาวน์</FormLabel>
            <Controller
              name="downPaymentPercent"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Select
                  {...field}
                  labelId="type-label"
                  id="type"
                  size="small"
                  variant="outlined"
                  error={!!errors?.downPaymentPercent}
                  displayEmpty
                  value={value || ""}
                  renderValue={(selected) => {
                    return renderSelectPlaceholder({
                      selected: selected && selected + "%",
                      placeholder: "กรุณาเลือกเปอร์เซ็นต์เงินดาวน์",
                      color: theme.palette.grey[500],
                    });
                  }}
                  {...disableTextFieldProps(true)}
                >
                  {shopGroup?.downPaymentAmountList &&
                  shopGroup?.downPaymentAmountList.length > 0 ? (
                    shopGroup?.downPaymentAmountList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}%
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>ไม่มีข้อมูล</MenuItem>
                  )}
                </Select>
              )}
            />
            {errors?.downPaymentPercent && (
              <FormHelperText error>
                {errors.downPaymentPercent.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth error={!!errors.installmentCount}>
            <FormLabel id="type-label">จำนวนงวด</FormLabel>
            <Controller
              name="installmentCount"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Select
                  {...field}
                  labelId="type-label"
                  id="type"
                  size="small"
                  variant="outlined"
                  error={!!errors?.installmentCount}
                  displayEmpty
                  value={value ? JSON.stringify(value) : ""}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    const selectedObject =
                      shopGroup?.installmentCountList?.find(
                        (item) => JSON.stringify(item) === selectedValue
                      );
                    setValue("installmentCount", selectedObject);
                  }}
                  renderValue={(selected) => {
                    const selectedObject =
                      shopGroup?.installmentCountList?.find(
                        (item) => JSON.stringify(item) === selected
                      );

                    return renderSelectPlaceholder({
                      selected:
                        selectedObject && `${selectedObject.monthAmount} เดือน`,
                      placeholder: "กรุณาเลือกจำนวนงวด",
                      color: theme.palette.grey[500],
                    });
                  }}
                  {...disableTextFieldProps(true)}
                >
                  {shopGroup?.installmentCountList &&
                  shopGroup?.installmentCountList.length > 0 ? (
                    shopGroup?.installmentCountList.map((item, index) => (
                      <MenuItem key={index} value={JSON.stringify(item)}>
                        {item.monthAmount} เดือน
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>ไม่มีข้อมูล</MenuItem>
                  )}
                </Select>
              )}
            />
            {errors?.installmentCount && (
              <FormHelperText error>
                {errors.installmentCount.message ||
                  errors.installmentCount.monthAmount?.message ||
                  errors.installmentCount.interestRate?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Stack>

        <Stack direction={{ lg: "row" }} gap={2}>
          <FormControl fullWidth error={!!errors.installmentAmount}>
            <FormLabel>ยอดเงินผ่อนต่องวด</FormLabel>
            <Controller
              name="installmentAmount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.installmentAmount}
                  helperText={errors?.installmentAmount?.message}
                  size="small"
                  value={field.value ? Number(field.value).toFixed(2) : "0.00"}
                  {...disableTextFieldProps(true)}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>เงินดาวน์</FormLabel>
            <TextField
              type="number"
              value={downPaymentAmountValue.toFixed(2)}
              size="small"
              variant="outlined"
              {...disableTextFieldProps(true)}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>ราคาเช่าซื้อรวม</FormLabel>
            <TextField
              type="number"
              value={totalPrice?.toFixed(2)}
              size="small"
              variant="outlined"
              {...disableTextFieldProps(true)}
            />
          </FormControl>
        </Stack>
        <Stack direction={{ lg: "row" }} gap={2}>
          <FormControl fullWidth>
            <FormLabel>วันที่จ่ายเงินดาวน์ (วัน/เดือน/ปี ค.ศ.)</FormLabel>
            <Controller
              control={control}
              name="downPaymentDate"
              render={({ field }) => (
                <DatePicker
                  format="DD/MM/YYYY"
                  value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                  onChange={(date) =>
                    field.onChange(date ? date.format("YYYY-MM-DD") : "")
                  }
                  slotProps={{
                    textField: { size: "small", variant: "outlined" },
                  }}
                  {...disableTextFieldProps(true)}
                />
              )}
            />
            {errors.downPaymentDate && (
              <FormHelperText error>
                {errors.downPaymentDate.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
              }}
            >
              วันที่เริ่มผ่อนงวดแรก (วัน/เดือน/ปี ค.ศ.)
            </FormLabel>
            <Controller
              control={control}
              name="installmentStartDate"
              render={({ field }) => (
                <DatePicker
                  format="DD/MM/YYYY"
                  value={field.value ? dayjs(field.value) : null} // Convert to Dayjs
                  onChange={(date) =>
                    field.onChange(date ? date.format("YYYY-MM-DD") : "")
                  }
                  slotProps={{
                    textField: { size: "small", variant: "outlined" },
                  }}
                  {...disableTextFieldProps(true)}
                />
              )}
            />
            {errors.installmentStartDate && (
              <FormHelperText error>
                {errors.installmentStartDate.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>ระยะเวลาชำระต่องวดทุกๆ</FormLabel>
            <FormControl fullWidth>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={`${watch("installmentFrequency")},${watch(
                  "installmentFrequencyType"
                )}`}
                onChange={(e) => {
                  const value = (e.target.value as string).split(",");
                  setValue("installmentFrequency", parseInt(value[0]));
                  setValue(
                    "installmentFrequencyType",
                    value[1] as StepUpdateLoanDraftDeviceAndLoanApprovalRequest.installmentFrequencyType
                  );
                }}
                {...disableTextFieldProps(true)}
              >
                <MenuItem value={"7,DAY"}>7 วัน</MenuItem>
                <MenuItem value={"15,DAY"}>15 วัน</MenuItem>
                <MenuItem value={"30,DAY"}>30 วัน</MenuItem>
                <MenuItem value={"1,MONTH"}>1 เดือน</MenuItem>
              </Select>
            </FormControl>
          </FormControl>
        </Stack>
      </Stack>
      <Stack gap={2} mt={3}>
        <Typography variant="h6">ข้อมูลเครื่อง</Typography>
        <Controller
          control={control}
          name="usedType"
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel
                value={ProductDto.usedType.NEW}
                label="สินค้าใหม่"
                control={<Radio />}
                disabled={true}
              />
              <FormControlLabel
                value={ProductDto.usedType.USED}
                label="สินค้าใช้แล้ว"
                control={<Radio />}
                disabled={true}
              />
            </RadioGroup>
          )}
        />
        {mode === "combobox" ? (
          <FormControl required>
            <TogglableLabel
              toggleFormModeText="เลือกจากรายการ"
              onModeChanged={onModeChanged}
              label="แบรนด์และรุ่น"
              disable={true}
            />

            <div
              style={{
                display: "flex",
                gap: "16px",
              }}
            >
              <SearchableBrandField
                label="แบรนด์"
                onSelected={(brand) => {
                  setValue("brand", brand);
                }}
                control={control}
                error={errors.brand?.message}
                mode={SearchableMode.COMBOBOX}
                disabled={true}
              />
              <SearchableModelField
                label="รุ่น"
                onSelected={(model) => {
                  setValue("model", model);
                }}
                brand={watch("brand")!}
                mode={SearchableMode.COMBOBOX}
                control={control}
                error={errors.model?.message}
                disabled={!watch("brand") || true}
              />
            </div>
          </FormControl>
        ) : (
          <>
            <FormControl fullWidth error={!!errors.brand}>
              <TogglableLabel
                toggleFormModeText="รุ่นอื่นๆโปรดระบุ"
                onModeChanged={onModeChanged}
                label="แบรนด์และรุ่น"
                disable={true}
              />
              <Controller
                name="brand"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="แบรนด์"
                    error={!!errors?.brand}
                    helperText={errors?.brand?.message}
                    size="small"
                    {...disableTextFieldProps(true)}
                  />
                )}
              />
            </FormControl>
            <Stack direction="row" gap={2}>
              <FormControl fullWidth error={!!errors.model}>
                <Controller
                  name="model"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="รุ่น"
                      error={!!errors?.model}
                      helperText={errors?.model?.message}
                      size="small"
                      {...disableTextFieldProps(true)}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth error={!!errors.color}>
                <Controller
                  name="color"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="สี"
                      error={!!errors?.color}
                      helperText={errors?.color?.message}
                      size="small"
                      {...disableTextFieldProps(true)}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </>
        )}

        <FormControl fullWidth error={!!errors.description}>
          <FormLabel>รายละเอียด (อุปกรณ์เสริม, อื่นๆ)</FormLabel>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.description}
                helperText={errors?.description?.message}
                size="small"
                {...disableTextFieldProps(true)}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth error={!!errors.deviceImei}>
          <FormLabel required>อีมี่ (IMEI)</FormLabel>
          <Controller
            name="deviceImei"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.deviceImei}
                helperText={errors?.deviceImei?.message}
                size="small"
                {...disableTextFieldProps(true)}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth error={!!errors.deviceSerialNumber}>
          <FormLabel required>Serial Number</FormLabel>
          <Controller
            name="deviceSerialNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.deviceSerialNumber}
                helperText={errors?.deviceSerialNumber?.message}
                size="small"
                {...disableTextFieldProps(true)}
              />
            )}
          />
        </FormControl>

        <FileUploadWithPreview
          title="รูปสินค้า"
          document={fileUploads?.document}
          onChange={() => {}}
          onRemove={() => {}}
          disableDelete
          disableUpload
        />

        <FormControl fullWidth error={!!errors.note}>
          <FormLabel>หมายเหตุ</FormLabel>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                error={!!errors?.note}
                helperText={errors?.note?.message}
                size="small"
                {...disableTextFieldProps(true)}
              />
            )}
          />
        </FormControl>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={3} sx={{ mt: 3 }}>
        <Button
          fullWidth
          variant="outlined"
          type="button"
          size="large"
          disabled
          sx={{ width: { xs: "100%", md: "200px" } }}
        >
          ย้อนกลับ
        </Button>
      </Stack>
    </form>
  );
};

export default ContractApproval;
