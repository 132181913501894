import { Box, Button } from "@mui/material";
import CustomInputLabel from "../CustomInputLabel";

interface IProps {
  toggleFormModeText: string;
  onModeChanged: () => void;
  label: string;
  disable?: boolean;
}

export const TogglableLabel: React.FC<IProps> = ({
  toggleFormModeText,
  onModeChanged,
  label,
  disable,
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 1,
      }}
    >
      <CustomInputLabel>{label}</CustomInputLabel>
      <Button variant="contained" onClick={onModeChanged} disabled={disable}>
        {toggleFormModeText}
      </Button>
    </Box>
  );
};
