/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductVariantRequest } from './ProductVariantRequest';
export type CreateShopProductsVariantRequest = {
    name: string;
    description?: string;
    sku?: string;
    documentRefId?: string;
    representativeImageUrl?: string;
    type: CreateShopProductsVariantRequest.type;
    optionName1?: string;
    optionName2?: string;
    optionValue1?: Array<string>;
    optionValue2?: Array<string>;
    productVariants: Array<ProductVariantRequest>;
    note?: string;
    brand?: string;
    shopId: string;
    categoryId: string;
    isSecondhand?: boolean;
    isActive?: boolean;
};
export namespace CreateShopProductsVariantRequest {
    export enum type {
        GENERAL = 'GENERAL',
        MOBILE = 'MOBILE',
    }
}

