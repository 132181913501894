import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { InstallmentPlanDto } from "../../../client/motalvip-apis/generated";
import { useLoansQuery } from "../../../client/query-client/useLoansQuery";
import { LoanStatusChip } from "../../../component/display/LoanStatusChip";
import { useShopContext } from "../../../context/ShopContextProvider";
import { downloadFile } from "../../../util/FileUtils";
import { useGenerateInstallmentPlansCsvMutation } from "../../../client/query-client/useGenerateInstallmentPlansCsvReportMutation";

export const loanStatusoptions = [
  { value: InstallmentPlanDto.status.NORMAL, display: "กำลังผ่อน" },
  { value: InstallmentPlanDto.status.CLOSED, display: "ปิดสินเชื่อ" },
  { value: InstallmentPlanDto.status.REFUND, display: "คืนเครื่อง" },
  { value: InstallmentPlanDto.status.FOLLOWING_UP, display: "ติดตามเครื่อง" },
  {
    value: InstallmentPlanDto.status.CANNOT_CONTACT_CUSTOMER,
    display: "ติดต่อไม่ได้",
  },
  { value: InstallmentPlanDto.status.NON_PERFORMING, display: "หนี้เสีย" },
  { value: InstallmentPlanDto.status.REPORTED, display: "แจ้งความ" },
  { value: InstallmentPlanDto.status.CANCEL, display: "ยกเลิก" },
];



export const InstallmentPlanListScreen = () => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [daysFrom, setDaysFrom] = useState<number | undefined>(undefined);
  const [daysTo, setDaysTo] = useState<number | undefined>(undefined);
  const [transactionDateFrom, setTransactionDateFrom] = useState<
    string | undefined
  >(searchParams.get("transactionDateFrom") || undefined);
  const [transactionDateTo, setTransactionDateTo] = useState<
    string | undefined
  >(searchParams.get("transactionDateTo") || undefined);
  const [isAppointmentSelected, setIsAppointmentSelected] = useState(
    searchParams.get("isAppointment") === "true"
  );
  const [isNeedCostSelected, setNeedCostSelected] = useState(
    searchParams.get("needCost") === "true"
  );

  const [filterStatus, setFilterStatus] = useState<
    { value: InstallmentPlanDto.status; display: string }[]
  >(
    loanStatusoptions.filter((o) =>
      searchParams.getAll("status").includes(o.value)
    )
  );
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

  const location = useLocation();
  const { shopId } = useParams();
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );
  const [filter, setFilter] = useState<string>(
    searchParams.get("filter") || "ALL"
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: searchParams.get("sorting") || "sequenceNumber",
      desc: searchParams.get("sort") === "desc",
    },
  ]);
  const shopCtx = useShopContext();
  const loansQuery = useLoansQuery({
    shopId: shopId || "",
    search: search.length > 3 ? search : "",
    status:
      filterStatus.length > 0 ? filterStatus.map((o) => o.value) : undefined,
    scheduledDaysFrom: daysFrom,
    scheduledDaysTo: daysTo,
    appointmentDaysFrom: daysFrom,
    appointmentDaysTo: daysTo,
    page: pagination.pageIndex + 1,
    transactionDateFrom,
    transactionDateTo,
    isAppointment: isAppointmentSelected,
    needCost: isNeedCostSelected,
    limit: pagination.pageSize,
    sortBy: sorting.length > 0 ? sorting[0].id : undefined,
    sort: sorting.length > 0 ? (sorting[0].desc ? "desc" : "asc") : undefined,
  });

  const { mutateAsync: generateCsvReportAsync } = useGenerateInstallmentPlansCsvMutation(shopId || '');

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (search.length > 3) searchParams.set("search", search);
    searchParams.set("page", pagination.pageIndex.toString());
    searchParams.set("limit", pagination.pageSize.toString());
    // filterStatus.forEach(o => searchParams.append('status', o.value));
    if (sorting.length > 0) {
      searchParams.set("sorting", sorting[0].id);
      searchParams.set("sort", sorting[0].desc ? "desc" : "asc");
    }
    if (transactionDateFrom)
      searchParams.set("transactionDateFrom", transactionDateFrom);
    if (transactionDateTo)
      searchParams.set("transactionDateTo", transactionDateTo);
    if (filterStatus.length > 0)
      filterStatus.forEach((o) => searchParams.append("status", o.value));
    if (isAppointmentSelected)
      searchParams.set("isAppointment", isAppointmentSelected.toString());
    if (isNeedCostSelected)
      searchParams.set("needCost", isNeedCostSelected.toString());
    searchParams.set("filter", filter);
    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    pagination,
    sorting,
    setSearchParams,
    filterStatus,
    transactionDateFrom,
    transactionDateTo,
    filter,
    isAppointmentSelected,
    isNeedCostSelected,
  ]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFilter(value);
  };
  useEffect(() => {
    switch (filter) {
      case "ALL":
        setDaysFrom(undefined);
        setDaysTo(undefined);
        break;
      case "Upcoming 7 days":
        setDaysFrom(0);
        setDaysTo(7);
        break;
      case "Upcoming 3 days":
        setDaysFrom(0);
        setDaysTo(3);
        break;
      case "Payment Due":
        setDaysFrom(0);
        setDaysTo(0);
        break;
      case "Overdue 0-10 days":
        setDaysFrom(-10);
        setDaysTo(-1);
        break;
      case "Overdue 10-30 days":
        setDaysFrom(-30);
        setDaysTo(-10);
        break;
      case "Overdue more than 30 days":
        setDaysFrom(-999999);
        setDaysTo(-30);
        break;
      case "Overdue":
        setDaysFrom(-999999);
        setDaysTo(-1);
        break;
      default:
        break;
    }
  }, [filter, setDaysFrom, setDaysTo]);

  const handleGenerateCsv = async () => {
    setIsGeneratingCsv(true);
    try {
      const result = await generateCsvReportAsync();
      downloadFile(result.csvReportUrl, "installment-plans-report.csv");
    } finally {
      setIsGeneratingCsv(false);
    }
  };

  useEffect(() => {
    loansQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const columns = useMemo<MRT_ColumnDef<InstallmentPlanDto>[]>(
    () => [
      {
        id: "sequenceNumber",
        accessorFn: (row: InstallmentPlanDto) => (
          <Typography>{row?.sequenceNumber}</Typography>
        ), //simple recommended way to define a column
        header: "ลำดับเคส",
        enableHiding: false,
        enablePinning: false,
        enableSorting: true,
        size: 80,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },

      {
        id: "referenceId",
        accessorFn: (row: InstallmentPlanDto) => (
          <Typography>{row?.referenceId}</Typography>
        ), //simple recommended way to define a column
        header: "หมายเลขเคส",
        enableHiding: false,
        enablePinning: false,
        grow: false,
        enableSorting: true,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "downPaymentDate",
        accessorFn: (row: InstallmentPlanDto) => {
          return dayjs(row?.downPaymentDate).format("DD/MM/YYYY");
        }, //simple recommended way to define a column
        header: "วันที่จ่ายเงินดาวน์",
        enableHiding: false,
        enablePinning: false,
        enableSorting: true,
        size: 100,
      },

      {
        accessorFn: (row: InstallmentPlanDto) =>
          `${row?.profile?.firstName} ${row?.profile?.lastName}`, //simple recommended way to define a column
        header: "ชื่อ-นามสกุล",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
      },
      {
        id: "activeTermNumber",
        accessorFn: (row: InstallmentPlanDto) => (
          <Typography>{`${row?.activeTermNumber ? row?.activeTermNumber : "-"
            }`}</Typography>
        ), //simple recommended way to define a column
        header: "งวดปัจจุบัน",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 80,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "upcomingPaymentDays",
        accessorFn: (row: InstallmentPlanDto) => (
          <Typography
            color={(row?.activeDueDateDiff || 0) >= 0 ? "#43a047" : "#f44336"}
          >
            {row?.activeTermNumber
              ? row.activeDueDateDiff === 0
                ? `ถึงกำหนด`
                : (row.activeDueDateDiff || 0) > 0
                  ? `อีก ${row?.activeDueDateDiff} วัน`
                  : `เกิน ${-(row?.activeDueDateDiff || 0)} วัน`
              : "-"}
          </Typography>
        ),

        header: "กำหนดจ่าย",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
        size: 120,
      },
      {
        id: "apppointmentDays",
        accessorFn: (row: InstallmentPlanDto) => (
          <Typography color={"#d053ef"}>
            {row?.activeInstallmentPaymentDate
              ? row.appointmentDateDiff === 0
                ? `ถึงกำหนด`
                : (row.appointmentDateDiff || 0) > 0
                  ? `อีก ${row?.appointmentDateDiff} วัน`
                  : `เกิน ${-(row?.appointmentDateDiff || 0)} วัน`
              : "-"}
          </Typography>
        ),

        header: "นัดจ่าย",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
        size: 120,
      },
      {
        id: "apppointmentAmount",
        accessorFn: (row: InstallmentPlanDto) => (
          <Typography>
            {currencyFormat.format(row?.activeInstallmentPaymentAmount || 0)}
          </Typography>
        ),
        header: "ยอดนัดจ่าย",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
        size: 120,
      },
      {
        id: "status",
        accessorFn: (row: InstallmentPlanDto) => (
          <LoanStatusChip status={row?.status} />
        ), //simple recommended way to define a column
        header: "สถานะสินเชื่อ",
        enableHiding: false,
        enableSorting: false,

        enablePinning: false,
      },

      {
        accessorFn: (row: InstallmentPlanDto) =>
          `${row?.profile?.phoneNumbers
            ?.map((e) => `${e.phoneNumber}`)
            ?.join(",")}`, //simple recommended way to define a column
        header: "เบอร์โทร",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
      },
      {
        accessorFn: (row: InstallmentPlanDto) =>
          `${row?.product?.brand} ${row?.product?.model}`, //simple recommended way to define a column
        header: "ข้อมูลเครื่อง",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
      },
      {
        accessorFn: (row: InstallmentPlanDto) => `${row.product?.imei}`, //simple recommended way to define a column
        header: "อีมี่ (IMEI)",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
      },
      {
        accessorFn: (row: InstallmentPlanDto) => `${row.product?.serialNumber}`, //simple recommended way to define a column
        header: "Serial Number",
        enableHiding: false,
        enableSorting: false,
        enablePinning: false,
      },
    ],
    [currencyFormat]
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: loansQuery.data?.content || [],
    layoutMode: "semantic",
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnVirtualization: false,
    enableColumnOrdering: false,
    positionGlobalFilter: "left",
    enableTopToolbar: false,
    manualSorting: true,
    paginationDisplayMode: "pages",
    onSortingChange: setSorting,
    muiPaginationProps: {
      shape: "rounded",
      page: pagination.pageIndex + 1,
      rowsPerPageOptions: [10, 25, 50, 100],
      defaultPage: 0,
      showRowsPerPage: true,
      showFirstButton: false,
    },
    localization: {
      rowsPerPage: "จำนวนต่อหน้า",
    },
    manualPagination: true,
    rowCount: loansQuery.data?.pagination?.totalElements || 1,
    onPaginationChange: setPagination,
    initialState: {
      showGlobalFilter: true,
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: loansQuery.isFetching,
      pagination,
      sorting,
      columnVisibility: { totalProfit: shopCtx.isOwner },
    },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <IconButton
        onClick={() => nav(`${location.pathname}/${row.original?.id}`)}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    ),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 12, //make actions column wider
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0", //add a border between columns
        backgroundColor: "#455a64",
        color: "white",
        fontWeight: "500",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: "grey.100",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #eeeeee", //add a border between columns
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
      <Box p={2}>
        <Typography variant="h5" pb={2}>
          {"รายการติดตามเคส"}
        </Typography>
        <Stack gap={2} sx={{ maxWidth: "90vw" }}>
          <Stack
            direction={{ lg: "row" }}
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              fullWidth
              sx={{ backgroundColor: "white", flex: 2 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              placeholder="ค้นหา หมายเลขเคส, ชื่อ-นามสกุล, เลขบัตร, พาสปอร์ต, เบอร์โทร, IMEI, Serial Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              fullWidth
              value={filter}
              onChange={handleChange}
              variant="outlined"
              sx={{ backgroundColor: "white", height: "56px", flex: 1 }}
            >
              <MenuItem value="ALL">ทั้งหมด</MenuItem>
              <MenuItem value="Upcoming 7 days">ครบกำหนดภายใน 7 วัน</MenuItem>
              <MenuItem value="Upcoming 3 days">ครบกำหนดภายใน 3 วัน</MenuItem>
              <MenuItem value="Payment Due">ครบกำหนดวันนี้</MenuItem>
              <MenuItem value="Overdue 0-10 days">
                เกินกำหนด 0 ถึง 10 วัน
              </MenuItem>
              <MenuItem value="Overdue 10-30 days">
                เกินกำหนด 10 ถึง 30
              </MenuItem>
              <MenuItem value="Overdue more than 30 days">
                เกินกำหนดมากกว่า 30 วัน
              </MenuItem>
              <MenuItem value="Overdue">เกินกำหนดทั้งหมด</MenuItem>
            </Select>
            <Button
              variant="contained"
              onClick={() => nav(`/${shopId}/loan/import`)}
              sx={{ height: "56px", width: { xs: "100%", lg: "20%" } }}
            >
              <AddIcon />
              นำเข้าเคส
            </Button>
            <Button
              sx={{ height: "56px", width: { xs: "100%", lg: "20%" } }}
              size="medium"
              variant="contained"
              onClick={handleGenerateCsv}
            >
              <DownloadIcon />
              {isGeneratingCsv ? "กำลังดาวน์โหลด..." : "ดาวน์โหลดรายงาน CSV"}
            </Button>
          </Stack>
          <Stack direction={"row"} gap={2}>
            <FormControl fullWidth>
              {/*  */}
              <DatePicker
                format="DD/MM/YYYY"
                label="วันที่จ่ายเงินดาวน์ (เริ่มต้น)"
                value={transactionDateFrom ? dayjs(transactionDateFrom) : null} // Convert to Dayjs
                onChange={(date) =>
                  date
                    ? setTransactionDateFrom(date.format("YYYY-MM-DD"))
                    : setTransactionDateFrom(undefined)
                }
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </FormControl>
            <FormControl fullWidth>
              {/* <CustomInputLabel>วันที่</CustomInputLabel> */}
              <DatePicker
                format="DD/MM/YYYY"
                label="วันที่จ่ายเงินดาวน์ (สิ้นสุด)"
                value={transactionDateTo ? dayjs(transactionDateTo) : null} // Convert to Dayjs
                onChange={(date) =>
                  date
                    ? setTransactionDateTo(date.format("YYYY-MM-DD"))
                    : setTransactionDateTo(undefined)
                }
                slotProps={{ textField: { variant: "outlined" } }}
              />
            </FormControl>
            <Autocomplete
              fullWidth
              multiple
              id="selected-status"
              value={filterStatus || []}
              onChange={(event, newValue) => {
                setFilterStatus(newValue);
              }}
              options={loanStatusoptions}
              getOptionLabel={(o) => o?.display || ""}
              renderInput={(params) => (
                <TextField {...params} label="สถานะสินเชื่อ" placeholder="" />
              )}
            />
            <ToggleButton
              fullWidth
              sx={{ height: "56px", width: { xs: "100%", lg: "30%" } }}
              color="secondary"
              value="check"
              selected={isAppointmentSelected}
              onChange={() =>
                setIsAppointmentSelected((prevSelected) => !prevSelected)
              }
            >
              <Stack direction={"row"} gap={1}>
                <Typography>นัดจ่าย</Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton
              fullWidth
              sx={{ height: "56px", width: { xs: "100%", lg: "30%" } }}
              color="secondary"
              value="check"
              selected={isNeedCostSelected}
              onChange={() =>
                setNeedCostSelected((prevSelected) => !prevSelected)
              }
            >
              <Stack direction={"row"} gap={1}>
                <Typography>ยังไม่มีต้นทุน</Typography>
              </Stack>
            </ToggleButton>
            {/* <Button variant='contained' onClick={handleDownloadExcelButton}>Download Excel</Button> */}
          </Stack>
          <Box>
            <Typography>{`จำนวนเคส: ${loansQuery.data?.pagination?.totalElements || "0"
              }`}</Typography>
          </Box>
          <Box sx={{ maxWidth: "100%" }}>
            <MaterialReactTable table={table} />
          </Box>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};
