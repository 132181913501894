/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancierAvatarUploadConfirmRequest } from '../models/FinancierAvatarUploadConfirmRequest';
import type { FinancierAvatarUploadUrlRequest } from '../models/FinancierAvatarUploadUrlRequest';
import type { FinancierCashFlowDto } from '../models/FinancierCashFlowDto';
import type { FinancierDto } from '../models/FinancierDto';
import type { FinancierEmployeeDetailResponse } from '../models/FinancierEmployeeDetailResponse';
import type { FinancierEmployeeDetailUpdateRequest } from '../models/FinancierEmployeeDetailUpdateRequest';
import type { FinancierInvitationShop } from '../models/FinancierInvitationShop';
import type { FinancierPaymentChannelCreateRequest } from '../models/FinancierPaymentChannelCreateRequest';
import type { FinancierPaymentChannelDto } from '../models/FinancierPaymentChannelDto';
import type { FinancierPaymentChannelQrCodeUploadConfirmRequest } from '../models/FinancierPaymentChannelQrCodeUploadConfirmRequest';
import type { FinancierPaymentChannelQrCodeUploadUrlRequest } from '../models/FinancierPaymentChannelQrCodeUploadUrlRequest';
import type { FinancierShopDetailResponse } from '../models/FinancierShopDetailResponse';
import type { FinancierShopGroupCreateRequest } from '../models/FinancierShopGroupCreateRequest';
import type { FinancierShopsGroupDetailResponse } from '../models/FinancierShopsGroupDetailResponse';
import type { FinancierShopsGroupIdRequest } from '../models/FinancierShopsGroupIdRequest';
import type { FinancierShopsGroupResponse } from '../models/FinancierShopsGroupResponse';
import type { FinancierShopUpdateRequest } from '../models/FinancierShopUpdateRequest';
import type { FinancierUserInvitationRequest } from '../models/FinancierUserInvitationRequest';
import type { FinancierUserRemoveRequest } from '../models/FinancierUserRemoveRequest';
import type { FinancierUserResponse } from '../models/FinancierUserResponse';
import type { InstallmentCommissionAddCommission } from '../models/InstallmentCommissionAddCommission';
import type { PaginatedFinancierEmployeeResponse } from '../models/PaginatedFinancierEmployeeResponse';
import type { PaginatedFinancierSearchDto } from '../models/PaginatedFinancierSearchDto';
import type { PaginatedFinancierShopResponse } from '../models/PaginatedFinancierShopResponse';
import type { PaginatedFinancierShopsGroupListResponse } from '../models/PaginatedFinancierShopsGroupListResponse';
import type { ShopDto } from '../models/ShopDto';
import type { ShopPaymentChannelCreateRequest } from '../models/ShopPaymentChannelCreateRequest';
import type { ShopPaymentChannelDto } from '../models/ShopPaymentChannelDto';
import type { ShopPaymentChannelQrCodeUploadConfirmRequest } from '../models/ShopPaymentChannelQrCodeUploadConfirmRequest';
import type { ShopPaymentChannelQrCodeUploadUrlRequest } from '../models/ShopPaymentChannelQrCodeUploadUrlRequest';
import type { ShopResponse } from '../models/ShopResponse';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { UploadUrlResponse } from '../models/UploadUrlResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FinancierService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns FinancierDto OK
     * @throws ApiError
     */
    public updateFinancier({
        requestBody,
    }: {
        requestBody: FinancierDto,
    }): CancelablePromise<FinancierDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/financier',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierEmployeeDetailResponse OK
     * @throws ApiError
     */
    public getFinancierIdEmployee({
        financierId,
        employeeId,
    }: {
        financierId: string,
        employeeId: string,
    }): CancelablePromise<FinancierEmployeeDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/{employeeId}',
            path: {
                'financierId': financierId,
                'employeeId': employeeId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public updateFinancierIdEmployee({
        financierId,
        employeeId,
        requestBody,
    }: {
        financierId: string,
        employeeId: string,
        requestBody: FinancierEmployeeDetailUpdateRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/financier/{financierId}/{employeeId}',
            path: {
                'financierId': financierId,
                'employeeId': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopDetailResponse OK
     * @throws ApiError
     */
    public findShopById({
        financierId,
        shopId,
    }: {
        financierId: string,
        shopId: string,
    }): CancelablePromise<FinancierShopDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/shops/{shopId}',
            path: {
                'financierId': financierId,
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopDetailResponse OK
     * @throws ApiError
     */
    public updateShop1({
        financierId,
        shopId,
        requestBody,
    }: {
        financierId: string,
        shopId: string,
        requestBody: FinancierShopUpdateRequest,
    }): CancelablePromise<FinancierShopDetailResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/financier/{financierId}/shops/{shopId}',
            path: {
                'financierId': financierId,
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopsGroupDetailResponse OK
     * @throws ApiError
     */
    public findFinancierShopGroupById({
        financierId,
        financierShopsGroupId,
    }: {
        financierId: string,
        financierShopsGroupId: string,
    }): CancelablePromise<FinancierShopsGroupDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/financier-shops-group/{financierShopsGroupId}',
            path: {
                'financierId': financierId,
                'financierShopsGroupId': financierShopsGroupId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopsGroupDetailResponse OK
     * @throws ApiError
     */
    public updateFinancierShopGroupById({
        financierId,
        financierShopsGroupId,
        requestBody,
    }: {
        financierId: string,
        financierShopsGroupId: string,
        requestBody: FinancierShopGroupCreateRequest,
    }): CancelablePromise<FinancierShopsGroupDetailResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/financier/{financierId}/financier-shops-group/{financierShopsGroupId}',
            path: {
                'financierId': financierId,
                'financierShopsGroupId': financierShopsGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierCashFlowDto OK
     * @throws ApiError
     */
    public updateCapitalFinancier({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: number,
    }): CancelablePromise<FinancierCashFlowDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/financier/{financierId}/capital',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopsGroupDetailResponse OK
     * @throws ApiError
     */
    public paidCommissionByInstallmentCommissionId({
        requestBody,
    }: {
        requestBody: InstallmentCommissionAddCommission,
    }): CancelablePromise<FinancierShopsGroupDetailResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/financier/loan/paid-installment-commission',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public enableFinancierShopPaymentChannel({
        shopId,
        paymentChannelId,
        financierId,
    }: {
        shopId: string,
        paymentChannelId: string,
        financierId: string,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/{shopId}/payment-channels/{paymentChannelId}/enable',
            path: {
                'shopId': shopId,
                'paymentChannelId': paymentChannelId,
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public disableFinancierShopPaymentChannel({
        shopId,
        paymentChannelId,
        financierId,
    }: {
        shopId: string,
        paymentChannelId: string,
        financierId: string,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/{shopId}/payment-channels/{paymentChannelId}/disable',
            path: {
                'shopId': shopId,
                'paymentChannelId': paymentChannelId,
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopsGroupResponse OK
     * @throws ApiError
     */
    public findFinancierShopsGroupUserManage({
        financierId,
        employeeId,
    }: {
        financierId: string,
        employeeId: string,
    }): CancelablePromise<Array<FinancierShopsGroupResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/users/{employeeId}/financier-shops-group/manage',
            path: {
                'financierId': financierId,
                'employeeId': employeeId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public createFinancierShopsGroupUserManage({
        financierId,
        employeeId,
        requestBody,
    }: {
        financierId: string,
        employeeId: string,
        requestBody: FinancierShopsGroupIdRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/users/{employeeId}/financier-shops-group/manage',
            path: {
                'financierId': financierId,
                'employeeId': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteFinancierShopsGroupUserManage({
        financierId,
        employeeId,
        requestBody,
    }: {
        financierId: string,
        employeeId: string,
        requestBody: FinancierShopsGroupIdRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/financier/{financierId}/users/{employeeId}/financier-shops-group/manage',
            path: {
                'financierId': financierId,
                'employeeId': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getFinancierShopQrCodeUploadUrl({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: ShopPaymentChannelQrCodeUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/shops/payment-channels/qr-code-upload-url',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public getFinancierShopQrCodeUploadUrl1({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: ShopPaymentChannelQrCodeUploadConfirmRequest,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/shops/payment-channels/qr-code-upload-confirm',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public createShopPaymentChannel1({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: ShopPaymentChannelCreateRequest,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/shop/payment-channels',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public financierInvitationShop({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: FinancierInvitationShop,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/shop/invitation',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public enableFinancierPaymentChannel({
        paymentChannelId,
        financierId,
    }: {
        paymentChannelId: string,
        financierId: string,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/payment-channels/{paymentChannelId}/enable',
            path: {
                'paymentChannelId': paymentChannelId,
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public disableFinancierPaymentChannel({
        paymentChannelId,
        financierId,
    }: {
        paymentChannelId: string,
        financierId: string,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/payment-channels/{paymentChannelId}/disable',
            path: {
                'paymentChannelId': paymentChannelId,
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public confirmFinancierQrCodeUpload({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: FinancierPaymentChannelQrCodeUploadConfirmRequest,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/payment-channels/qr-code-upload-confirm',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedFinancierShopsGroupListResponse OK
     * @throws ApiError
     */
    public findFinancierShopGroup({
        financierId,
        search,
        sortBy = 'createdAt',
        sort = 'desc',
        page,
        limit = 10,
    }: {
        financierId: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedFinancierShopsGroupListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/financier-shops-group',
            path: {
                'financierId': financierId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public createFinancierShopGroup({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: FinancierShopGroupCreateRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/{financierId}/financier-shops-group',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierUserResponse OK
     * @throws ApiError
     */
    public createFinancierUserInvitation({
        requestBody,
    }: {
        requestBody: FinancierUserInvitationRequest,
    }): CancelablePromise<FinancierUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/users/invitation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public createFinancierPaymentChannel({
        requestBody,
    }: {
        requestBody: FinancierPaymentChannelCreateRequest,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/payment-channels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getFinancierQrCodeUploadUrl({
        requestBody,
    }: {
        requestBody: FinancierPaymentChannelQrCodeUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/payment-channels/qr-code-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getFinancierAvatarUploadUrl({
        requestBody,
    }: {
        requestBody: FinancierAvatarUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/avatar-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmShopAvatarUpload1({
        requestBody,
    }: {
        requestBody: FinancierAvatarUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/financier/avatar-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierDto OK
     * @throws ApiError
     */
    public getFinancierUsers({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<FinancierDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierUserResponse OK
     * @throws ApiError
     */
    public findEmployeeManageShopGroup({
        financierId,
        financierShopGroupId,
    }: {
        financierId: string,
        financierShopGroupId: string,
    }): CancelablePromise<Array<FinancierUserResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/{financierShopGroupId}/employee-manage-shops-group',
            path: {
                'financierId': financierId,
                'financierShopGroupId': financierShopGroupId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedFinancierEmployeeResponse OK
     * @throws ApiError
     */
    public getFinancierIdEmployee1({
        financierId,
        sortBy = 'role',
        sort = 'desc',
        page,
        limit = 10,
    }: {
        financierId: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedFinancierEmployeeResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/users',
            path: {
                'financierId': financierId,
            },
            query: {
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopsGroupResponse OK
     * @throws ApiError
     */
    public findFinancierShopsGroupUserNotManage({
        financierId,
        employeeId,
    }: {
        financierId: string,
        employeeId: string,
    }): CancelablePromise<Array<FinancierShopsGroupResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/users/{employeeId}/financier-shops-group/not-manage',
            path: {
                'financierId': financierId,
                'employeeId': employeeId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierUserResponse OK
     * @throws ApiError
     */
    public findUserShopAgent({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<Array<FinancierUserResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/user-shops-agent',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedFinancierShopResponse OK
     * @throws ApiError
     */
    public findShops({
        financierId,
        search,
        sortBy = 'createdAt',
        sort = 'desc',
        page,
        limit = 10,
    }: {
        financierId: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedFinancierShopResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/shops',
            path: {
                'financierId': financierId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public findShopPaymentChannel({
        financierId,
        shopId,
    }: {
        financierId: string,
        shopId: string,
    }): CancelablePromise<Array<ShopPaymentChannelDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/shops/{shopId}/payment-channels',
            path: {
                'financierId': financierId,
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public getFinancierPaymentChannels({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<Array<FinancierPaymentChannelDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/payment-channels',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopResponse OK
     * @throws ApiError
     */
    public findShopByFinancierShopGroupById({
        financierId,
        financierShopsGroupId,
    }: {
        financierId: string,
        financierShopsGroupId: string,
    }): CancelablePromise<Array<ShopResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/financier-shops-group/{financierShopsGroupId}/shops',
            path: {
                'financierId': financierId,
                'financierShopsGroupId': financierShopsGroupId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierCashFlowDto OK
     * @throws ApiError
     */
    public getFinancierCashFlow({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<FinancierCashFlowDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/{financierId}/cash-flow',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierShopsGroupDetailResponse OK
     * @throws ApiError
     */
    public getFinancierShopsGroupByShopId({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<FinancierShopsGroupDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/shops/{shopId}/financier-shops-group',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedFinancierSearchDto OK
     * @throws ApiError
     */
    public getFinancierUsers1({
        search,
        sortBy = 'createdAt',
        sort = 'desc',
        page,
        limit = 10,
    }: {
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedFinancierSearchDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/financier/search',
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public removeFinancierShopGroup({
        financierId,
        financierShopGroupId,
    }: {
        financierId: string,
        financierShopGroupId: string,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/financier/{financierId}/financier-shops-group/{financierShopGroupId}',
            path: {
                'financierId': financierId,
                'financierShopGroupId': financierShopGroupId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public removeFinancierUser({
        requestBody,
    }: {
        requestBody: FinancierUserRemoveRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/financier/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
