import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { QueryKey } from ".";
import { CreateShopProductsRequest, CreateShopProductsVariantRequest, ShopProductsService } from "../motalvip-apis/generated";
import toast from "react-hot-toast";

export const useProductsQuery = (params: Parameters<ShopProductsService['getAllShopProducts']>[0]) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.PRODUCT, 'products', params.shopId],
        queryFn: () => backendApiClient.shopProducts.getAllShopProducts(params)
    });
};

export const useProductQuery = (params: Parameters<ShopProductsService['getShopProductById']>[0]) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.PRODUCT, 'product', params.shopId, params.productId],
        queryFn: () => backendApiClient.shopProducts.getShopProductById(params)
    });
};


export const useProductVariantCreateMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.PRODUCT, 'create-variant'],
        mutationFn: (req: CreateShopProductsVariantRequest) =>
            apiClient.shopProducts.createShopProductVariant({
                requestBody: req,
            }),
        onSuccess: () => {
            toast.success("สร้างสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.PRODUCT, 'products'], type: 'active' })
        },
        onError: () => {
            toast.error("สร้างล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

export const useProductCreateMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.PRODUCT, 'create'],
        mutationFn: (req: CreateShopProductsRequest) =>
            apiClient.shopProducts.createShopProduct({
                requestBody: req,
            }),
        onSuccess: () => {
            toast.success("สร้างสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.PRODUCT, 'products'], type: 'active' })
        },
        onError: () => {
            toast.error("สร้างล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

interface ProductVariantEditMutationParams {
    productId: number
    req: CreateShopProductsVariantRequest
}

export const useProductVariantEditMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.PRODUCT, 'edit'],
        mutationFn: ({ productId, req }: ProductVariantEditMutationParams) =>
            apiClient.shopProducts.editShopProductVariant({
                productId,
                requestBody: req,
            }),
        onSuccess: () => {
            toast.success("แก้ไขสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.PRODUCT, 'product'], type: 'active' })
        },
        onError: () => {
            toast.error("แก้ไขล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

interface ProductEditMutationParams {
    productId: number
    req: CreateShopProductsRequest
}

export const useProductEditMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.PRODUCT, 'edit'],
        mutationFn: ({ productId, req }: ProductEditMutationParams) =>
            apiClient.shopProducts.editShopProductSingle({
                productId,
                requestBody: req,
            }),
        onSuccess: () => {
            toast.success("แก้ไขสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.PRODUCT, 'product'], type: 'active' })
        },
        onError: () => {
            toast.error("แก้ไขล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

interface DeleteParams {
    shopId: string
    productId: number
}

export const useProductDeleteMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.PRODUCT, 'delete'],
        mutationFn: ({ shopId, productId }: DeleteParams) =>
            apiClient.shopProducts.deleteShopProductById({
                shopId,
                productId
            }),
        onSuccess: () => {
            toast.success("ลบสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.PRODUCT, 'products'], type: 'active' })
        },
        onError: () => {
            toast.error("ลบล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

interface EditDisplayParams {
    shopId: string
    productId: number
    isDisplay: boolean
}

export const useProductEditDisplayMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.PRODUCT, 'toggle-display'],
        mutationFn: ({ shopId, productId, isDisplay }: EditDisplayParams) =>
            apiClient.shopProducts.editDisplayShopProduct({
                productId,
                requestBody: {
                    shopId,
                    isDisplay
                }
            }),
        onSuccess: () => {
            toast.success("เปลี่ยนสถานะการแสดงสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.PRODUCT, 'products'], type: 'active' })
        },
        onError: () => {
            toast.error("แก้ไข status ล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};
