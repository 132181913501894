import { InstallmentPaymentReportDto } from "../../../../../client/motalvip-apis/generated";

export function mapChannelToText(
  channel: InstallmentPaymentReportDto.shopPaymentChannel
) {
  const map: Record<InstallmentPaymentReportDto.shopPaymentChannel, string> = {
    AUTOMATIC_QR: "Payment Gateway",
    BANK_ACCOUNT: "บัญชีธนาคาร",
    CASH: "เงินสด",
    PROMPT_PAY: "พร้อมเพย์",
    PROMPT_PAY_QR: "พร้อมเพย์ QR",
    TRUE_WALLET: "ทรูวอลเล็ต",
  };

  return map[channel];
}
