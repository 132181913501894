import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { QueryKey } from ".";
import { CategoryService, CreateCategoryRequest } from "../motalvip-apis/generated";
import toast from "react-hot-toast";

export const useCategoriesQuery = (shopId: string) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.CATEGORY, 'categories', shopId],
        queryFn: () => backendApiClient.category.getCategories({ shopId: shopId! })
    });
};

export const useCategoriesSearchQuery = (params: Parameters<CategoryService['getCategoriesSearch']>[0]) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.CATEGORY, 'categories-search', params.shopId],
        queryFn: () => backendApiClient.category.getCategoriesSearch(params)
    });
};

export const useCategoryCreateMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.CATEGORY, 'create'],
        mutationFn: (req: CreateCategoryRequest) =>
            apiClient.category.createCategory({
                requestBody: req,
            }),
        onSuccess: () => {
            toast.success("สร้างสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.CATEGORY, 'categories-search'], type: 'active' })
        },
        onError: () => {
            toast.error("สร้างล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

interface DeleteParams {
    categoryId: string
    shopId: string
}

export const useCategoryDeleteMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.CATEGORY, 'delete'],
        mutationFn: ({ categoryId, shopId }: DeleteParams) =>
            apiClient.category.deleteCategory({
                categoryId,
                shopId,
            }),
        onSuccess: () => {
            toast.success("ลบสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.CATEGORY, 'categories-search'], type: 'active' })
        },
        onError: () => {
            toast.error("ลบล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};

interface EditParams {
    categoryId: string
    shopId: string
    name: string
}

export const useCategoryEditMutation = () => {
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [QueryKey.CATEGORY, 'edit'],
        mutationFn: ({ categoryId, shopId, name }: EditParams) =>
            apiClient.category.editCategory({
                categoryId,
                requestBody: {
                    name,
                    shopId
                }
            }),
        onSuccess: () => {
            toast.success("แก้ไขสำเร็จ");
            queryClient.refetchQueries({ queryKey: [QueryKey.CATEGORY, 'categories-search'], type: 'active' })
        },
        onError: () => {
            toast.error("แก้ไขล้มเหลว กรุณาลองใหม่อีกครั้ง");
        }
    });
};
