/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateShopProductsRequest = {
    productId?: number;
    name: string;
    description?: string;
    sku: string;
    documentRefId?: string;
    type: CreateShopProductsRequest.type;
    price: number;
    note?: string;
    brand?: string;
    shopId: string;
    categoryId: string;
    isSecondhand?: boolean;
    isActive?: boolean;
};
export namespace CreateShopProductsRequest {
    export enum type {
        GENERAL = 'GENERAL',
        MOBILE = 'MOBILE',
    }
}

