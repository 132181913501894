/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetShopProductsResponse = {
    productId?: number;
    productName?: string;
    productPrice?: number;
    imageUrl?: string;
    type?: GetShopProductsResponse.type;
    isSecondhand?: boolean;
    categoryName?: string;
    skuCount?: number;
    isDisplay?: boolean;
    createdAt?: string;
};
export namespace GetShopProductsResponse {
    export enum type {
        GENERAL = 'GENERAL',
        MOBILE = 'MOBILE',
    }
}

