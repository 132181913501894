import { Switch } from "@mui/material"
import { GetShopProductsResponse } from "../../client/motalvip-apis/generated"
import { useProductEditDisplayMutation } from "../../client/query-client/useProductQuery"
import { useParams } from "react-router-dom"

interface Props {
    product: GetShopProductsResponse
}

export const ProductDisplayToggle = ({ product }: Props) => {
    const { shopId } = useParams()

    const { mutate: editDisplay, isPending } = useProductEditDisplayMutation()

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, isDisplay: boolean) => {
        event.stopPropagation()
        editDisplay({ productId: product.productId!, isDisplay, shopId: shopId! })
    }

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation()
    }

    return <Switch checked={product.isDisplay} onClick={handleClick} onChange={handleSwitchChange} disabled={isPending} />
}
