import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDialog } from "../../../context/DialogContext";
import { useBackendApiClient } from "../../motalvip-apis";
import { DownSavingCancelRequest, DownSavingResponse } from "../../motalvip-apis/generated";


export const useDownSavingCancelMutation = () => {
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: ['downSaving'],
        mutationFn: (req: DownSavingCancelRequest) =>
            backendApiClient.downSaving.cancelDownSaving({
                requestBody: req,
            }),
        onSuccess: (res: DownSavingResponse) => {
            queryClient.invalidateQueries({ queryKey: [res.id] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
        retry: 0,
    });
}