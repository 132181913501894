import {
  DeleteForeverRounded,
  HourglassBottomRounded,
} from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";
import { Fragment } from "react/jsx-runtime";
import {
  InstallmentNote,
  InstallmentPaymentDto,
  InstallmentPlanDto,
  InstallmentTerm,
  ShopPaymentChannelDto,
} from "../../../../client/motalvip-apis/generated";
import { useDeleteInstallmentPlanNote } from "../../../../client/query-client";
import { usePaymentChannelQuery } from "../../../../client/query-client/usePaymentChannelQuery";
import { useDialog } from "../../../../context/DialogContext";
import { animations } from "../../../../util/Animations";
import { formatLongReadableThaiDate } from "../../../../util/DateUtil";
import { formatOptionalFullName } from "../../../../util/NameUtil";
import { CustomDataField } from "../../../common/CustomDataField";
import { InstallmentQRPaymentCreateDialog } from "../../dialog/InstallmentCreateQRPaymentDialog";
import { InstallmentPlanCostDialog } from "../../dialog/InstallmentPlanCostDialog";
import { InstallmentPaymentCreateDialog } from "../../InstallmentPaymentCreateDialog";
import InstallmentPlanInfoCard from "../../InstallmentPlanInfoCard";
import { InstallmentPlanNoteDialog } from "../../InstallmentPlanNoteDialog";
import InstallmentTermInfoCard from "../../InstallmentTermInfoCard";

export function LoanOverview({
  installmentPlanQuery,
  installmentPlanId,
  shopId,
  activeInstallmentTerm,
}: {
  installmentPlanQuery?: InstallmentPlanDto;
  shopId?: string;
  installmentPlanId?: string;
  activeInstallmentTerm?: InstallmentTerm;
}) {
  // Payment Dialog
  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [defaultInstallmentPaymentDto, setDefaultInstallmentPaymentDto] =
    useState<InstallmentPaymentDto>();
  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const dialog = useDialog();
  const [noteIdPendingDeletion, setNoteIdPendingDeletion] = useState<string>();
  const deleteNote = useDeleteInstallmentPlanNote(installmentPlanId);
  const onDeleteNoteClicked = async (note: InstallmentNote) => {
    const confirm = await new Promise<boolean>((resolve) =>
      dialog.showDialog({
        title: "คุณต้องการลบโน้ตนี้ใช่หรือไม่",
        description: "ลบโน้ต",
        onConfirm: async () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      })
    );

    if (!confirm) {
      return;
    }

    try {
      setNoteIdPendingDeletion(note.id);
      await deleteNote.mutateAsync({ noteId: note.id! });
      toast.success("ลบโน้ตสำเร็จ");
    } catch (e) {
      console.error(e);
      toast.error("เกิดข้อผิดพลาด");
    }
    setNoteIdPendingDeletion(undefined);
  };

  const paymentChannelsQuery = usePaymentChannelQuery(shopId || "");

  // Installment Plan Note Dialog
  const [openInstallmentPlanNoteDialog, setOpenInstallmentPlanNoteDialog] =
    useState<boolean>(false);

  // Installment Plan Cost Dialog
  const [openInstallmentPlanCostDialog, setOpenInstallmentPlanCostDialog] =
    useState<boolean>(false);

  const [openGenerateQrDialog, setOpenGenerateQrDialog] = useState(false);

  return (
    <Fragment>
      <Box sx={{ bgcolor: "white", width: "100%", p: 2 }}>
        <Stack direction={{ md: "column", lg: "row" }} gap={2}>
          <Card sx={{ width: "100%", border: "2px solid #eeeeee", p: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h5">ลูกค้า</Typography>
                <Stack gap={2}>
                  <Typography
                    variant="h4"
                    sx={{ color: "#0b57d0" }}
                  >{`${installmentPlanQuery?.profile?.firstName} ${installmentPlanQuery?.profile?.lastName}`}</Typography>
                  <Stack>
                    <Typography variant="h6">{`${installmentPlanQuery?.product.brand} ${installmentPlanQuery?.product.model}`}</Typography>
                    <Typography>{`เบอร์โทรศัพท์: ${
                      installmentPlanQuery?.profile?.phoneNumbers
                        ?.filter((p) => p.phoneNumber)
                        .map((e) => e.phoneNumber)
                        .join(", ") || "-"
                    }`}</Typography>
                    <Typography>
                      หน้า Facebook:{" "}
                      <Link
                        href={installmentPlanQuery?.profile?.facebookUrl || ""}
                        target="_blank"
                      >
                        {installmentPlanQuery?.profile?.facebookUrl || "-"}
                      </Link>
                    </Typography>
                    <Typography>{`ไอดี LINE ${
                      installmentPlanQuery?.profile?.lineId || "-"
                    }`}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              {installmentPlanQuery?.activeInstallmentPaymentDate && (
                <Stack direction="column" alignItems="center" gap={1}>
                  <Chip
                    label={"นัดจ่าย"}
                    sx={{
                      width: 180,
                      border: "3px solid #9500ba",
                      backgroundColor: "white",
                      color: "#9500ba",
                    }}
                  />
                  <Box
                    sx={{
                      width: 180,
                      py: 1,
                      px: 2,
                      border: "1px solid #dddddd",
                      borderRadius: 2,
                    }}
                  >
                    <Stack direction={"row"} gap={1}>
                      <Typography>วันที่</Typography>
                      <Typography color="secondary">{`${dayjs(
                        installmentPlanQuery?.activeInstallmentPaymentDate
                      ).format("YYYY-MM-DD")}`}</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1}>
                      <Typography>ยอด</Typography>
                      <Typography color="secondary">{`${currencyFormat.format(
                        installmentPlanQuery?.activeInstallmentPaymentAmount ||
                          0
                      )}`}</Typography>
                    </Stack>
                  </Box>
                </Stack>
              )}
            </Stack>
          </Card>
          <Card sx={{ width: "100%", border: "2px solid #eeeeee", p: 2 }}>
            <Stack direction={"column"} gap={1}>
              <Stack direction={{ md: "column", lg: "row" }} gap={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ alignSelf: "center", height: "56px" }}
                  onClick={() => {
                    setDialogTitle("ปิดยอดสินเชื่อ");
                    setDefaultInstallmentPaymentDto({
                      shopId: shopId,
                      installmentPlanId: installmentPlanId,
                      appointmentAt: dayjs().format("YYYY-MM-DD"),
                      transactionAt: dayjs().format("YYYY-MM-DD"),
                      status: InstallmentPaymentDto.status.COMPLETED,
                      type: InstallmentPaymentDto.type.REPAYMENT_FULL,
                      paidDueAmount: 0,
                      paidFineAmount: 0,
                      installmentTermNumber:
                        installmentPlanQuery?.activeTermNumber,
                      shopPaymentChannelId: "",
                      documents: [],
                    });
                    setOpenPaymentDialog(true);
                  }}
                >
                  ปิดยอด
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ alignSelf: "center", height: "56px" }}
                  onClick={() => {
                    setDialogTitle("จ่ายค่างวด");
                    setDefaultInstallmentPaymentDto({
                      shopId: shopId,
                      installmentPlanId: installmentPlanId,
                      appointmentAt: dayjs().format("YYYY-MM-DD"),
                      transactionAt: dayjs().format("YYYY-MM-DD"),
                      status: InstallmentPaymentDto.status.COMPLETED,
                      type: InstallmentPaymentDto.type.TERM_PAYMENT,
                      paidDueAmount: 0,
                      paidFineAmount: 0,
                      installmentTermNumber:
                        installmentPlanQuery?.activeTermNumber,
                      shopPaymentChannelId: "",
                      documents: [],
                    });
                    setOpenPaymentDialog(true);
                  }}
                >
                  จ่ายค่างวด
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ alignSelf: "center", height: "56px" }}
                  onClick={() => {
                    setDialogTitle(
                      `สร้าง QR รับยอดงวดที่ ${installmentPlanQuery?.activeTermNumber}`
                    );
                    setOpenGenerateQrDialog(true);
                  }}
                >
                  สร้าง QR รับยอด
                </Button>
              </Stack>

              <Stack direction={{ md: "column", lg: "row" }} gap={1}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{ alignSelf: "center", height: "56px" }}
                  onClick={() => {
                    setDialogTitle("นัดจ่าย");
                    setDefaultInstallmentPaymentDto({
                      shopId: shopId,
                      installmentPlanId: installmentPlanId,
                      appointmentAt: dayjs().format("YYYY-MM-DD"),
                      transactionAt: dayjs().format("YYYY-MM-DD"),
                      status: InstallmentPaymentDto.status.COMPLETED,
                      type: InstallmentPaymentDto.type.APPOINTMENT,
                      paidDueAmount: 0,
                      paidFineAmount: 0,
                      installmentTermNumber:
                        installmentPlanQuery?.activeTermNumber,
                      shopPaymentChannelId: "",
                      documents: [],
                    });
                    setOpenPaymentDialog(true);
                  }}
                >
                  นัดจ่าย
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{ alignSelf: "center", height: "56px" }}
                  onClick={() => {
                    setOpenInstallmentPlanCostDialog(true);
                  }}
                >
                  แก้ไขต้นทุน
                </Button>
                <Box width={"100%"} p={2}></Box>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Box>

      <Box sx={{ bgcolor: "white", width: "100%", px: 2 }}>
        <Stack direction={{ md: "column", lg: "row" }} sx={{ mb: 2 }} gap={2}>
          <InstallmentPlanInfoCard installmentPlan={installmentPlanQuery} />
          <InstallmentTermInfoCard
            installmentTerm={activeInstallmentTerm}
            payments={installmentPlanQuery?.payments?.filter(
              (p) =>
                p.installmentTermNumber ===
                installmentPlanQuery.activeTermNumber
            )}
            activeDueDateDiff={installmentPlanQuery?.activeDueDateDiff}
          />
        </Stack>
      </Box>

      <Box sx={{ bgcolor: "white", width: "100%", p: 2 }}>
        <Stack direction={{ md: "column", lg: "row" }} gap={2}>
          <Card sx={{ width: "100%", border: "2px solid #eeeeee", py: 2 }}>
            <Stack sx={{ px: 2 }} gap={2}>
              <Typography variant="h5">ข้อมูลอื่นๆ</Typography>
              <Stack>
                <CustomDataField label={"ลิ้งสำหรับลูกค้า"}>
                  <Stack direction={"row"} gap={1}>
                    <Typography>
                      {installmentPlanId
                        ? `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?loanId=${installmentPlanId}`
                        : "-"}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?loanId=${installmentPlanId}`
                          )
                          .then(() => {
                            toast.success("คัดลอกสำเร็จ");
                          });
                      }}
                    >
                      <ContentCopyIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                </CustomDataField>
                <CustomDataField label="วันที่สมัคร">
                  <Typography>
                    {installmentPlanQuery?.registrationDate
                      ? `${dayjs(installmentPlanQuery.registrationDate)
                          .locale("th")
                          .format("DD MMM YYYY")}`
                      : "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="รหัสอ้างอิง">
                  <Typography>
                    {installmentPlanQuery?.referenceId || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="ยอดรวม">
                  <Typography>{`${currencyFormat.format(
                    installmentPlanQuery?.totalAmount || 0
                  )}`}</Typography>
                </CustomDataField>
                <CustomDataField label="ยอดเงินดาวน์">
                  <Typography>{`${currencyFormat.format(
                    installmentPlanQuery?.downPaymentAmount || 0
                  )}`}</Typography>
                </CustomDataField>
                <CustomDataField label="วันที่จ่ายเงินดาวน์">
                  <Typography>
                    {installmentPlanQuery?.downPaymentDate
                      ? `${dayjs(installmentPlanQuery.downPaymentDate)
                          .locale("th")
                          .format("DD MMM YYYY")}`
                      : "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="ยอดผ่อนต่อเดือน">
                  <Typography>{`${currencyFormat.format(
                    installmentPlanQuery?.installmentAmount || 0
                  )}`}</Typography>
                </CustomDataField>
                <CustomDataField label="จำนวนงวด">
                  <Typography>{`${
                    installmentPlanQuery?.installmentCount || 0
                  } งวด (ทุก ${installmentPlanQuery?.installmentFrequency} ${
                    installmentPlanQuery?.installmentFrequencyType ===
                    InstallmentPlanDto.installmentFrequencyType.MONTH
                      ? "เดือน"
                      : "วัน"
                  })`}</Typography>
                </CustomDataField>
                <CustomDataField label="เครื่อง">
                  <Typography>{`${installmentPlanQuery?.product.brand} ${installmentPlanQuery?.product.model}`}</Typography>
                </CustomDataField>
                <CustomDataField label="IMEI">
                  <Typography>{`${installmentPlanQuery?.product.imei}`}</Typography>
                </CustomDataField>
                <CustomDataField label="Serial Number">
                  <Typography>{`${installmentPlanQuery?.product.serialNumber}`}</Typography>
                </CustomDataField>
                <CustomDataField label="อีเมล">
                  <Typography>
                    {installmentPlanQuery?.profile?.email || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="เลขบัตรประชาชน">
                  <Typography>
                    {installmentPlanQuery?.profile?.citizenId || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="เลขพาสปอร์ต">
                  <Typography>
                    {installmentPlanQuery?.profile?.passportNumber || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="ที่อยู่ปัจจุบัน">
                  <Typography>
                    {installmentPlanQuery?.profile?.currentAddress || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="ที่อยู่ตามบัตร">
                  <Typography>
                    {installmentPlanQuery?.profile?.citizenAddress || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="อาชีพ">
                  <Typography>
                    {installmentPlanQuery?.profile?.occupation || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="สถานที่ทำงาน">
                  <Typography>
                    {installmentPlanQuery?.profile?.workPlace || "-"}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="นำเข้าโดย">
                  <Typography>
                    {formatOptionalFullName(
                      installmentPlanQuery?.createdByUserName,
                      installmentPlanQuery?.createdByLastName
                    )}
                  </Typography>
                </CustomDataField>
                <CustomDataField label="วันที่นำเข้า">
                  <Typography>
                    {installmentPlanQuery?.createdAt
                      ? formatLongReadableThaiDate(
                          new Date(installmentPlanQuery.createdAt)
                        )
                      : "-"}
                  </Typography>
                </CustomDataField>
              </Stack>
            </Stack>
          </Card>
          <Card sx={{ width: "100%", border: "2px solid #eeeeee" }}>
            <Box p={2}>
              <Stack gap={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5">โน้ต</Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      alignSelf: "center",
                      height: "fit-content",
                      width: "fit-content",
                    }}
                    onClick={() => {
                      setOpenInstallmentPlanNoteDialog(true);
                    }}
                  >
                    + เพิ่มโน้ต
                  </Button>
                </div>
                {(installmentPlanQuery?.notes?.length ?? 0) > 0 ? (
                  installmentPlanQuery?.notes?.map((n) => (
                    <Box
                      key={n.note}
                      p={2}
                      sx={{
                        border: "2px solid #eeeeee",
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          whiteSpace: "pre-line",
                          display: "flex",
                          flexDirection: "column",
                          gap: "0px",
                        }}
                      >
                        <Typography sx={{ whiteSpace: "pre-line" }}>
                          {n.note}
                        </Typography>
                        {n.createdAt && (
                          <small
                            style={{
                              color: "rgba(0,0,0,0.4)",
                            }}
                          >
                            {formatLongReadableThaiDate(new Date(n.createdAt))}
                          </small>
                        )}
                      </Box>
                      {deleteNote.isPending &&
                      n.id === noteIdPendingDeletion ? (
                        <IconButton
                          sx={{
                            animation: `${animations.rotate360} 2s linear infinite`,
                          }}
                          color="error"
                        >
                          <HourglassBottomRounded />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => {
                            onDeleteNoteClicked(n);
                          }}
                          color="error"
                        >
                          <DeleteForeverRounded />
                        </IconButton>
                      )}
                    </Box>
                  ))
                ) : (
                  <div>-</div>
                )}
              </Stack>
            </Box>
          </Card>
        </Stack>
      </Box>
      {paymentChannelsQuery.data?.some(
        (p) => p.type === ShopPaymentChannelDto.type.AUTOMATIC_QR
      ) && (
        <InstallmentQRPaymentCreateDialog
          open={openGenerateQrDialog}
          onClose={() => setOpenGenerateQrDialog(false)}
          title={dialogTitle}
        />
      )}
      <InstallmentPaymentCreateDialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        title={dialogTitle}
        defauleValues={defaultInstallmentPaymentDto}
      />
      <InstallmentPlanNoteDialog
        open={openInstallmentPlanNoteDialog}
        onClose={() => setOpenInstallmentPlanNoteDialog(false)}
        installmentPlanDto={installmentPlanQuery}
      />
      <InstallmentPlanCostDialog
        open={openInstallmentPlanCostDialog}
        onClose={() => setOpenInstallmentPlanCostDialog(false)}
        installmentPlanDto={installmentPlanQuery}
      />
    </Fragment>
  );
}
