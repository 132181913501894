import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  useTheme,
} from "@mui/material";
import { useState, useMemo } from "react";
import { useSearchMasterDeviceModels } from "../../client/query-client";
import { useDebouncedValue } from "../../util/useDebouncedValue";
import { SearchableMode } from "./SearchableBrandField";
import { Control, Controller } from "react-hook-form";
import { IProductDetailForm } from "../../layout/ShopFinancierContract/ProductDetails";

const DEFAULT_LIMIT = 300;
const DEBOUNCE_DELAY = 250;

interface IProps {
  onSelected?: (device: string) => void;
  label: string;
  brand: string;
  disabled: boolean;
  mode: SearchableMode;
  control: Control<IProductDetailForm>;
  error?: string;
}

export const SearchableModelField: React.FC<IProps> = ({
  label,
  brand,
  disabled,
  mode,
  control,
  error,
}): JSX.Element => {
  const [inputValue, setInputValue] = useState("");
  const debouncedSearch = useDebouncedValue(inputValue, DEBOUNCE_DELAY);
  const { data } = useSearchMasterDeviceModels({
    limit: DEFAULT_LIMIT,
    model: debouncedSearch,
    brand,
    page: 0,
  });

  const theme = useTheme();

  // กำจัดค่าซ้ำและทำให้ตัวอักษรตัวแรกเป็น uppercase
  const mapped = useMemo(() => {
    const models = data?.data ?? [];
    const set = new Set(models.map((d) => d.displayName));
    const uniqueModels = Array.from(set);

    const upperCaseFirstLetter = (str: string) =>
      str.charAt(0).toUpperCase() + str.slice(1);

    return uniqueModels.map(upperCaseFirstLetter);
  }, [data?.data]);

  return (
    <FormControl fullWidth error={!!error}>
      <Controller
        name="model"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            value={field.value ?? null}
            disablePortal={mode !== SearchableMode.FREETEXT}
            freeSolo={mode === SearchableMode.FREETEXT}
            fullWidth
            options={mapped}
            onChange={(_e, v) => field.onChange(v)}
            onInputChange={(_event, newInputValue) =>
              setInputValue(newInputValue)
            }
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label={label}
                  size="small"
                  variant="outlined"
                />
                {!!error && <FormHelperText>{error}</FormHelperText>}
              </>
            )}
            renderOption={(props, option, state) => (
              <li {...props} key={`${option}-${state.index}`}>
                {option}
              </li>
            )}
            sx={disabled ? { backgroundColor: theme.palette.grey[200] } : {}}
            disabled={disabled}
          />
        )}
      />
    </FormControl>
  );
};
