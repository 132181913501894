/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetGatewayPaymentQRResponse } from '../models/GetGatewayPaymentQRResponse';
import type { PaginatedGatewayPaymentDto } from '../models/PaginatedGatewayPaymentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GatewayPaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedGatewayPaymentDto OK
     * @throws ApiError
     */
    public searchGatewayPayments({
        shopId,
        downSavingId,
        type,
        loanId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        shopId: string,
        downSavingId: string,
        type: 'ALL' | 'DOWN_SAVING' | 'LOAN',
        loanId?: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'PENDING' | 'COMPLETED',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedGatewayPaymentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/gateway-payment/{shopId}/search',
            path: {
                'shopId': shopId,
            },
            query: {
                'downSavingId': downSavingId,
                'type': type,
                'loanId': loanId,
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GetGatewayPaymentQRResponse OK
     * @throws ApiError
     */
    public getQr({
        gatewayPaymentId,
    }: {
        gatewayPaymentId: string,
    }): CancelablePromise<GetGatewayPaymentQRResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/gateway-payment/qr/loan/{gatewayPaymentId}',
            path: {
                'gatewayPaymentId': gatewayPaymentId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteGatewayPayment({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/gateway-payment/qr/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
