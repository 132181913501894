import {
  Box,
  Button,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { ShopLoanCalculationSettings } from "../../../client/motalvip-apis/generated";
import { useGetShopLoanCalculationSettings } from "../../../client/query-client/shopSettings/useGetShopLoanCalculationSettings";
import { useUpdatShopLoanCalculationSettings } from "../../../client/query-client/shopSettings/useUpdateShopLoanCalculationSettings";
import CustomInputLabel from "../../../component/CustomInputLabel";
import { useShopContext } from "../../../context/ShopContextProvider";

export function ShopCalculationSettingsScreen() {
  const { shopId } = useShopContext();
  const [type, setType] = useState<ShopLoanCalculationSettings.type>(
    ShopLoanCalculationSettings.type.IOS
  );
  const update = useUpdatShopLoanCalculationSettings({
    onError: () => {
      toast.error("Failed to update settings");
    },
    onSuccess: () => {
      toast.success("Settings updated successfully");
    },
  });
  const { data: originalSettings, rawData } = useGetShopLoanCalculationSettings(
    {
      shopId: shopId ?? "",
      type,
    }
  );

  const [settings, setSettings] = useState(originalSettings);
  const [newPercentage, setNewPercentage] = useState<number[]>([]);

  useEffect(() => {
    setSettings(originalSettings);
  }, [originalSettings]);

  function handleRoundNumbersChange(v: boolean) {
    setSettings((s) => ({ ...s, roundNumbers: v }));
  }

  function handleInputChange<T extends keyof (typeof originalSettings.data)[0]>(
    index: number,
    field: T,
    value: (typeof originalSettings.data)[0][T]
  ) {
    const newSettings = JSON.parse(
      JSON.stringify(settings)
    ) as typeof originalSettings;
    newSettings.data[index][field] = value;
    setSettings(newSettings);
  }

  const handleSave = () => {
    if (!rawData) return;
    if (window.confirm("Confirm?")) {
      update.mutate({
        ...rawData,
        jsonValue: JSON.stringify(settings.data),
        roundNumbers: settings.roundNumbers,
      });
    }
  };

  const hasChanges = useMemo(() => {
    if (settings.roundNumbers !== originalSettings.roundNumbers) {
      return true;
    }

    for (let i = 0; i < settings.data.length; i++) {
      const factor = settings.data[i];
      if (
        factor.downPercentage !== originalSettings.data[i].downPercentage ||
        factor.month !== originalSettings.data[i].month ||
        factor.profitFactor !== originalSettings.data[i].profitFactor ||
        factor.enabled !== originalSettings.data[i].enabled
      ) {
        return true;
      }
    }
  }, [settings, originalSettings]);

  const onNewDownPercentageSubmit = (
    setting: (typeof settings.data)[0],
    index: number
  ) => {
    if (newPercentage[index] == null) return;
    const newDownPercentage = [
      ...setting.downPercentage,
      Number(newPercentage[index]) || 0,
    ];
    handleInputChange(
      index,
      "downPercentage",
      Array.from(new Set(newDownPercentage))
    );
    // reset the input
    setNewPercentage((v) => {
      v[index] = 0;
      return [...v];
    });
  };

  return (
    <Box sx={{ p: 2, mb: 8 }}>
      <FormControl fullWidth>
        <CustomInputLabel>Android/iOS</CustomInputLabel>
        <Select
          size="small"
          variant="outlined"
          value={type}
          label="Opeating System"
          onChange={(v) => {
            setType(v.target.value as ShopLoanCalculationSettings.type);
          }}
        >
          <MenuItem value={ShopLoanCalculationSettings.type.ANDROID}>
            Android
          </MenuItem>
          <MenuItem value={ShopLoanCalculationSettings.type.IOS}>iOS</MenuItem>
        </Select>
      </FormControl>

      <div>
        <h2>ปัดเศษ 99</h2>
        <Switch
          checked={settings.roundNumbers}
          sx={{
            outlineOffset: "2px",
            outline:
              settings.roundNumbers !== originalSettings.roundNumbers
                ? "2px solid green"
                : "",
          }}
          onChange={(e) => handleRoundNumbersChange(e.target.checked)}
        />
      </div>
      <div>
        <h2>ตัวแปรคำนวณ</h2>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>เดือน</TableCell>
                <TableCell>เปอร์เซ็นต์วางดาว์น</TableCell>
                <TableCell>ตัวคูณกำไร</TableCell>
                <TableCell>เปิด</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settings.data.map((setting, index) => (
                <TableRow key={index}>
                  <TableCell>{setting.month}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          outline:
                            JSON.stringify(setting.downPercentage) !==
                            JSON.stringify(
                              originalSettings.data[index].downPercentage
                            )
                              ? "2px solid green"
                              : "",
                        }}
                      >
                        {setting.downPercentage.map((percentage, pIndex) => (
                          <Chip
                            key={pIndex}
                            label={percentage}
                            onDelete={() => {
                              const newDownPercentage =
                                setting.downPercentage.filter(
                                  (_, i) => i !== pIndex
                                );
                              handleInputChange(
                                index,
                                "downPercentage",
                                newDownPercentage
                              );
                            }}
                          />
                        ))}
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          type="text"
                          value={newPercentage[index]}
                          onChange={(e) => {
                            setNewPercentage((v) => {
                              v[index] = Number(e.target.value);
                              return [...v];
                            });
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "Enter" &&
                              typeof newPercentage[index] === "number"
                            ) {
                              onNewDownPercentageSubmit(setting, index);
                            }
                          }}
                          sx={{ mt: 1 }}
                          placeholder="เพิ่มเปอร์เซ็นต์"
                        />
                        <Button
                          onClick={() =>
                            onNewDownPercentageSubmit(setting, index)
                          }
                          sx={{
                            width: "fit-content",
                          }}
                          variant="contained"
                        >
                          เพิ่ม
                        </Button>
                      </div>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={setting.profitFactor}
                      sx={{
                        opacity: setting.enabled ? 1 : 0.5,
                        width: "fit-content",
                        outlineOffset: "2px",
                        outline:
                          setting.profitFactor !==
                          originalSettings.data[index].profitFactor
                            ? "2px solid green"
                            : "",
                      }}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "profitFactor",
                          Number(e.target.value)
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={setting.enabled}
                      sx={{
                        outlineOffset: "2px",
                        outline:
                          setting.enabled !==
                          originalSettings.data[index].enabled
                            ? "2px solid green"
                            : "",
                      }}
                      onChange={(e) =>
                        handleInputChange(index, "enabled", e.target.checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={!hasChanges}
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </Box>
  );
}
