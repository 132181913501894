import { useQuery } from "@tanstack/react-query"
import { useBackendApiClient } from "../../motalvip-apis"

export const useDownSavingQuery = (downSavingId: string) => {
    const backendClient = useBackendApiClient()
    return useQuery({
        queryKey: [downSavingId],
        queryFn: () =>
            backendClient.downSaving.getDownSaving({ downSavingId }),
        enabled: !!downSavingId,
    })
}