import { Box } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef } from "react";

import { useQuery } from "@tanstack/react-query";

const fetchDeviceLocations = async ({
  imei,
  serialNumber,
}: { imei?: string; serialNumber?: string } = {}): Promise<
  [number, number][]
> => {
  const apiKey = process.env.REACT_APP_LOCATION_API_KEY;
  if (!apiKey) {
    console.error("API key not found");
    throw new Error("API key not found");
  }
  // add to x-api-key header
  let path = "";
  if (imei) {
    path = `location/imei/${imei}`;
  } else {
    path = `location/serialNumber/${serialNumber}`;
  }
  const response = await fetch(
    `${process.env.REACT_APP_LOCATION_API_URL}/${path}`,
    {
      headers: {
        "x-api-key": apiKey,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch device locations");
  }
  const data: { lat: number; long: number }[] = await response.json();
  const mapped = data.map(({ lat, long }) => [lat, long] as [number, number]);
  return mapped;
};

export function useDeviceLocations({
  imei,
  serialNumber,
}: {
  imei?: string;
  serialNumber?: string;
}) {
  const enabled = !!imei || !!serialNumber;
  const { data = [], ...queryInfo } = useQuery({
    queryKey: ["deviceLocations", imei, serialNumber, enabled],
    enabled,
    queryFn: () =>
      fetchDeviceLocations({
        imei,
        serialNumber,
      }),
  });

  return { data, ...queryInfo };
}

export function LocationTracker({
  imei,
  serialNumber,
}: {
  imei?: string;
  serialNumber?: string;
}) {
  const mapContainer = useRef<HTMLElement | null>(null);
  const mapInstance = useRef<L.Map | null>(null);
  const { data: coordinates } = useDeviceLocations({
    imei,
    serialNumber,
  });

  useEffect(() => {
    if (!mapContainer.current) return;

    // Initialize map if it doesn't exist
    if (!mapInstance.current) {
      mapInstance.current = L.map(mapContainer.current).setView(
        coordinates[0],
        13
      );

      // Add tile layer (using OpenStreetMap)
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapInstance.current);
    }

    // Add markers with popups
    const markers: L.Marker[] = [];
    const bounds = L.latLngBounds(
      L.latLng(coordinates[0][0], coordinates[0][1]),
      L.latLng(coordinates[0][0], coordinates[0][1])
    );

    coordinates.forEach((point) => {
      const marker = L.marker(point, {
        icon: L.divIcon({
          className: "custom-marker",
          html: `<div style="width: 20px; height: 20px; border-radius: 50%; background-color: #3388ff; border: 2px solid white; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);"></div>`,
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        }),
      });

      marker.addTo(mapInstance.current!);
      markers.push(marker);
      bounds.extend(point);
    });

    // Create a polyline connecting all points in order
    L.polyline(coordinates, {
      color: "#0022ff",
      weight: 3,
      opacity: 0.7,
      dashArray: "5, 10", // Creates a dashed line
      lineJoin: "round",
    }).addTo(mapInstance.current!);

    // Add distance information to the polyline
    let totalDistance = 0;
    for (let i = 0; i < coordinates.length - 1; i++) {
      const start = L.latLng(coordinates[i][0], coordinates[i][1]);
      const end = L.latLng(coordinates[i + 1][0], coordinates[i + 1][1]);
      const segmentDistance = start.distanceTo(end);
      totalDistance += segmentDistance;

      // Add midpoint popup with segment distance
      const midpoint = L.latLng(
        (coordinates[i][0] + coordinates[i + 1][0]) / 2,
        (coordinates[i][1] + coordinates[i + 1][1]) / 2
      );

      L.marker(midpoint, {
        icon: L.divIcon({
          className: "distance-marker",
          html: `<div style="background-color: rgba(255,255,255,0.8); padding: 3px 6px; border-radius: 3px; font-size: 10px; border: 1px solid #ff6600;">${(
            segmentDistance / 1000
          ).toFixed(2)} km</div>`,
          iconSize: [60, 20],
          iconAnchor: [30, 10],
        }),
      }).addTo(mapInstance.current!);
    }

    // Correctly implement the total distance control
    class TotalDistanceControl extends L.Control {
      onAdd(map: L.Map): HTMLElement {
        const div = L.DomUtil.create("div", "info total-distance");
        div.innerHTML = `<strong>Total Distance:</strong> ${(
          totalDistance / 1000
        ).toFixed(2)} km`;
        div.style.backgroundColor = "white";
        div.style.padding = "6px 8px";
        div.style.border = "1px solid #ccc";
        div.style.borderRadius = "4px";
        div.style.boxShadow = "0 0 5px rgba(0,0,0,0.2)";
        return div;
      }
    }

    // Add the custom control to the map
    new TotalDistanceControl({ position: "bottomright" }).addTo(
      mapInstance.current!
    );

    // Fit bounds to show all markers
    mapInstance.current?.fitBounds(bounds, {
      padding: [50, 50],
      maxZoom: 15,
    });

    // Clean up function
    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
      }
    };
  }, [coordinates]);

  if (coordinates.length === 0) {
    return <div>No data</div>;
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "600px",
        // Additional styles for Leaflet
        "& .custom-marker": {
          background: "transparent",
          border: "none",
        },
        "& .distance-marker": {
          background: "transparent",
          border: "none",
        },
      }}
      ref={mapContainer}
      id="map"
    ></Box>
  );
}

export default LocationTracker;
