import { Box } from "@mui/material";
import { ShopPaymentChannelDto } from "../../../../client/motalvip-apis/generated";
import { paymentChannelConfig } from "../../../../config/PaymentChannelAssetConfig";

export function PaymentChannelInfo({
  bankCode,
  channel,
  accountNumber,
}: {
  channel?: ShopPaymentChannelDto.type;
  bankCode?: ShopPaymentChannelDto.bankCode;
  accountNumber?: string;
}) {
  if (!channel) {
    return <p>ไม่ได้กำหนด</p>;
  }

  const foundConfig = paymentChannelConfig.find(
    (config) =>
      config.type === channel &&
      (!config.bankCode || config.bankCode === bankCode)
  );

  if (!foundConfig) {
    return null;
  }

  return (
    <Box
      sx={{
        background: foundConfig.color,
        padding: "0.5rem",
        borderRadius: "0.5rem",
        color: "white",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: 1,
      }}
    >
      <img width={28} src={foundConfig.logo} alt="" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {foundConfig.name}
        {accountNumber && <small>{accountNumber}</small>}
      </div>
    </Box>
  );
}
