/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShopLoanCalculationSettings } from '../models/ShopLoanCalculationSettings';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopSettingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopLoanCalculationSettings OK
     * @throws ApiError
     */
    public updateShopLoanCalculationSettings({
        requestBody,
    }: {
        requestBody: ShopLoanCalculationSettings,
    }): CancelablePromise<ShopLoanCalculationSettings> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shop-settings/loan-calculation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
