/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShopAvatarUploadConfirmRequest } from '../models/ShopAvatarUploadConfirmRequest';
import type { ShopAvatarUploadUrlRequest } from '../models/ShopAvatarUploadUrlRequest';
import type { ShopDto } from '../models/ShopDto';
import type { ShopFeature } from '../models/ShopFeature';
import type { ShopPaymentChannelCreateRequest } from '../models/ShopPaymentChannelCreateRequest';
import type { ShopPaymentChannelDto } from '../models/ShopPaymentChannelDto';
import type { ShopPaymentChannelQrCodeUploadConfirmRequest } from '../models/ShopPaymentChannelQrCodeUploadConfirmRequest';
import type { ShopPaymentChannelQrCodeUploadUrlRequest } from '../models/ShopPaymentChannelQrCodeUploadUrlRequest';
import type { ShopUserInvitationRequest } from '../models/ShopUserInvitationRequest';
import type { ShopUserRemoveRequest } from '../models/ShopUserRemoveRequest';
import type { ShopUserResponse } from '../models/ShopUserResponse';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { UploadUrlResponse } from '../models/UploadUrlResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public getUserShops(): CancelablePromise<Array<ShopDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public updateShop({
        requestBody,
    }: {
        requestBody: ShopDto,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shops',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public createShop({
        requestBody,
    }: {
        requestBody: ShopDto,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopUserResponse OK
     * @throws ApiError
     */
    public createShopUserInvitation({
        requestBody,
    }: {
        requestBody: ShopUserInvitationRequest,
    }): CancelablePromise<ShopUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/users/invitation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public createShopPaymentChannel({
        requestBody,
    }: {
        requestBody: ShopPaymentChannelCreateRequest,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/payment-channels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public enableShopPaymentChannel({
        paymentChannelId,
    }: {
        paymentChannelId: string,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/payment-channels/{paymentChannelId}/enable',
            path: {
                'paymentChannelId': paymentChannelId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public disableShopPaymentChannel({
        paymentChannelId,
    }: {
        paymentChannelId: string,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/payment-channels/{paymentChannelId}/disable',
            path: {
                'paymentChannelId': paymentChannelId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getShopQrCodeUploadUrl({
        requestBody,
    }: {
        requestBody: ShopPaymentChannelQrCodeUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/payment-channels/qr-code-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public confirmShopQrCodeUpload({
        requestBody,
    }: {
        requestBody: ShopPaymentChannelQrCodeUploadConfirmRequest,
    }): CancelablePromise<ShopPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/payment-channels/qr-code-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getShopAvatarUploadUrl({
        requestBody,
    }: {
        requestBody: ShopAvatarUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/avatar-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmShopAvatarUpload({
        requestBody,
    }: {
        requestBody: ShopAvatarUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shops/avatar-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public getShopById({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopUserResponse OK
     * @throws ApiError
     */
    public getShopUsers({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Array<ShopUserResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/users',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public getShopPaymentChannels({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Array<ShopPaymentChannelDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/payment-channels',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopFeature OK
     * @throws ApiError
     */
    public getShopFeatures({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopFeature> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/features',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public removeShopUser({
        requestBody,
    }: {
        requestBody: ShopUserRemoveRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/shops/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
