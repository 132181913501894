import { Box, Card, CardContent, Divider, Stack, Typography } from "@mui/material"
import { InstallmentPlanMonthlyAggregation, InstallmentPlanMonthlyRevenue } from "../../../client/motalvip-apis/generated";

interface RevenueWidgetProps {
    installmentPlanAggregations?: Array<InstallmentPlanMonthlyAggregation>
    installmentPlanRevenue: InstallmentPlanMonthlyRevenue;
}
export const RevenueWidget = (props: RevenueWidgetProps) => {

    const currencyFormat = new Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    return (
        <>
            <Stack direction={{ lg: 'row' }} sx={{ pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                <Card sx={{ width: { xs: '100%', lg: '31%' } }}>
                    <CardContent sx={{ padding: 2 }}>
                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="body1" component="div">
                                    จำนวนสินเชื่อใหม่
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="h2" component="div" >
                                    {props.installmentPlanAggregations?.reduce((acc, cur) => acc + (cur.installmentPlanCount || 0), 0) || 0}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ width: { lg: '33%' } }}>
                    <CardContent sx={{ padding: 2 }}>
                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="body1" component="div">
                                    {`ค่างวดเรียกเก็บ (${props.installmentPlanRevenue?.installmentTermDueCount || 0} รายการ)`}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="h2" component="div" color='#9e9e9e'>
                                    {currencyFormat.format(props.installmentPlanRevenue?.installmentTermDueAmount || 0)}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{ width: { lg: '33%' } }}>
                    <CardContent sx={{ padding: 2 }}>
                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="body1" component="div">
                                    {`ค่างวดเก็บได้จริง (${props.installmentPlanRevenue?.installmentPaymentTermCount || 0} รายการ)`}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="h2" component="div" color='#aed581'>
                                    {currencyFormat.format(props.installmentPlanRevenue?.totalTermPaidDueAmount || 0)}
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <Stack direction={{ lg: 'row' }} sx={{ width: '100%', pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                <Card sx={{ width: { lg: '49%' } }}>
                    <CardContent sx={{ padding: 2 }}>
                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="body1" component="div">
                                    {`รายได้ (${((props.installmentPlanRevenue.installmentPaymentDownCount || 0) + (props.installmentPlanRevenue.installmentPaymentTermCount || 0))} รายการ)`}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="h2" component="div">
                                    {currencyFormat.format((props.installmentPlanRevenue.totalIncome || 0))}
                                </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                <Stack alignContent='center' alignItems='center'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body2" component="div">
                                            {`เงินดาว (${props.installmentPlanRevenue?.installmentPaymentDownCount || 0} รายการ)`}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color='#9e9e9e'>
                                        {currencyFormat.format(props.installmentPlanRevenue?.totalDownPaymentAmount || 0)}
                                    </Typography>
                                </Stack>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Stack alignContent='center' alignItems='center'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body2" component="div">
                                            {`ค่างวด (${props.installmentPlanRevenue?.installmentPaymentTermCount || 0} รายการ)`}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color='#aed581'>
                                        {currencyFormat.format(props.installmentPlanRevenue?.totalTermPaidDueAmount || 0)}
                                    </Typography>
                                </Stack>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Stack alignContent='center' alignItems='center'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body2" component="div" >
                                            {`ค่าปรับ`}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color='#03a9f4'>
                                        {currencyFormat.format(props.installmentPlanRevenue?.totalTermPaidFineAmount || 0)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>

                <Card sx={{ width: { lg: '49%' } }}>
                    <CardContent sx={{ padding: 2 }}>
                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="body1" component="div">
                                    รายจ่าย
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="h2" component="div" color='#ef9a9a'>
                                    {currencyFormat.format(props.installmentPlanRevenue?.totalExpense || 0)}
                                </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                <Stack alignContent='center' alignItems='center'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body2" component="div">
                                            ต้นทุนเครื่อง
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color='#9e9e9e'>
                                        {currencyFormat.format(props.installmentPlanRevenue?.totalPurchaseCost || 0)}
                                    </Typography>
                                </Stack>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Stack alignContent='center' alignItems='center'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body2" component="div">
                                            ค่าคอม
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color='#9e9e9e'>
                                        {currencyFormat.format(props.installmentPlanRevenue?.totalCommission || 0)}
                                    </Typography>
                                </Stack>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Stack alignContent='center' alignItems='center'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body2" component="div">
                                            ต้นทุนอื่นๆ
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color='#9e9e9e'>
                                        {currencyFormat.format(props.installmentPlanRevenue?.totalOtherCost || 0)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </>
    )
}