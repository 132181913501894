import { Box, Button, Card, CardContent, Dialog, FormControl, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DownSavingTransactionCreateRequest } from "../../../../../client/motalvip-apis/generated";
import { useDownSavingTransactionCreateMutation } from "../../../../../client/query-client/downSaving/useDownSavingTransactionCreateMutation";
import CustomInputLabel from "../../../../../component/CustomInputLabel";
import { useShopContext } from "../../../../../context/ShopContextProvider";

interface DownSavingTransactionWithdrawDialogProps {
    downSavingId: string;
    balance: number;
    open: boolean
    onClose: () => void;
}
export const DownSavingTransactionWithdrawDialog = (props: DownSavingTransactionWithdrawDialogProps) => {

    const shopCtx = useShopContext()
    const downSavingTransactionCreateMutation = useDownSavingTransactionCreateMutation()

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors }
    } = useForm<DownSavingTransactionCreateRequest>({
        defaultValues: {
            shopId: shopCtx.shop?.id || '',
            downSavingId: props.downSavingId,
            direction: DownSavingTransactionCreateRequest.direction.WITHDRAW,
            amount: 0,
            fineAmount: 0,
            shopPaymentChannelId: ''
        },
        mode: 'all'
    });

    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const onSubmit = () => {
        handleSubmit((data) => {
            downSavingTransactionCreateMutation.mutate(getValues(), {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ')
                    props.onClose()
                },
                onError: (error) => {
                    toast.error('เกิดข้อผิดพลาด')
                    props.onClose()
                }
            });
        })()
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onCloseDialog = () => {
        reset({
            shopId: shopCtx.shop?.id || '',
            downSavingId: props.downSavingId,
            direction: DownSavingTransactionCreateRequest.direction.WITHDRAW,
            amount: 0,
            fineAmount: 0,
            shopPaymentChannelId: ''
        });
        props.onClose();
    }

    return (
        <Dialog
            fullWidth={isMobile}
            maxWidth={isMobile ? false : 'sm'}
            open={props.open}
            onClose={onCloseDialog}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <Box sx={{ bgcolor: 'white', width: isMobile ? '100%' : theme.breakpoints.values.sm }} >
                <Box sx={{ p: 2, justifyContent: 'space-between', bgcolor: '#263238' }}>
                    <Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
                        <Typography variant="h5" color='white'>{`ถอนเงิน`}</Typography>
                    </Stack>
                </Box>
                <Box sx={{ p: 2, width: '100%', bgcolor: 'white' }}>
                    <Card sx={{ my: 1, width: { md: '100%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack direction={'column'} gap={2}>
                                <Typography variant="h6">ข้อมูลการถอนเงิน</Typography>
                                <Stack direction={{ sm: 'column' }} gap={2}>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>วันที่</CustomInputLabel>
                                        <Controller
                                            control={control}
                                            name="transactionAt"
                                            rules={{ required: 'กรุณากรอกวันที่' }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    value={field.value ? dayjs(field.value) : null}
                                                    onChange={(date) =>
                                                        field.onChange(
                                                            date ? date.format("YYYY-MM-DD") : ""
                                                        )
                                                    }
                                                    slotProps={{
                                                        textField: { size: "small", variant: "outlined" },
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.transactionAt && (
                                            <Typography color="error">
                                                {errors.transactionAt.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth error={errors.amount?.message ? true : false}>
                                        <CustomInputLabel>ยอดถอนเงิน</CustomInputLabel>
                                        <Controller
                                            name="amount"
                                            control={control}
                                            rules={{
                                                min: { value: 1, message: 'ต้องมากกว่าขั้นต่ำ' },
                                                max: { value: props.balance, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(props.balance)}` }
                                            }}
                                            render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.amount && <Typography color="error">{errors.amount.message}</Typography>}
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                </Box>
                <Box sx={{ p: 2, pt: 0, bgcolor: 'white', width: '100%' }} >
                    <Stack direction='row' gap={1} justifyContent='center'>
                        <Button variant='outlined' size='large'
                            onClick={onCloseDialog}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>ยกเลิก</Button>
                        <Button variant='contained' size='large'
                            onClick={onSubmit}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>บันทึก</Button>
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    )
}