import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useBackendApiClient } from "../../motalvip-apis";

export const useDeleteDeviceManagementApiKey = () => {
  const backendClient = useBackendApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deviceManagementApiKey-delete"],
    mutationFn: (
      args: Parameters<
        typeof backendClient.deviceManagement.deleteDeviceManagementApiKey
      >[0]
    ) => {
      return backendClient.deviceManagement.deleteDeviceManagementApiKey(args);
    },
    onSuccess() {
      toast.success("ลบ API key สำเร็จ");
      queryClient.invalidateQueries({
        queryKey: ["deviceManagement"],
      });
    },
    onError: () => {
      toast.error("ลบ API key ไม่สำเร็จ");
    },
    retry: 0,
  });
};
