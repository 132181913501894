import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useShopQuery } from "../client/query-client";
import { dayDiff } from "../util/DateUtil";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ConstructionIcon from "@mui/icons-material/ConstructionOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PriceCheckIcon from "@mui/icons-material/PriceCheckOutlined";
import QrCodeOutlinedIcon from "@mui/icons-material/QrCodeOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

import { LocationOnOutlined, PhoneAndroidOutlined } from "@mui/icons-material";
import {
  ShopDto,
  ShopFeature,
  ShopPaymentChannelDto,
} from "../client/motalvip-apis/generated";
import { usePaymentChannelQuery } from "../client/query-client/usePaymentChannelQuery";
import { useShopFeaturesQuery } from "../client/query-client/useShopFeaturesQuery";
import { Spin } from "../component/common/Spin";
// import { ShopResponse } from "../client/motal-apis/generated";

type ShopContextType = {
  shopId?: string;
  shop?: ShopDto;
  selectedMenu?: string;
  setSelectedMenu: (menuId: string) => void;
  remainingDays: number;
  isOwner: boolean;
  menuData?: {
    key: string;
    label: string;
    icon: ReactNode;
    isRequiredOwner: boolean;
    featureKey?: string;
    children?: {
      key: string;
      label: string;
      isRequiredOwner: boolean;
    }[];
  }[];
  posMenuData?: {
    key: string;
    label: string;
    icon: ReactNode;
    isRequiredOwner: boolean;
    featureKey?: string;
    children?: {
      key: string;
      label: string;
      isRequiredOwner: boolean;
    }[];
  }[];
  shopMenuData?: {
    key: string;
    label: string;
    icon: ReactNode;
    isRequiredOwner: boolean;
    featureKey?: string;
    children?: {
      key: string;
      label: string;
      isRequiredOwner: boolean;
    }[];
  }[];
  features?: ShopFeature;
  paymentChannels?: ShopPaymentChannelDto[];
  isLoading?: boolean;
};

const ShopContext = createContext<ShopContextType>({
  setSelectedMenu: () => {},
  remainingDays: 0,
  isOwner: false,
});

type ShopContextProviderProps = {
  children: ReactNode;
};

export const ShopContextProvider = ({ children }: ShopContextProviderProps) => {
  const menuData = [
    {
      key: "/:shopId",
      label: "หน้าแรก",
      icon: <HomeOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/dashboard",
      label: "รายงาน",
      icon: <QueryStatsOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/reports/statements",
      label: "รายการชำระเงิน",
      icon: <AccountBalanceOutlinedIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/reports/payment-gateway",
      label: "เพย์เม้นท์เกตเวย์",
      icon: <QrCodeOutlinedIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/loan-calculator",
      label: "คำนวณสินเชื่อ",
      icon: <CalculateOutlinedIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/loan",
      label: "ติดตามเคสสินเชื่อ",
      icon: <CreditScoreOutlinedIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/blacklist",
      label: "ตรวจสอบแบล็คลิส",
      icon: <PersonSearchOutlinedIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/down-saving",
      label: "ออมดาวน์",
      icon: <SavingsOutlinedIcon />,
      isRequiredOwner: false,
      featureKey: "downSaving",
    },
    {
      key: "/:shopId/coming-soon",
      label: "จำนำ (กำลังพัฒนา)",
      icon: <PriceCheckIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/coming-soon",
      label: "เคลมซ่อม (กำลังพัฒนา)",
      icon: <ConstructionIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/tracking",
      label: "ติดตาม",
      icon: <LocationOnOutlined />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/verify-slip",
      label: "เช็คสลิปปลอม",
      icon: <PriceCheckIcon />, 
      isRequiredOwner: false,
    },
  ];
  const posMenuData = [
    {
      key: "/:shopId/pos/category-list",
      label: "หมวดหมู่สินค้า",
      icon: <CategoryOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/pos/product-list",
      label: "สินค้า",
      icon: <SellOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/pos/inventory-list",
      label: "สต๊อกสินค้า",
      icon: <Inventory2OutlinedIcon />,
      isRequiredOwner: true,
    },
  ];
  const shopMenuData = [
    {
      key: "/:shopId/settings/shop",
      label: "ข้อมูลร้านค้า",
      icon: <StoreOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/settings/payment-channel",
      label: "ช่องทางการรับเงิน",
      icon: <AccountBalanceOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/settings/calculation-settings",
      label: "ตัวแปรคำนวณสินเชื่อ",
      icon: <CalculateOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/settings/user",
      label: "ผู้ใช้งาน",
      icon: <PeopleOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/settings/device-management",
      label: "เชื่อมต่ออุปกรณ์",
      icon: <PhoneAndroidOutlined />,
      isRequiredOwner: true,
      featureKey: "deviceManagement",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const { shopId } = useParams();
  const [selectedMenu, setSelectedMenu] = useState<string>(
    menuData.find(
      (menu) =>
        menu.key &&
        shopId &&
        menu.key.replace(":shopId", shopId) === location.pathname
    )?.key ||
      shopMenuData.find(
        (menu) =>
          menu.key &&
          shopId &&
          menu.key.replace(":shopId", shopId) === location.pathname
      )?.key ||
      ""
  );
  const shopQuery = useShopQuery(shopId || "");
  const shopFeaturesQuery = useShopFeaturesQuery(shopId || "");
  const paymentChannelsQuery = usePaymentChannelQuery(shopId || "");
  const currentDate = new Date();

  const shop = shopQuery.data;
  const isOwner = shop?.shopUserRole === ShopDto.shopUserRole.OWNER;
  const remainingDays = shop?.expireAt
    ? dayDiff(new Date(shop.expireAt), currentDate)
    : 0;
  const availableMenus = menuData
    .filter((e) => (e.isRequiredOwner ? isOwner : true))
    .filter((menu) => {
      if (menu.featureKey === "downSaving") {
        return shopFeaturesQuery.data?.enableDownSaving;
      }
      return true;
    });
  const availableShopMenus = shopMenuData
    .filter((e) => (e.isRequiredOwner ? isOwner : true))
    .filter((menu) => {
      if (menu.featureKey === "deviceManagement") {
        return process.env.NODE_ENV === "development";
      }
      return true;
    });
  const availablePOSMenus = posMenuData.filter((e) =>
    e.isRequiredOwner ? isOwner : true
  );

  useEffect(() => {
    if (
      !shopQuery.isLoading &&
      (!shopQuery.data || shopQuery.data?.financier)
    ) {
      navigate(`/`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopQuery.isLoading, shopQuery.data]);

  return (
    <ShopContext.Provider
      value={{
        shopId,
        shop,
        selectedMenu,
        setSelectedMenu,
        remainingDays,
        menuData: availableMenus,
        shopMenuData: availableShopMenus,
        posMenuData: availablePOSMenus,
        paymentChannels: paymentChannelsQuery.data,
        isOwner,
        features: shopFeaturesQuery.data,
        isLoading: shopQuery.isLoading || paymentChannelsQuery.isLoading,
      }}
    >
      <Spin
        spinning={
          shopQuery.isLoading ||
          paymentChannelsQuery.isLoading ||
          shopFeaturesQuery.isLoading
        }
      >
        {children}
      </Spin>
    </ShopContext.Provider>
  );
};

export const useShopContext = () => useContext(ShopContext);
