import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InstallmentPlanDto } from "../../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import {
  useContractQuery,
  useInstallmentActivitiesQuery,
  useShopCancelLoanMutation,
  useUpdateLoanDraftBackMutation,
} from "../../../client/query-client/shopFinancier/contract";
import CustomTabPanel from "../../../component/CustomTabPanel";
import DisplayIf from "../../../component/DisplayIf";
import ContractApproval from "../../../layout/ShopFinancierContract/ContractApproval";
import ContractDocuments from "../../../layout/ShopFinancierContract/ContractDocuments";
import ContractSuccess from "../../../layout/ShopFinancierContract/ContractSuccess";
import CustomerDetails from "../../../layout/ShopFinancierContract/CustomerDetails";
import CustomerDocuments from "../../../layout/ShopFinancierContract/CustomerDocuments";
import DownPayment from "../../../layout/ShopFinancierContract/DownPayment";
import ProductDetails from "../../../layout/ShopFinancierContract/ProductDetails";
import TabContractDocumentDetails from "../../../layout/ShopFinancierContract/TabContractDocumentDetails";
import TabInstallmentPlanActivity from "../../../layout/ShopFinancierContract/TabInstallmentActivity";
import TabInstallmentPlanList from "../../../layout/ShopFinancierContract/TabInstallmentPlanList";
import { Spin } from "../../../component/common/Spin";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { StepUpdateLoanDraftCancelRequest } from "../../../client/motalvip-apis/generated";

const ShopFinancierContractCreate: React.FC = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [tabState, setTabState] = useState<number>(0);
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);

  const theme = useTheme();
  const { shopId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const contractId = searchParams.get("contractId");
  const navigate = useNavigate();

  const contract = useContractQuery(contractId || "");
  const installmentActivities = useInstallmentActivitiesQuery(contractId || "");
  const updateLoanDraftBack = useUpdateLoanDraftBackMutation({
    shopId: shopId || "",
    contractId: contractId || "",
  });

  const shopCancelLoan = useShopCancelLoanMutation({
    shopId: shopId || "",
    contractId: contractId || "",
  });

  const schema = yup.object({
    note: yup
      .string()
      .transform((value) => (!value || value === "" ? undefined : value))
      .max(255, "หมายเหตุต้องไม่เกิน 255 ตัวอักษร"),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<StepUpdateLoanDraftCancelRequest>({
    defaultValues: {
      note: "",
    },
    resolver: yupResolver(schema),
  });

  const onCancel = async (): Promise<void> => {
    try {
      handleSubmit(async (data) => {
        await shopCancelLoan.mutateAsync(data);
      })();
      setOpenModalCancel(false);
    } catch (error) {
      console.error(error);
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const handleSetContractStep = (
    status: InstallmentPlanDto.registrationStatus | undefined
  ) => {
    if (
      status === InstallmentPlanDto.registrationStatus.REJECTED &&
      contract.data?.status ===
        InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS
    ) {
      setActiveStep(4);
      return;
    }

    switch (status) {
      case InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_INFO:
        setActiveStep(0);
        break;
      case InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_DOCUMENTS:
        setActiveStep(1);
        break;
      case InstallmentPlanDto.registrationStatus.PENDING_LOAN_AND_DEVICE_INFO:
        setActiveStep(2);
        break;
      case InstallmentPlanDto.registrationStatus.REJECTED:
        setActiveStep(2);
        break;
      case InstallmentPlanDto.registrationStatus.PENDING_APPROVAL:
        setActiveStep(3);
        break;
      case InstallmentPlanDto.registrationStatus.APPROVED:
        switch (contract.data?.status) {
          case InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS:
            setActiveStep(4);
            break;
          case InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT:
            setActiveStep(5);
            break;
          default:
            setActiveStep(6);
            break;
        }
        break;
      default:
        setActiveStep(6);
        break;
    }
  };

  const handleBackStep = async (): Promise<void> => {
    try {
      await updateLoanDraftBack.mutateAsync();
      contract.refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const steps = useMemo(() => {
    return [
      "ข้อมูลลูกค้า",
      "เอกสารลูกค้า",
      "ข้อมูลสินเชื่อเครื่อง",
      contract.data?.status === InstallmentPlanDto.status.DRAFT
        ? "รออนุมัติ"
        : "อนุมัติ",
      "เอกสารสัญญา",
      "จ่ายเงินดาวน์",
      contract.data?.registrationStatus ===
      InstallmentPlanDto.registrationStatus.CANCEL
        ? "ยกเลิก"
        : "สำเร็จ",
    ];
  }, [contract.data?.status, contract.data?.registrationStatus]);

  useEffect(() => {
    if (contract.data) {
      handleSetContractStep(contract.data.registrationStatus);
    } else if (!contractId) {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.data, contractId]);

  return (
    <Spin spinning={contract.isLoading}>
      <Dialog
        open={openModalCancel}
        onClose={() => {
          setOpenModalCancel(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>ยกเลิกสัญญา</DialogTitle>
        <DialogContent>
          <FormControl fullWidth error={!!errors.note} sx={{ mt: 1 }}>
            <FormLabel>หมายเหตุ</FormLabel>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  error={!!errors?.note}
                  helperText={errors?.note?.message}
                  size="small"
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalCancel(false);
            }}
            color="inherit"
            disabled={shopCancelLoan.isPending}
          >
            ยกเลิก
          </Button>
          <Button
            onClick={() => onCancel()}
            type="button"
            variant="contained"
            color="error"
            autoFocus
            disabled={shopCancelLoan.isPending}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
        <Container
          maxWidth={"md"}
          disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
        >
          <Stack p={2} pb={11}>
            <Typography variant="h5" p={2}>
              {"เพิ่มสัญญา"}
            </Typography>
            <Card sx={{ px: 2, py: 4, mb: 2 }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Card>
            <Card sx={{ width: { xs: "100%" } }}>
              <CardContent sx={{ padding: 2, py: 1 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                  }}
                >
                  <Tabs
                    value={tabState}
                    onChange={(_, value) => setTabState(value)}
                    aria-label="tabs"
                    variant="scrollable"
                    scrollButtons={false}
                  >
                    <Tab label="ข้อมูลสัญญา" {...a11yProps(0)} />
                    <Tab
                      label="หลักฐานประกอบสัญญา"
                      {...a11yProps(1)}
                      disabled={(contract.data?.documents ?? []).length <= 0}
                    />
                    <Tab
                      label="ประวัติการใช้งาน"
                      {...a11yProps(2)}
                      disabled={(installmentActivities.data ?? []).length <= 0}
                    />
                    <Tab
                      label="ข้อมูลงวดสัญญา"
                      {...a11yProps(3)}
                      disabled={
                        (contract.data?.installmentTerms ?? []).length <= 0
                      }
                    />
                  </Tabs>
                </Box>

                <CustomTabPanel value={tabState} index={0}>
                  <DisplayIf condition={activeStep === 0}>
                    <CustomerDetails
                      contract={contract.data}
                      shopId={shopId}
                      onSubmit={(contractId) => {
                        setSearchParams({ contractId: contractId || "" });
                        setTimeout(() => {
                          contract.refetch();
                        }, 200);
                      }}
                    />
                  </DisplayIf>

                  <DisplayIf condition={activeStep === 1}>
                    <CustomerDocuments
                      contract={contract.data}
                      shopId={shopId}
                      onSubmit={() => contract.refetch()}
                      onBack={() => handleBackStep()}
                    />
                  </DisplayIf>

                  <DisplayIf condition={activeStep === 2}>
                    <ProductDetails
                      contract={contract.data}
                      shopId={shopId}
                      onSubmit={() => contract.refetch()}
                      onBack={() => handleBackStep()}
                    />
                  </DisplayIf>

                  <DisplayIf condition={activeStep === 3}>
                    <ContractApproval
                      contract={contract.data}
                      shopId={shopId}
                      onSubmit={() => contract.refetch()}
                      onBack={() => handleBackStep()}
                    />
                  </DisplayIf>

                  <DisplayIf condition={activeStep === 4}>
                    <ContractDocuments
                      contract={contract.data}
                      shopId={shopId}
                      onSubmit={() => contract.refetch()}
                    />
                  </DisplayIf>

                  <DisplayIf condition={activeStep === 5}>
                    <DownPayment
                      contract={contract.data}
                      shopId={shopId}
                      onSubmit={() => {
                        contract.refetch();
                        navigate(`/shop-finance/${shopId}/contract`);
                      }}
                      onBack={() => handleBackStep()}
                    />
                  </DisplayIf>

                  <DisplayIf condition={activeStep === 6}>
                    <ContractSuccess
                      contract={contract.data}
                      shopId={shopId}
                      isCancel={
                        contract.data?.registrationStatus ===
                        InstallmentPlanDto.registrationStatus.CANCEL
                      }
                    />
                  </DisplayIf>
                </CustomTabPanel>
                <CustomTabPanel value={tabState} index={1}>
                  <TabContractDocumentDetails contract={contract.data} />
                </CustomTabPanel>
                <CustomTabPanel value={tabState} index={2}>
                  <TabInstallmentPlanActivity
                    activityLogs={installmentActivities.data}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabState} index={3}>
                  <TabInstallmentPlanList contract={contract.data} />
                </CustomTabPanel>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      </LocalizationProvider>

      <Box
        sx={{
          p: 2,
          bgcolor: "white",
          width: { xs: "100%", sm: "calc(100% - 260px)" },
          borderTop: 1,
          borderColor: theme.palette.grey[300],
          position: "fixed",
          bottom: 0,
          zIndex: 99,
        }}
      >
        <Button
          disabled={
            contract.data?.registrationStatus !==
            InstallmentPlanDto.registrationStatus.PENDING_APPROVAL
          }
          fullWidth
          onClick={() => setOpenModalCancel(true)}
          variant="contained"
          size="large"
          color="error"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: 200,
            },
          }}
        >
          ยกเลิกสัญญา
        </Button>
      </Box>
    </Spin>
  );
};

export default ShopFinancierContractCreate;
