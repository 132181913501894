import { Button, Typography, Box, Card } from "@mui/material"
import { CreateCategoryDialog } from "../../../component/product/CreateCategoryDialog"
import { useState } from "react"
import { CategoryList } from "../../../component/product/CategoryList"
import { DeleteCategoryDialog } from "../../../component/product/DeleteCategoryDialog"
import { CategoryResponse } from "../../../client/motalvip-apis/generated"
import { EditCategoryDialog } from "../../../component/product/EditCategoryDialog"

export const CategoryListScreen = () => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deletedCategory, setDeletedCategory] = useState<CategoryResponse | null>(null)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [editedCategory, setEditedCategory] = useState<CategoryResponse | null>(null)

    const onOpenCreateDialog = () => {
        setOpenCreateDialog(true)
    }

    const onCloseCreateDialog = () => {
        setOpenCreateDialog(false)
    }

    const onOpenDeleteDialog = async (category: CategoryResponse) => {
        setDeletedCategory(category)
        setOpenDeleteDialog(true)
    }

    const onCloseDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }

    const onOpenEditDialog = async (category: CategoryResponse) => {
        setEditedCategory(category)
        setOpenEditDialog(true)
    }

    const onCloseEditDialog = () => {
        setOpenEditDialog(false)
    }

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" py={2}>
                <Typography variant="h5" p={2}>หมวดหมู่สินค้า</Typography>
                <Button sx={{ mb: 2 }} variant="contained" size="large" onClick={onOpenCreateDialog}>สร้างหมวดหมู่สินค้าใหม่</Button>
            </Box>
            <Card>
                <CategoryList onOpenDeleteDialog={onOpenDeleteDialog} onOpenEditDialog={onOpenEditDialog} />
            </Card>
            <CreateCategoryDialog open={openCreateDialog} onClose={onCloseCreateDialog} />
            <DeleteCategoryDialog open={openDeleteDialog} onClose={onCloseDeleteDialog} category={deletedCategory} />
            <EditCategoryDialog open={openEditDialog} onClose={onCloseEditDialog} category={editedCategory} />
        </Box>
    )
}
