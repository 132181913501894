import { Box, Typography, Card } from "@mui/material"
import { InventoryList } from "../../../component/product/InventoryList"
import { useState } from "react"
import { ProductDeleteDialog } from "./types"
import { DeleteProductDialog } from "../../../component/product/DeleteProductDialog"
import { GetShopProductsResponse } from "../../../client/motalvip-apis/generated/models/GetShopProductsResponse"

export const InventoryListScreen = () => {
    const [deleteDialogData, setDeleteDialog] = useState<ProductDeleteDialog>({ isOpen: false })

    const handleOpenDeleteDialog = (product: GetShopProductsResponse) => {
        setDeleteDialog({ isOpen: true, product })
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialog({ isOpen: false, product: undefined })
    }

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" py={2}>
                <Typography variant="h5" p={2}>สต๊อกสินค้า</Typography>
            </Box>
            <Card sx={{ px: 2, py: 4 }}>
                <InventoryList onOpenDeleteDialog={handleOpenDeleteDialog} />
            </Card>
            <DeleteProductDialog dialogData={deleteDialogData} onClose={handleCloseDeleteDialog} />
        </Box >
    )
}
