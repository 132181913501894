import { useQueryClient } from "@tanstack/react-query";
import { useDialog } from "../../context/DialogContext";
import { useBackendApiClient } from "../motalvip-apis";
import useSyncMutation from "./common/useSyncMutation";
import { InstallmentPaymentDto } from "../motalvip-apis/generated";

export const usePaymentCreateMutation = (installmentPlanId: string) => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();
    return useSyncMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: { payment: InstallmentPaymentDto }) =>
            apiClient.installmentPayment.createTermPayment({
                requestBody: req.payment,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
        retry: 0,
    });
};