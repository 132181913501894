/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OverdueReportResponse } from '../models/OverdueReportResponse';
import type { PawningContractResponse } from '../models/PawningContractResponse';
import type { SumAcceptPaymentReportResponse } from '../models/SumAcceptPaymentReportResponse';
import type { SumDisbursedLoanReportResponse } from '../models/SumDisbursedLoanReportResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopPawningReportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public profitLossReport(): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/profit-loss-report',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns OverdueReportResponse OK
     * @throws ApiError
     */
    public overdueReport({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<OverdueReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/overdue-report',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns SumDisbursedLoanReportResponse OK
     * @throws ApiError
     */
    public disbursedLoadReport({
        shopId,
        startDate,
        endDate,
    }: {
        shopId: string,
        startDate: string,
        endDate: string,
    }): CancelablePromise<SumDisbursedLoanReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/disbursed-loan-report',
            path: {
                'shopId': shopId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PawningContractResponse OK
     * @throws ApiError
     */
    public cashFlowReport(): CancelablePromise<PawningContractResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/cash-flow-report',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns SumAcceptPaymentReportResponse OK
     * @throws ApiError
     */
    public acceptPaymentReport({
        shopId,
        startDate,
        endDate,
    }: {
        shopId: string,
        startDate: string,
        endDate: string,
    }): CancelablePromise<SumAcceptPaymentReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shops/{shopId}/pawning/accept-payment-report',
            path: {
                'shopId': shopId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
