import { Box, Button, Card, CardContent, Dialog, FormControl, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DownSavingTransactionCreateRequest, ShopPaymentChannelDto } from "../../../../../client/motalvip-apis/generated";
import { useDownSavingTransactionCreateMutation } from "../../../../../client/query-client/downSaving/useDownSavingTransactionCreateMutation";
import { useShopContext } from "../../../../../context/ShopContextProvider";
import CustomInputLabel from "../../../../../component/CustomInputLabel";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";


interface DownSavingTransactionDepositDialogProps {
    downSavingId: string;
    downSavingAmount: number;
    open: boolean
    onClose: () => void;
}

export const DownSavingTransactionDepositDialog = (props: DownSavingTransactionDepositDialogProps) => {

    const shopCtx = useShopContext()
    const downSavingTransactionCreateMutation = useDownSavingTransactionCreateMutation()

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors }
    } = useForm<DownSavingTransactionCreateRequest>({
        defaultValues: {
            shopId: shopCtx.shop?.id || '',
            downSavingId: props.downSavingId,
            direction: DownSavingTransactionCreateRequest.direction.DEPOSIT,
            amount: props.downSavingAmount,
            fineAmount: 0,
            shopPaymentChannelId: ''
        },
        mode: 'all'
    });

    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const onSubmit = () => {
        handleSubmit((data) => {
            downSavingTransactionCreateMutation.mutate(getValues(), {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ')
                    props.onClose()
                },
                onError: (error) => {
                    toast.error('เกิดข้อผิดพลาด')
                    props.onClose()
                }
            });
        })()
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onCloseDialog = () => {
        reset({
            shopId: shopCtx.shop?.id || '',
            downSavingId: props.downSavingId,
            direction: DownSavingTransactionCreateRequest.direction.DEPOSIT,
            amount: props.downSavingAmount,
            fineAmount: 0,
            shopPaymentChannelId: ''
        });
        props.onClose();
    }

    return (

        <Dialog
            fullWidth={isMobile}
            maxWidth={isMobile ? false : 'sm'}
            open={props.open}
            onClose={onCloseDialog}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <Box sx={{ bgcolor: 'white', width: isMobile ? '100%' : theme.breakpoints.values.sm }} >
                <Box sx={{ p: 2, justifyContent: 'space-between', bgcolor: '#263238' }}>
                    <Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
                        <Typography variant="h5" color='white'>{`บันทึกการออม`}</Typography>
                    </Stack>
                </Box>
                <Box sx={{ p: 2, width: '100%', bgcolor: 'white' }}>
                    <Card sx={{ my: 1, width: { md: '100%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack direction={'column'} gap={2}>
                                <Typography variant="h6">ข้อมูลการออม</Typography>
                                <Stack direction={{ sm: 'column' }} gap={2}>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>วันที่</CustomInputLabel>
                                        <Controller
                                            control={control}
                                            name="transactionAt"
                                            render={({ field }) => (
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    value={field.value ? dayjs(field.value) : null}
                                                    onChange={(date) =>
                                                        field.onChange(
                                                            date ? date.format("YYYY-MM-DD") : ""
                                                        )
                                                    }
                                                    slotProps={{
                                                        textField: { size: "small", variant: "outlined" },
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.transactionAt && (
                                            <Typography color="error">
                                                {errors.transactionAt.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth error={errors.amount?.message ? true : false}>
                                        <CustomInputLabel>ยอดออม</CustomInputLabel>
                                        <Controller
                                            name="amount"
                                            control={control}
                                            rules={{
                                                min: { value: props.downSavingAmount, message: 'ต้องมากกว่าขั้นต่ำ' },
                                                max: { value: 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(999999999)}` }
                                            }}
                                            render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.amount && <Typography color="error">{errors.amount.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={errors.fineAmount?.message ? true : false}>
                                        <CustomInputLabel>ค่าปรับ</CustomInputLabel>
                                        <Controller
                                            name="fineAmount"
                                            control={control}
                                            rules={{
                                                min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' },
                                                max: { value: 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(999999999)}` }
                                            }}
                                            render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.fineAmount && <Typography color="error">{errors.fineAmount.message}</Typography>}
                                    </FormControl>
                                    <FormControl
                                        sx={{ width: { xs: "100%" } }}
                                        error={
                                            errors.shopPaymentChannelId?.message
                                                ? true
                                                : false
                                        }
                                    >
                                        <CustomInputLabel>
                                            ช่องทางการรับเงิน
                                        </CustomInputLabel>
                                        <Controller
                                            control={control}
                                            name="shopPaymentChannelId"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "ต้องการช่องทางรับเงิน",
                                                },
                                            }}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        labelId="paymentChannel-label"
                                                        id="paymentChannel"
                                                        size="small"
                                                        value={field.value}
                                                    >
                                                        {shopCtx.paymentChannels?.map((p) => (
                                                            <MenuItem key={p.id} value={`${p.id}`}>
                                                                {p.type ===
                                                                    ShopPaymentChannelDto.type.BANK_ACCOUNT
                                                                    ? `${p.bankCode} : ${p.accountId} : ${p.accountName}`
                                                                    : p.type ===
                                                                        ShopPaymentChannelDto.type.CASH
                                                                        ? "เงินสด"
                                                                        : p.type ===
                                                                            ShopPaymentChannelDto.type.PROMPT_PAY_QR
                                                                            ? `คิวอาร์ : ${p.accountName}`
                                                                            : p.type ===
                                                                                ShopPaymentChannelDto.type.PROMPT_PAY
                                                                                ? `พ้อมเพย์ : ${p.accountId} : ${p.accountName}`
                                                                                : ""}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                );
                                            }}
                                        />
                                        {errors.shopPaymentChannelId && (
                                            <Typography color="error">
                                                {errors.shopPaymentChannelId.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                </Box>
                <Box sx={{ p: 2, pt: 0, bgcolor: 'white', width: '100%' }} >
                    <Stack direction='row' gap={1} justifyContent='center'>
                        <Button variant='outlined' size='large'
                            onClick={onCloseDialog}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>ยกเลิก</Button>
                        <Button variant='contained' size='large'
                            onClick={onSubmit}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>บันทึก</Button>
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    );
};