import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useQueryState } from "nuqs";
import { ReactNode, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  GatewayPaymentDto,
  GatewayPaymentService,
  InstallmentPaymentReportDto,
  QrPaymentStatusCheckResponse,
} from "../../../client/motalvip-apis/generated";
import { useCheckPaymentStatus } from "../../../client/query-client/useCheckPaymentStatus";
import { useDeleteGatewayPayment } from "../../../client/query-client/useDeleteGatewayPayment";
import { useSearchPayment } from "../../../client/query-client/usePaymentSearch";
import { formatShortReadableThaiDate } from "../../../util/DateUtil";
import { useDebouncedValue } from "../../../util/useDebouncedValue";
import { ViewQRDialogButton } from "./ViewQrDialogButton";

type SearchType = Parameters<
  GatewayPaymentService["searchGatewayPayments"]
>[0]["type"];

function mapStatusToText(status?: string): ReactNode {
  switch (status) {
    case "SUCCESS":
      return "สำเร็จ";
    case "CANCEL":
      return "ยกเลิก";
    case "PENDING":
      return "รอดำเนินการ";
    case "COMPLETED":
      return "เสร็จสิ้น";
    case "OVERDUE":
      return "เลยกำหนด";
    default:
      return "ไม่ทราบสถานะ";
  }
}

export const PaymentHistory = ({ type }: { type: SearchType }) => {
  const { shopId, installmentPlanId, downSavingId } = useParams();
  const [page, setPage] = useQueryState<number>("page", {
    defaultValue: 0,
    parse: (v) => Number(v) || 0,
  });
  const [rowsPerPage, setRowsPerPage] = useQueryState<number>("20", {
    defaultValue: 20,
    parse: (v) => Number(v) || 20,
  });
  const [search, setSearch] = useQueryState("");
  const debouncedSearch = useDebouncedValue(search ?? "", 250);
  const [sortBy, setSortBy] = useQueryState<keyof GatewayPaymentDto>("sortBy", {
    defaultValue: "createdAt",
    parse: (v) => v as keyof GatewayPaymentDto,
  });
  const [sortDirection, setSortDirection] = useQueryState<"asc" | "desc">(
    "sortDirection",
    {
      defaultValue: "desc",
      parse: (v) =>
        (["asc", "desc"].includes(v) ? v : "desc") as "asc" | "desc",
    }
  );
  const isDateValid = (date: string) => {
    return new Date(date).toString() !== "Invalid Date";
  };
  const [startDate, setStartDate] = useQueryState<Dayjs | null>(
    "startDate",
    useMemo(
      () => ({
        defaultValue: null,
        parse: (v) => {
          if (isDateValid(v)) {
            return dayjs(v);
          }
          return null;
        },
      }),
      []
    )
  );
  const [endDate, setEndDate] = useQueryState<Dayjs | null>(
    "endDate",
    useMemo(
      () => ({
        defaultValue: dayjs(new Date()).endOf("day"),
        parse: (v) => {
          if (isDateValid(v)) {
            return dayjs(v);
          }
          return null;
        },
      }),
      []
    )
  );
  const [status, setStatus] = useQueryState<GatewayPaymentDto.status | "all">(
    "status",
    {
      defaultValue: "all",
      parse: (v) => v as GatewayPaymentDto.status | "all",
    }
  );

  const [idOfPaymentToDelete, setIdOfPaymentToDelete] = useState("");

  const { mutateAsync } = useDeleteGatewayPayment({});

  const handleDeleteClicked = async (
    paymentId: string,
    referenceId: string
  ) => {
    const yes = window.confirm(
      `ต้องการลบข้อมูลเลขอ้างอิง ${referenceId} ใช่หรือไม่`
    );
    if (!yes) return;

    setIdOfPaymentToDelete(paymentId);
    try {
      await mutateAsync(paymentId);
      refetchSearch();
      toast.success("ลบข้อมูลสำเร็จ");
    } catch (e) {
      toast.error("ไม่สามารถลบข้อมูลได้");
      console.error(e);
      return;
    } finally {
      setIdOfPaymentToDelete("");
    }
  };

  const parameters = useMemo(() => {
    return {
      type,
      loanId: installmentPlanId ?? "",
      shopId: shopId ?? "",
      downSavingId: downSavingId ?? "",
      status: status === "all" ? undefined : status,
      from: startDate?.toISOString() ?? "",
      to: endDate?.toISOString() ?? "",
      direction: sortDirection?.toUpperCase(),
      page,
      search: debouncedSearch,
      limit: Number(rowsPerPage) || 20,
      sortBy,
    } satisfies Parameters<typeof useSearchPayment>[0];
  }, [
    type,
    installmentPlanId,
    shopId,
    downSavingId,
    status,
    startDate,
    endDate,
    sortDirection,
    page,
    debouncedSearch,
    rowsPerPage,
    sortBy,
  ]);

  const { data, refetch: refetchSearch } = useSearchPayment(parameters);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property: keyof GatewayPaymentDto) => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const [idOfPaymentToCheck, setIdOfPaymentToCheck] = useState<string | null>(
    null
  );

  const { mutate, isPending: isCheckingStatus } = useCheckPaymentStatus({
    onError: (e) => {
      console.error(e);
      toast.error("ไม่สามารถเช็คสถานะของ QR ได้");
      setIdOfPaymentToCheck(null);
    },
    onSuccess: (resp) => {
      setIdOfPaymentToCheck(null);
      const map: Record<QrPaymentStatusCheckResponse.status, () => void> = {
        DECLINE: () => {
          toast.error("สถานะถูกปฏิเสธ");
        },
        PENDING: () => {
          toast.success("ยังไม่มีการชำระเงิน", {
            icon: null,
          });
        },
        SUCCESS: () => {
          refetchSearch();
          toast.success("สถานะการชำระเงินถูกต้อง อัพเดทสถานะเรียบร้อย");
        },
      };
      map[resp.status]?.();
    },
  });

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        sx={{
          gap: "16px",
          alignItems: "Center",
          flexDirection: { xs: "column", md: "row" },
        }}
        mb={2}
        mt={2}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            width: "100%",
          }}
        >
          <TextField
            sx={{
              margin: 0,
              padding: 0,
            }}
            label="ค้นหาด้วยเลขอ้างอิง หรือชื่อลูกค้า"
            value={search}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
          <DatePicker
            sx={{
              width: { xs: "100%", md: "fit-content" },
            }}
            label="วันที่เริ่มต้น"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
          <DatePicker
            sx={{
              width: { xs: "100%", md: "fit-content" },
            }}
            label="วันที่สิ้นสุด"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          maxWidth: "600px",
          width: "100%",
          gap: 2,
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            sx={{
              flex: 1,
            }}
            value={status}
            onChange={(v) =>
              setStatus(v.target.value as GatewayPaymentDto.status | "all")
            }
          >
            <MenuItem value={"all"}>ทั้งหมด</MenuItem>
            {Object.values(InstallmentPaymentReportDto.status).map((status) => (
              <MenuItem value={status} key={status}>
                {mapStatusToText(status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={sortBy === "reference" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "reference"}
                  direction={sortBy === "reference" ? sortDirection : "asc"}
                  onClick={() => handleSort("reference")}
                >
                  เลขอ้างอิง
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "customerFullName" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "customerFullName"}
                  direction={
                    sortBy === "customerFullName" ? sortDirection : "asc"
                  }
                  onClick={() => handleSort("customerFullName")}
                >
                  ชื่อลูกค้า
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "createdAt" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "createdAt"}
                  direction={sortBy === "createdAt" ? sortDirection : "asc"}
                  onClick={() => handleSort("createdAt")}
                >
                  วันจ่าย
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "amount" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "amount"}
                  direction={sortBy === "amount" ? sortDirection : "asc"}
                  onClick={() => handleSort("amount")}
                >
                  จำนวนที่จ่าย
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "status" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sortBy === "status" ? sortDirection : "asc"}
                  onClick={() => handleSort("status")}
                >
                  สถานะ
                </TableSortLabel>
              </TableCell>
              <TableCell>ดูข้อมูล</TableCell>
              <TableCell>ตรวจสอบสถานะ</TableCell>
              <TableCell>ลบ</TableCell>
              <TableCell>สร้างโดย</TableCell>
              <TableCell>ลบโดย</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.content?.length ?? 0) > 0 ? (
              data!.content!.map((payment: GatewayPaymentDto) => (
                <TableRow key={payment.id}>
                  <TableCell>{payment.reference ?? "-"}</TableCell>
                  <TableCell>{payment.customerFullName}</TableCell>
                  <TableCell>
                    {formatShortReadableThaiDate(
                      new Date(payment.createdAt),
                      true
                    )}
                  </TableCell>
                  <TableCell>
                    {Number(payment.amount?.toFixed(2) ?? 0).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Chip label={mapStatusToText(payment.status)} />
                  </TableCell>
                  <TableCell>
                    <ViewQRDialogButton paymentId={payment.id}>
                      QR
                    </ViewQRDialogButton>
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        width: "130px",
                      }}
                      variant="outlined"
                      onClick={() => {
                        setIdOfPaymentToCheck(payment.id);
                        mutate({
                          requestBody: {
                            paymentId: payment.id,
                          },
                        });
                      }}
                    >
                      {isCheckingStatus && payment.id === idOfPaymentToCheck
                        ? "กำลังตรวจสอบ"
                        : "ตรวจสอบ"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      style={{
                        background:
                          idOfPaymentToDelete === payment.id
                            ? "grey"
                            : "crimson",
                        color: "white",
                        width: "90px",
                      }}
                      disabled={idOfPaymentToDelete === payment.id}
                      onClick={() => {
                        handleDeleteClicked(payment.id, payment.reference);
                      }}
                    >
                      {payment.id === idOfPaymentToDelete ? "กำลังลบ" : "ลบ"}
                    </Button>
                  </TableCell>
                  <TableCell>{payment.createdBy ?? "-"}</TableCell>
                  <TableCell>{payment.deletedBy ?? "-"}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box sx={{ textAlign: "center", p: 3 }}>
                    <Typography variant="h6">ไม่พบรายการจ่ายเงิน</Typography>
                    <Typography variant="body2">
                      กรุณาลองค้นหาอีกครั้ง หรือตรวจสอบเงื่อนไขการค้นหา
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data?.pagination?.totalElements ?? 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
