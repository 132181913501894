import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardMedia,
  IconButton,
  SxProps,
  Theme,
  DialogContent,
  Dialog,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { DocumentDto } from "../client/motalvip-apis/generated";
import CloseIcon from "@mui/icons-material/Close";

// Props interface
interface FileUploadWithPreviewProps {
  title: string;
  document: DocumentDto | undefined;
  onChange: (file: File | null) => void;
  onRemove: () => void;
  accept?: string;
  maxFileSize?: number; // in bytes
  sx?: SxProps<Theme>;
  disableDelete?: boolean;
  disableUpload?: boolean;
}

const FileUploadWithPreview: React.FC<FileUploadWithPreviewProps> = ({
  title,
  document,
  onChange,
  onRemove,
  accept = "image/*",
  maxFileSize = 5 * 1024 * 1024,
  sx,
  disableDelete,
  disableUpload,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);

    const file = event.target.files?.[0] || null;

    if (file) {
      if (file.size > maxFileSize) {
        setError(`ขนาดไฟล์ต้องไม่เกิน ${maxFileSize / 1024 / 1024} MB`);
        return;
      }

      onChange(file);
    }
  };

  return (
    <Box sx={sx}>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <img
            src={document?.downloadUrl}
            alt=""
            style={{
              objectFit: "contain",
              maxWidth: "80vh",
              width: "100%",
            }}
          />
        </DialogContent>
      </Dialog>

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {title}
      </Typography>

      {error && (
        <Box sx={{ color: "error.main", mb: 2 }}>
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}

      {!document && (
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{ mb: 2 }}
          disabled={disableUpload}
        >
          อัปโหลดไฟล์
          <input
            type="file"
            hidden
            accept={accept}
            onClick={(e) => {
              (e.target as HTMLInputElement).value = "";
            }}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </Button>
      )}

      <Grid container spacing={2}>
        {document && (
          <Grid item xs={12}>
            <Card
              sx={{
                position: "relative",
                height: 140,
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(true)}
                image={document.downloadUrl}
                alt={document.originalName}
              />
              {!disableDelete && (
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => onRemove()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "hite",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FileUploadWithPreview;
