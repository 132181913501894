/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetShopLoanCalculationSettingsResponse = {
    shopId: string;
    type: GetShopLoanCalculationSettingsResponse.type;
    roundNumbers: boolean;
    jsonValue: string;
    shopName: string;
};
export namespace GetShopLoanCalculationSettingsResponse {
    export enum type {
        ANDROID = 'ANDROID',
        IOS = 'IOS',
    }
}

