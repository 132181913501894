/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DownSavingResponse = {
    id: string;
    shopId: string;
    startDate: string;
    contractReferenceId: string;
    customerFirstName: string;
    customerLastName: string;
    customerCitizenId?: string;
    customerPhoneNumber?: string;
    customerEmail?: string;
    customerAddress?: string;
    customerFacebookUrl?: string;
    customerLineId?: string;
    savingAmount: number;
    savingFrequency: DownSavingResponse.savingFrequency;
    productBrand?: string;
    productModel?: string;
    productPrice: number;
    downPercentage: number;
    downAmount: number;
    balanceAmount: number;
    status: DownSavingResponse.status;
    nextDueDate?: string;
    nextDueDateDiff?: number;
};
export namespace DownSavingResponse {
    export enum savingFrequency {
        DAILY = 'DAILY',
        THREE_DAYS = 'THREE_DAYS',
        WEEKLY = 'WEEKLY',
        BIWEEKLY = 'BIWEEKLY',
        MONTHLY = 'MONTHLY',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        CLOSED = 'CLOSED',
        CANCELLED = 'CANCELLED',
        WITHDRAWN = 'WITHDRAWN',
    }
}

