import { Typography, Button, Box, Grid, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import InfoItem from "../../component/InfoItem";
import {
  InstallmentPlanDocumentDto,
  InstallmentPlanDto,
} from "../../client/motalvip-apis/generated";
import FileUploadWithPreview from "../../component/FileUploadWithPreview";

interface IFileUpload {
  document: InstallmentPlanDocumentDto;
}

interface IProps {
  shopId: string | null | undefined;
  contract: InstallmentPlanDto | undefined;
  isCancel: boolean;
}

const ContractSuccess: React.FC<IProps> = (props): JSX.Element => {
  const { contract, isCancel } = props;

  const [showData, setShowData] = useState<boolean>(false);
  const [fileUploads, setFileUploads] = useState<IFileUpload | null>(null);

  const getInstallmentFrequencyText = (
    installmentFrequency: number | undefined,
    installmentFrequencyType:
      | InstallmentPlanDto.installmentFrequencyType
      | undefined
  ): string => {
    if (!installmentFrequency || !installmentFrequencyType) return "-";

    let unit: string;

    switch (installmentFrequencyType) {
      case InstallmentPlanDto.installmentFrequencyType.DAY:
        unit = "วัน";
        break;
      default:
        unit = "เดือน";
        break;
    }

    return `${installmentFrequency} ${unit}`;
  };

  useEffect(() => {
    const doc = contract?.documents?.find(
      (doc) => doc.type === InstallmentPlanDocumentDto.type.DEVICE_IMAGE
    );
    if (doc) {
      setFileUploads({
        document: doc,
      });
    }
  }, [contract]);

  return !showData ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center", p: 4, maxWidth: 400 }}>
        {isCancel ? (
          <>
            <CancelIcon sx={{ fontSize: 80, color: "red" }} />
            <Typography variant="h5" sx={{ mt: 2 }}>
              การดำเนินการถูกยกเลิก
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, color: "gray" }}>
              คำขอของคุณไม่ได้ถูกบันทึก
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleIcon sx={{ fontSize: 80, color: "green" }} />
            <Typography variant="h5" sx={{ mt: 2 }}>
              การดำเนินการสำเร็จ!
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, color: "gray" }}>
              ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว
            </Typography>
          </>
        )}

        <Button
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => setShowData(true)}
        >
          ดูรายละเอียดสัญญา
        </Button>
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="h6" gutterBottom>
        ข้อมูลลูกค้า
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="รหัสอ้างอิง" value={contract?.referenceId} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="ชื่อ" value={contract?.profile?.firstName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="นามสกุล" value={contract?.profile?.lastName} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="หมายเลขประจำตัวประชาชน"
            value={contract?.profile?.citizenId}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="หมายเลขพาสปอร์ต"
            value={contract?.profile?.passportNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="อาชีพ" value={contract?.profile?.occupation} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="เบอร์โทรศัพท์"
            value={contract?.profile?.phoneNumbers?.[0].phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="อีเมล" value={contract?.profile?.email} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ชื่อ Facebook"
            value={contract?.profile?.facebookName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="Facebook URL"
            value={contract?.profile?.facebookUrl}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="LINE ID" value={contract?.profile?.lineId} />
        </Grid>
        <Grid item xs={12}>
          <InfoItem label="สถานที่ทำงาน" value={contract?.profile?.workPlace} />
        </Grid>
        <Grid item xs={12}>
          <InfoItem
            label="ที่อยู่ตามบัตรประชาชน"
            value={contract?.profile?.citizenAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoItem
            label="ที่อยู่ปัจจุบัน"
            value={contract?.profile?.currentAddress}
          />
        </Grid>
      </Grid>

      {/* Reference Person 1 Section */}
      <Typography variant="h6" gutterBottom>
        บุคคลอ้างอิง 1
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ชื่อ"
            value={contract?.profile?.firstReferencePersonFirstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="นามสกุล"
            value={contract?.profile?.firstReferencePersonLastName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ความสัมพันธ์"
            value={contract?.profile?.firstReferencePersonRelation}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="เบอร์โทรศัพท์"
            value={contract?.profile?.firstReferencePersonPhoneNumber}
          />
        </Grid>
      </Grid>

      {/* Reference Person 2 Section */}
      <Typography variant="h6" gutterBottom>
        บุคคลอ้างอิง 2
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ชื่อ"
            value={contract?.profile?.secondReferencePersonFirstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="นามสกุล"
            value={contract?.profile?.secondReferencePersonLastName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ความสัมพันธ์"
            value={contract?.profile?.secondReferencePersonRelation}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="เบอร์โทรศัพท์"
            value={contract?.profile?.secondReferencePersonPhoneNumber}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      {/* Loan Information Section */}
      <Typography variant="h6" gutterBottom>
        ข้อมูลสินเชื่อ
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ราคาสินค้า"
            value={
              contract?.product?.purchaseCost
                ? `${contract?.product?.purchaseCost?.toLocaleString()} บาท`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="เปอร์เซ็นต์เงินดาวน์"
            value={
              contract?.downPaymentPercent
                ? `${contract?.downPaymentPercent}%`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="จำนวนงวด"
            value={
              contract?.installmentCount
                ? `${contract?.installmentCount} เดือน`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ยอดเงินผ่อนต่องวด"
            value={
              contract?.installmentAmount
                ? `${contract?.installmentAmount?.toLocaleString()} บาท`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="เงินดาวน์"
            value={
              contract?.downPaymentAmount
                ? `${contract?.downPaymentAmount?.toLocaleString()} บาท`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ราคาเช่าซื้อรวม"
            value={
              contract?.loanBalance
                ? `${contract?.loanBalance?.toLocaleString()} บาท`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="วันที่จ่ายเงินดาวน์"
            value={
              contract?.downPaymentDate
                ? new Date(contract?.downPaymentDate).toLocaleDateString(
                    "th-TH"
                  )
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="วันที่เริ่มผ่อนงวดแรก"
            value={
              contract?.installmentStartDate
                ? new Date(contract?.installmentStartDate).toLocaleDateString(
                    "th-TH"
                  )
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ระยะเวลาชำระต่องวด"
            value={getInstallmentFrequencyText(
              contract?.installmentFrequency,
              contract?.installmentFrequencyType
            )}
          />
        </Grid>
      </Grid>

      {/* Device Information Section */}
      <Typography variant="h6" gutterBottom>
        ข้อมูลเครื่อง
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="ประเภทสินค้า"
            value={
              contract?.product?.usedType === "NEW"
                ? "สินค้าใหม่"
                : "สินค้าใช้แล้ว"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="แบรนด์" value={contract?.product?.brand} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="รุ่น" value={contract?.product?.model} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="สี" value={contract?.product?.color} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem label="IMEI" value={contract?.product?.imei} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem
            label="Serial Number"
            value={contract?.product?.serialNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoItem label="รายละเอียด" value={contract?.product?.description} />
        </Grid>

        {fileUploads && (
          <Grid item xs={12}>
            <FileUploadWithPreview
              title="รูปสินค้า"
              document={fileUploads?.document}
              onChange={() => {}}
              onRemove={() => {}}
              disableDelete
              disableUpload
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ContractSuccess;
