import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";
import { DownSavingResponse } from "../../motalvip-apis/generated";

export const useDownSavingSearchQuery = (request: {
    shopId: string,
    searchText: string,
    startDateFrom?: string,
    startDateTo?: string,
    scheduledDateFrom?: string,
    scheduledDateTo?: string,
    status: DownSavingResponse.status[]
}) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: ["downSaving",
            request.shopId,
            request.searchText,
            request.startDateFrom,
            request.startDateTo,
            request.scheduledDateFrom,
            request.scheduledDateTo,
            request.status
        ],
        queryFn: () => apiClient.downSaving.searchDownSavings({
            shopId: request.shopId,
            searchText: request.searchText,
            startDateFrom: request.startDateFrom,
            startDateTo: request.startDateTo,
            scheduledDateFrom: request.scheduledDateFrom,
            scheduledDateTo: request.scheduledDateTo,
            status: request.status
        }),
    });
}