/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DownSavingResponse } from '../models/DownSavingResponse';
import type { DownSavingTransactionResponse } from '../models/DownSavingTransactionResponse';
import type { FinancierDto } from '../models/FinancierDto';
import type { GenerateDownSavingQRRequest } from '../models/GenerateDownSavingQRRequest';
import type { GenerateGatewayPaymentQRResponse } from '../models/GenerateGatewayPaymentQRResponse';
import type { GenerateLoanGatewayPaymentQRRequest } from '../models/GenerateLoanGatewayPaymentQRRequest';
import type { GeneratePawningPaymentGatewayQRRequest } from '../models/GeneratePawningPaymentGatewayQRRequest';
import type { GetShopLoanCalculationSettingsResponse } from '../models/GetShopLoanCalculationSettingsResponse';
import type { InstallmentPlanDto } from '../models/InstallmentPlanDto';
import type { PaginatedMasterDeviceBrandDto } from '../models/PaginatedMasterDeviceBrandDto';
import type { PaginatedMasterDeviceModelDto } from '../models/PaginatedMasterDeviceModelDto';
import type { QrPaymentAcknowledgementRequest } from '../models/QrPaymentAcknowledgementRequest';
import type { QrPaymentStatusCheckRequest } from '../models/QrPaymentStatusCheckRequest';
import type { QrPaymentStatusCheckResponse } from '../models/QrPaymentStatusCheckResponse';
import type { ShopDto } from '../models/ShopDto';
import type { ShopPaymentChannelDto } from '../models/ShopPaymentChannelDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns QrPaymentStatusCheckResponse OK
     * @throws ApiError
     */
    public checkQrPaymentStatus({
        requestBody,
    }: {
        requestBody: QrPaymentStatusCheckRequest,
    }): CancelablePromise<QrPaymentStatusCheckResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/webhooks/feelfreepay/qrcode/status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public ackPawningRedeemQrPayment({
        requestBody,
    }: {
        requestBody: QrPaymentAcknowledgementRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/webhooks/feelfreepay/qrcode/ack/pawning/redeem',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public ackPawningAcceptQrPayment({
        requestBody,
    }: {
        requestBody: QrPaymentAcknowledgementRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/webhooks/feelfreepay/qrcode/ack/pawning/accept-payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public ackLoanQrPayment({
        requestBody,
    }: {
        requestBody: QrPaymentAcknowledgementRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/webhooks/feelfreepay/qrcode/ack/loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public ackDownSavingQrPayment({
        requestBody,
    }: {
        requestBody: QrPaymentAcknowledgementRequest,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/webhooks/feelfreepay/qrcode/ack/down-saving',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GenerateGatewayPaymentQRResponse OK
     * @throws ApiError
     */
    public generatePawningQrPaymentRequest({
        requestBody,
    }: {
        requestBody: GeneratePawningPaymentGatewayQRRequest,
    }): CancelablePromise<GenerateGatewayPaymentQRResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/v2/shops/payment/qr/pawning',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GenerateGatewayPaymentQRResponse OK
     * @throws ApiError
     */
    public generateLoanQrPaymentRequest({
        requestBody,
    }: {
        requestBody: GenerateLoanGatewayPaymentQRRequest,
    }): CancelablePromise<GenerateGatewayPaymentQRResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/v2/shops/payment/qr/loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GenerateGatewayPaymentQRResponse OK
     * @throws ApiError
     */
    public generateDownSavingQrPaymentRequest({
        requestBody,
    }: {
        requestBody: GenerateDownSavingQRRequest,
    }): CancelablePromise<GenerateGatewayPaymentQRResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/v2/shops/payment/qr/down-saving',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public recalculateInstallmentPlan1({
        installmentPlanId,
    }: {
        installmentPlanId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/v2/migrate/recalculate/{installmentPlanId}',
            path: {
                'installmentPlanId': installmentPlanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public getShopPublicProfile({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public getShopPublicPaymentChannels({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Array<ShopPaymentChannelDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/{shopId}/payment-channels',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GetShopLoanCalculationSettingsResponse OK
     * @throws ApiError
     */
    public getShopLoanCalculationSettings({
        shopId,
        type,
    }: {
        shopId: string,
        type: 'ANDROID' | 'IOS',
    }): CancelablePromise<GetShopLoanCalculationSettingsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/shop-settings/loan-calculation/{shopId}/{type}',
            path: {
                'shopId': shopId,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public getPublicLoanById({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingResponse OK
     * @throws ApiError
     */
    public getPublicDownSavingById({
        downSavingId,
    }: {
        downSavingId: string,
    }): CancelablePromise<DownSavingResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/down-saving/{downSavingId}',
            path: {
                'downSavingId': downSavingId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns DownSavingTransactionResponse OK
     * @throws ApiError
     */
    public getPublicDownSavingTransactions({
        downSavingId,
    }: {
        downSavingId: string,
    }): CancelablePromise<Array<DownSavingTransactionResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/down-saving/{downSavingId}/transactions',
            path: {
                'downSavingId': downSavingId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJob(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-users',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateShops(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-shops',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigratePayments(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-payments',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerNotesMigration(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-notes',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateLoans(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-loans',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateCustomers(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-customers',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateCustomers1({
        customerId,
    }: {
        customerId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-customers/{customerId}',
            path: {
                'customerId': customerId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobFixInstallmentPlan(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/fix',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedMasterDeviceModelDto OK
     * @throws ApiError
     */
    public getMasterDeviceModels({
        model,
        brand,
        limit,
        page,
    }: {
        model: string,
        brand: string,
        limit: number,
        page: number,
    }): CancelablePromise<PaginatedMasterDeviceModelDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/master/devices/models',
            query: {
                'model': model,
                'brand': brand,
                'limit': limit,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedMasterDeviceBrandDto OK
     * @throws ApiError
     */
    public getMasterDeviceBrands({
        query,
        limit,
        page,
    }: {
        query: string,
        limit: number,
        page: number,
    }): CancelablePromise<PaginatedMasterDeviceBrandDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/master/devices/brands',
            query: {
                'query': query,
                'limit': limit,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierDto OK
     * @throws ApiError
     */
    public getFinancierPublicProfile({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<FinancierDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/financiers/{financierId}',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public getPublicLoanById1({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/financiers/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
