import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormLabel, Stack, TextField, Typography } from "@mui/material"
import { useCategoryEditMutation } from "../../client/query-client/useCategoryQuery"
import { CategoryResponse } from "../../client/motalvip-apis/generated"
import { Controller, useForm } from "react-hook-form"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

interface EditCategoryForm {
    name: string
}

interface Props {
    open: boolean
    onClose: () => void
    category: CategoryResponse | null
}

export const EditCategoryDialog = ({ open, onClose, category }: Props) => {
    const { shopId } = useParams()
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm<EditCategoryForm>({
        mode: 'all'
    })
    const { mutateAsync: editCategory, isPending } = useCategoryEditMutation()

    const editedName = watch('name')

    useEffect(() => {
        if (category?.name) {
            setValue('name', category.name)
        }
    }, [category, setValue])

    const onSubmit = async ({ name }: EditCategoryForm) => {
        if (category?.id && name) {
            await editCategory({
                categoryId: category.id,
                shopId: shopId!,
                name
            })
            onClose()
        }
    }

    const onDialogClose = () => {
        if (category?.name) {
            setValue('name', category.name)
        }
        onClose()
    }

    return (
        <Dialog open={open && !!category} onClose={onDialogClose}>
            <DialogTitle>แก้ไขหมวดหมู่สินค้า</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack gap={2}>
                        <FormControl fullWidth error={!!errors.name}>
                            <FormLabel>ชื่อหมวดหมู่สินค้า</FormLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "กรุณาระบุ" }}
                                render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                            />
                            {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                        </FormControl>
                        <Stack direction='row' gap={4} marginTop={2}>
                            <Button size='large' variant='outlined' type="button" onClick={onDialogClose} fullWidth>
                                ยกเลิก
                            </Button>
                            <Button size='large' variant='contained' type="submit" disabled={isPending || (category?.name === editedName)} fullWidth>
                                ยืนยัน
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    )
}
