import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";
import { DownSavingCreateRequest, DownSavingResponse } from "../../motalvip-apis/generated";
import { useDialog } from "../../..//context/DialogContext";

export const useDownSavingCreateMutation = () => {
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: ['downSaving'],
        mutationFn: (req: DownSavingCreateRequest) =>
            backendApiClient.downSaving.createDownSaving({
                requestBody: req,
            }),
        onSuccess: (res: DownSavingResponse) => {
            queryClient.invalidateQueries({ queryKey: ['downSaving', res.id] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
        retry: 0,
    });
}