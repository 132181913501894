import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DownSavingResponse } from "../../../client/motalvip-apis/generated";
import { useDownSavingSearchQuery } from "../../../client/query-client/downSaving/useDownSavingSearchQuery";
import { useDownSavingSearchSummaryQuery } from "../../../client/query-client/downSaving/useDownSavingSearchSummaryQuery";
import { Spin } from "../../../component/common/Spin";
import { DownSavingStatusChip } from "../../../component/display/DownSavingStatusChip";
import PaymentChannelRecord from "../../../component/PaymentChannelRecord";
import { useShopContext } from "../../../context/ShopContextProvider";

export const downSavingStatusOptions = [
  { value: DownSavingResponse.status.ACTIVE, display: "กำลังออม" },
  { value: DownSavingResponse.status.WITHDRAWN, display: "ถอนเงิน" },
  { value: DownSavingResponse.status.CLOSED, display: "เปิดสินเชื่อ" },
  { value: DownSavingResponse.status.CANCELLED, display: "ยกเลิก" }
];

export const DownSavingScreen = () => {
  const navigate = useNavigate();
  const { shopId } = useParams();

  const SEARCH = "search";
  const STATUS = "status";
  const START_DATE_FROM = "startDateFrom";
  const START_DATE_TO = "startDateTo";
  const DUE_DATE_PERIOD = "dueDatePeriod";
  const [searchParams, setSearchParams] = useSearchParams();

  // Filter
  const [filterSearchText, setFilterSearchText] = useState(searchParams.get(SEARCH) || "");
  const [filterStatus, setFilterStatus] = useState<
    { value: DownSavingResponse.status; display: string }[]
  >(
    downSavingStatusOptions.filter((o) =>
      searchParams.getAll(STATUS).includes(o.value)
    )
  );
  const [filterStartDateFrom, setFilterStartDateFrom] = useState<
    string | undefined
  >(searchParams.get(START_DATE_FROM) || undefined);
  const [filterStartDateTo, setFilterStartDateTo] = useState<
    string | undefined
  >(searchParams.get(START_DATE_TO) || undefined);
  const [filterDueDatePeriod, setFilterDueDatePeriod] = useState<
    string | undefined
  >(searchParams.get(DUE_DATE_PERIOD) || undefined);
  const [filterScheduledDateFrom, setFilterScheduledDateFrom] = useState<string | undefined>();
  const [filterScheduledDateTo, setFilterScheduledDateTo] = useState<string | undefined>();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (filterSearchText.length > 3) searchParams.set(SEARCH, filterSearchText);
    searchParams.set("page", pagination.pageIndex.toString());
    searchParams.set("limit", pagination.pageSize.toString());

    if (filterStartDateFrom)
      searchParams.set(START_DATE_FROM, filterStartDateFrom);
    if (filterStartDateTo)
      searchParams.set(START_DATE_TO, filterStartDateTo);
    if (filterStatus.length > 0)
      filterStatus.forEach((o) => searchParams.append(STATUS, o.value));
    if (filterDueDatePeriod)
      searchParams.set(DUE_DATE_PERIOD, filterDueDatePeriod);
    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSearchText,
    filterStatus,
    filterStartDateFrom,
    filterStartDateTo,
    filterDueDatePeriod,
    pagination,
  ]);

  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const shopCtx = useShopContext();

  const downSavingSearchQuery = useDownSavingSearchQuery({
    shopId: shopId || "",
    searchText: filterSearchText,
    startDateFrom: filterStartDateFrom,
    startDateTo: filterStartDateTo,
    scheduledDateFrom: filterScheduledDateFrom,
    scheduledDateTo: filterScheduledDateTo,
    status: filterStatus.length > 0 ? filterStatus.map((o) => o.value) : [],
  });
  const downSavingSearchSummaryQuery = useDownSavingSearchSummaryQuery({
    shopId: shopId || "",
    searchText: filterSearchText,
    startDateFrom: filterStartDateFrom,
    startDateTo: filterStartDateTo,
    scheduledDateFrom: filterScheduledDateFrom,
    scheduledDateTo: filterScheduledDateTo,
    status: filterStatus.length > 0 ? filterStatus.map((o) => o.value) : [],
  });

  useEffect(() => {
    switch (filterDueDatePeriod) {
      case "ALL":
        setFilterScheduledDateFrom(undefined);
        setFilterScheduledDateTo(undefined);
        break;
      case "UPCOMING_7":
        setFilterScheduledDateFrom(dayjs().format("YYYY-MM-DD"));
        setFilterScheduledDateTo(dayjs().add(7, "day").format("YYYY-MM-DD"));
        break;
      case "UPCOMING_3":
        setFilterScheduledDateFrom(dayjs().format("YYYY-MM-DD"));
        setFilterScheduledDateTo(dayjs().add(3, "day").format("YYYY-MM-DD"));
        break;
      case "DUE":
        setFilterScheduledDateFrom(dayjs().format("YYYY-MM-DD"));
        setFilterScheduledDateTo(dayjs().format("YYYY-MM-DD"));
        break;
      case "OVERDUE_0_10":
        setFilterScheduledDateFrom(dayjs().subtract(10, "day").format("YYYY-MM-DD"));
        setFilterScheduledDateTo(dayjs().subtract(1, "day").format("YYYY-MM-DD"));
        break;
      case "OVERDUE_10_30":
        setFilterScheduledDateFrom(dayjs().subtract(30, "day").format("YYYY-MM-DD"));
        setFilterScheduledDateTo(dayjs().subtract(10, "day").format("YYYY-MM-DD"));
        break;
      case "OVERDUE_30":
        setFilterScheduledDateFrom(undefined);
        setFilterScheduledDateTo(dayjs().subtract(30, "day").format("YYYY-MM-DD"));
        break;
      case "OVERDUE":
        setFilterScheduledDateFrom(undefined);
        setFilterScheduledDateTo(dayjs().subtract(1, "day").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  }, [filterDueDatePeriod]);

  const columns: MRT_ColumnDef<DownSavingResponse>[] = [
    {
      id: "contractReferenceId",
      accessorFn: (row: DownSavingResponse) => {
        return row.contractReferenceId;
      }, //simple recommended way to define a column
      header: "เลขอ้างอิง",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },
    {
      id: "status",
      accessorFn: (row: DownSavingResponse) => {
        return <DownSavingStatusChip status={row?.status!!} />
      }, //simple recommended way to define a column
      header: "สถานะ",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },
    {
      id: "startDate",
      accessorFn: (row: DownSavingResponse) => {
        return dayjs(row?.startDate).format("DD/MM/YYYY");
      }, //simple recommended way to define a column
      header: "วันที่เริ่มออม",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },
    {
      id: "customerFullName",
      accessorFn: (row: DownSavingResponse) => {
        return `${row.customerFirstName} ${row.customerLastName}`;
      }, //simple recommended way to define a column
      header: "ชื่อ-นามสกุล ลูกค้า",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },
    {
      id: "nextDueDate",
      accessorFn: (row: DownSavingResponse) => {
        return dayjs(row?.nextDueDate).format("DD/MM/YYYY");
      }, //simple recommended way to define a column
      header: "วันครบกำหนด",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 150,
    },
    {
      id: "nextDueDateDiff",
      accessorFn: (row: DownSavingResponse) => {
        return <Typography
          color={(row?.nextDueDateDiff || 0) >= 0 ? "#43a047" : "#f44336"}
        >
          {row?.nextDueDateDiff
            ? row.nextDueDateDiff === 0
              ? `ถึงกำหนด`
              : (row.nextDueDateDiff || 0) > 0
                ? `อีก ${row?.nextDueDateDiff} วัน`
                : `เกิน ${-(row?.nextDueDateDiff || 0)} วัน`
            : "-"}
        </Typography>;
      }, //simple recommended way to define a column
      header: "ครบกำหนดอีก",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 150,
    },
    {
      id: "customerPhoneNumber",
      accessorFn: (row: DownSavingResponse) => {
        return row.customerPhoneNumber;
      }, //simple recommended way to define a column
      header: "เบอร์โทร",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },
    {
      id: "balanceAmount",
      accessorFn: (row: DownSavingResponse) => {
        return `${row.balanceAmount} (${((row.balanceAmount || 0) / (row.downAmount || 1) * 100).toFixed(2)} %)`;
      }, //simple recommended way to define a column
      header: "ยอดสะสม",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },
    {
      id: "downAmount",
      accessorFn: (row: DownSavingResponse) => {
        return currencyFormat.format(row.downAmount || 0);
      }, //simple recommended way to define a column
      header: "เป้าเงินดาวน์",
      enableHiding: false,
      enablePinning: false,
      enableSorting: true,
      size: 100,
    },

  ];

  const table = useMaterialReactTable({
    columns: columns,
    data: downSavingSearchQuery.data?.content || [],
    layoutMode: "semantic",
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnVirtualization: false,
    enableColumnOrdering: false,
    positionGlobalFilter: "left",
    enableTopToolbar: false,
    manualSorting: true,
    paginationDisplayMode: "pages",
    // onSortingChange: setSorting,
    muiPaginationProps: {
      shape: "rounded",
      page: pagination.pageIndex + 1,
      rowsPerPageOptions: [10, 25, 50, 100],
      defaultPage: 0,
      showRowsPerPage: true,
      showFirstButton: false,
    },
    localization: {
      rowsPerPage: "จำนวนต่อหน้า",
    },
    manualPagination: true,
    rowCount: downSavingSearchQuery.data?.pagination?.totalElements || 1,
    onPaginationChange: setPagination,
    initialState: {
      showGlobalFilter: true,
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: downSavingSearchQuery.isFetching,
      pagination,
      // sorting,
    },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <IconButton onClick={() => navigate(row.original.id || '')}>
        <ArrowForwardIosIcon />
      </IconButton>
    ),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 12, //make actions column wider
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0", //add a border between columns
        backgroundColor: "#455a64",
        color: "white",
        fontWeight: "500",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: "grey.100",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #eeeeee", //add a border between columns
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
      <Box p={2}>
        <Typography variant="h5" pb={2}>
          {"รายการออมดาวน์"}
        </Typography>
        <Stack gap={2} sx={{ maxWidth: "90vw" }}>
          <Stack gap={2}>
            <Stack
              direction={{ lg: "row" }}
              gap={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                fullWidth
                sx={{ backgroundColor: "white", flex: 2 }}
                value={filterSearchText}
                onChange={(e) => setFilterSearchText(e.target.value)}
                variant="outlined"
                placeholder="ค้นหา หมายเลขเคส, ชื่อ-นามสกุล, เลขบัตร, พาสปอร์ต, เบอร์โทร, IMEI, Serial Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Select
                fullWidth
                value={filterDueDatePeriod || "ALL"}
                onChange={(e) => setFilterDueDatePeriod(e.target.value)}
                variant="outlined"
                sx={{ backgroundColor: "white", height: "56px", flex: 1 }}
              >
                <MenuItem value="ALL">ทั้งหมด</MenuItem>
                <MenuItem value="UPCOMING_7">ครบกำหนดภายใน 7 วัน</MenuItem>
                <MenuItem value="UPCOMING_3">ครบกำหนดภายใน 3 วัน</MenuItem>
                <MenuItem value="DUE">ครบกำหนดวันนี้</MenuItem>
                <MenuItem value="OVERDUE_0_10">เกินกำหนด 0 ถึง 10 วัน</MenuItem>
                <MenuItem value="OVERDUE_10_30">เกินกำหนด 10 ถึง 30</MenuItem>
                <MenuItem value="OVERDUE_30">เกินกำหนดมากกว่า 30 วัน</MenuItem>
                <MenuItem value="OVERDUE">เกินกำหนดทั้งหมด</MenuItem>
              </Select>
              <Button
                variant="contained"
                size="medium"
                onClick={() => navigate("create")}
                sx={{ height: "56px", width: { xs: "100%", lg: "20%" } }}
              >
                สร้างออมดาวน์
              </Button>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <FormControl fullWidth>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="วันที่เริ่มออม (เริ่มต้น)"
                  value={filterStartDateFrom ? dayjs(filterStartDateFrom) : null} // Convert to Dayjs
                  onChange={(date) =>
                    date
                      ? setFilterStartDateFrom(date.format("YYYY-MM-DD"))
                      : setFilterStartDateTo(undefined)
                  }
                  slotProps={{ textField: { variant: "outlined" } }}
                />
              </FormControl>
              <FormControl fullWidth>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="วันที่เริ่มออม (สิ้นสุด)"
                  value={filterStartDateTo ? dayjs(filterStartDateTo) : null} // Convert to Dayjs
                  onChange={(date) =>
                    date
                      ? setFilterStartDateTo(date.format("YYYY-MM-DD"))
                      : setFilterStartDateTo(undefined)
                  }
                  slotProps={{ textField: { variant: "outlined" } }}
                />
              </FormControl>
              <Autocomplete
                fullWidth
                multiple
                id="selected-status"
                value={filterStatus || []}
                onChange={(event, newValue) => {
                  setFilterStatus(newValue);
                }}
                options={downSavingStatusOptions}
                getOptionLabel={(o) => o?.display || ""}
                renderInput={(params) => (
                  <TextField {...params} label="สถานะ" placeholder="" />
                )}
              />
            </Stack>
          </Stack>
          <Spin spinning={shopCtx.isLoading}>
            <Stack direction={"row"} gap={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Card sx={{ p: 2, width: { xs: "100%", lg: "40%" } }} >
                <Stack direction='row' gap={2}>
                  <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }}>
                    <Typography>
                      จำนวนเคส
                    </Typography>
                    <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                      {downSavingSearchQuery.data?.pagination?.totalElements}
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }}>
                    <Typography>
                      ยอดออมรวม
                    </Typography>
                    <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                      {currencyFormat.format(downSavingSearchSummaryQuery.data?.totalDownSavingAmount || 0)}
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }}>
                    <Typography>
                      ยอดถอนรวม
                    </Typography>
                    <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                      {currencyFormat.format(downSavingSearchSummaryQuery.data?.totalWithdrawAmount || 0)}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
              {Object.entries(downSavingSearchSummaryQuery.data?.totalAmountByPaymentChannels || [])
                .filter(item => item[1] > 0)
                .map(item =>
                  <Card sx={{ p: 2, width: { xs: "100%", lg: "15%" } }} >
                    <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }}>
                      <Typography>
                        {shopCtx.paymentChannels?.find(p => p.id === item[0]) &&
                          <PaymentChannelRecord paymentChannel={shopCtx.paymentChannels?.find(p => p.id === item[0])!!} hideIcon size="small" />
                        }
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                        {currencyFormat.format(item[1])}
                      </Typography>
                    </Stack>
                  </Card>)
              }
            </Stack>
          </Spin>
          <Box sx={{ maxWidth: "100%" }}>
            <MaterialReactTable table={table} />
          </Box>
        </Stack>
      </Box>
    </LocalizationProvider >
  );
};
