/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentPaymentCsvReportGenerationResult } from '../models/InstallmentPaymentCsvReportGenerationResult';
import type { InstallmentPaymentDocumentDto } from '../models/InstallmentPaymentDocumentDto';
import type { InstallmentPaymentDto } from '../models/InstallmentPaymentDto';
import type { InstallmentPaymentReportResponse } from '../models/InstallmentPaymentReportResponse';
import type { InstallmentPaymentSumFinancierReport } from '../models/InstallmentPaymentSumFinancierReport';
import type { InstallmentPaymentSumReport } from '../models/InstallmentPaymentSumReport';
import type { PaginatedInstallmentPaymentDto } from '../models/PaginatedInstallmentPaymentDto';
import type { PaymentDocumentUploadConfirmRequest } from '../models/PaymentDocumentUploadConfirmRequest';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstallmentPaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns InstallmentPaymentCsvReportGenerationResult OK
     * @throws ApiError
     */
    public generateCsvReport({
        shopId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        type,
        shopPaymentChannelType,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        type?: 'DOWN_PAYMENT' | 'TERM_PAYMENT' | 'REPAYMENT_PARTIAL' | 'REPAYMENT_FULL' | 'FINE' | 'APPOINTMENT' | 'CLOSED',
        shopPaymentChannelType?: 'CASH' | 'PROMPT_PAY' | 'PROMPT_PAY_QR' | 'BANK_ACCOUNT' | 'AUTOMATIC_QR' | 'TRUE_WALLET',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<InstallmentPaymentCsvReportGenerationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/payments/report/{shopId}/csv',
            path: {
                'shopId': shopId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'type': type,
                'shopPaymentChannelType': shopPaymentChannelType,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentCsvReportGenerationResult OK
     * @throws ApiError
     */
    public generateCsvFinancierReport({
        financierId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        type,
        paymentChannelType,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        financierId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        type?: 'DOWN_PAYMENT' | 'TERM_PAYMENT' | 'REPAYMENT_PARTIAL' | 'REPAYMENT_FULL' | 'FINE' | 'APPOINTMENT' | 'CLOSED',
        paymentChannelType?: 'CASH' | 'PROMPT_PAY' | 'PROMPT_PAY_QR' | 'BANK_ACCOUNT' | 'AUTOMATIC_QR' | 'TRUE_WALLET',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<InstallmentPaymentCsvReportGenerationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/payments/financier/report/{financierId}/csv',
            path: {
                'financierId': financierId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'type': type,
                'paymentChannelType': paymentChannelType,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentDto OK
     * @throws ApiError
     */
    public createFinancierTermPayment({
        requestBody,
    }: {
        requestBody: InstallmentPaymentDto,
    }): CancelablePromise<InstallmentPaymentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/payments/financier/create-term-payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentDocumentDto OK
     * @throws ApiError
     */
    public createInstallmentPaymentDocument({
        requestBody,
    }: {
        requestBody: InstallmentPaymentDocumentDto,
    }): CancelablePromise<InstallmentPaymentDocumentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/payments/document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmPaymentDocumentUpload({
        requestBody,
    }: {
        requestBody: PaymentDocumentUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/payments/document-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentDto OK
     * @throws ApiError
     */
    public createTermPayment({
        requestBody,
    }: {
        requestBody: InstallmentPaymentDto,
    }): CancelablePromise<InstallmentPaymentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/installments/payments/create-term-payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentDto OK
     * @throws ApiError
     */
    public getPaymentById({
        paymentId,
    }: {
        paymentId: string,
    }): CancelablePromise<InstallmentPaymentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/payments/{paymentId}',
            path: {
                'paymentId': paymentId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPaymentDto OK
     * @throws ApiError
     */
    public searchPayment({
        shopId,
        loanId,
        status,
        page = 1,
        limit = 10,
    }: {
        shopId: string,
        loanId?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedInstallmentPaymentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/payments/search',
            query: {
                'shopId': shopId,
                'loanId': loanId,
                'status': status,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentReportResponse OK
     * @throws ApiError
     */
    public getPaginatedReport({
        shopId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        type,
        shopPaymentChannelType,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        type?: 'DOWN_PAYMENT' | 'TERM_PAYMENT' | 'REPAYMENT_PARTIAL' | 'REPAYMENT_FULL' | 'FINE' | 'APPOINTMENT' | 'CLOSED',
        shopPaymentChannelType?: 'CASH' | 'PROMPT_PAY' | 'PROMPT_PAY_QR' | 'BANK_ACCOUNT' | 'AUTOMATIC_QR' | 'TRUE_WALLET',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<InstallmentPaymentReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/payments/report/{shopId}',
            path: {
                'shopId': shopId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'type': type,
                'shopPaymentChannelType': shopPaymentChannelType,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentSumReport OK
     * @throws ApiError
     */
    public sumByPaymentChannel({
        shopId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        type,
        shopPaymentChannelType,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        type?: 'DOWN_PAYMENT' | 'TERM_PAYMENT' | 'REPAYMENT_PARTIAL' | 'REPAYMENT_FULL' | 'FINE' | 'APPOINTMENT' | 'CLOSED',
        shopPaymentChannelType?: 'CASH' | 'PROMPT_PAY' | 'PROMPT_PAY_QR' | 'BANK_ACCOUNT' | 'AUTOMATIC_QR' | 'TRUE_WALLET',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<Array<InstallmentPaymentSumReport>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/payments/report/{shopId}/sum',
            path: {
                'shopId': shopId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'type': type,
                'shopPaymentChannelType': shopPaymentChannelType,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentReportResponse OK
     * @throws ApiError
     */
    public getPaginatedFinancierReport({
        financierId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        type,
        financierPaymentChannelType,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        financierId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        type?: 'DOWN_PAYMENT' | 'TERM_PAYMENT' | 'REPAYMENT_PARTIAL' | 'REPAYMENT_FULL' | 'FINE' | 'APPOINTMENT' | 'CLOSED',
        financierPaymentChannelType?: 'CASH' | 'PROMPT_PAY' | 'PROMPT_PAY_QR' | 'BANK_ACCOUNT' | 'AUTOMATIC_QR' | 'TRUE_WALLET',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<InstallmentPaymentReportResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/payments/financier/report/{financierId}',
            path: {
                'financierId': financierId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'type': type,
                'financierPaymentChannelType': financierPaymentChannelType,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPaymentSumFinancierReport OK
     * @throws ApiError
     */
    public sumByFinancierPaymentChannel({
        financierId,
        search = '',
        sortBy = '',
        direction = '',
        status,
        type,
        paymentChannelType,
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        financierId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        status?: 'SUCCESS' | 'CANCEL' | 'PENDING' | 'COMPLETED' | 'OVERDUE',
        type?: 'DOWN_PAYMENT' | 'TERM_PAYMENT' | 'REPAYMENT_PARTIAL' | 'REPAYMENT_FULL' | 'FINE' | 'APPOINTMENT' | 'CLOSED',
        paymentChannelType?: 'CASH' | 'PROMPT_PAY' | 'PROMPT_PAY_QR' | 'BANK_ACCOUNT' | 'AUTOMATIC_QR' | 'TRUE_WALLET',
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<Array<InstallmentPaymentSumFinancierReport>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/installments/payments/financier/report/{financierId}/sum',
            path: {
                'financierId': financierId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'status': status,
                'type': type,
                'paymentChannelType': paymentChannelType,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public deletePayment({
        requestBody,
    }: {
        requestBody: InstallmentPaymentDto,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/installments/payments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
