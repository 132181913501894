/* eslint-disable */
import { ContentCopy, LinkOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { ShopLoanCalculationSettings } from "../../../client/motalvip-apis/generated";
import { useGetShopLoanCalculationSettings } from "../../../client/query-client/shopSettings/useGetShopLoanCalculationSettings";
import CustomInputLabel from "../../../component/CustomInputLabel";
import { useShopContext } from "../../../context/ShopContextProvider";

interface LoanCalculationResult {
  row: number;
  monthCount: number;
  downPercentage: number;
  downPaymentAmount: number;
  loanCostAmount: number;
  installmentAmount: number;
  profitFactor: number;
  profit: number;
  totalAmount: number;
}

interface LoanCalculationInput {
  row: number;
  downPercentage: number;
  month: number;
  profitFactor: number;
}

export const LoanCalculatorScreen = () => {
  const { shopId } = useShopContext();
  const [cost, setCost] = useState<number>(0);
  const [calculatedData, setCalculatedData] = useState<LoanCalculationResult[]>(
    []
  );

  const [selectedType, setSelectedType] =
    useState<ShopLoanCalculationSettings.type>(
      ShopLoanCalculationSettings.type.ANDROID
    );

  const { data: originalSettings } = useGetShopLoanCalculationSettings({
    shopId: shopId ?? "",
    type: selectedType,
  });
  const settings = useMemo(() => {
    if (!originalSettings) return [];
    const filtered = originalSettings.data.filter((s) => {
      return s.enabled;
    });
    const filteredWithSelectedPercentage: LoanCalculationInput[] = filtered.map(
      (s) => {
        return {
          row: s.row,
          downPercentage: s.downPercentage[0],
          month: s.month,
          profitFactor: s.profitFactor,
        };
      }
    );

    return filteredWithSelectedPercentage;
  }, [originalSettings]);

  const [monthProfitFactors, setMonthProfitFactors] = useState<
    LoanCalculationInput[]
  >([]);

  useEffect(() => {
    const cloned = JSON.parse(JSON.stringify(settings));
    setMonthProfitFactors(cloned);
  }, [settings]);

  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    const result: LoanCalculationResult[] = monthProfitFactors.map(
      (monthProfitFactor) => {
        const downPercentage = monthProfitFactor.downPercentage;
        const month = monthProfitFactor.month;
        const profitFactor = monthProfitFactor.profitFactor;
        const downPaymentAmount = originalSettings.roundNumbers
          ? Math.ceil(Math.round((downPercentage / 100) * cost)) - 1
          : Math.round((downPercentage / 100) * cost);
        const loanCostAmount = cost - downPaymentAmount;
        const installmentAmount = originalSettings.roundNumbers
          ? Math.ceil(
              Math.round((profitFactor * loanCostAmount) / month) / 100
            ) *
              100 -
            1
          : Math.round((profitFactor * loanCostAmount) / month / 10) * 10;
        const totalAmount = downPaymentAmount + installmentAmount * month;
        return {
          row: monthProfitFactor.row,
          monthCount: month,
          downPercentage: downPercentage,
          downPaymentAmount: downPaymentAmount,
          loanCostAmount: loanCostAmount,
          profitFactor: profitFactor,
          installmentAmount: installmentAmount,
          totalAmount: downPaymentAmount + installmentAmount * month,
          profit: totalAmount - cost,
        };
      }
    );
    setCalculatedData(result);
  }, [cost, monthProfitFactors]);

  const columns = useMemo<MRT_ColumnDef<LoanCalculationResult>[]>(
    () => [
      {
        id: "monthCount",
        accessorFn: (row: LoanCalculationResult) => {
          return <Typography>{`ผ่อน ${row.monthCount} เดือน`}</Typography>;
        }, //simple recommended way to define a column
        header: "",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Box width={100}>
            <Typography noWrap>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        id: "downPercentage",
        accessorFn: (row: LoanCalculationResult) => {
          return (
            <Stack direction={"row"} alignItems="center" gap={1}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  variant="outlined"
                  value={row.downPercentage}
                  onChange={(e) => {
                    const newFactors = monthProfitFactors.map(
                      (monthProfitFactor) => {
                        if (monthProfitFactor.row === row.row) {
                          monthProfitFactor.downPercentage = e.target.value
                            ? Number(e.target.value)
                            : 0;
                        }
                        return monthProfitFactor;
                      }
                    );
                    setMonthProfitFactors(newFactors);
                  }}
                >
                  {originalSettings.data
                    .find((s) => s.row === row.row)
                    ?.downPercentage.map((value) => (
                      <MenuItem value={value}>{value}%</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          );
        }, //simple recommended way to define a column
        header: "เปอร์เซ็นต์วางดาว์น",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        size: 100,
        Cell: ({ renderedCellValue, row }) => <Box>{renderedCellValue}</Box>,
      },
      {
        id: "downPaymentAmount",
        accessorFn: (row: LoanCalculationResult) => (
          <Typography>
            {cost > 0 ? currencyFormat.format(row.downPaymentAmount || 0) : ""}
          </Typography>
        ),
        header: "ดาว์น",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "loanCostAmount",
        accessorFn: (row: LoanCalculationResult) => (
          <Typography>
            {cost > 0 ? currencyFormat.format(row.loanCostAmount || 0) : ""}
          </Typography>
        ),
        header: "ยอดจัด",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "profitFactor",
        accessorFn: (row: LoanCalculationResult) => {
          return (
            <TextField
              size="small"
              type="number"
              value={row.profitFactor}
              onChange={(e) => {
                const newFactors = monthProfitFactors.map(
                  (monthProfitFactor) => {
                    if (monthProfitFactor.row === row.row) {
                      monthProfitFactor.profitFactor = e.target.value
                        ? parseFloat(e.target.value)
                        : 0;
                    }
                    return monthProfitFactor;
                  }
                );
                setMonthProfitFactors(newFactors);
              }}
            />
          );
        },
        header: "ตัวคูณกำไร",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "installmentAmount",
        accessorFn: (row: LoanCalculationResult) => (
          <Typography>
            {row.installmentAmount > 0 && cost > 0
              ? currencyFormat.format(row.installmentAmount)
              : ""}
          </Typography>
        ),
        header: "งวดละ",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "totalAmount",
        accessorFn: (row: LoanCalculationResult) => (
          <Typography>
            {row.totalAmount > 0 && cost > 0
              ? currencyFormat.format(row.totalAmount)
              : ""}
          </Typography>
        ),
        header: "ยอดรวม",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "profit",
        accessorFn: (row: LoanCalculationResult) => (
          <Typography>
            {row.profit > 0 && cost > 0
              ? currencyFormat.format(row.profit)
              : ""}
          </Typography>
        ),
        header: "กำไร",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
    ],
    [currencyFormat]
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: calculatedData || [],
    layoutMode: "semantic",
    enablePagination: false,
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnVirtualization: false,
    enableColumnOrdering: false,
    enableBottomToolbar: false,
    positionGlobalFilter: "left",
    enableTopToolbar: false,
    manualSorting: true,
    paginationDisplayMode: "pages",
    localization: {
      rowsPerPage: "จำนวนต่อหน้า",
    },
    manualPagination: true,
    initialState: {
      showGlobalFilter: false,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    enableRowActions: false,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 12, //make actions column wider
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0", //add a border between columns
        backgroundColor: "#455a64",
        color: "white",
        fontWeight: "500",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: "grey.100",
          },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #eeeeee", //add a border between columns
      },
    },
  });

  return (
    <Box p={2} mb={16}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "8px",
          gap: 4,
        }}
      >
        <Typography variant="h5">{"เครื่องมือคำนวณสินเชื่อ"}</Typography>
        <Tooltip
          title={
            <div>
              <div>คำนวณผ่านหน้าเว็บลูกค้า</div>
              <Button
                startIcon={<ContentCopy />}
                sx={{
                  color: "white",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}/loan/calculator?shopId=${shopId}`
                  );
                  toast.success("คัดลอกลิงค์สำเร็จ");
                }}
              >
                คัดลอกลิงค์
              </Button>
            </div>
          }
        >
          <a
            href={`${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}/loan/calculator?shopId=${shopId}`}
            target="_blank"
            style={{ width: 24, height: 24, color: "black" }}
          >
            <LinkOutlined />
          </a>
        </Tooltip>
      </div>
      <Card sx={{ px: 2, py: 4, mb: 2 }}>
        <Stack gap={2}>
          <Stack direction={"row"} gap={2}>
            <FormControl fullWidth>
              <CustomInputLabel>iOS/Android</CustomInputLabel>
              <Select
                size="small"
                variant="outlined"
                value={selectedType}
                label="Opeating System"
                onChange={(v) => {
                  setSelectedType(
                    v.target.value as ShopLoanCalculationSettings.type
                  );
                }}
              >
                <MenuItem value={ShopLoanCalculationSettings.type.ANDROID}>
                  Android
                </MenuItem>
                <MenuItem value={ShopLoanCalculationSettings.type.IOS}>
                  iOS
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <CustomInputLabel>ราคาขาย</CustomInputLabel>
              <TextField
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setCost(e.target.value ? parseFloat(e.target.value) : 0);
                }}
              />
            </FormControl>
          </Stack>
          <MaterialReactTable table={table} />
        </Stack>
      </Card>
    </Box>
  );
};
