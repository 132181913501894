import { Stack, Typography, Box, Button } from "@mui/material";
import FileUploadWithPreview from "../../component/FileUploadWithPreview";
import { useEffect, useMemo, useState } from "react";
import { InstallmentPlanDocumentDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDocumentDto";
import {
  useConfirmContractDocumentMutation,
  useCreateContractDocumentMutation,
  useRemoveLoanDocumentMutation,
  useUpdateLoanDraftCustomerDocumentMutation,
} from "../../client/query-client/shopFinancier/contract";
import { useUploadFileMutation } from "../../client/query-client/useUploadFileMutation";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated/models/InstallmentPlanDto";
import { Spin } from "../../component/common/Spin";
import DialogConfirm from "../../component/DialogConfirm";

interface IFileUpload {
  document: InstallmentPlanDocumentDto;
  type: InstallmentPlanDocumentDto.type;
  confirmed: boolean;
}

interface IProps {
  onSubmit: () => void;
  onBack: () => void;
  shopId: string | undefined;
  contract: InstallmentPlanDto | undefined;
}

const CustomerDocuments: React.FC<IProps> = (props): JSX.Element => {
  const [fileUploads, setFileUploads] = useState<IFileUpload[]>([]);
  const [selectedDocumentRemove, setSelectedDocumentRemove] =
    useState<IFileUpload | null>(null);

  const createDocument = useCreateContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const uploadFileMutation = useUploadFileMutation();
  const confirmDocument = useConfirmContractDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const updateCustomerDocument = useUpdateLoanDraftCustomerDocumentMutation({
    contractId: props.contract?.id || "",
    shopId: props.shopId || "",
  });
  const removeCustomerDocument = useRemoveLoanDocumentMutation(
    props.contract?.id || ""
  );

  const handleFindDocumentPreveiw = (type: InstallmentPlanDocumentDto.type) => {
    return fileUploads.find((doc) => doc.type === type);
  };

  const handleFileChange = async (
    file: File | null,
    type: InstallmentPlanDocumentDto.type
  ): Promise<void> => {
    try {
      if (file) {
        const document = await createDocument.mutateAsync({
          originalName: file.name,
          type: type,
        });

        await uploadFileMutation.mutateAsync({
          file: file,
          uploadUrl: document.uploadUrl || "",
        });

        const existingFileIndex = fileUploads.findIndex(
          (upload) => upload.type === type
        );

        if (existingFileIndex !== -1) {
          const updatedFileUploads = [...fileUploads];
          updatedFileUploads[existingFileIndex] = {
            document,
            type,
            confirmed: false,
          };
          setFileUploads(updatedFileUploads);
        } else {
          setFileUploads((prev) => [
            ...prev,
            { document, type, confirmed: false },
          ]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDocumentRemove = async (
    data: IFileUpload | null
  ): Promise<void> => {
    if (!data) return;

    try {
      await removeCustomerDocument.mutateAsync({
        loanDocumentId: data.document?.id,
      });
      setFileUploads((prev) => prev.filter((doc) => doc.type !== data.type));
      setSelectedDocumentRemove(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const promises = fileUploads.map(async (data) => {
        if (!data.confirmed) {
          await confirmDocument.mutateAsync({
            loanId: props.contract?.id || "",
            objectKey: data.document.objectKey,
            type: data.type,
          });
        }
      });

      await Promise.all(promises);
      await updateCustomerDocument.mutateAsync();
      props.onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const isDisabled = useMemo(() => {
    const files = fileUploads.filter(
      (file) => file.type !== InstallmentPlanDocumentDto.type.OTHERS
    );

    return files.length !== 3;
  }, [fileUploads]);

  useEffect(() => {
    if (
      props.contract &&
      props.contract.documents &&
      props.contract?.documents?.length > 0
    ) {
      const docs = props.contract.documents
        .filter(
          (doc) =>
            doc.type === InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD ||
            doc.type === InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD ||
            doc.type === InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN ||
            doc.type === InstallmentPlanDocumentDto.type.OTHERS
        )
        .map((doc) => {
          return {
            document: doc,
            type: doc.type,
            confirmed: true,
          };
        });

      setFileUploads(docs);
    }
  }, [props.contract]);

  return (
    <Spin
      spinning={
        createDocument.isPending ||
        uploadFileMutation.isPending ||
        removeCustomerDocument.isPending
      }
    >
      <DialogConfirm
        isOpen={!!selectedDocumentRemove}
        title="ลบไฟล์"
        message="คุณต้องการลบไฟล์ใช่หรือไม่?"
        onClose={() => setSelectedDocumentRemove(null)}
        onConfirm={() => handleDocumentRemove(selectedDocumentRemove)}
        disable={removeCustomerDocument.isPending}
      />

      <Stack gap={2} useFlexGap flexWrap="wrap">
        <Typography variant="h6">เอกสารประกอบการทำสัญญา</Typography>
        <Box>
          <FileUploadWithPreview
            title="1. บัตรประชาชน *"
            document={
              handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD
              )?.document
            }
            onChange={(file) =>
              handleFileChange(
                file,
                InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD
              )
            }
            onRemove={() => {
              const documentRemove = handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.CITIZEN_ID_CARD
              );
              if (documentRemove) {
                setSelectedDocumentRemove(documentRemove);
              }
            }}
          />
        </Box>

        <Box>
          <FileUploadWithPreview
            title="2. รูปถ่ายคู่บัตรประชาชน *"
            document={
              handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD
              )?.document
            }
            onChange={(file) =>
              handleFileChange(
                file,
                InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD
              )
            }
            onRemove={() => {
              const documentRemove = handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.PHOTO_WITH_CARD
              );
              if (documentRemove) {
                setSelectedDocumentRemove(documentRemove);
              }
            }}
          />
        </Box>

        <Box>
          <FileUploadWithPreview
            title="3. หน้า Facebook *"
            document={
              handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN
              )?.document
            }
            onChange={(file) =>
              handleFileChange(
                file,
                InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN
              )
            }
            onRemove={() => {
              const documentRemove = handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.FACEBOOK_SCREEN
              );
              if (documentRemove) {
                setSelectedDocumentRemove(documentRemove);
              }
            }}
          />
        </Box>

        <Box>
          <FileUploadWithPreview
            title="4. เอกสารประกอบอื่นๆ"
            document={
              handleFindDocumentPreveiw(InstallmentPlanDocumentDto.type.OTHERS)
                ?.document
            }
            onChange={(file) =>
              handleFileChange(file, InstallmentPlanDocumentDto.type.OTHERS)
            }
            onRemove={() => {
              const documentRemove = handleFindDocumentPreveiw(
                InstallmentPlanDocumentDto.type.OTHERS
              );
              if (documentRemove) {
                setSelectedDocumentRemove(documentRemove);
              }
            }}
          />
        </Box>

        <Stack direction="row" flexWrap="wrap" gap={3} sx={{ mt: 3 }}>
          <Button
            fullWidth
            variant="outlined"
            type="button"
            size="large"
            onClick={() => props.onBack()}
            sx={{ width: { xs: "100%", md: "200px" } }}
            disabled={
              createDocument.isPending ||
              uploadFileMutation.isPending ||
              confirmDocument.isPending ||
              updateCustomerDocument.isPending
            }
          >
            ย้อนกลับ
          </Button>
          <Button
            variant="contained"
            type="button"
            size="large"
            onClick={() => handleSubmit()}
            sx={{ width: { xs: "100%", md: "200px" } }}
            disabled={
              isDisabled ||
              confirmDocument.isPending ||
              updateCustomerDocument.isPending
            }
          >
            บันทึกเอกสาร
          </Button>
        </Stack>
      </Stack>
    </Spin>
  );
};

export default CustomerDocuments;
