import { Box, FormControl, Stack, Typography } from "@mui/material";
import {
  MaterialReactTable,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useLoanCommissionByShopQuery,
  useReportLoanByShopIdQuery,
} from "../../client/query-client/shopFinancier/dashboard";
import { useShopFinancierContext } from "../../context/ShopFinancierContextProvider";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoanSummaryWidget from "../../component/ShopFinancierDashboard/LoanSummaryWidget";
import TableColumnLoanCommission from "../../component/ShopFinancierDashboard/TableColumnLoanCommission";
import dayjs from "dayjs";

export const ShopFinancierDashboardScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateFrom = searchParams.get("dateFrom") || undefined;
  const dateTo = searchParams.get("dateTo") || undefined;
  const sortBy = searchParams.get("sortBy") || "createdAt";
  const sort = searchParams.get("sort") || "desc";
  const pageIndex = parseInt(searchParams.get("pageIndex") || "0");
  const pageSize = parseInt(searchParams.get("pageSize") || "10");

  const [paidPagination, setPaidPagination] = useState({
    pageIndex: pageIndex, //initial page index
    pageSize: pageSize, //default page size
  });

  const [paidSorting, setPaidSorting] = useState<MRT_SortingState>([
    {
      id: sortBy || "createdAt",
      desc: sort ? sort === "desc" : true,
    },
  ]);

  const { shopId } = useParams();
  const shopFinancierContext = useShopFinancierContext();

  const loanCommissionList = useLoanCommissionByShopQuery({
    shopId: shopId || "",
    dateFrom: dateFrom,
    dateTo: dateTo,
    sortBy: paidSorting.length > 0 ? paidSorting?.[0]?.id : undefined,
    sort:
      paidSorting.length > 0
        ? paidSorting?.[0]?.desc
          ? "desc"
          : "asc"
        : undefined,
    page: pageIndex,
    limit: pageSize,
  });

  const report = useReportLoanByShopIdQuery({
    shopId: shopId || "",
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  const table = useMaterialReactTable({
    columns: TableColumnLoanCommission(),
    data: loanCommissionList.data?.content || [],
    layoutMode: "semantic",
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    enableSorting: true,
    enableSortingRemoval: false,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnVirtualization: false,
    enableColumnOrdering: false,
    positionGlobalFilter: "left",
    enableTopToolbar: false,
    manualSorting: true,
    paginationDisplayMode: "pages",
    onSortingChange: setPaidSorting,
    muiPaginationProps: {
      shape: "rounded",
      page: pageIndex + 1,
      rowsPerPageOptions: [10, 25, 50, 100],
      defaultPage: 0,
      showRowsPerPage: true,
      showFirstButton: false,
    },
    localization: {
      rowsPerPage: "จำนวนต่อหน้า",
    },
    manualPagination: true,
    rowCount: loanCommissionList.data?.pagination?.totalElements || 1,
    onPaginationChange: setPaidPagination,
    initialState: {
      showGlobalFilter: true,
      pagination: paidPagination,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: loanCommissionList.isFetching,
      pagination: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      sorting: paidSorting,
      columnVisibility: { totalProfit: shopFinancierContext.isOwner },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 12, //make actions column wider
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0", //add a border between columns
        backgroundColor: "#455a64",
        color: "white",
        fontWeight: "500",
        padding: 1,
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: "grey.100",
          },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #eeeeee", //add a border between columns
        padding: 1,
      },
    },
  });

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("pageIndex", paidPagination.pageIndex.toString());
    newSearchParams.set("pageSize", paidPagination.pageSize.toString());
    setSearchParams(newSearchParams);
  }, [paidPagination, searchParams, setSearchParams]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (paidSorting.length > 0) {
      newSearchParams.set("sortBy", paidSorting[0].id);
      newSearchParams.set("sort", paidSorting[0].desc ? "desc" : "asc");
      setSearchParams(newSearchParams);
    }
  }, [paidSorting, searchParams, setSearchParams]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
      <Stack p={2} gap={3}>
        <Stack
          direction={{ xs: "row", md: "row" }}
          justifyContent="space-between"
          alignItems="end"
          flexWrap="wrap-reverse"
          gap={2}
        >
          <Typography variant="h5">{"รายงานยอดรวมทั้งหมด"}</Typography>
          <Stack
            width={{ xs: "100%", lg: 500 }}
            direction={{ lg: "row" }}
            gap={2}
          >
            <FormControl fullWidth>
              <DatePicker
                format="DD MMM YYYY"
                label="วันที่เริ่มต้น"
                disableFuture
                value={dateFrom ? dayjs(dateFrom).locale("th") : null} // Convert to Dayjs
                onChange={(date) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  if (date) {
                    newSearchParams.set("dateFrom", date.format("YYYY-MM-DD"));
                  } else {
                    newSearchParams.delete("dateFrom");
                  }
                  setSearchParams(newSearchParams);
                }}
                slotProps={{ textField: { variant: "outlined" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    bgcolor: "white",
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                format="DD/MM/YYYY"
                label="วันที่สิ้นสุด"
                disableFuture
                value={dateTo ? dayjs(dateTo) : null} // Convert to Dayjs
                onChange={(date) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  if (date) {
                    newSearchParams.set("dateTo", date.format("YYYY-MM-DD"));
                  } else {
                    newSearchParams.delete("dateTo");
                  }
                  setSearchParams(newSearchParams);
                }}
                slotProps={{ textField: { variant: "outlined" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    bgcolor: "white",
                  },
                }}
              />
            </FormControl>
          </Stack>
        </Stack>
        <Box sx={{ maxWidth: "100%" }}>
          <LoanSummaryWidget report={report.data} />
        </Box>
        <Typography variant="h5">{"รายงานยอดรวมรายวัน"}</Typography>
        <Box sx={{ maxWidth: "100%" }}>
          <MaterialReactTable table={table} />
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};
