import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../../motalvip-apis";
import { NoteDto } from "../../motalvip-apis/generated";

export const useNotesQuery = (type: NoteDto.referenceType, id: string) => {
    const backendClient = useBackendApiClient();
    return useQuery({
        queryKey: ['notesQuery'],
        queryFn: () => {
            return backendClient.document.getNotes({ referenceType: type, referenceId: id })
        },
        enabled: !!id,
    })
}