/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetAllOrderResponse = {
    orderNo?: string;
    customerName?: string;
    itemCount?: number;
    paymentMethod?: GetAllOrderResponse.paymentMethod;
    subTotal?: number;
    discount?: number;
    total?: number;
    createdAt?: string;
};
export namespace GetAllOrderResponse {
    export enum paymentMethod {
        CASH = 'CASH',
        CREDIT_CARD = 'CREDIT_CARD',
        BANK_TRANSFER = 'BANK_TRANSFER',
        INSTALLMENT = 'INSTALLMENT',
    }
}

