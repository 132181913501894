import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../../../motalvip-apis";
import { QueryKey } from "../..";
import { LoanDraftCreateRequest } from "../../../motalvip-apis/generated/models/LoanDraftCreateRequest";
import toast from "react-hot-toast";
import { useDialog } from "../../../../context/DialogContext";
import { StepUpdateLoanDraftCustomerInfoRequest } from "../../../motalvip-apis/generated/models/StepUpdateLoanDraftCustomerInfoRequest";
import { InstallmentPlanDocumentDto } from "../../../motalvip-apis/generated/models/InstallmentPlanDocumentDto";
import { LoanDocumentUploadConfirmRequest } from "../../../motalvip-apis/generated/models/LoanDocumentUploadConfirmRequest";
import { LoanDocumentRemoveRequest } from "../../../motalvip-apis/generated/models/LoanDocumentRemoveRequest";
import { StepUpdateLoanDraftConfirmDownPaymentRequest } from "../../../motalvip-apis/generated/models/StepUpdateLoanDraftConfirmDownPaymentRequest";
import { LoanRegisterSearchRequest } from "../../../motalvip-apis/generated/models/LoanRegisterSearchRequest";
import {
  StepUpdateLoanDraftCancelRequest,
  StepUpdateLoanDraftDeviceAndLoanApprovalRequest,
} from "../../../motalvip-apis/generated";

export const useContractQuery = (contractId: string) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.CONTRACT, contractId],
    queryFn: () =>
      backendApiClient.installmentPlan.getInstallmentPlanById({
        loanId: contractId,
      }),
    enabled: !!contractId,
  });
};

export const useCreateLoanDraftMutation = (shopId: string) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();

  return useMutation({
    mutationKey: [QueryKey.CONTRACT, shopId, "createLoanDraft"],
    mutationFn: (req: LoanDraftCreateRequest) =>
      backendApiClient.installmentPlan.createLoanDraft({
        requestBody: { ...req },
      }),
    onSuccess: () => {},
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftCustomerInfoMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "updateLoanDraftCustomerInfo",
    ],
    mutationFn: (req: StepUpdateLoanDraftCustomerInfoRequest) =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftCustomerInfo({
        requestBody: { ...req },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useCreateContractDocumentMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "createContractDocument",
    ],
    mutationFn: (req: InstallmentPlanDocumentDto) =>
      backendApiClient.installmentPlan.createInstallmentPlanDocument({
        requestBody: { ...req },
      }),
    onSuccess: () => {},
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useConfirmContractDocumentMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "confirmContractDocument",
    ],
    mutationFn: (req: LoanDocumentUploadConfirmRequest) =>
      backendApiClient.installmentPlan.confirmLoanDocumentUpload({
        requestBody: { ...req },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftCustomerDocumentMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "updateLoanDraftCustomerDocument",
    ],
    mutationFn: () =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftCustomerDocument({
        requestBody: { shopId: shopId, loanId: contractId },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftDeviceAndLoanMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "updateLoanDraftDeviceAndLoan",
    ],
    mutationFn: (req: StepUpdateLoanDraftDeviceAndLoanApprovalRequest) =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftDeviceAndLoan({
        requestBody: { ...req, loanId: contractId, shopId: shopId },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftApproveMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "updateLoanDraftApprove",
    ],
    mutationFn: () =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftApproveLoan({
        requestBody: { loanId: contractId, shopId: shopId },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftBackMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKey.CONTRACT, shopId, contractId, "updateLoanDraftBack"],
    mutationFn: () =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftBack({
        requestBody: { loanId: contractId, shopId: shopId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useRemoveLoanDocumentMutation = (contractId: string) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKey.CONTRACT, contractId, "removeLoanDocument"],
    mutationFn: (requestBody: LoanDocumentRemoveRequest) =>
      backendApiClient.installmentPlan.removeLoanDocument({
        requestBody,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftContractDocumentMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "updateLoanDraftContractDocument",
    ],
    mutationFn: () =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftContractDocument({
        requestBody: { loanId: contractId, shopId: shopId },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useUpdateLoanDraftConfirmDownPaymentMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QueryKey.CONTRACT,
      shopId,
      contractId,
      "updateLoanDraftConfirmDownPayment",
    ],
    mutationFn: (req: StepUpdateLoanDraftConfirmDownPaymentRequest) =>
      backendApiClient.installmentPlan.stepUpdateLoanDraftConfirmDownPayment({
        requestBody: { ...req },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};

export const useInstallmentActivitiesQuery = (contractId: string) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
    queryFn: () =>
      backendApiClient.installmentPlan.getInstallmentActivitiesByInstallmentPlanId(
        {
          installmentPlanId: contractId,
        }
      ),
    enabled: !!contractId,
  });
};

export const useSearchLoansRegisterQuery = (res: LoanRegisterSearchRequest) => {
  const backendApiClient = useBackendApiClient();
  return useQuery({
    queryKey: [
      QueryKey.CONTRACT,
      res.shopId,
      res.searchText,
      res.sortBy,
      res.sort,
      res.registrationDateFrom,
      res.registrationDateTo,
      res.registrationStatus,
      res.page,
      res.limit,
      "searchLoansRegister",
    ],
    queryFn: () =>
      backendApiClient.installmentPlan.searchLoansRegister({
        requestBody: res,
      }),
  });
};

export const useShopCancelLoanMutation = ({
  shopId,
  contractId,
}: {
  shopId: string;
  contractId: string;
}) => {
  const backendApiClient = useBackendApiClient();
  const dialog = useDialog();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKey.CONTRACT, contractId, "shopCancelLoan"],
    mutationFn: (requestBody: StepUpdateLoanDraftCancelRequest) =>
      backendApiClient.installmentPlan.shopStepUpdateLoanDraftCancelLoan({
        requestBody: { ...requestBody, loanId: contractId, shopId: shopId },
      }),
    onSuccess: () => {
      toast.success("บันทึกสำเร็จ");
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CONTRACT, contractId, "installmentActivities"],
      });
    },
    onError: (error) => {
      dialog.showDialog({
        title: "Error",
        description: error.message || "An error occurred",
        onConfirm: () => {},
      });
    },
  });
};
