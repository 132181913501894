import { Button, Stack, Typography, Card } from "@mui/material"


import { SubmitHandler, useForm } from "react-hook-form"
import { DocumentDto, GetShopProductsResponse } from "../../../client/motalvip-apis/generated"
import { useCategoriesQuery } from "../../../client/query-client/useCategoryQuery"
import { useNavigate, useParams } from "react-router-dom"
import { ProductInformation } from "./ProductInformation"
import { CreateProductFormType, MobileType, ProductVariantValue } from "./types"
import { ProductGeneral } from "./ProductGeneral"
import { useProductCreateMutation, useProductVariantCreateMutation } from "../../../client/query-client/useProductQuery"
import toast from "react-hot-toast"
import { UploadFileResponse, useUploadFile } from "../hooks/useUploadFile"

export function getProductSingleVariants(productSingleVariant: CreateProductFormType['productSingleVariant'], elements: any, imageRefIds: string[]) {
    if (!productSingleVariant) return []

    const variants: ProductVariantValue[] = []

    Object.keys(productSingleVariant).forEach((optionValue1, index) => {
        const productItem = productSingleVariant[optionValue1]
        const skuCode = productItem.skuCode || elements[`productSingleVariant.${optionValue1}.skuCode`].placeholder
        const productImageId = imageRefIds[index]

        variants.push({
            sku: skuCode,
            price: Number(productItem.price),
            optionValue1,
            note: productItem.note,
            variantId: productItem.variantId,
            representDocumentRefId: productImageId!
        })
    })

    return variants
}

export function getProductMultiVariants(productMultiVariant: CreateProductFormType['productMultiVariant'], elements: any, imageRefIds: string[]) {
    if (!productMultiVariant) return []

    const productVariants: ProductVariantValue[] = []

    Object.keys(productMultiVariant).forEach((optionValue1, index) => {
        Object.keys(productMultiVariant[optionValue1].detail).forEach(optionValue2 => {
            const productItem = productMultiVariant[optionValue1].detail[optionValue2]
            const skuCode = productItem.skuCode || elements[`productMultiVariant.${optionValue1}.detail.${optionValue2}.skuCode`].placeholder
            const imageRefId = imageRefIds[index]

            productVariants.push({
                sku: skuCode,
                price: Number(productItem.price),
                optionValue1,
                optionValue2,
                note: productItem.note,
                variantId: productItem.variantId,
                ...(imageRefId && { representDocumentRefId: imageRefId })
            })
        })
    })

    return productVariants
}

export async function getProductVariants(data: CreateProductFormType, elements: any, uploadImage: (file: File) => Promise<UploadFileResponse>) {
    if (data.optionName2) {
        const uploadImages = await Promise.all(Object.keys(data.productMultiVariant).map(key => {
            const productImage = data.productMultiVariant[key].productImage
            if (typeof productImage === 'string') return null
            return uploadImage(productImage as File)
        }))
        const imageRefIds = uploadImages.map(data => {
            if (!data) return ''
            return data.refId
        })

        return getProductMultiVariants(data.productMultiVariant, elements, imageRefIds)
    }

    const uploadImages = await Promise.all(Object.keys(data.productSingleVariant).map(key => {
        const productImage = data.productSingleVariant[key].productImage
        if (typeof productImage === 'string') return null
        return uploadImage(productImage as File)
    }))
    const imageRefIds = uploadImages.map(data => {
        if (!data) return ''
        return data.refId
    })

    return getProductSingleVariants(data.productSingleVariant, elements, imageRefIds)
}

export const CreateProductForm = () => {
    const { control, handleSubmit, formState: { errors }, watch, setValue, register, resetField } = useForm<CreateProductFormType>({
        mode: 'all'
    })
    const { shopId } = useParams()
    const { data: categories, isLoading: isLoadingCategories } = useCategoriesQuery(shopId!)
    const { mutateAsync: createProductVariant, isPending: isPendingProductVariant, error: productVariantError } = useProductVariantCreateMutation()
    const { mutateAsync: createProduct, isPending: isPendingProduct, error: productError } = useProductCreateMutation()
    const navigate = useNavigate()
    const { upload } = useUploadFile(DocumentDto.referenceType.POS)

    const productType = watch('type')

    const isMobile = productType === GetShopProductsResponse.type.MOBILE

    const onSubmit: SubmitHandler<CreateProductFormType> = async (data, event) => {
        if (!event) return

        const elements = event.target.elements

        const loadingId = toast.loading("กำลังสร้างสินค้า โปรดรอสักครู่...");
        try {
            let productImageId = null
            if (data.productImage && typeof data.productImage !== 'string') {
                const { refId } = await upload(data.productImage)
                productImageId = refId
            }
            if (data.hasOption) {
                const productVariants = await getProductVariants(data, elements, upload)

                await createProductVariant({
                    name: data.name,
                    description: data.description,
                    type: data.type,
                    categoryId: data.categoryId,
                    documentRefId: productImageId!,
                    shopId: shopId!,
                    ...(data.type === GetShopProductsResponse.type.MOBILE ? { isSecondhand: data.mobileType === MobileType.SECONDHAND } : undefined),
                    productVariants,
                    optionName1: data.optionName1,
                    optionName2: data.optionName2
                })
            } else {
                await createProduct({
                    name: data.name,
                    description: data.description,
                    documentRefId: productImageId!,
                    sku: data.productSku || elements['productSku'].placeholder,
                    type: data.type,
                    price: Number(data.productPrice),
                    note: data.productNote,
                    shopId: shopId!,
                    categoryId: data.categoryId,
                })
            }
            navigate(`/${shopId}/pos/product-list`)
        } catch (e) { console.error(e) }
        toast.dismiss(loadingId)
    }

    if (isLoadingCategories) {
        <div>Loading...</div>
    }

    if (!categories || (categories && categories?.length <= 0)) {
        return (<Card sx={{ p: 2 }}>
            <Typography variant="body1">ไม่สามารถเพิ่มสินค้าได้ เนื่องจากไม่มีหมวดหมู่สินค้า โปรดสร้างหมวดหมู่สินค้า</Typography>
        </Card>)
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
            <ProductInformation categories={categories!} errors={errors} control={control} watch={watch} isMobile={isMobile} setValue={setValue} />
            <ProductGeneral control={control} errors={errors} setValue={setValue} watch={watch} register={register} resetField={resetField} isPending={isPendingProduct || isPendingProductVariant} productVariantError={(productVariantError as any)?.body} productError={(productError as any)?.body} />
            <Button sx={{ my: 4 }} size='large' variant='contained' type="submit" disabled={isPendingProductVariant || isPendingProduct}>
                ยืนยัน
            </Button>
        </Stack>
    </form>
}
