/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DownSavingCreateRequest = {
    shopId: string;
    startDate: string;
    contractReferenceId?: string;
    customerFirstName: string;
    customerLastName: string;
    customerCitizenId?: string;
    customerPhoneNumber?: string;
    customerEmail?: string;
    customerAddress?: string;
    customerFacebookUrl?: string;
    customerLineId?: string;
    productBrand?: string;
    productModel?: string;
    productPrice?: number;
    downPercentage?: number;
    savingStartAmount?: number;
    savingAmount?: number;
    savingFrequency: DownSavingCreateRequest.savingFrequency;
    shopPaymentChannelId?: string;
    downAmount?: number;
    balanceAmount?: number;
    documentIds?: Array<string>;
    note?: string;
};
export namespace DownSavingCreateRequest {
    export enum savingFrequency {
        DAILY = 'DAILY',
        THREE_DAYS = 'THREE_DAYS',
        WEEKLY = 'WEEKLY',
        BIWEEKLY = 'BIWEEKLY',
        MONTHLY = 'MONTHLY',
    }
}

