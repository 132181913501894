/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GeneratePawningPaymentGatewayQRRequest = {
    pawningId?: string;
    shopPaymentChannelId?: string;
    capitalAmount: number;
    interestAmount: number;
    fineAmount: number;
    acceptedAt?: string;
    pawningAction?: GeneratePawningPaymentGatewayQRRequest.pawningAction;
    totalAmountToPay?: number;
};
export namespace GeneratePawningPaymentGatewayQRRequest {
    export enum pawningAction {
        ACCEPT_PAYMENT = 'ACCEPT_PAYMENT',
        REDEEM = 'REDEEM',
    }
}

