/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancierEmployeeCommissionResponse } from '../models/FinancierEmployeeCommissionResponse';
import type { ShopDashboardRequest } from '../models/ShopDashboardRequest';
import type { ShopDashboardResponse } from '../models/ShopDashboardResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ReportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any OK
     * @throws ApiError
     */
    public calculateInstallmentPlanMonthlyAggregation({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/reports/installment-plan-monthly-aggregation-calculate/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public financierCalculateInstallmentPlanMonthlyAggregation({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/reports/financier/installment-plan-monthly-aggregation-calculate/{financierId}',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierEmployeeCommissionResponse OK
     * @throws ApiError
     */
    public getFinancierEmployeeCommissionDashboard({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: number,
    }): CancelablePromise<FinancierEmployeeCommissionResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/reports/financier/employee-commission/{financierId}',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDashboardResponse OK
     * @throws ApiError
     */
    public getFinancierDashboard({
        financierId,
        requestBody,
    }: {
        financierId: string,
        requestBody: ShopDashboardRequest,
    }): CancelablePromise<ShopDashboardResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/reports/financier/dashboard/{financierId}',
            path: {
                'financierId': financierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDashboardResponse OK
     * @throws ApiError
     */
    public getShopDashboard({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: ShopDashboardRequest,
    }): CancelablePromise<ShopDashboardResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/reports/dashboard/{shopId}',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
