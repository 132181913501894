import { useQuery } from "@tanstack/react-query"
import { useBackendApiClient } from "../motalvip-apis"

export const useShopFeaturesQuery = (shopId: string) => {
    const backendClient = useBackendApiClient()
    return useQuery({
        queryKey: [shopId],
        queryFn: () =>
            backendClient.shop.getShopFeatures({ shopId }),
        enabled: !!shopId,
    })
}