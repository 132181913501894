/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancierPaymentChannelDto } from './FinancierPaymentChannelDto';
import type { PhoneNumber } from './PhoneNumber';
export type ShopFinancierDetailDto = {
    id: string;
    name: string;
    address: string;
    lineId?: string;
    facebookUrl: string;
    phoneNumbers: Array<PhoneNumber>;
    status?: ShopFinancierDetailDto.status;
    expireAt?: string;
    avatarUrl?: string;
    paymentChannels?: Array<FinancierPaymentChannelDto>;
};
export namespace ShopFinancierDetailDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
}

