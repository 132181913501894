/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderRequest } from '../models/CreateOrderRequest';
import type { GerOrderDetailResponse } from '../models/GerOrderDetailResponse';
import type { OrderDTO } from '../models/OrderDTO';
import type { PaginatedGetAllOrderResponse } from '../models/PaginatedGetAllOrderResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns string OK
     * @throws ApiError
     */
    public cancelOrder({
        shopId,
        orderNo,
    }: {
        shopId: string,
        orderNo: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/orders/{orderNo}/cancel',
            path: {
                'orderNo': orderNo,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedGetAllOrderResponse OK
     * @throws ApiError
     */
    public getAllOrder({
        shopId,
        search,
        sortBy = 'created_at',
        sort = 'desc',
        page = 1,
        limit = 20,
        type,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
        type?: 'CASH' | 'CREDIT_CARD' | 'BANK_TRANSFER' | 'INSTALLMENT',
    }): CancelablePromise<PaginatedGetAllOrderResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/orders',
            query: {
                'shopId': shopId,
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public createOrder({
        requestBody,
    }: {
        requestBody: CreateOrderRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns GerOrderDetailResponse OK
     * @throws ApiError
     */
    public getOrderDetail({
        shopId,
        orderNo,
    }: {
        shopId: string,
        orderNo: string,
    }): CancelablePromise<GerOrderDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/orders/{orderNo}',
            path: {
                'orderNo': orderNo,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns OrderDTO OK
     * @throws ApiError
     */
    public getTaxInvoice({
        shopId,
        orderNo,
    }: {
        shopId: string,
        orderNo: string,
    }): CancelablePromise<OrderDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/orders/{orderNo}/tax-invoice',
            path: {
                'orderNo': orderNo,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns OrderDTO OK
     * @throws ApiError
     */
    public getReceipt({
        shopId,
        orderNo,
    }: {
        shopId: string,
        orderNo: string,
    }): CancelablePromise<OrderDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/orders/{orderNo}/receipt',
            path: {
                'orderNo': orderNo,
            },
            query: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
