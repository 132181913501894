/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateShopQuotaRequest } from '../models/CreateShopQuotaRequest';
import type { JsonNode } from '../models/JsonNode';
import type { ShopQuotaDto } from '../models/ShopQuotaDto';
import type { UpdateShopQuotaRequest } from '../models/UpdateShopQuotaRequest';
import type { VerifySlipRequest } from '../models/VerifySlipRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopQuotasService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopQuotaDto OK
     * @throws ApiError
     */
    public updateShopQuota({
        requestBody,
    }: {
        requestBody: UpdateShopQuotaRequest,
    }): CancelablePromise<ShopQuotaDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/shop-quotas',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopQuotaDto OK
     * @throws ApiError
     */
    public createShopQuota({
        requestBody,
    }: {
        requestBody: CreateShopQuotaRequest,
    }): CancelablePromise<ShopQuotaDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shop-quotas',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns JsonNode OK
     * @throws ApiError
     */
    public verifySlip({
        request,
    }: {
        request: VerifySlipRequest,
    }): CancelablePromise<JsonNode> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/shop-quotas/verify-slip',
            query: {
                'request': request,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopQuotaDto OK
     * @throws ApiError
     */
    public getShopQuota({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopQuotaDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shop-quotas/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteShopQuota({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/shop-quotas/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns boolean OK
     * @throws ApiError
     */
    public verifyQuota({
        shopId,
        type,
        amount,
    }: {
        shopId: string,
        type: string,
        amount?: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/shop-quotas/verify',
            query: {
                'shopId': shopId,
                'type': type,
                'amount': amount,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
