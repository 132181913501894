import { Button } from "@mui/material";
import { Fragment, ImgHTMLAttributes, useEffect, useState } from "react";

/**
 * Cropped feelfreepay qr image
 */
export function QrCropped({
  src,
  ...rest
}: Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> & {
  src: string;
}) {
  const [qrCodeCropped, setQrCodeCropped] = useState<string | null>(null);
  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.src = src;
    img.setAttribute("crossorigin", "anonymous");
    img.onload = () => {
      const width = img.width;
      const height = img.height * 0.88; // Crop bottom 20%

      canvas.width = width;
      canvas.height = height;

      ctx?.drawImage(img, 0, 0, width, height, 0, 0, width, height);

      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          setQrCodeCropped(url);
        }
      });
    };
  }, [src]);

  if (!qrCodeCropped) {
    return <Fragment />;
  }

  const handleDownload = () => {
    if (!qrCodeCropped) {
      console.error("No blob url to download");
      return;
    }

    const a = document.createElement("a");
    a.href = qrCodeCropped;
    a.download = `qr-code.png`;
    a.click();
  };

  return (
    <div>
      <img src={qrCodeCropped} alt="QR Code" {...rest} />
      <Button
        variant="contained"
        onClick={handleDownload}
        sx={{
          display: "block",
          margin: "0 auto",
          width: "fit-content",
        }}
      >
        ดาวน์โหลด QR Code
      </Button>
    </div>
  );
}
