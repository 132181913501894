import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Box,
  Button,
  Card,
  IconButton,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { DocumentDto } from "../../../../client/motalvip-apis/generated";
import { useCreateDocumentMutation } from "../../../../client/query-client/document/useDocumentCreateMutation";
import { useDocumentDeleteMutation } from "../../../../client/query-client/document/useDocumentDeleteMutation";
import { useDocumentsQuery } from "../../../../client/query-client/document/useDocumentsQuery";
import { useNotesQuery } from "../../../../client/query-client/document/useNotesQuery";
import { useDownSavingQuery } from "../../../../client/query-client/downSaving/useDownSavingQuery";
import { useDownSavingTransactionsQuery } from "../../../../client/query-client/downSaving/useDownSavingTransactionsQuery";
import { useUploadFileMutation } from "../../../../client/query-client/useUploadFileMutation";
import { PaperBackground } from "../../../../component/Shared/PaperBackground";
import { CustomDataField } from "../../../../component/common/CustomDataField";
import { PaymentHistory } from "../../../../component/common/PaymentHistory";
import { Spin } from "../../../../component/common/Spin";
import { DocumentPreviewCard } from "../../../../component/display/DocumentPreviewCard";
import { DownSavingStatusChip } from "../../../../component/display/DownSavingStatusChip";
import { DownSavingTransactionTable } from "../../../../component/downSaving/DownSavingTransactionTable";
import { CreateDownSavingQRPaymentDialog } from "./dialog/CreateDownSavingQRPaymentDialog";
import { DownSavingCancelDialog } from "./dialog/DownSavingCancelDialog";
import { DownSavingTransactionDepositDialog } from "./dialog/DownSavingTransactionDepositDialog";
import { DownSavingTransactionWithdrawDialog } from "./dialog/DownSavingTransactionWithdrawDialog";
import { NoteCreateDialog } from "./dialog/NoteCreateDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

interface FileWithPreview {
  file: DocumentDto;
  uploaded: boolean;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ maxWidth: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export const DownSavingDetailScreen = () => {
  const { downSavingId, shopId } = useParams<{
    shopId: string;
    downSavingId: string;
  }>();
  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const downSavingQuery = useDownSavingQuery(downSavingId || "");
  const downSavingTransactionsQuery = useDownSavingTransactionsQuery(
    downSavingId || ""
  );
  const createDocumentMutation = useCreateDocumentMutation();
  const documentQuery = useDocumentsQuery(
    DocumentDto.referenceType.DOWN_SAVING,
    downSavingId || ""
  );
  const noteQuery = useNotesQuery(
    DocumentDto.referenceType.DOWN_SAVING,
    downSavingId || ""
  );
  const documentDeleteMutation = useDocumentDeleteMutation();
  const uploadFileMutation = useUploadFileMutation();

  const [selectedTab, setSelectedTab] = useState<number>(
    Number(searchParams.get("tab")) || 0
  );
  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    if (selectedTab !== Number(searchParams.get("tab"))) {
      const searchParams = new URLSearchParams();
      searchParams.set("tab", selectedTab.toString());
      setSearchParams(searchParams, { replace: true });
    }
  }, [selectedTab, searchParams, setSearchParams]);

  const [openTransactionDialog, setOpenTransactionDialog] =
    useState<boolean>(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState<boolean>(false);

  const [openCreateQRDialog, setOpenCreateQRDialog] = useState<boolean>(false);

  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false);

  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  const [fileUploadStatuses, setFileUploadStatuses] = useState<
    FileWithPreview[]
  >([]);
  const onDrop = async (files: File[]) => {
    await Promise.all(
      files.map(
        (file) =>
          new Promise<void>(async (resolve, reject) => {
            const res = await createDocumentMutation.mutateAsync(
              {
                originalName: file.name,
                referenceType: DocumentDto.referenceType.DOWN_SAVING,
                referenceId: downSavingId || "",
              },
              {
                onError: reject,
              }
            );
            await uploadFileMutation.mutateAsync(
              { file: file, uploadUrl: res.uploadUrl || "" },
              { onError: reject }
            );
            setFileUploadStatuses((prev) => [
              ...prev,
              { file: res || "", uploaded: true },
            ]);
            resolve();
          })
      )
    );
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop }); // TODO: Refactor duplicate code

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
      <Spin
        spinning={
          downSavingQuery.isPending || downSavingTransactionsQuery.isPending
        }
      >
        <Box p={2} mb={16} maxWidth="100vw">
          <Box sx={{ justifyContent: "space-between" }}>
            <Stack direction="column">
              <Typography
                variant="h5"
                pb={2}
              >{`ออมดาวน์: ${downSavingQuery.data?.contractReferenceId}`}</Typography>
            </Stack>
          </Box>
          <Paper elevation={2} sx={{ backgroundColor: "white" }}>
            <Tabs
              variant="scrollable"
              value={selectedTab}
              onChange={onTabChange}
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="ข้อมูลออมดาวน์" />
              <Tab label="ประวัติการออม" />
              <Tab label="ประวัติการจ่ายเงินผ่าน Payment Gateway" />
              <Tab label="ไฟล์แนบ" />
            </Tabs>
          </Paper>

          <TabPanel value={selectedTab} index={0}>
            <Box sx={{ bgcolor: "white", width: "100%", p: 2 }}>
              <Stack direction={{ md: "column", lg: "row" }} gap={2}>
                <Card sx={{ width: "100%", border: "2px solid #eeeeee", p: 2 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <Typography variant="h5">ลูกค้า</Typography>
                      <Stack gap={2}>
                        <Typography
                          variant="h4"
                          sx={{ color: "#0b57d0" }}
                        >{`${downSavingQuery.data?.customerFirstName} ${downSavingQuery?.data?.customerLastName}`}</Typography>
                        <Stack>
                          {/* <Typography variant="h6">{`${downSavingQuery.data?.product.brand} ${installmentPlanQuery?.product.model}`}</Typography> */}
                          <Typography>{`เบอร์โทรศัพท์: ${
                            downSavingQuery?.data?.customerPhoneNumber || "-"
                          }`}</Typography>
                          <Typography>
                            หน้า Facebook:{" "}
                            <Link
                              href={
                                downSavingQuery?.data?.customerFacebookUrl || ""
                              }
                              target="_blank"
                            >
                              {downSavingQuery?.data?.customerFacebookUrl ||
                                "-"}
                            </Link>
                          </Typography>
                          <Typography>{`ไอดี LINE ${
                            downSavingQuery?.data?.customerLineId || "-"
                          }`}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
                <Card sx={{ width: "100%", border: "2px solid #eeeeee", p: 2 }}>
                  <Stack direction={"column"} gap={1}>
                    <Stack direction={{ md: "column", lg: "row" }} gap={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ alignSelf: "center", height: "56px" }}
                        onClick={() => {
                          setOpenTransactionDialog(true);
                        }}
                      >
                        บันทึกการออม
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ alignSelf: "center", height: "56px" }}
                        onClick={() => {
                          setOpenCreateQRDialog(true);
                        }}
                      >
                        สร้าง QR รับออม
                      </Button>
                      <Button
                        disabled={
                          downSavingQuery.data?.status !== "ACTIVE" ||
                          (downSavingQuery.data?.balanceAmount || 0) <
                            (downSavingQuery.data?.downAmount || 0)
                        }
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ alignSelf: "center", height: "56px" }}
                        onClick={() => {}}
                      >
                        {`เปิดสัญญาผ่อน ${
                          downSavingQuery.data?.status === "ACTIVE"
                            ? "(ยังไม่ถึงยอดดาวน์)"
                            : ""
                        }`}
                      </Button>
                    </Stack>

                    <Stack direction={{ md: "column", lg: "row" }} gap={1}>
                      <Button
                        disabled={downSavingQuery.data?.status !== "ACTIVE"}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        sx={{ alignSelf: "center", height: "56px" }}
                        onClick={() => {
                          setOpenWithdrawDialog(true);
                        }}
                      >
                        ถอนเงิน
                      </Button>
                      <Button
                        disabled={downSavingQuery.data?.status !== "ACTIVE"}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        sx={{ alignSelf: "center", height: "56px" }}
                        onClick={() => {
                          setOpenCancelDialog(true);
                        }}
                      >
                        ยกเลิก
                      </Button>
                      <Box width={"100%"} p={2}></Box>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Box>
            <Box sx={{ bgcolor: "white", width: "100%", px: 2 }}>
              <Stack direction={{ md: "column", lg: "row" }} gap={2}>
                <Card sx={{ width: "100%", border: "2px solid #eeeeee" }}>
                  <Stack
                    direction={{ sm: "column" }}
                    justifyContent="space-between"
                    gap={1}
                    sx={{ padding: 2 }}
                  >
                    <Stack direction={{ sm: "column", md: "row" }} gap={1}>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>สถานะ</Typography>
                        <DownSavingStatusChip
                          status={downSavingQuery.data?.status!!}
                        />
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>ยอดออมขั้นต่ำต่อครั้ง</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {currencyFormat.format(
                            downSavingQuery.data?.savingAmount || 0
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>ยอดสะสม</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {`${currencyFormat.format(
                            downSavingQuery.data?.balanceAmount || 0
                          )} (${(
                            ((downSavingQuery.data?.balanceAmount || 0) /
                              (downSavingQuery.data?.downAmount || 1)) *
                            100
                          ).toFixed(2)} %)`}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>ยอดดาวน์</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {currencyFormat.format(
                            downSavingQuery.data?.downAmount || 0
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} gap={1}>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>วันที่ครบกำหนด</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {dayjs(downSavingQuery.data?.nextDueDate).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>ครบกำหนดภายใน</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {`${downSavingQuery.data?.nextDueDateDiff} วัน`}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>ค่าปรับสะสม</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {currencyFormat.format(
                            downSavingTransactionsQuery.data?.reduce(
                              (acc, curr) => acc + (curr.fineAmount || 0),
                              0
                            ) || 0
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"column"}
                        gap={1}
                        sx={{
                          width: "100%",
                          border: "2px solid #eeeeee",
                          borderRadius: 3,
                          padding: 2,
                        }}
                      >
                        <Typography>จำนวนครั้งที่ออม</Typography>
                        <Typography variant="h5" sx={{ color: "#0b57d0" }}>
                          {downSavingTransactionsQuery.data?.length}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Box>
            <Box sx={{ bgcolor: "white", width: "100%", p: 2 }}>
              <Stack direction={{ md: "column", lg: "row" }} gap={2}>
                <Card
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    border: "2px solid #eeeeee",
                    p: 2,
                  }}
                >
                  <Stack direction={"column"} gap={1}>
                    <Stack
                      direction={{ md: "column", lg: "row" }}
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">โน้ต</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          alignSelf: "center",
                          height: "56px",
                          width: "200px",
                        }}
                        onClick={() => {
                          setOpenNoteDialog(true);
                        }}
                      >
                        เพิ่มโน้ต
                      </Button>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                      <Stack direction={"column"} gap={1}>
                        {noteQuery.data?.map((note) => (
                          <Stack direction={"row"} gap={1}>
                            <Typography>
                              {dayjs(note.createdAt).format("DD/MM/YYYY")}
                            </Typography>
                            <Typography>{note.note}</Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    border: "2px solid #eeeeee",
                    p: 2,
                  }}
                >
                  <Stack direction={"column"} gap={1}>
                    <Stack
                      direction={{ md: "column" }}
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">ข้อมูลอื่นๆ</Typography>
                      <Stack>
                        <CustomDataField label={"ลิ้งสำหรับลูกค้า"}>
                          <Stack direction={"row"} gap={1}>
                            <Typography>
                              {downSavingId
                                ? `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}/downSaving?downSavingId=${downSavingId}`
                                : "-"}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(
                                    `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}/downSaving?downSavingId=${downSavingId}`
                                  )
                                  .then(() => {
                                    toast.success("คัดลอกสำเร็จ");
                                  });
                              }}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </Stack>
                        </CustomDataField>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Box sx={{ bgcolor: "white", width: "100%", p: 2 }}>
              <DownSavingTransactionTable
                data={downSavingTransactionsQuery.data}
              />
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <PaperBackground>
              <PaymentHistory type={"DOWN_SAVING"} />
            </PaperBackground>
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Box sx={{ p: 2, bgcolor: "white" }}>
              <Stack gap={2}>
                <Box
                  {...getRootProps()}
                  sx={{
                    width: "100%",
                    border: "2px dashed darkgray",
                    borderRadius: "8px",
                    backgroundColor: "white",
                    padding: 4,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#b3e5fc",
                      color: "#03a9f4",
                      borderColor: "#03a9f4",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "120px",
                  }}
                >
                  <input {...getInputProps()} />
                  <Stack direction="column" alignItems="center" gap={1}>
                    <FileUploadIcon />
                    <Typography>อัพโหลดไฟล์</Typography>
                  </Stack>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Stack
                    sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                    direction={"row"}
                    gap={2}
                  >
                    {documentQuery.data?.map((doc) => (
                      <DocumentPreviewCard
                        document={doc}
                        uploaded={
                          fileUploadStatuses.find((f) => f.file === doc.id)
                            ?.uploaded || true
                        }
                        onDelete={() => {
                          documentDeleteMutation.mutate(doc.id || "");
                        }}
                        onDownload={() => {}}
                      />
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </TabPanel>

          <CreateDownSavingQRPaymentDialog
            shopId={shopId ?? ""}
            downSavingId={downSavingId || ""}
            open={openCreateQRDialog}
            onClose={() => setOpenCreateQRDialog(false)}
            title={undefined}
          />
          <DownSavingTransactionDepositDialog
            downSavingId={downSavingId || ""}
            downSavingAmount={downSavingQuery.data?.savingAmount || 0}
            open={openTransactionDialog}
            onClose={() => setOpenTransactionDialog(false)}
          />
          <DownSavingTransactionWithdrawDialog
            downSavingId={downSavingId || ""}
            balance={downSavingQuery.data?.balanceAmount || 0}
            open={openWithdrawDialog}
            onClose={() => setOpenWithdrawDialog(false)}
          />
          <DownSavingCancelDialog
            open={openCancelDialog}
            onClose={() => setOpenCancelDialog(false)}
            downSavingId={downSavingId || ""}
          />
          <NoteCreateDialog
            open={openNoteDialog}
            onClose={() => setOpenNoteDialog(false)}
            referenceId={downSavingId || ""}
            referenceType={DocumentDto.referenceType.DOWN_SAVING}
          />
        </Box>
      </Spin>
    </LocalizationProvider>
  );
};
