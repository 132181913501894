import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useCreateDeviceManagementApiKey } from "../../../client/query-client/devicemanagement/useCreateDeviceManagementApiKey";
import { useDeleteDeviceManagementApiKey } from "../../../client/query-client/devicemanagement/useDeleteDeviceManagementApiKey";
import { useViewMaskedDeviceManagementApiKey } from "../../../client/query-client/devicemanagement/useViewMaskedDeviceManagementApiKey";
import { useShopContext } from "../../../context/ShopContextProvider";

export function DeviceManagementScreen() {
  const { shopId } = useShopContext();
  const { mutateAsync: deleteApiKey, isPending: isDeleting } =
    useDeleteDeviceManagementApiKey();
  const { mutateAsync: createApiKey, isPending: isCreating } =
    useCreateDeviceManagementApiKey();
  const {
    data: maskedApiKeyResponse,
    isError,
    isLoading,
  } = useViewMaskedDeviceManagementApiKey(shopId);
  const [apiKey, setApiKey] = useState("");

  const handleCreateApiKey = async () => {
    await createApiKey({
      requestBody: {
        apiKey: apiKey,
        shopId: shopId ?? "",
      },
    });
    setApiKey("");
  };

  const handleDeleteApiKey = async () => {
    const result = window.confirm("ลบ API Key ใช่หรือไม่?");
    if (!result) return;
    await deleteApiKey({
      shopId: shopId ?? "",
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 2, mb: 8 }}>
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }

  if (isError && !maskedApiKeyResponse) {
    return (
      <Box sx={{ p: 2, mb: 8 }}>
        <Typography variant="h5">ไม่สามารถโหลด API Key ได้</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, mb: 8 }}>
      <Typography variant="h3" marginBottom="1rem">
        API Key
      </Typography>
      {maskedApiKeyResponse?.maskedApiKey ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card>
            <CardContent>
              <Typography variant="h6">
                <span style={{ color: "grey" }}>Key</span>{" "}
                {maskedApiKeyResponse.maskedApiKey}
              </Typography>
              <Typography variant="body2">
                Created At: {maskedApiKeyResponse.createdAt}
              </Typography>
              {maskedApiKeyResponse.createdByName && (
                <Typography variant="body2">
                  Created By: {maskedApiKeyResponse.createdByName}
                </Typography>
              )}
              <div style={{ height: "8px" }} />
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                <Link href={`/${shopId}/tracking`}>
                  <Button variant="contained" color="primary">
                    ไปที่หน้าติดตาม
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleDeleteApiKey}
                  disabled={isDeleting}
                >
                  Delete API Key
                </Button>
              </div>
            </CardContent>
          </Card>
          <Box
            style={{
              height: "16px",
            }}
          />
        </div>
      ) : (
        <Box>
          <TextField
            label="API Key"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            fullWidth
            required
          />
          <div style={{ height: "8px" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateApiKey}
            disabled={isCreating}
          >
            Create API Key
          </Button>
        </Box>
      )}
    </Box>
  );
}
