import { createBrowserRouter } from "react-router-dom";
import { ShopContextProvider } from "../context/ShopContextProvider";
import { ShopFinancierContextProvider } from "../context/ShopFinancierContextProvider";
import { BasicLayout } from "../layout/BasicLayout";
import { ShopFinancierLayout } from "../layout/ShopFinancierLayout";
import { ShopLayout } from "../layout/ShopLayout";
import { ComingSoonFeatureScreen } from "../screen/ComingSoonFeatureScreen";
import { HomeScreen } from "../screen/HomeScreen";
import { LocationTrackingScreen } from "../screen/LocationTrackingScreen";
import { BacklistSearchScreen } from "../screen/shop/BlacklistSearchScreen";
import { DownSavingCreateScreen } from "../screen/shop/downSaving/DownSavingCreateScreen";
import { DownSavingDetailScreen } from "../screen/shop/downSaving/DownSavingDetailScreen";
import { DownSavingScreen } from "../screen/shop/downSaving/DownSavingScreen";
import { InstallmentPlanDetailScreen } from "../screen/shop/installment/InstallmentPlanDetailScreen";
import { InstallmentPlanImportScreen } from "../screen/shop/installment/InstallmentPlanImportScreen";
import { InstallmentPlanListScreen } from "../screen/shop/installment/InstallmentPlanListScreen";
import { LoanApprovalScreen } from "../screen/shop/installment/LoanApprovalScreen";
import { LoanApproveListScreen } from "../screen/shop/installment/LoanApproveListScreen";
import { LoanCalculatorScreen } from "../screen/shop/installment/LoanCalculatorScreen";
import { LoanCreateListScreen } from "../screen/shop/installment/LoanCreateListScreen";
import { LoanCreateScreen } from "../screen/shop/installment/LoanCreateScreen";
import { CategoryListScreen } from "../screen/shop/product/CategoryListScreen";
import { CreateProductScreen } from "../screen/shop/product/CreateProductScreen";
import { EditInventoryScreen } from "../screen/shop/product/EditInventoryScreen";
import { EditProductScreen } from "../screen/shop/product/EditProductScreen";
import { InventoryListScreen } from "../screen/shop/product/InventoryListScreen";
import { ProductListScreen } from "../screen/shop/product/ProductListScreen";
import { PaymentGatewayScreen } from "../screen/shop/reports/PaymentGateway";
import { StatementsScreen } from "../screen/shop/reports/Statements";
import { DeviceManagementScreen } from "../screen/shop/settings/DeviceManagementScreen";
import { ShopCalculationSettingsScreen } from "../screen/shop/settings/ShopCalculationSettingScreen";
import { ShopDetailScreen } from "../screen/shop/settings/ShopDetailScreen";
import { ShopPaymentChannelScreen } from "../screen/shop/settings/ShopPaymentChannelScreen";
import { UserListScreen } from "../screen/shop/settings/UserListScreen";
import { ShopDashboardScreen } from "../screen/shop/ShopDashboardScreen";
import { ShopLandingScreen } from "../screen/shop/ShopLandingScreen";
import ShopFinancierContractCreate from "../screen/shopFinancier/contract/ShopFinancierContractCreate";
import ShopFinancierContractList from "../screen/shopFinancier/contract/ShopFinancierContractList";
import { ShopFinancierDetailScreen } from "../screen/shopFinancier/settings/ShopFinancierDetailScreen";
import { ShopFinancierPaymentChannelScreen } from "../screen/shopFinancier/settings/ShopFinancierPaymentChannelScreen";
import { UserShopFinancierListScreen } from "../screen/shopFinancier/settings/UserShopFinancierListScreen";
import { ShopFinancierDashboardScreen } from "../screen/shopFinancier/ShopFinancierDashboardScreen";
import ShopFinancierLandingScreen from "../screen/shopFinancier/ShopFinancierLandingScreen";
import { ShopRegisterScreen } from "../screen/ShopRegisterScreen";
import { ShopUnavailableScreen } from "../screen/ShopUnavailableScreen";
import { SignInScreen } from "../screen/SignInScreen";
import { SignUpScreen } from "../screen/SignUpScreen";
import { ProtectedRoute } from "./ProtectedRoute";
import { ShopSlipVerifyScreen } from "../screen/shop/slipVerify/ShopSlipVerifyScreen";

export const RouterDefinition = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute element={<BasicLayout />} />,
    children: [
      {
        path: "",
        element: <HomeScreen />,
      },
      {
        path: "register-shop",
        element: <ShopRegisterScreen />,
      },
    ],
  },
  {
    path: "sign-in",
    element: <SignInScreen />,
  },
  {
    path: "sign-up",
    element: <SignUpScreen />,
  },
  {
    path: "/:shopId",
    element: (
      <ProtectedRoute
        element={
          <ShopContextProvider>
            <ShopLayout />
          </ShopContextProvider>
        }
      />
    ),
    children: [
      {
        index: true,
        element: <ShopLandingScreen />,
      },
      {
        path: "dashboard",
        element: <ShopDashboardScreen />,
      },
      {
        path: "down-saving",
        element: <DownSavingScreen />,
      },
      {
        path: "down-saving/create",
        element: <DownSavingCreateScreen />,
      },
      {
        path: "down-saving/:downSavingId",
        element: <DownSavingDetailScreen />,
      },
      {
        path: "reports/statements",
        element: <StatementsScreen />,
      },
      {
        path: "reports/payment-gateway",
        element: <PaymentGatewayScreen />,
      },
      {
        path: "settings/shop",
        element: <ShopDetailScreen />,
      },
      {
        path: "settings/device-management",
        element: <DeviceManagementScreen />,
      },
      {
        path: "settings/payment-channel",
        element: <ShopPaymentChannelScreen />,
      },
      {
        path: "settings/calculation-settings",
        element: <ShopCalculationSettingsScreen />,
      },
      {
        path: "loan",
        element: <InstallmentPlanListScreen />,
      },
      {
        path: "loan-calculator",
        element: <LoanCalculatorScreen />,
      },
      {
        path: "loan/create-list",
        element: <LoanCreateListScreen />,
      },
      {
        path: "loan/import",
        element: <InstallmentPlanImportScreen />,
      },
      {
        path: "loan/create",
        element: <LoanCreateScreen />,
      },
      {
        path: "loan/approval-list",
        element: <LoanApproveListScreen />,
      },
      {
        path: "loan/approval",
        element: <LoanApprovalScreen />,
      },
      {
        path: "loan/:installmentPlanId",
        element: <InstallmentPlanDetailScreen />,
      },
      {
        path: "pos/create-product",
        element: <CreateProductScreen />,
      },
      {
        path: "pos/product-list",
        element: <ProductListScreen />,
      },
      {
        path: "pos/edit-product/:productId",
        element: <EditProductScreen />,
      },
      {
        path: "pos/category-list",
        element: <CategoryListScreen />,
      },
      {
        path: "pos/inventory-list",
        element: <InventoryListScreen />,
      },
      {
        path: "pos/edit-inventory/:inventoryId",
        element: <EditInventoryScreen />,
      },
      {
        path: "blacklist",
        element: <BacklistSearchScreen />,
      },
      {
        path: "settings/user",
        element: <UserListScreen />,
      },
      {
        path: "coming-soon",
        element: <ComingSoonFeatureScreen />,
      },
      {
        path: "unavailable",
        element: <ShopUnavailableScreen />,
      },
      {
        path: "tracking",
        element: <LocationTrackingScreen />,
      },
      {
        path: "verify-slip",
        element: <ShopSlipVerifyScreen/>,
      },
    ],
  },
  {
    path: "/shop-finance/:shopId",
    element: (
      <ProtectedRoute
        element={
          <ShopFinancierContextProvider>
            <ShopFinancierLayout />
          </ShopFinancierContextProvider>
        }
      />
    ),
    children: [
      {
        index: true,
        element: <ShopFinancierLandingScreen />,
      },
      {
        path: "dashboard",
        element: <ShopFinancierDashboardScreen />,
      },
      {
        path: "contract",
        element: <ShopFinancierContractList />,
      },
      {
        path: "contract/create",
        element: <ShopFinancierContractCreate />,
      },
      {
        path: "settings/shop",
        element: <ShopFinancierDetailScreen />,
      },
      {
        path: "settings/payment-channel",
        element: <ShopFinancierPaymentChannelScreen />,
      },

      {
        path: "settings/user",
        element: <UserShopFinancierListScreen />,
      },
      {
        path: "coming-soon",
        element: <ComingSoonFeatureScreen />,
      },
      {
        path: "unavailable",
        element: <ShopUnavailableScreen />,
      },
    ],
  },
]);
