import { Box } from "@mui/material";

interface IProps {
  condition: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const DisplayIf: React.FC<IProps> = ({ condition, children }): JSX.Element => {
  return condition ? (
    <Box sx={{ display: "block" }}>{children}</Box>
  ) : (
    <Box></Box>
  );
};

export default DisplayIf;
